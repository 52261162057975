<template>
    <GetStartedLayout
        ref="layout"
        :errors="errors"
        :title="currentStep?.title"
        :subtitle="currentStep?.subtitle"
        :guest="guest"
        :breadcrumbs="breadcrumbs"
        :review="review"
        fluid
        ><template #toggleFilter>
            <!--<v-btn
                v-if="!stepNumber && !shoppingCartItems"
                text
                rounded
                small
                color="white"
                class="text-capitalize"
                @click="
                    $inertia.reload({
                        data: { showAll: Number(!showAll) },
                    })
                "
                >{{
                    showAll ? "Recommended program" : "See all programs"
                }}
                >></v-btn
            >-->
            <v-btn
                v-if="shoppingCartItems"
                icon
                outlined
                color="white"
                @click="$inertia.visit(route('checkout.show'))"
                ><v-badge
                    :content="shoppingCartItems"
                    color="secondary"
                    overlap
                    bordered
                >
                    <v-icon class="white--text">shopping_cart</v-icon></v-badge
                ></v-btn
            >
        </template>
        <template #titleAction>
            <v-btn-toggle
                v-if="stepNumber == 0"
                v-model="membershipView"
                mandatory
                class="soli-round mt-6"
                color="accent"
            >
                <v-btn small value="privateLessons">Private Lessons</v-btn>
                <v-btn small value="groupClasses">Group Learning</v-btn>
            </v-btn-toggle>
        </template>
        <v-window
            v-model="stepNumber"
            class="elevation-0 w-full pa-0 flex justify-center"
            style="background: transparent"
            touchless
        >
            <div v-for="step in stepData" :key="step.name">
                <v-window-item
                    :step="stepData.indexOf(step)"
                    class="custom-stepper"
                    style="overflow: visible; min-height: 238px"
                >
                    <component
                        v-if="currentStep.name == step.name"
                        :key="step.name"
                        :is="step.comp"
                        v-bind="{ ...step.props() }"
                        v-on="step.on"
                        v-model="form[step.name]"
                        :class="step.classes"
                    ></component>
                </v-window-item>
            </div>
        </v-window>

        <WindowMarker
            v-model="stepNumber"
            :length="stepData.length"
            class="mt-1"
            @input="clearFutureSteps($event)"
        />

        <SoliResponsiveDialog
            v-if="showHelp"
            fullscreen
            bottomSheetOnly
            @reset="closeModal(() => (showHelp = false))"
        >
            <template #body> <component :is="showHelp.comp" /></template>
        </SoliResponsiveDialog>
    </GetStartedLayout>
</template>

<script>
import GetStartedLayout from "../../Layouts/GetStartedLayout.vue";
import { Link } from "@inertiajs/vue2";
import ShortContactForm from "../../Components/GetStarted/ShortContactForm.vue";
import WindowMarker from "./WindowMarker.vue";
import FoundersClubCard from "../../Components/GetStarted/FoundersClubCard.vue";
import FoundersClubCards from "../../Components/GetStarted/FoundersClubCards.vue";
import CreateGuestRelationship from "../../Components/GetStarted/CreateGuestRelationship.vue";
import PrivateLessons from "../../Components/GetStarted/AboutPrograms/PrivateLessons.vue";
import AcceleratedLabs from "../../Components/GetStarted/AboutPrograms/AcceleratedLabs.vue";
import RockStars from "../../Components/GetStarted/AboutPrograms/RockStars.vue";
import MusicologieJunior from "../../Components/GetStarted/AboutPrograms/MusicologieJunior.vue";

export default {
    components: {
        GetStartedLayout,
        Link,
        ShortContactForm,
        WindowMarker,
        FoundersClubCard,
        FoundersClubCards,
        CreateGuestRelationship,
        PrivateLessons,
        AcceleratedLabs,
        RockStars,
        MusicologieJunior,
    },
    props: {
        errors: {},
        guest: {
            type: Object,
            default: () => {},
        },
        recipient: {
            type: Object,
            default: () => {},
        },
        recommendation: {
            type: Object,
            default: () => {},
        },
        rockStars: {
            type: Object,
            default: () => {},
        },
        review: {
            type: Object,
            default: null,
        },
        shoppingCartItems: {
            type: Number,
            default: 0,
        },
        relationships: {
            type: Array,
            default: () => [],
        },
        allMemberships: {
            type: Array,
            default: () => [],
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    name: "FoundersClub",
    data() {
        return {
            breadcrumbs: ["Founders Club"],
            showHelp: false,
            // if I use the form and stepData titles to control steps, then I need to need to emit something besides input or find a workaround for v-model
            form: this.$inertia.form({
                priceIds: null,
                membership: null,
                rockStars: null,
                addStudent: null,
            }),
            membershipView: this.recommendation.membershipView,
            stepNumber: 0,
            selectedMembership: null,
            stepData: [
                {
                    name: "membership",
                    step: 0,
                    title: `Choose ${
                        this.recipient.id == this.guest.id
                            ? "Your"
                            : `${this.recipient.first_name}'s`
                    } <wbr>Founders Membership`,
                    /*subtitle: `Recommended for ${
                        this.recipient.id == this.guest.id
                            ? "you"
                            : this.recipient.first_name
                    }: ${this.recommendation.program}`,*/
                    comp: "FoundersClubCards",
                    on: {
                        input: (value) => {
                            this.form.priceIds = [value.price_id];
                            this.selectedMembership = this.allMemberships.find(
                                (e) => e.id == value.id
                            );

                            if (!this.rockStars) this.submit();

                            this.nextStep();
                        },
                        learnMore: (value) => {
                            this.showHelp = value;
                        },
                    },
                    props: () => ({
                        memberships: this.allMemberships
                            .filter(
                                (e) => e.membershipView == this.membershipView
                            )
                            .sort((a, b) => {
                                if (this.$vuetify.breakpoint.mdAndUp) return;
                                return a.id == this.recommendation.id ? -1 : 1;
                            }),
                        button1: "Continue",
                        heading: "Founders",
                        recommendation: this.recommendation.id,
                    }),
                },
                {
                    name: "rockStars",
                    step: 1,
                    title: "More offers",
                    subtitle: `Recommended for ${
                        this.recipient.id == this.guest.id
                            ? "you"
                            : this.recipient.first_name
                    }`,
                    comp: "FoundersClubCard",
                    classes: "px-4",
                    hidden: !this.rockStars,
                    on: {
                        input: (value) => {
                            if (value) {
                                this.form.priceIds.push(value.price_id);
                                this.form.rockStars = true;
                            }
                            this.submit();
                        },
                        learnMore: (value) => {
                            this.showHelp = value;
                        },
                    },
                    props: () => ({
                        membership: this.rockStars,
                        button1: "Yes Please!",
                        button2: "No Thanks",
                        heading: "Add",
                        title: "RockStars?",
                        description: this.rockStars.description,
                        image: this.rockStars.image,
                    }),
                },
                {
                    name: "addStudent",
                    step: 2 - Number(!this.rockStars),
                    title: "One More Step!",
                    subtitle: "Would you like to register another student?",
                    comp: "CreateGuestRelationship",
                    on: {
                        input: () => this.nextStep(),
                    },
                    props: () => ({
                        guest: this.guest,
                        user: this.$page.props.auth.user,
                        relationships: this.relationships,
                        errors: this.errors,
                    }),
                },
            ].filter((e) => !e.hidden),
        };
    },
    computed: {
        currentStep() {
            return this.stepData[this.stepNumber];
        },
    },
    methods: {
        nextStep() {
            this.stepNumber = Object.values(this.stepData).find((e) => {
                return this.form[e.name] == null;
            }).step;

            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        },
        clearFutureSteps(step) {
            this.form.priceIds.splice(this.form.priceIds - step);

            this.stepData.forEach((e) => {
                if (e.step >= step) this.form[e.name] = null;
            });
        },
        submit() {
            this.form
                .transform((data) => ({
                    priceIds: data.priceIds,
                    recipientId: this.recipient.id,
                }))
                .post(route("shoppingCart.add"), {
                    preserveState: true,
                    preserveScroll: false,
                    onSuccess: () => {
                        this.$refs.layout.showMessage = true;
                        this.nextStep();
                    },
                    onError: () => {
                        console.log(
                            "Form returned errors: " +
                                JSON.stringify(this.errors)
                        );
                    },
                });
        },
    },
};
</script>

<style>
.description-content ul {
    list-style: disc;
}
</style>
