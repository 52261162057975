<template>
    <v-footer style="background: #f9f9f9" height="204">
        <span id="help-fab">
            <SoliBtn
                @click="showQuickContact = true"
                class="shadow-orange-xl help-fab"
                v-if="!hideFab"
                icon="question_mark"
                round
                gradient="gradient-orange"
                fab
                right
                :fixed="fixedFab"
                :bottom="fixedFab"
                :absolute="!fixedFab"
                :top="!fixedFab"
            />
            <SoliResponsiveDialog
                v-if="showQuickContact"
                @reset="closeModal(() => (showQuickContact = false))"
                title="Contact Us!"
            >
                <template #body>
                    <v-card class="soli-round" flat>
                        <SoliLocationExpandedContent
                            v-if="guest"
                            class="mt-2"
                            :location="guest.location"
                            :value="guest.location"
                            :icon="false"
                        />
                        <SoliLocations
                            v-else
                            :parentLocations="locations"
                            showAddress
                            iterator
                            expandable
                        /> </v-card
                ></template>
            </SoliResponsiveDialog> </span
        ><v-container class="justify-center safe-bottom">
            <v-col>
                <div class="subtitle flex-col flex soli-grey">
                    <a href="https://musicologielessons.com" class="mb-1">
                        <jet-application-logo
                            class="block w-auto"
                            :class="$vuetify.breakpoint.xs ? 'h-6' : 'h-9'"
                        />
                        <small class="soli-grey">
                            Simply Delightful Music Lessons
                        </small></a
                    >
                    <a
                        v-if="$page.props.auth.user"
                        color="primary"
                        class="soli-round"
                        href="/"
                    >
                        Home</a
                    >
                    <a
                        color="primary"
                        class="soli-round"
                        href="/privacy"
                        :class="{
                            'accent--text': $page.url.includes('/privacy'),
                        }"
                    >
                        Privacy</a
                    >
                    <a
                        color="primary"
                        class="soli-round"
                        href="/about"
                        :class="{
                            'accent--text': $page.url.includes('/about'),
                        }"
                    >
                        About MyMusicologie Software</a
                    >
                    <a
                        v-if="!$page.props.auth.user"
                        color="primary"
                        class="soli-round"
                        href="/get-started"
                        :class="{
                            'accent--text': $page.url.includes('/get-started'),
                        }"
                    >
                        Get Started</a
                    >
                </div></v-col
            ></v-container
        >
    </v-footer>
</template>

<script>
import JetApplicationLogo from "../../Jetstream/ApplicationLogo.vue";

export default {
    props: {
        hideFab: {
            type: Boolean,
            default: false,
        },
        guest: {
            type: Object,
            default: null,
        },
        locations: {
            type: Array,
            default: () => [],
        },
        fixedFab: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        JetApplicationLogo,
    },
    data() {
        return {
            showQuickContact: false,
        };
    },
};
</script>

<style>
.help-fab {
    margin-bottom: 12px;
    z-index: 12;
    margin-right: env(safe-area-inset-right) !important;
    bottom: env(safe-area-inset-bottom, 12px);
}
</style>
