<template>
    <component
        :is="componentStyle.comp"
        v-bind="{ ...componentStyle.props }"
        v-on="componentStyle.on"
        v-model="show"
    >
        <v-card
            id="soli-responsive-dialog"
            flat
            :light="theme == 'light'"
            :dark="theme == 'dark'"
            :max-height="fullscreen ? '90vh' : size * 1.5"
            :class="[
                { 'mobile-scroll': $vuetify.breakpoint.xs && mobileScroll },
                color,
            ]"
            class="half-round safe-bottom"
            style="overflow: auto"
        >
            <v-container
                :class="{
                    'pt-0': !!title || $vuetify.breakpoint.xs,
                    'pa-0': hideContainer,
                }"
            >
                <div>
                    <v-btn
                        icon
                        v-if="close"
                        @click="show = false"
                        class="ma-3 soli-close"
                        ><v-icon>clear</v-icon></v-btn
                    >
                    <v-card-title
                        v-if="title"
                        :class="[
                            theme == 'light' ? 'soli-grey' : 'white--text',
                            ...titleClasses,
                        ]"
                        >{{ title }}</v-card-title
                    >
                    <v-card-subtitle
                        v-if="subtitle || $slots.subtitle"
                        :class="subtitleClasses"
                        ><slot name="subtitle"></slot
                        >{{ subtitle }}</v-card-subtitle
                    >
                </div>

                <slot name="body"></slot>
            </v-container>
            <slot name="cover"></slot>
        </v-card>
    </component>
</template>

<script>
import { VDialog, VBottomSheet, VMenu } from "vuetify/lib";

export default {
    components: {
        VDialog,
        VBottomSheet,
        VMenu,
    },
    props: {
        theme: {
            type: String,
            default: "light",
        },
        large: {
            type: Boolean,
            default: false,
        },
        size: {
            type: [String, Number],
            default: 450,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: null,
        },
        titleClasses: {
            type: Array,
            default: () => [],
        },
        subtitle: {
            type: String,
            default: null,
        },
        subtitleClasses: {
            type: Array,
            default: () => [],
        },
        bottomSheetOnly: {
            type: Boolean,
            default: false,
        },
        mobileScroll: {
            type: Boolean,
            default: true,
        },
        menu: {
            type: Boolean,
            default: false,
        },
        hideContainer: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "white",
        },
    },
    data() {
        return {
            show: true,
        };
    },
    computed: {
        componentStyle() {
            let mobileDevice =
                this.$vuetify.breakpoint.xs || this.bottomSheetOnly;
            return {
                comp: mobileDevice
                    ? "v-bottom-sheet"
                    : this.menu
                    ? "v-menu"
                    : "v-dialog",
                props: mobileDevice
                    ? {
                          persistent: this.persistent,
                          class: "soli-round mx-1",
                          "content-class":
                              "half-round overflow-hidden safe-bottom white flex flex-column",
                      }
                    : {
                          "max-width": this.fullscreen ? null : this.size,
                          activator: this.menu ? ".soli-resp-menu" : null,
                          "offset-y": this.menu,
                          left: this.menu,
                          "content-class": "soli-round",
                          transition: this.$vuetify.breakpoint.smAndDown
                              ? "dialog-bottom-transition"
                              : "dialog-transition",
                          persistent: this.persistent,
                      },
            };
        },
    },
    watch: {
        show(val) {
            if (!val) this.closeModal(() => this.$emit("reset"));
        },
    },
};
</script>

<style scoped>
.v-dialog__content--active {
    backdrop-filter: blur(1px);
}
</style>
