<template>
    <v-row>
        <v-col :class="$vuetify.breakpoint.xs ? 'py-1' : null">
            <v-item-group
                :value="value"
                @change="$emit('input', $event)"
                class="text-center"
                mandatory
            >
                <v-item
                    v-for="(n, idx) in length"
                    :key="n.name"
                    v-slot="{ active, toggle }"
                >
                    <span>
                        <v-btn
                            :input-value="active"
                            icon
                            @click="toggle"
                            :disabled="idx > value"
                        >
                            <div
                                class="flex justify-center align-center window-marker"
                            >
                                <small class="white--text">{{ idx + 1 }}</small>
                            </div>
                        </v-btn>
                    </span>
                </v-item>
            </v-item-group>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        length: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>

<style>
.window-marker {
    max-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.3);
}

.v-btn--disabled .window-marker {
    background: rgba(0, 0, 0, 0.1) !important;
}
</style>
