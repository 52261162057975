<template>
    <div>
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="list-item, list-item, list-item"
            v-if="apiLoading"
        ></v-skeleton-loader>
        <div
            v-if="!apiLoading && !apiUpcomingGroupEvents.length"
            class="soli-grey px-10"
        >
            Sorry, there are no classes available at this time
        </div>
        <v-timeline dense v-if="apiUpcomingGroupEvents.length">
            <v-timeline-item
                v-for="groupEvent in apiUpcomingGroupEvents"
                :key="groupEvent.id"
                fill-dot
                class="mb-5"
            >
                <template v-slot:icon>
                    <small class="white--text">{{
                        globalMomentWeekDayShort(groupEvent.starts_at)
                    }}</small>
                </template>
                <div>
                    <v-card
                        class="row soli-round"
                        @click="$emit('input', groupEvent)"
                    >
                        <v-col>
                            <div>
                                <small>
                                    {{
                                        globalMomentDateDots(
                                            groupEvent.starts_at
                                        )
                                    }}
                                    ·
                                    {{
                                        `${globalMomentTime(
                                            groupEvent.starts_at
                                        )}`
                                    }}</small
                                >
                            </div>
                            <div>
                                <strong>{{
                                    groupEvent.group_event.title
                                }}</strong>
                            </div>
                            <div>
                                <div class="text-caption">
                                    w/
                                    {{ groupEvent.teacher.name }}
                                    ·
                                    {{ groupEvent.location.name }}
                                </div>
                            </div>
                        </v-col>
                    </v-card>
                </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>
<script>
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        eventId: {
            type: Number,
            default: null,
        },
        studentId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            apiUpcomingGroupEvents: [],
            apiLoading: false,
        };
    },
    methods: {
        getApiUpcomingGroupEvents() {
            if (!this.eventId || !this.studentId) return;
            this.apiLoading = true;
            axios
                .get("/api/lessons/upcoming-group-events", {
                    params: {
                        eventId: this.eventId,
                        student_id: this.studentId,
                        nearMe: this.$page.props.auth.user.selected_location
                            .emma_account_id,
                        calendarMax: moment().add(2, "weeks"),
                    },
                })
                .then((resp) => {
                    this.apiUpcomingGroupEvents = resp.data;
                    this.apiLoading = false;
                })
                .catch((error) => {
                    this.axiosError = error;
                });
        },
    },
    mounted() {
        this.getApiUpcomingGroupEvents();
    },
};
</script>

<style scoped>
.limit-height {
    max-height: 500px;
    overflow: auto;
}

.limit-scroll {
    max-height: 500px;
    min-height: 500px;
    overflow-y: hidden;
    transition: min-height 0.3s ease-out;
}

.shrink-limit-scroll {
    min-height: 100px;
    transition: min-height 0.3s ease-out;
}
</style>
