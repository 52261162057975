<template>
    <v-menu
        left
        :close-on-content-click="false"
        content-class="white"
        v-model="menu"
        :disabled="
            true /*purchaseOption.name !== 'Assessment 30' || !can('Override attendance')*/
        "
    >
        <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" small class="pl-0 pr-1" outlined>
                <div :class="['align-center justify-start flex mr-1']">
                    <v-list-item-avatar
                        :class="[getStatus(purchaseOption).color, 'mx-0']"
                        ><div class="white--text font-weight-bold">
                            {{ getStatus(purchaseOption).title }}
                        </div>
                    </v-list-item-avatar>
                </div>
                <div class="soli-grey caption">{{ purchaseOption.name }}</div>
            </v-chip>
        </template>
        <v-skeleton-loader
            class="mx-auto"
            type="list-item"
            v-if="apiLoading"
        ></v-skeleton-loader>
        <v-list v-if="!apiLoading">
            <v-list-item
                v-for="option in otherOptions"
                :key="option.id"
                :class="[
                    'soli-round',
                    purchaseOption.id == option.id ? 'soli-status-blue' : null,
                ]"
                @click="newOption = option"
                :disabled="is('Admin') ? false : !option.paid"
            >
                <div :class="['align-center justify-start flex mr-3']">
                    <v-list-item-avatar
                        :class="[getStatus(option).color, 'mx-0']"
                        ><div class="white--text font-weight-bold">
                            {{ getStatus(option).title }}
                        </div>
                    </v-list-item-avatar>
                </div>
                <div
                    class="caption"
                    :class="{
                        'white--text': purchaseOption.id == option.id,
                        'soli-grey': option.paid,
                    }"
                >
                    {{ option.name }}
                </div>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import globalMoment from "../../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        attendance: {
            type: Object,
            default: () => {},
        },
        purchaseOption: {
            type: Object,
            default: null,
        },
        size: {
            type: [Number, String],
            default: 48,
        },
    },
    data() {
        return {
            menu: false,
            otherOptions: null,
            newOption: null,
            apiLoading: false,
            typeMap: {
                Membership: {
                    title: "M",
                    color: "soli-status-blue",
                },
                "First Visit": {
                    title: "1st",
                    color: "soli-status-green",
                },
                "Drop-In": {
                    title: "P",
                    color: "soli-status-purple",
                },
                Assessment: {
                    title: "A",
                    color: "soli-status-green",
                },
                Event: {
                    title: "P",
                    color: "soli-status-group",
                },
            },
        };
    },
    methods: {
        getPaymentStatus(option) {
            // handle event in series
            if (!option) return "Error";

            if (option.ends_at) {
                if (
                    this.attendance.rescheduled_to ||
                    this.attendance.status == "Cancel-credit"
                )
                    return "Cancelled";
                return option.ends_at >
                    // if this.event is a lesson, compare to lesson date, otherwise compare to now
                    (this.attendance?.id
                        ? this.globalMoment(this.attendance.lesson.starts_at)
                        : this.globalMoment())
                    ? "Cancelled"
                    : "Unpaid";
            }

            if (option.category == "memberships") {
                return option.subPaid ? "Paid" : "Unpaid";
            }

            return option.paid ? "Paid" : "Unpaid";
        },
        statusMap(option) {
            return {
                Error: {
                    color: "soli-status-red",
                    title: "Contact Admin",
                },
                Unpaid: {
                    color: "soli-status-red",
                    title: "$",
                },
                Cancelled: {
                    color: "soli-status-grey",
                    title: "X",
                },
                Paid: {
                    color: this.typeMap[this.getType(option)].color,
                    title: this.typeMap[this.getType(option)].title,
                },
            };
        },
        getType(option) {
            if (option.category == "memberships") {
                return "Membership";
            }
            if (option.name.includes("First")) {
                return "First Visit";
            }
            if (this.attendance.lesson.group_event?.id) return "Event";
            return option.access;
        },
        getStatus(option) {
            if (!option) return this.statusMap(option).errorStatus;
            return (
                this.statusMap(option)[this.getPaymentStatus(option)] ??
                this.statusMap(option).errorStatus
            );
        },
        getOptions() {
            this.apiLoading = true;

            axios
                .get(
                    `/api/purchase-option/${this.purchaseOption.id}/other-options`
                )
                .then((resp) => {
                    this.otherOptions = resp.data;
                    this.apiLoading = false;
                });
        },
        updateOption(option) {
            this.$inertia.put(
                `/lesson-users/${this.attendance.id}`,
                { option: option.id },
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit("success");
                        this.menu = false;
                    },
                    onError: (errors) => {
                        console.log(errors);
                        this.$emit("reset");
                        this.menu = false;
                    },
                }
            );
        },
    },
    watch: {
        menu(value) {
            if (value) {
                this.getOptions();
            }
        },
        newOption(option) {
            this.updateOption(option);
        },
    },
};
</script>

<style scoped>
.v-chip .v-avatar {
    height: 22px !important;
    width: 22px !important;
    min-width: 22px !important;
}
</style>
