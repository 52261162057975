<template>
    <div>
        <v-skeleton-loader
            type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
            class="mb-6"
            boilerplate
            v-if="!relationships"
        ></v-skeleton-loader>
        <v-expansion-panels
            accordian
            flat
            v-model="panel"
            @change="$emit('change')"
        >
            <v-expansion-panel
                v-for="(item, index) in /*user.family*/ relationships || []"
                :key="item.id"
                :class="['mb-2 mt-0', `panel-${index}`]"
            >
                <v-expansion-panel-header
                    expand-icon="keyboard_arrow_down"
                    focusable
                    :class="['soli-round mb-2 pa-0']"
                    v-if="user.id !== item.id"
                >
                    <v-row :class="['align-center']">
                        <v-col class="col-3 flex justify-center">
                            <v-avatar size="55"
                                ><v-img :src="item.profile_photo_url"></v-img
                            ></v-avatar>
                        </v-col>
                        <v-col class="pl-0">
                            <v-list class="mx-2">
                                <div class="caption soli-grey">
                                    {{
                                        //user.family
                                        relationships
                                            ? item.pivot.user_id !== user.id
                                                ? item.pivot.relation
                                                : item.pivot.inverse
                                            : null
                                        /*: item.email && !item.loginable
                      ? "Additional Email"
                      : "Child Account"*/
                                    }}
                                </div>
                                <div
                                    :class="[
                                        'soli-grey',
                                        $vuetify.breakpoint.xsOnly
                                            ? 'body-2'
                                            : 'body-1',
                                    ]"
                                >
                                    {{ item.name }}
                                </div>
                                <div class="caption soli-grey">
                                    {{ item.email }}
                                </div>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="justify-around align-center py-5">
                        <v-btn
                            icon
                            small
                            color="dark"
                            @click="selectedRelationship = item"
                        >
                            <v-icon>edit</v-icon>
                            <small class="mt-1 panel-text">Edit</small>
                        </v-btn>
                        <v-btn
                            icon
                            small
                            color="dark"
                            @click="confirmRemoveRelationship = item"
                            :disabled="
                                paidByUser(item) ||
                                !can('Manage user relationships') ||
                                (!user.email &&
                                    relationships.filter((e) => e.email)
                                        .length <= 1)
                            "
                        >
                            <v-icon>delete_outline</v-icon>
                            <small class="mt-1 panel-text">Remove</small>
                        </v-btn>
                        <Link
                            :href="//user.family
                            //  ? `/students/${user.family_id}/#account`
                            //  :
                            `/profile/${item.id}/account`"
                        >
                            <v-btn icon small color="dark">
                                <v-icon>account_circle</v-icon>
                                <small
                                    class="mt-1 panel-text"
                                    style="white-space: normal"
                                    >Profile</small
                                >
                            </v-btn></Link
                        >
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <SoliChoice
            v-if="confirmRemoveRelationship"
            :title="paysForUser ? 'Error' : 'Remove Relationship?'"
            :subtitle="
                paysForUser
                    ? `This relationship is unable to be removed. ${confirmRemoveRelationship.first_name} has an active pass or membership that is paid for by this account.`
                    : `This can't be undone`
            "
            @option1="
                paysForUser
                    ? null
                    : removeRelationship(confirmRemoveRelationship)
            "
            :button1="paysForUser ? 'Nevermind' : 'Delete'"
            :button2="paysForUser ? null : 'Nevermind'"
            @reset="confirmRemoveRelationship = false"
        ></SoliChoice>
        <ManageRelationships
            v-if="selectedRelationship"
            :relationships="relationships || null"
            :relationship="selectedRelationship"
            :user="user"
            @success="
                [
                    $emit('success'),
                    closeModal(() => (selectedRelationship = null)),
                ]
            "
            @reset="selectedRelationship = null"
        ></ManageRelationships>
    </div>
</template>

<script>
import ManageRelationships from "./ManageRelationships";
import { Link } from "@inertiajs/vue2";

export default {
    props: ["user", "errors", "relationships", "sharedSubscriptions"],
    components: {
        ManageRelationships,
        Link,
    },
    data: function () {
        return {
            panel: false,
            confirmRemoveRelationship: false,
            selectedRelationship: null,
        };
    },
    methods: {
        paidByUser(item) {
            if (!this.sharedSubscriptions) return false;
            return this.sharedSubscriptions.find((e) => item.id == e.user_id)
                ? true
                : false;
        },
        removeRelationship(relation) {
            this.$inertia.delete(
                route("relationUsers.destroy", relation.pivot.id),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit("success");
                    },
                    onFailure: (errors) => {
                        console.log(
                            "This form returned errors:" +
                                JSON.stringify(errors)
                        );
                    },
                }
            );
        },
    },
    computed: {
        paysForUser() {
            let item = this.confirmRemoveRelationship;
            if (!this.sharedSubscriptions) return false;

            return this.sharedSubscriptions.find(
                (e) => item.id == e.recipient.id && !e.void
            )
                ? true
                : false;
        },
    },
};
</script>

<style scoped>
.v-list {
    color: unset !important;
}
</style>
