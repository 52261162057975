<template>
    <GetStartedLayout
        :errors="errors"
        :title="`Hi ${guest.first_name}!`"
        :subtitle="`Recommended for ${child?.first_name ?? 'you'}`"
        fluid
        :guest="guest"
        :child="child"
        :breadcrumbs="breadcrumbs"
        :review="review"
        :location="guest.location"
    >
        <template #toggleFilter>
            <v-btn
                outlined
                class="soli-round"
                small
                color="white"
                @click="showAll = !showAll"
                >{{ showAll ? "All" : "Recommended"
                }}<v-icon v-if="$vuetify.breakpoint.width > 400" right
                    >filter_list</v-icon
                ></v-btn
            ></template
        >

        <v-col
            cols="12"
            sm="8"
            md="4"
            lg="3"
            style="min-width: 336px; max-width: 378px"
            class="flex justify-center"
            v-for="card in getProgramCards"
            :key="card.title"
        >
            <v-hover v-slot="{ hover }">
                <v-card
                    class="soli-round"
                    hover
                    light
                    style="border: none"
                    :elevation="hover ? 12 : 2"
                    :class="{
                        'on-hover': hover,
                        'mx-3': $vuetify.breakpoint.smAndUp,
                        'mx-auto': $vuetify.breakpoint.xs,
                        notRecommended: !card.recommended,
                    }"
                    @click="proceed(card)"
                >
                    <v-img
                        height="300"
                        :src="card.src"
                        :lazy-src="card.lazy"
                        class="align-end"
                        gradient="to bottom, rgb(255 255 255 / 0%), rgba(25, 73, 114, .75)"
                    >
                        <!-- <v-icon
                            v-if="card.recommended()"
                            color="white"
                            x-large
                            class="float-right pa-5"
                            >recommend</v-icon
                        >-->
                        <v-card-title
                            class="white--text font-weight-bold text-h5"
                            >{{ card.title }}</v-card-title
                        >
                    </v-img>
                    <!--<v-card-subtitle
                        class="px-6 pb-0 font-weight-bold"
                        v-if="card.recommended()"
                        >Recommended for you!</v-card-subtitle
                    >-->

                    <v-card-actions class="flex justify-end ma-2">
                        <v-btn
                            @click.stop="help(card)"
                            class="soli-round"
                            text
                            small
                            color="dark"
                            >How It Works</v-btn
                        >
                        <v-btn class="soli-round px-2" outlined color="primary"
                            >Book Now</v-btn
                        >
                    </v-card-actions>
                </v-card></v-hover
            >
        </v-col>
        <SoliResponsiveDialog
            v-if="showHelp"
            fullscreen
            bottomSheetOnly
            @reset="closeModal(() => (showHelp = false))"
        >
            <template #body>
                <component :is="showHelp.comp" :location="guest.location"
            /></template>
        </SoliResponsiveDialog>
        <ShortContactForm
            ref="shortContactForm"
            v-if="showProgramInquiry"
            :programInquiry="showProgramInquiry"
            :guest="guest"
            :child="child"
            :errors="errors"
            @submit="closeModal(() => (showProgramInquiry = false))"
            @reset="closeModal(() => (showProgramInquiry = false))"
        ></ShortContactForm>
    </GetStartedLayout>
</template>

<script>
import GetStartedLayout from "../../Layouts/GetStartedLayout.vue";
import { Link } from "@inertiajs/vue2";
import PrivateLessons from "../../Components/GetStarted/AboutPrograms/PrivateLessons.vue";
import AcceleratedLabs from "../../Components/GetStarted/AboutPrograms/AcceleratedLabs.vue";
import RockStars from "../../Components/GetStarted/AboutPrograms/RockStars.vue";
import MusicologieJunior from "../../Components/GetStarted/AboutPrograms/MusicologieJunior.vue";
import ShortContactForm from "../../Components/GetStarted/ShortContactForm.vue";

export default {
    components: {
        GetStartedLayout,
        Link,
        PrivateLessons,
        AcceleratedLabs,
        RockStars,
        MusicologieJunior,
        ShortContactForm,
    },
    props: {
        errors: {},
        guest: {
            type: Object,
            default: () => {},
        },
        child: {
            type: Object,
            default: () => {},
        },
        relationships: {
            type: Array,
            default: () => [],
        },
        programs: {
            type: Array,
            default: () => [],
        },
        review: {
            type: Object,
            default: null,
        },
    },
    name: "Programs",
    data: function () {
        return {
            breadcrumbs: ["Get Started", "Programs"],
            hover: false,
            showAll: false,
            showHelp: null,
            active: null,
            showProgramInquiry: false,
        };
    },
    computed: {
        getProgramCards() {
            if (this.showAll) return this.programs;
            return this.programs.filter((e) => e.recommended);
        },
    },
    methods: {
        proceed(card) {
            this.$inertia.put(
                `/update-guest/${this.guest.hash_id}/${
                    this.child?.hash_id ?? ""
                }`,
                {
                    program_id: card.id,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    onError: (errors) => {
                        console.log(JSON.stringify(errors));
                    },
                }
            );
            if (!card.continue) {
                return (this.showProgramInquiry = card.title);
            }
            this.$inertia.visit(card.continue, { preserveScroll: false });
        },
        help(card) {
            if (card.link) {
                window.open(card.link, "_blank");
                return;
            }
            this.showHelp = card;
        },
    },
};
</script>

<style scoped>
.notRecommended {
    box-shadow: none !important;
    border: none !important;
}

.fixed-text-height {
    height: 108px !important;
}
</style>
