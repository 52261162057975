<template>
    <AppLayout
        page="/store"
        pageTitle="Store"
        ref="layout"
        :hasFab="can('Manage inventory') && getTab?.createable"
        fabClasses="store-fab"
        :tabs="true"
        :cart="
            productsInCart.length
                ? productsInCart.map((e) => e.quantity).reduce((a, b) => a + b)
                : null
        "
        @fabAction="manageProduct = {}"
        @showCart="$refs.shoppingCart.dialog = true"
        @pageSearch="search = $event"
    >
        <ShoppingCart
            v-if="can('Work')"
            ref="shoppingCart"
            id="shoppingCart"
            :productsInCart="productsInCart"
            @increment="increment"
            @decrement="decrement"
            @removeFromCart="removeFromCart"
            @loadUnpaidAssessments="loadUnpaidAssessments"
            @success="$refs.layout.showMessage = true"
            :client="client"
            @reset="closeCart"
            @reload="reload($event)"
            @createInlinePrice="setupInlinePrice($event)"
            :selectedTab="selectedTab"
            :errors="errors"
            :taxRate="taxRate"
            :guestCheckout="guestCheckout"
            @guestCheckout="
                [
                    (guestCheckout = $event),
                    closeCart(),
                    successData ? (viewReceipt = true) : null,
                ]
            "
            @locationSelect="
                [
                    ($refs.layout.locationRedirect = `store/frequent/${$event.id}`),
                    $event.locations.length == 1
                        ? $refs.layout.updateSelectedLocation(
                              $event.locations[0]
                          )
                        : [
                              ($refs.layout.locationSelect = true),
                              ($refs.layout.studentLocations =
                                  $event.locations),
                          ],
                ]
            "
            @tagPrices="tagPrices($event)"
            @clearPromo="clearPromo($event)"
        ></ShoppingCart>

        <template #tabs>
            <v-tabs
                center-active
                v-model="selectedTab"
                background-color="transparent"
                height="42px"
                class="pr-5 custom-tabs"
            >
                <v-tab
                    v-for="tab in tabList"
                    :key="tab.slug"
                    class="no-link"
                    :href="`/store/${tab.slug}`"
                >
                    <Link
                        class="no-link flex align-center justify-center min-h-full"
                        :href="`/store/${tab.slug}${
                            client ? `/${client.id}` : ''
                        }`"
                        preserve-state
                        :only="['products']"
                        >{{ tab.title }}</Link
                    ></v-tab
                >
            </v-tabs>
        </template>

        <v-tabs-items :value="selectedTab" style="height: 100%" touchless>
            <div v-for="tab in tabList" :key="tab.slug">
                <v-tab-item
                    :value="`/store/${tab.slug}`"
                    :transition="false"
                    v-if="selectedTab == `/store/${tab.slug}`"
                >
                    <v-container
                        fluid
                        class="animate__animated animate__fadeIn"
                    >
                        <v-data-iterator
                            :items="getProducts"
                            :search="search"
                            item-key="id"
                            hide-default-footer
                            mobile-breakpoint="0"
                            disable-pagination
                            :groupBy="
                                selectedTab == '/store/programs'
                                    ? 'program_id'
                                    : null
                            "
                        >
                            <template v-slot:default="props">
                                <ProductList
                                    v-if="selectedTab !== '/store/programs'"
                                    :products="props.items"
                                    :listView="listView"
                                    :key="props.items.length"
                                    @addProductToCart="addProductToCart($event)"
                                    @manageProduct="manageProduct = $event"
                                    @success="$refs.layout.showMessage = true"
                                />
                                <div v-if="selectedTab == '/store/programs'">
                                    <div
                                        v-for="(
                                            data, idx
                                        ) in props.groupedItems"
                                        :key="idx"
                                    >
                                        <div
                                            class="flex ma-5"
                                            v-if="!$root.loading"
                                        >
                                            <v-avatar size="96" tile
                                                ><v-img
                                                    class="soli-round"
                                                    :src="
                                                        getProgramImage(
                                                            data.name
                                                        )
                                                    " /></v-avatar
                                            ><v-card-title>{{
                                                programs.find(
                                                    (e) => e.id == data.name
                                                )?.title
                                            }}</v-card-title>
                                        </div>
                                        <ProductList
                                            :products="data.items"
                                            :divider="listView"
                                            :listView="listView"
                                            @addProductToCart="
                                                addProductToCart($event)
                                            "
                                            @manageProduct="
                                                manageProduct = $event
                                            "
                                        />
                                    </div>
                                </div>
                            </template>
                        </v-data-iterator>
                    </v-container>
                </v-tab-item></div
        ></v-tabs-items>

        <div class="soli-round" v-if="viewReceipt !== null">
            <v-dialog
                v-model="viewReceipt"
                persistent
                :fullscreen="$vuetify.breakpoint.xs ? true : false"
                :content-class="$vuetify.breakpoint.xs ? '' : 'soli-round'"
                :transition="
                    $vuetify.breakpoint.smAndDown
                        ? 'dialog-bottom-transition'
                        : 'dialog-transition'
                "
                max-width="600px"
            >
                <v-card>
                    <v-btn
                        outlined
                        x-small
                        @click="[(viewReceipt = false)]"
                        :disabled="!$refs.shoppingCart.selectedClient"
                        :href="
                            $refs.shoppingCart.selectedClient
                                ? `/profile/${$refs.shoppingCart.selectedClient.id}/account`
                                : null
                        "
                        v-if="$refs.shoppingCart"
                        class="ma-5 mr-15 soli-close soli-round"
                        >View Profile</v-btn
                    >
                    <v-btn
                        icon
                        @click="
                            [
                                (viewReceipt = false),
                                ($refs.shoppingCart.selectedClient = null),
                                ($page.props.successData = null),
                            ]
                        "
                        class="ma-3 soli-close soli-grey soli-text-shadow"
                        ><v-icon>clear</v-icon></v-btn
                    >
                    <v-card-title>Thank you!</v-card-title>
                    <v-card-subtitle>Here's your receipt</v-card-subtitle>
                    <v-container class="pt-0">
                        <v-list v-if="successData">
                            <v-list-item>
                                Email: {{ successData.customer_email }}
                                <br />
                                Amount:
                                {{ formatUnitAmount(successData.amount) }}
                                <br />
                                Payment:
                                {{ successData.last4 }}
                                <br />
                            </v-list-item>
                            <v-list-item v-if="successData.giftCards?.length">
                                <v-list
                                    ><div class="soli-grey font-weight-bold">
                                        Gift Cards:
                                    </div>
                                    <div
                                        class="flex-col align-start mb-2"
                                        v-for="gift in successData.giftCards"
                                        :key="gift.id"
                                    >
                                        <div>
                                            Gift Amount:
                                            {{ formatUnitAmount(gift.amount) }}
                                        </div>
                                        <div>Code: {{ gift.code }}</div>
                                    </div></v-list
                                >
                            </v-list-item>
                        </v-list>
                    </v-container></v-card
                ></v-dialog
            >
        </div>
        <SoliChoice
            v-if="showLessonMembershipRequired"
            title="Lesson Membership Required"
            subtitle="This membership requires an additional lesson membership. Please add one before completing this purchase."
            button1="Okay"
            permanent
            @option1="closeModal(() => (showLessonMembershipRequired = false))"
        />

        <SoliForm
            v-if="createInlinePrice"
            ref="inlinePriceForm"
            v-model="validInlinePrice"
            title="Adjust Price"
            choices
            permanent
            persistent
            :textSubtitle="
                createInlinePrice.price.interval
                    ? 'This product is a membership. Changing the price will affect future billing.'
                    : 'This product is a one-time purchase. Changing the price will not affect future billing.'
            "
            :saveChanges="!$refs.inlinePriceText?.$refs.input?.hasError"
            @submit="confirmInlinePrice(createInlinePrice)"
            @reset="closeModal(() => (createInlinePrice = false))"
            ><template #body>
                <div class="flex flex-col gap-4">
                    <v-currency-field
                        ref="inlinePriceText"
                        v-model="inlinePrice.unit_amount"
                        :rules="[
                            (val) => !!val || 'Price is required',
                            (val) =>
                                val * 100 !==
                                    (
                                        createInlinePrice.prices.find(
                                            (e) =>
                                                e.id ==
                                                createInlinePrice.price
                                                    .originalPrice
                                        ) ?? createInlinePrice.price
                                    ).unit_amount || 'Price must be different',
                            (val) =>
                                String(val).replace(/,/g, '') >= 0 ||
                                'Price must be greater than or equal to $0.00',
                            (val) =>
                                String(val).replace(/,/g, '') <= 500 ||
                                'Price must be not be greater than $500.00',
                        ]"
                        outlined
                        dense
                        class="soli-round"
                        label="Price"
                        error-message="errors.amount"
                        prepend-inner-icon="attach_money"
                        validate-on-blur
                        :allowNegative="false"
                        hide-details="auto"
                    ></v-currency-field>
                    <PriceCouponDuration
                        v-if="
                            inlinePrice.unit_amount === 0 &&
                            inlinePrice.interval
                        "
                        v-model="inlinePrice.coupon_duration"
                    />
                    <!--<PriceCouponRestore
                        v-if="
                            inlinePrice.coupon_duration &&
                            inlinePrice.coupon_duration !== 'forever'
                        "
                        v-model="inlinePrice.restore_price_id"
                        :priceId="inlinePrice.originalPrice"
                    />-->
                    <PriceTags ref="priceTags" v-model="inlinePrice.tags" />
                </div> </template
        ></SoliForm>
        <ManageProduct
            v-if="manageProduct"
            :product="manageProduct"
            :category="getTab"
            :errors="errors"
            @reset="closeModal(() => (manageProduct = null))"
            @success="[($refs.layout.showMessage = true), closeCart()]"
        />
    </AppLayout>
</template>

<script>
import AppLayout from "./../Layouts/AppLayout";
import ShoppingCart from "./../Components/Store/ShoppingCart";
import { Link } from "@inertiajs/vue2";
import ProductList from "./../Components/Store/ProductList.vue";
import ManageProduct from "../Components/Store/ManageProduct.vue";
import PriceTags from "../Components/Store/PriceTags.vue";
import PriceCouponDuration from "../Components/Store/PriceCouponDuration.vue";
import PriceCouponRestore from "../Components/Store/PriceCouponRestore.vue";

export default {
    props: {
        products: {},
        client: {},
        errors: {},
        finalUser: {},
        finalPrice: {},
        anchor: {},
        taxRate: {},
        successData: {},
        programs: {},
    },
    name: "Store",
    components: {
        AppLayout,
        ShoppingCart,
        Link,
        ProductList,
        ManageProduct,
        PriceTags,
        PriceCouponDuration,
        PriceCouponRestore,
    },
    data() {
        return {
            tabList: [
                { title: "Frequent", slug: "frequent", createable: false },
                { title: "Programs", slug: "programs", createable: false },
                //{ title: "Memberships", slug: "memberships" },
                //{ title: "Passes", slug: "passes" },
                {
                    title: "Books",
                    slug: "books",
                    createable: true,
                    singular: "a Book",
                },
                { title: "Gift Cards", slug: "gifts", createable: false },
                {
                    title: "Merch",
                    slug: "merch",
                    createable: true,
                    singular: "Merchandise",
                },
                { title: "Offers", slug: "offers", createable: false },
            ],
            search: null,
            selectedTab: window.location.pathname,
            viewReceipt: false,
            locationCycle: null,
            showLessonMembershipRequired: false,
            headers: [
                {
                    text: "Info",
                    align: "start",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Prices",
                    value: "prices",
                },
                {
                    text: "Barcode",
                    value: "barcode",
                },
            ],
            productsInCart: [],
            checkout: null,
            guestCheckout: false,
            createInlinePrice: false,
            inlinePrice: {
                unit_amount: null,
                productId: null,
                recurring: null,
                coupon_duration: null,
                restore_price_id: null,
                tags: [],
            },
            validInlinePrice: false,
            manageProduct: null,
        };
    },
    computed: {
        getTab() {
            return this.tabList.find(
                (e) => e.slug == this.selectedTab.split("/store/")[1]
            );
        },
        getProducts() {
            if (this.guestCheckout) {
                return this.products.filter(
                    (e) => e.physical || e.category == "gifts"
                );
            } else {
                return this.products;
            }
        },
        listView() {
            return (
                this.$vuetify.breakpoint.width -
                    (this.$refs.shoppingCart.dialog ? 400 : 0) <
                750
            );
        },
    },
    methods: {
        tagPrices({ priceIds, tag }) {
            this.productsInCart.forEach((product) => {
                if (priceIds.includes(product.price.id)) {
                    product.price.tags = [...product.price.tags, tag];
                }
            });
        },
        clearPromo(promos) {
            this.productsInCart.forEach((product) => {
                product.price.tags = product.price.tags?.filter(
                    (e) => !promos.map((promo) => promo.id).includes(e.promo_id)
                );
            });
        },
        setupInlinePrice(product) {
            //console.log(product);
            this.createInlinePrice = product;
            this.inlinePrice.unit_amount = product.price.unit_amount / 100;
            this.inlinePrice.productId = product.id;
            this.inlinePrice.interval = product.price.interval;
            this.inlinePrice.inline = true;
            this.inlinePrice.duration = product.price.duration;
            this.inlinePrice.passes = product.price.passes;
            this.inlinePrice.tags = product.price.tags ?? [];
            this.inlinePrice.coupon_duration =
                product.price.coupon_duration ?? null;
            this.inlinePrice.restore_price_id =
                product.price.restore_price_id ?? null;
            if (!product.price.originalPrice) {
                this.inlinePrice.originalPrice = product.price.id;
            } else {
                this.inlinePrice.originalPrice = product.price.originalPrice;
            }
        },
        confirmInlinePrice(product) {
            if (!this.validInlinePrice) return;
            this.$refs.inlinePriceForm.reset();

            //setTimeout(() => {
            this.inlinePrice.unit_amount = this.inlinePrice.unit_amount * 100;

            /*const mergePrices = this.productsInCart.find((e) => {
                return (
                    e.price.unit_amount == this.inlinePrice.unit_amount &&
                    e.id == product.id &&
                    e.unpaid == product.unpaid
                );
            });

            if (mergePrices.length) {
                const parentProduct = this.productsInCart.find(
                    (e) => e.price.id == mergePrices.price.id
                );

                parentProduct.quantity += product.quantity;
                parentProduct.recipients = [
                    ...new Set([
                        ...parentProduct.recipients,
                        ...product.recipients,
                    ]),
                ];
                parentProduct.contracts = [
                    ...parentProduct.contracts,
                    ...product.contracts,
                ];
                product.price.id = product.price.originalPrice;
                this.removeFromCart(product);
                return;
            }*/

            const productInCart = this.productsInCart.find(
                (e) => e.price.id == product.price.id
            );

            productInCart.price = this.inlinePrice;

            productInCart.formatAmount = this.formatUnitAmount(
                this.inlinePrice.unit_amount
            );

            if (product.price.interval) {
                this.getProration(productInCart);
            } else {
                productInCart.finalPrice = this.inlinePrice.unit_amount;
            }

            this.resetInlinePrice();
            //}, 300);
        },
        resetInlinePrice() {
            this.inlinePrice = {
                unit_amount: null,
                productId: null,
                recurring: null,
                interval: null,
                coupon_duration: null,
                restore_price_id: null,
                tags: [],
            };
        },
        reload(locationCycle) {
            this.locationCycle = locationCycle;
            let products = this.productsInCart;
            this.productsInCart = [];
            products.forEach((e) => this.addProductToCart(e));
        },
        getProration(product) {
            let anchor = moment().add(1, "M").startOf("month").format();
            let start = moment().startOf("day").format();

            if (this.locationCycle) {
                anchor = moment(
                    new Date().setDate(this.$refs.shoppingCart.locationCycle)
                )
                    .add(1, "month")
                    .format();
            }

            axios
                .get(
                    route("proration", {
                        price: product.price.unit_amount,
                        anchor: anchor,
                        starts_at: start,
                        notice: Number(false),
                        originalPrice: null,
                        alwaysProrate: product.program.always_prorate ? 1 : 0,
                    })
                )
                .then((e) => {
                    product.proration = e.data;
                    product.starts_at = start;
                    product.anchor = anchor;
                    product.finalPrice = e.data;
                    this.$refs.shoppingCart.disabled = false;
                });
        },
        loadUnpaidAssessments(unpaidPO) {
            if (!unpaidPO.length) return;
            const allUnpaidAssessments = new Set();

            const uniqueUnpaidAssessments = unpaidPO.filter((entry) => {
                if (allUnpaidAssessments.has(entry.price_id)) return false;
                return allUnpaidAssessments.add(entry.price_id);
            });

            uniqueUnpaidAssessments.forEach((unpaidAssessment) => {
                const product = { ...unpaidAssessment.product };

                if (
                    this.productsInCart
                        .map((e) => e.id)
                        .includes(product.price_id)
                ) {
                    this.removeFromCart(product);
                }

                product.formatAmount = this.formatUnitAmount(
                    unpaidAssessment.price.unit_amount
                );
                product.price = unpaidAssessment.price;

                const unpaidByPrice = unpaidPO.filter((option) => {
                    return option.price.id == product.price.id;
                });

                product.finalPrice = unpaidAssessment.price.unit_amount;
                product.quantity = unpaidByPrice.length;
                product.recipients = unpaidByPrice.map((e) => e.recipient);
                product.price.tags = [
                    ...new Map(
                        unpaidByPrice
                            .flatMap((e) => e.tags)
                            .map((tag) => [tag.id, tag])
                    ).values(),
                ];
                product.doNotBill = false;
                product.unpaid = true;
                product.unpaidPO = unpaidByPrice.map((e) => e.id);
                if (product.contract) {
                    product.contracts = [];
                    product.contract.signed = !unpaidPO.contract_url;
                    product.contract.productId = product.id;
                    for (let i = 0; i < product.quantity; i++) {
                        product.contracts.push({ ...product.contract });
                    }
                } else {
                    product.contracts = [];
                }
                this.productsInCart.push(product);
            });
            this.$nextTick(() => {
                this.$refs.shoppingCart.openCheckout();
            });
        },
        removeFromCart(product) {
            let update = this.productsInCart.filter(
                (e) => e.price.id !== product.price.id
            );
            this.productsInCart = update;
        },
        increment(product) {
            let quantity = product.quantity++;
            if (product.contract) {
                product.contract.signed = false;
                product.contracts.push({ ...product.contract });
            }
            return quantity;
        },
        decrement(product) {
            if (product.contract) {
                product.contracts.pop();
            }
            let quantity = product.quantity--;
            //console.log(quantity);
            if (quantity <= 1) {
                this.removeFromCart(product);
            } else {
                return quantity;
            }
        },
        addProductToCart(original) {
            if (original.requires_lesson_membership) {
                this.showLessonMembershipRequired = true;
            }

            const selectedClient = this.$refs.shoppingCart.selectedClient;

            // auto-select first matching price and add to cart
            // Updated 01/12/2025 - no longer in use, backend can handle this and saves quite a few queries
            // If I ever want required products to mean "Add this to the cart automatically", I can consider it, but it's only for deposits right now
            /*const requiredProduct = original.required_product;
            if (requiredProduct) {
                if (!original.is_deposit) {
                    this.addProductToCart(requiredProduct);
                } else {
                    original.price.deposit_for = requiredProduct.price.id;
                }
            }*/

            let product = { ...original };

            if (
                this.productsInCart
                    .map((e) => e.price.id)
                    .includes(product.price.id)
            ) {
                let adjustProduct = this.productsInCart.find(
                    (e) => e.price.id == product.price.id
                );
                adjustProduct.quantity++;
                if (selectedClient?.relationships?.length == 0) {
                    adjustProduct.recipients.push(selectedClient);
                }
                if (adjustProduct.contract) {
                    adjustProduct.contract.signed = false;
                    adjustProduct.contracts.push({ ...adjustProduct.contract });
                }
                return;
            }
            product.formatAmount = product.price.formatAmount;
            product.finalPrice = product.price.unit_amount;
            product.quantity = 1;
            product.recipients = [];
            product.proration = null;
            product.recipients = selectedClient?.relationships?.length
                ? []
                : [selectedClient] ?? [];

            if (product.price.interval) {
                this.$refs.shoppingCart.disabled = true;
                product.doNotBill = false;
                this.getProration(product);
            } else {
                product.formatAmount = this.formatUnitAmount(
                    product.price.unit_amount
                );
            }

            if (product.contract) {
                product.contract.signed = false;
                product.contract.productId = product.id;
                product.contracts = [{ ...product.contract }];
            } else {
                product.contracts = [];
            }

            this.productsInCart.push(product);
            this.$refs.shoppingCart.openCheckout();
        },
        closeCart() {
            this.productsInCart = [];
            this.locationCycle = null;
            this.$refs.shoppingCart.dialog = false;
        },
        getProgramImage(id) {
            const program = this.programs.find((e) => e.id == id);
            if (!program) return null;

            return `/api/image-service/96x96/${encodeURIComponent(
                program.imagePath
            )}`;
        },
    },
    watch: {
        inlinePrice: {
            handler: function (val) {
                if (
                    val.unit_amount === 0 &&
                    val.interval &&
                    !val.tags.map((e) => e.name).includes("Free Membership")
                ) {
                    this.inlinePrice.tags = [
                        ...val.tags,
                        this.$refs.priceTags.tags.find(
                            (e) => e.name === "Free Membership"
                        ),
                    ];
                }

                if (
                    val.unit_amount > 0 &&
                    val.tags.map((e) => e.name).includes("Free Membership")
                ) {
                    this.inlinePrice.tags = val.tags.filter(
                        (e) => e.name !== "Free Membership"
                    );
                }
            },
            deep: true,
        },
    },
    created() {
        this.$loadScript("https://js.stripe.com/terminal/v1/");
        this.$loadScript("https://js.stripe.com/v3/");
    },
    mounted() {},
    beforeDestroy() {
        this.productsInCart = [];
    },
};
</script>

<style scoped>
.img-height {
    height: 350px;
}
</style>
