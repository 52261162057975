<template>
    <v-app>
        <div
            :class="[
                $vuetify.breakpoint.xs
                    ? 'gradient-blue flex-col items-center'
                    : 'bg-white',
                'min-h-screen flex sm:justify-center overflow-hidden',
            ]"
        >
            <div class="my-12">
                <v-img
                    v-bind:src="'/Musicologie-White-Wordmark.png'"
                    max-width="200"
                    v-if="$vuetify.breakpoint.xs"
                />
            </div>
            <div
                :class="[
                    'max-w-3xl w-full px-6',
                    $vuetify.breakpoint.xs ? null : 'upper-third',
                ]"
            >
                <div
                    :class="[
                        'w-full bg-white custom-shadow soli-round overflow-hidden',
                    ]"
                >
                    <v-container class="ma-0 flex justify-center"
                        ><v-row :class="$vuetify.breakpoint.xs ? null : 'tall'"
                            ><v-col
                                v-if="$vuetify.breakpoint.smAndUp"
                                class="col-6 gradient-blue flex align-center justify-center"
                                style="background-color: #228cbd"
                                ><v-img
                                    v-bind:src="'/Musicologie-White-Wordmark.png'"
                                    max-width="275"
                            /></v-col>
                            <v-col class="xs:col-12 sm:col-6 align-self-center">
                                <div class="headline soli-grey px-3 py-5">
                                    {{
                                        `${
                                            hasErrors
                                                ? " Uh-oh"
                                                : "Hello, friend."
                                        }`
                                    }}
                                </div>
                                <div class="caption error--text px-3">
                                    {{ form.errors.email }}
                                </div>

                                <form @submit.prevent="submit">
                                    <div class="pa-3">
                                        <div
                                            v-show="!errors.google"
                                            class="flex justify-center pb-3"
                                            id="googleSignIn"
                                        ></div>
                                        <div
                                            class="flex justify-center error--text text-sm mb-6"
                                            v-if="errors.google"
                                        >
                                            {{ errors.google }}
                                        </div>
                                        <v-divider class="mt-3" />
                                        <div
                                            class="flex justify-center"
                                            style="transform: translateY(-13px)"
                                        >
                                            <v-sheet class="w-10 white"
                                                ><div
                                                    class="font-bold soli-grey flex justify-center"
                                                >
                                                    or
                                                </div></v-sheet
                                            >
                                        </div>
                                        <label
                                            class="caption pl-2.5 soli-grey"
                                            for="email"
                                            >Email</label
                                        >
                                        <SoliText
                                            v-model="form.email"
                                            name="email"
                                            ref="email"
                                            label=""
                                            @focus="scrollTo($refs.email.$el)"
                                            :email="true"
                                            required
                                        ></SoliText>
                                        <label
                                            class="caption pl-2.5 soli-grey"
                                            for="password"
                                            >Password</label
                                        >
                                        <SoliText
                                            name="password"
                                            ref="password"
                                            @focus="
                                                scrollTo($refs.password.$el)
                                            "
                                            v-model="form.password"
                                            label=""
                                            required
                                            :password="true"
                                            :show="show"
                                            @append="show = !show"
                                        ></SoliText>

                                        <v-checkbox
                                            v-model="form.remember"
                                            color="primary"
                                            label="Remember me"
                                            on-icon="check_box"
                                            off-icon="check_box_outline_blank"
                                        />
                                        <v-row>
                                            <v-col
                                                class="justify-end flex align-center"
                                            >
                                                <Link
                                                    v-if="canResetPassword"
                                                    :href="
                                                        route(
                                                            'password.request'
                                                        )
                                                    "
                                                    class="underline text-sm text-gray-600 hover:text-gray-900"
                                                >
                                                    Forgot your password?
                                                </Link>
                                                <SoliBtn
                                                    gradient="gradient-orange"
                                                    text="Login"
                                                    :icon="null"
                                                    :loading="form.processing"
                                                    class="ml-3"
                                                    type="submit"
                                                ></SoliBtn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </form>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </div>
        </div>
        <SoliSnack
            v-if="
                Object.keys($page.props.errors).includes('expired') ||
                $page.props.status
            "
            :text="$page.props.errors.expired || $page.props.status"
        ></SoliSnack>
    </v-app>
</template>

<script>
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetAuthenticationCardLogo from "@/Jetstream/AuthenticationCardLogo.vue";
import JetApplicationLogo from "@/Jetstream/ApplicationLogo.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import AppLayout from "./../../Layouts/AppLayout";
import { Link } from "@inertiajs/vue2";

export default {
    components: {
        JetAuthenticationCard,
        JetApplicationLogo,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
        AppLayout,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: String,
        google_client_id: String,
        successRoute: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                email: "",
                password: "",
                remember: true,
            }),
            show: false,
        };
    },
    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
    methods: {
        handleCredentialResponse(response) {
            this.$inertia.post("/google/oauth", {
                token: response.credential,
                remember: this.form.remember,
                successRoute: this.successRoute,
                onSuccess: (resp) => {
                    console.log(resp);
                },
            });
        },
        scrollTo(el) {
            if (!this.$vuetify.breakpoint.xs) return;
            el.scrollIntoView();
        },
        submit() {
            this.form.post(
                this.route("soli.login", { successRoute: this.successRoute }),
                {
                    onSuccess: () => this.form.reset("password"),
                }
            );
        },
        async loadGoogleLogin() {
            await this.$loadScript("https://accounts.google.com/gsi/client");

            google.accounts.id.initialize({
                client_id: this.google_client_id,
                callback: this.handleCredentialResponse,
            });

            google.accounts.id.renderButton(
                document.getElementById("googleSignIn"),
                {
                    theme: "outline",
                    size: "medium",
                    text: "continue_withS",
                    shape: "pill",
                }
            );
            //};
        },
    },
    mounted() {
        this.loadGoogleLogin();
    },
};
</script>

<style scoped>
.v-dialog {
    border-radius: 20px !important;
}

.tall {
    height: 500px;
}

.quarter {
    transform: translateY(-25%);
}

@media (max-width: 599px) {
    .theme--light.v-application {
        background: rgb(42, 171, 230) !important;
        background: linear-gradient(
            100deg,
            rgba(42, 171, 230, 1) 2%,
            rgba(0, 139, 203, 1) 100%
        ) !important;
        box-shadow: 1px 4px 4px 0 rgba(42, 171, 230, 0.25) !important;
    }
}

.custom-shadow {
    box-shadow: 0 25px 50px 0px rgb(0 0 0 / 25%);
}
</style>
