<template>
    <v-navigation-drawer
        v-model="dialog"
        :width="
            $vuetify.breakpoint.width < 960 ? '-webkit-fill-available' : 400
        "
        right
        app
        mobile-breakpoint="799"
        :permanent="!$vuetify.breakpoint.smAndDown"
        disable-resize-watcher
        :class="['shadow-left safe-bottom-margin']"
        :fullscreen="$vuetify.breakpoint.width < 960"
        :bottom="$vuetify.breakpoint.width < 960"
        touchless
        style="z-index: 10 !important; max-height: 100%"
    >
        <div class="flex flex-col safe-bottom h-full">
            <v-card-title
                class="white w-full moveToTop ml-1 soli-grey"
                style="flex: 0"
            >
                Cart
                <v-btn
                    v-if="$vuetify.breakpoint.width < 960"
                    icon
                    class="cancel-btn mr-10 pb-2"
                    @click="minimize()"
                    ><v-icon>minimize</v-icon></v-btn
                >
                <v-btn icon class="cancel-btn mr-1" @click="clearCart = true"
                    ><v-icon>clear</v-icon></v-btn
                >
            </v-card-title>

            <v-container
                :class="['pt-0', checkout ? 'checkout-open' : '']"
                style="flex-grow: 1; overflow-y: auto"
            >
                <v-form ref="form">
                    <v-container class="pt-0">
                        <v-tabs v-model="tabs" class="px-1">
                            <v-tab
                                @click="
                                    [
                                        $emit('guestCheckout', false),
                                        (guestEmail = null),
                                    ]
                                "
                                >Existing Client</v-tab
                            >
                            <v-tab
                                @click="
                                    [
                                        $emit('guestCheckout', true),
                                        (selectedClient = null),
                                    ]
                                "
                                >Guest</v-tab
                            >
                        </v-tabs>
                    </v-container>

                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <SoliStudents
                                class="pt-3 mx-4"
                                ref="students"
                                v-model="selectedClient"
                                autocomplete
                                onDemand
                                :rules="rules.select"
                                :error="errors.user_id"
                                label="Search Billing Accounts"
                                hasEmail
                                @reset="
                                    [
                                        $emit('reset'),
                                        client ? null : (selectedClient = null),
                                        (skipContracts = false),
                                        (locationCycle = null),
                                    ]
                                "
                                @input="handleClientSelect"
                            ></SoliStudents
                        ></v-tab-item>
                        <v-tab-item>
                            <SoliText
                                v-model="guestEmail"
                                :errors="errors.email"
                                :rules="rules.email"
                                hideDetails
                                icon="alternate_email"
                                label="Email"
                                class="px-3 my-3"
                                @change="openCheckout()"
                            ></SoliText></v-tab-item
                    ></v-tabs-items>

                    <v-divider />

                    <v-skeleton-loader
                        type="list-item-avatar-three-line"
                        class="my-3"
                        boilerplate
                        v-if="checkoutLoading"
                    ></v-skeleton-loader>

                    <v-list
                        v-for="(product, index) in productsInCart"
                        :key="product.price.id"
                        v-show="!checkoutLoading"
                    >
                        <v-list-item class="flex justify-between">
                            <v-list-item-content class="py-0">
                                <v-list-item-title
                                    >{{ product.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    class="caption"
                                    style="max-width: 75%"
                                    >{{
                                        product.description
                                    }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            <div>
                                <v-list-item-title class="justify-end flex">
                                    {{
                                        `${
                                            product.price.interval
                                                ? `${formatUnitAmount(
                                                      product.proration
                                                  )}`
                                                : product.formatAmount
                                        }`
                                    }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    v-if="product.unpaidPO && selectedClient"
                                    class="caption error--text"
                                    ><div
                                        v-if="product.unpaidPO"
                                        class="justify-end flex"
                                    >
                                        {{
                                            `Unpaid from Account X ${
                                                unpaidPOs.filter(
                                                    (e) =>
                                                        e.price_id ==
                                                            product.price.id ||
                                                        e.price_id ==
                                                            product.price
                                                                .originalPrice
                                                ).length
                                            }`
                                        }}
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="
                                        product.price.interval &&
                                        (!product.price.coupon_duration ||
                                            product.price.coupon_duration ==
                                                'forever')
                                    "
                                >
                                    <div class="justify-end flex">
                                        {{
                                            `then ${formatUnitAmount(
                                                product.price.unit_amount
                                            )}/month`
                                        }}
                                    </div></v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                    v-if="
                                        product.price.interval &&
                                        product.price.coupon_duration &&
                                        product.price.coupon_duration !==
                                            'forever'
                                    "
                                >
                                    <div class="justify-end flex">
                                        {{
                                            `${formatUnitAmount(
                                                product.price.unit_amount
                                            )}/month ${
                                                product.price.coupon_duration
                                                    ? `(${product.price.coupon_duration} months)`
                                                    : ""
                                            }`
                                        }}
                                    </div></v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                    v-if="
                                        product.price.interval &&
                                        product.price.coupon_duration &&
                                        product.price.coupon_duration !==
                                            'forever'
                                    "
                                >
                                    <div class="justify-end flex">
                                        {{
                                            `then ${formatUnitAmount(
                                                product.prices.find(
                                                    (e) =>
                                                        e.id ==
                                                        product.price
                                                            .originalPrice
                                                ).unit_amount
                                            )}/month`
                                        }}
                                    </div></v-list-item-subtitle
                                >
                                <div
                                    v-if="
                                        product.price.interval &&
                                        product.proration == null
                                    "
                                    class="justify-end flex"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        :size="25"
                                        color="primary"
                                        class="justify-self-end"
                                    ></v-progress-circular>
                                </div>
                            </div>
                        </v-list-item>
                        <div v-if="product.price.tags" class="px-4">
                            <v-list-item-subtitle>
                                <div class="justify-start flex gap-1">
                                    <v-chip
                                        v-for="tag in product.price.tags"
                                        :key="tag.id"
                                        x-small
                                        :color="tag.color"
                                        class="white--text text-capitalize"
                                        >{{ tag.name }}</v-chip
                                    >
                                </div></v-list-item-subtitle
                            >
                        </div>
                        <v-list-item v-if="product.price.interval">
                            <v-divider
                                vertical
                                class="mr-2 my-1"
                                style="
                                    border-color: #78b2e9;
                                    border-width: 1px;
                                    border-radius: 12px;
                                "
                            />
                            <v-list class="py-0 w-full">
                                <v-list-item-subtitle
                                    v-if="product.futureDateMembership"
                                    class="flex justify-between w-full"
                                    ><div>
                                        {{
                                            `Due ${globalMomentDate(
                                                new Date(product.anchor)
                                            )}:`
                                        }}
                                    </div>
                                    <v-spacer />
                                    <div
                                        v-if="product.futureDateMembership"
                                        class="flex justify-end"
                                    >
                                        {{
                                            `${formatUnitAmount(
                                                product.futureDateMembership
                                                    .proration
                                            )}`
                                        }}
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="product.starts_at"
                                    class="flex justify-between w-full"
                                >
                                    <div>Proration Period:</div>
                                    <v-spacer />
                                    <div class="flex justify-end">
                                        {{
                                            `${globalMomentDate(
                                                new Date(
                                                    product.futureDateMembership
                                                        ?.starts_at ??
                                                        product.starts_at
                                                ),
                                                "MMM D"
                                            )} to ${globalMomentDate(
                                                new Date(
                                                    product.futureDateMembership
                                                        ?.anchor ??
                                                        product.anchor
                                                )
                                            )}`
                                        }}
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    class="flex justify-between w-full"
                                    v-if="product.price.interval"
                                    >Auto-billing Begins:<v-spacer />
                                    {{
                                        getBillingStart(product)
                                    }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                    v-if="product.price.interval && false"
                                    class="flex justify-between w-full"
                                >
                                    <div>Customer Terms Accepted</div>
                                    <v-spacer />

                                    <v-icon
                                        :class="[
                                            'pr-1',
                                            product.contracts.every(
                                                (e) => e.signed
                                            )
                                                ? 'primary--text'
                                                : 'error--text',
                                        ]"
                                        small
                                        >{{
                                            product.contracts.every(
                                                (e) => e.signed
                                            )
                                                ? "done_all"
                                                : "error"
                                        }}</v-icon
                                    >
                                </v-list-item-subtitle>
                            </v-list>
                        </v-list-item>
                        <v-list-item style="margin-top: -8px">
                            <v-list-item-subtitle>
                                <v-btn
                                    small
                                    icon
                                    color="dark"
                                    @click="
                                        [
                                            $emit('decrement', product),
                                            product.recipients.length >
                                            product.quantity
                                                ? product.recipients.pop()
                                                : null,
                                        ]
                                    "
                                    ><v-icon small>remove</v-icon></v-btn
                                >
                                <span class="px-2 soli-grey-text">{{
                                    product.quantity
                                }}</span>
                                <v-btn
                                    small
                                    icon
                                    color="dark"
                                    :disabled="product.unpaid"
                                    @click="
                                        [
                                            $emit('increment', product),
                                            selectedClient
                                                ? selectedClient.relationships
                                                      .length > 0
                                                    ? null
                                                    : product.recipients.push(
                                                          selectedClient
                                                      )
                                                : null,
                                        ]
                                    "
                                    ><v-icon small>add</v-icon></v-btn
                                >
                            </v-list-item-subtitle>
                            <v-spacer />
                            <div class="flex">
                                <v-btn
                                    v-if="
                                        can('Override membership proration') &&
                                        product.price.interval
                                    "
                                    small
                                    icon
                                    :color="
                                        product.doNotBill ? 'primary' : 'dark'
                                    "
                                    @click="
                                        product.doNotBill
                                            ? ((membershipStart = moment()
                                                  .startOf('day')
                                                  .format()),
                                              handleChangeMembershipStart(
                                                  product
                                              ),
                                              $emit('reload', locationCycle))
                                            : (doNotBill = product)
                                    "
                                >
                                    <v-icon small>money_off</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    color="dark"
                                    v-if="
                                        product.price.interval &&
                                        can('Override membership start date')
                                    "
                                    :disabled="product.doNotBill"
                                    @click="changeMembershipStart = product"
                                >
                                    <v-icon small>today</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="can('Override product prices')"
                                    small
                                    icon
                                    color="dark"
                                    @click="$emit('createInlinePrice', product)"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="can('Override membership start date')"
                                    small
                                    icon
                                    color="dark"
                                    @click="$emit('removeFromCart', product)"
                                >
                                    <v-icon small>delete_outline</v-icon>
                                </v-btn>
                            </div>
                        </v-list-item>
                        <div
                            v-if="
                                selectedClient &&
                                selectedClient.relationships.length &&
                                !product.physical
                            "
                            class="mb-4 flex flex-col gap-2"
                        >
                            <v-list-item
                                v-for="(quantity, idx) in [
                                    ...Array(product.quantity),
                                ].map((_, i) => i)"
                                :key="quantity"
                            >
                                <v-select
                                    :rules="rules.select"
                                    outlined
                                    return-object
                                    @change="
                                        [
                                            product.recipients.splice(
                                                idx,
                                                1,
                                                $event
                                            ),
                                            openCheckout(),
                                        ]
                                    "
                                    :items="
                                        [
                                            selectedClient,
                                            selectedClient.relationships,
                                        ].flatMap((e) => e)
                                    "
                                    :value="
                                        // if unpaidPO has recipient and it's the first item in the cart at the first index
                                        unpaidPOs.length
                                            ? productsInCart[index].recipients
                                                  .length
                                                ? productsInCart[index]
                                                      .recipients[idx]
                                                : null
                                            : null
                                    "
                                    :disabled="
                                        productsInCart[index].unpaidPO &&
                                        productsInCart[index].recipients.length
                                            ? unpaidPOs.filter(
                                                  (e) =>
                                                      e.price.id ==
                                                      productsInCart[index]
                                                          .price.id
                                              ).length -
                                                  idx >
                                              0
                                            : false
                                    "
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    prepend-inner-icon="people"
                                    label="Purchase for..."
                                    hide-details="auto"
                                >
                                </v-select
                            ></v-list-item>
                        </div>
                        <v-divider />
                    </v-list>
                    <v-list dense class="px-2">
                        <v-list-item
                            ><v-list-item-subtitle
                                >Subtotal</v-list-item-subtitle
                            >
                            <v-list-item-action>{{
                                formatUnitAmount(subtotal)
                            }}</v-list-item-action>
                        </v-list-item>
                        <div v-if="activePromos.length > 0">
                            <v-list-item
                                class="pr-2"
                                v-for="promo in activePromos"
                                :key="promo.id"
                                ><v-list-item-subtitle>{{
                                    `${promo.code} · ${promo.name}`
                                }}</v-list-item-subtitle>
                                <v-list-item-action
                                    style="width: 100%"
                                    class="justify-end"
                                    >{{
                                        `- ${formatUnitAmount(promo.amount)}`
                                    }}</v-list-item-action
                                >
                            </v-list-item>
                        </div>
                        <v-list-item class="pr-2"
                            ><v-list-item-subtitle>Tax</v-list-item-subtitle>
                            <v-list-item-action
                                style="width: 100%"
                                class="justify-end"
                                >{{
                                    `${
                                        getTaxes
                                            ? formatUnitAmount(getTaxes)
                                            : ""
                                    }`
                                }}</v-list-item-action
                            ></v-list-item
                        >
                        <div v-if="selectedClient">
                            <v-list-item class="pr-2" v-if="showClientBalance">
                                <v-list-item-subtitle
                                    >Account Balance</v-list-item-subtitle
                                >
                                <v-list-item-action
                                    style="width: 100%"
                                    class="justify-end"
                                    >{{
                                        `- ${formatUnitAmount(
                                            calculateClientBalance
                                        )}`
                                    }}</v-list-item-action
                                >
                            </v-list-item>
                        </div>
                        <v-list-item
                            ><v-list-item-title>Total</v-list-item-title>
                            <v-list-item-action class="font-bold">{{
                                formatUnitAmount(finalPrice)
                            }}</v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-form>
            </v-container>
            <v-card
                :class="[
                    'w-full half-round',
                    $vuetify.breakpoint.mdAndUp ? 'pb-3' : null,
                ]"
                style="flex: 0; position: relative"
                v-if="
                    productsInCart.length > 0 &&
                    requiresContract &&
                    !signedContracts
                "
                ><v-card-title class="px-6 soli-grey">Policies</v-card-title>
                <v-card-actions class="flex justify-end">
                    <v-btn
                        plain
                        class="soli-round"
                        color="error"
                        @click="handleSkipContracts()"
                        :disabled="!selectedClient || !validCheckout"
                        >Skip for now</v-btn
                    >
                    <SoliBtn
                        :disabled="!selectedClient || !validCheckout"
                        :loading="loading"
                        @click="[getContracts(), (loadContract = true)]"
                        color="primary"
                        gradient="blue"
                        class="soli-round"
                        >Review and Sign</SoliBtn
                    >
                </v-card-actions>
            </v-card>
            <Checkout
                v-if="
                    (selectedClient || guestEmail) &&
                    productsInCart.length > 0 &&
                    (!requiresContract || signedContracts)
                "
                ref="checkout"
                :rules="rules"
                :client="guestEmail ? { email: guestEmail } : selectedClient"
                :prices="productsInCart"
                :dialog="checkout"
                :errors="errors"
                :subtotal="subtotal"
                :total="finalPrice"
                :unpaidPOs="unpaidPOs"
                :activePromos="activePromos"
                :defaultMethod="defaultMethod"
                :checkoutLoading="checkoutLoading"
                :selectedTab="selectedTab"
                :taxRate="taxRate"
                :contracts="
                    productsInCart
                        .filter((e) => e.contracts)
                        .map((e) => e.contracts)
                        .flat()
                "
                :guestCheckout="guestCheckout"
                @reset="checkout = false"
                @applyPromo="applyPromo"
                @clearPromo="clearPromo"
                @success="[getStripeClient(), $emit('success')]"
                @guestCheckout="
                    $emit('guestCheckout'), (tabs = 0), (guestEmail = null)
                "
            ></Checkout>

            <Contracts
                v-if="loadContract"
                :contracts="contracts"
                :client="selectedClient"
                @success="handleContractSuccess"
                @reset="loadContract = false"
            ></Contracts>
            <!--<v-overlay :value="loading" style="z-index: 12">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>-->
            <SoliChoice
                v-if="changeMembershipStart"
                @option1="confirmChangeMembershipStart = changeMembershipStart"
                @reset="changeMembershipStart = false"
                title="STOP."
                subtitle="You’re about to change the membership start date. In most cases you don’t need to do this."
                button1="Do It Anyway"
                warning
                button2="Nevermind"
            ></SoliChoice>

            <SoliForm
                ref="membershipStart"
                title="Change Membership Start Date"
                v-if="confirmChangeMembershipStart"
                :textSubtitle="`Membership can be set to begin anytime between now and the end of ${
                    can('Future date memberships') ? 'next' : 'the'
                } month.`"
                @reset="confirmChangeMembershipStart = false"
                @submit="
                    handleChangeMembershipStart(confirmChangeMembershipStart)
                "
                text="Confirm"
                icon="save"
                :saveChanges="membershipStart ? true : false"
            >
                <template #body>
                    <SoliDate
                        :select="false"
                        v-model="membershipStart"
                        future
                        :max="
                            can('Future date memberships')
                                ? globalMomentEndOfNextMonth(new Date())
                                : globalMomentEndOfMonth(new Date())
                        "
                    ></SoliDate>
                    <!--<v-switch
          class="mx-6"
          dense
          color="primary"
          :label="`Do not prorate/Billing starts ${
            membershipStart
              ? globalMomentNextMonth(new Date(membershipStart))
              : globalMomentNextMonth()
          }  (Admin only)`"
          v-model="doNotBill"
          v-if="can('Override membership proration')"
        >
        </v-switch>-->
                </template>
            </SoliForm>
            <SoliChoice
                v-if="doNotBill"
                title="STOP."
                :subtitle="`This will skip prorating the current month. You are about to give away free lessons. Billing will start on ${getBillingStart(
                    doNotBill
                )}`"
                button1="Do it anyway"
                button2="Nevermind"
                warning
                @option1="handleDoNotBill(doNotBill)"
                @reset="doNotBill = null"
            ></SoliChoice>
            <SoliChoice
                v-if="clearCart"
                title="Remove all items from cart?"
                @option1="
                    [
                        $emit('reset'),
                        (selectedClient = null),
                        (skipContracts = false),
                    ]
                "
                @reset="
                    [
                        (clearCart = false),
                        $refs.checkout
                            ? ($refs.checkout.loading = false)
                            : null,
                    ]
                "
                button1="Clear"
                button2="Nevermind"
            ></SoliChoice>
            <SoliChoice
                v-if="pastDue"
                title="Account Past Due"
                subtitle="This client has a past due subscription and is unable to make additional purchases"
                button1="Okay"
                @option1="$inertia.visit(route('store'))"
                @reset="$inertia.visit(route('store'))"
            ></SoliChoice>
            <SoliChoice
                v-if="locationWarning"
                title="hold up."
                :subtitle="`${selectedClient.name} doesn't belong to this location.`"
                :button1="
                    selectedClient.locations.length == 1
                        ? `Switch to ${selectedClient.locations[0].name}`
                        : 'Change Locations'
                "
                button2="Go Back"
                loading1
                permanent
                @option1="$emit('locationSelect', selectedClient)"
                @option2="$inertia.visit(route('store'))"
            ></SoliChoice>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { path, isEmpty, pathOr } from "ramda";
import SoliForm from "../Base/SoliForm.vue";
import PaymentMethods from "./PaymentMethods.vue";
import Contracts from "./Contracts.vue";
import Promos from "./Promos";
import Checkout from "./Checkout";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    name: "ShoppingCart",
    mixins: [globalMoment],
    props: [
        "errors",
        "client",
        "productsInCart",
        "taxRate",
        "selectedTab",
        "guestCheckout",
    ],
    components: {
        PaymentMethods,
        Contracts,
        Promos,
        Checkout,
    },

    data() {
        return {
            checkoutLoading: false,
            dialog: false,
            checkout: false,
            clearCart: false,
            disabled: false,
            loading: false,
            completeRecipients: true,
            connectMemberships: [],
            selectedClient: this.client,
            changeMembershipStart: false,
            confirmChangeMembershipStart: false,
            membershipStart: null,
            rules: SoliForm.formRules,
            loadContract: false,
            activePromos: [],
            tabs: null,
            contracts: null,
            defaultMethod: this.client ? this.client.defaultMethod : null,
            clientBalance: 0,
            unpaidPOs: [],
            form: {
                prices: [],
                billingUser: this.billingUser,
                recipients: [],
            },
            skipContracts: false,
            doNotBill: false,
            guestEmail: null,
            pastDue: false,
            locationCycle: null,
            locationWarning: false,
            validCheckout: false,
            cartCopy: [],
        };
    },
    /*watch: {
    selectedClient(client) {
      if (client && this.signedContracts && this.completeRecipients) {
        this.checkout = true;
      } else {
        this.checkout = false;
      }
    },
  },*/
    methods: {
        getContracts() {
            let list = this.productsInCart
                .filter((e) => e.contracts)
                .map((e) => e.contracts)
                .flat();

            let currentContract = list.find((e) => !e.signed);
            let index = list.indexOf(currentContract) + 1;
            let total = list.length;
            if (!currentContract) {
                this.contracts = null;
                return;
            }
            currentContract.data = this.productsInCart
                .filter((e) => e.id == currentContract.productId)
                .map((e) => {
                    return {
                        proration: e.proration,
                        futureDateMembership: e.futureDateMembership ?? false,
                        prorationDueDate: e.futureDateMembership
                            ? this.globalMomentDate(new Date(e.anchor))
                            : "immediately",
                        startDate:
                            e.futureDateMembership?.starts_at ?? e.starts_at,
                        nextMonth:
                            e.futureDateMembership?.anchor ??
                            this.getBillingStart(e),
                        name: e.is_event
                            ? e.name
                            : e.name + " " + e.price.duration,
                        description: e.description,
                        monthlyRecurring: e.formatAmount,
                    };
                })[0];

            this.contracts = {
                list: list,
                contract: currentContract,
                index: index,
                total: total,
            };
        },
        getBillingStart(product) {
            if (!product.starts_at)
                return this.globalMomentNextMonth(new Date(), "ll");
            if (this.locationCycle) {
                return moment(new Date().setDate(this.locationCycle))
                    .add(1, "month")
                    .format("ll");
            }

            return this.globalMomentNextMonth(
                new Date(product.starts_at),
                "ll"
            );
        },
        handleClientSelect() {
            this.checkout = false;
            this.$emit("reset");
            if (!this.selectedClient) return;
            if (!this.selectedClient.locations) return;
            if (
                !this.selectedClient.locations
                    .map((e) => e.id)
                    .includes(this.$page.props.auth.user.selected_location.id)
            ) {
                this.locationWarning = true;
                return;
            }
            this.selectedClient.relationships.length
                ? this.productsInCart.map((e) => (e.recipients = []))
                : this.productsInCart.map((e) => {
                      for (let x = 0; x < e.quantity; x++) {
                          e.recipients.pop();
                          e.recipients.push(this.selectedClient);
                      }
                  });
            this.getStripeClient();
            if (!this.client) return;
            if (this.selectedClient.id !== this.client.id) {
                window.history.pushState("", "", "/store");
            }
        },
        handleSkipContracts() {
            this.skipContracts = true;
            this.openCheckout();
        },
        openCheckout() {
            //this.getRecipients();
            if (
                (this.selectedClient || this.guestEmail) &&
                this.signedContracts &&
                this.validCheckout
                //this.completeRecipients
            ) {
                this.checkout = true;
            } else {
                this.checkout = false;
            }
        },
        minimize() {
            this.checkout = false;
            this.dialog = false;
        },
        getStripeClient() {
            if (!this.selectedClient) return;
            this.checkoutLoading = true;

            return axios
                .get("/api/stripe/client", {
                    params: { client: this.selectedClient.id },
                })
                .then((resp) => {
                    this.selectedClient.defaultMethod =
                        resp.data.client.defaultMethod;
                    this.clientBalance = resp.data.client.balance;

                    this.pastDue = resp.data.client.unpaidSubscription;
                    this.locationCycle = resp.data.client.locationCycle;
                    this.$emit("reload", this.locationCycle);

                    if (resp.data.client.unpaidPOs.length) {
                        this.unpaidPOs = resp.data.client.unpaidPOs;
                        /*if (this.selectedClient.relationships.length) {
                            this.selectedClient.relationships.push({
                                id: this.selectedClient.id,
                                name: this.selectedClient.name
                            });
                        }*/
                        this.$emit("loadUnpaidAssessments", this.unpaidPOs);
                    } else {
                        this.unpaidPOs = [];
                    }

                    if (this.$refs.checkout) {
                        this.$refs.checkout.tabs = resp.data.client
                            .defaultMethod
                            ? 0
                            : 1;
                    }
                    this.defaultMethod = resp.data.client.defaultMethod;
                    this.checkoutLoading = false;
                    this.openCheckout();
                });
        },
        handleContractSuccess(success) {
            this.loadContract = false;
            setTimeout(() => {
                if (this.contracts) {
                    this.loadContract = true;
                } else {
                    this.$emit("success");
                    this.openCheckout();
                    //this.checkout = true;
                }
            }, 100);
        },
        handleChangeMembershipStart(product) {
            let currentAnchor = moment(new Date(product.starts_at))
                .add(1, "M")
                .startOf("month");
            let updatedAnchor = moment(new Date(this.membershipStart))
                .add(1, "M")
                .startOf("month");
            let startDate = moment(new Date(this.membershipStart)).format();
            let futureDateMembership = !currentAnchor.isSame(
                updatedAnchor,
                "month"
            );

            axios
                .get(
                    route("proration", {
                        price: product.price.unit_amount,
                        anchor: updatedAnchor.format(),
                        starts_at: startDate,
                        notice: Number(false),
                        originalPrice: null,
                        alwaysProrate: 0,
                    })
                )
                .then((e) => {
                    if (futureDateMembership) {
                        product.futureDateMembership = {
                            starts_at: this.membershipStart,
                            anchor: updatedAnchor,
                            proration: e.data,
                            subscriptionCredit:
                                e.data - product.price.unit_amount,
                        };
                        product.proration = 0;
                        product.starts_at = product.starts_at;
                        product.anchor = currentAnchor;
                        product.finalPrice = 0;
                        product.doNotBill = false;
                        product.originalStart = product.starts_at;
                    } else {
                        product.futureDateMembership = null;
                        product.proration = e.data;
                        product.starts_at = startDate;
                        product.anchor = currentAnchor;
                        product.finalPrice = e.data;
                        product.doNotBill = false;
                    }
                    if (this.$refs.membershipStart)
                        this.$refs.membershipStart.dialog = false;
                    setTimeout(() => {
                        this.confirmChangeMembershipStart = false;
                    }, 300);
                });
        },
        handleDoNotBill(product) {
            product.proration = 0;
            product.finalPrice = 0;
            product.doNotBill = true;
        },
        applyPromo(promo, ignoreTags) {
            //console.log(promo);
            this.activePromos.push(promo);
            if (promo.tag && !ignoreTags) {
                promo.tag.promo_id = promo.id;
                const priceIds = this.productsInCart
                    .filter((e) => promo.prices.includes(e.price.id))
                    .filter(
                        (e) =>
                            !e.price.tags
                                ?.map((e) => e.id)
                                ?.includes(promo.tag.id)
                    )
                    .map((e) => e.price.id);
                if (priceIds.length) {
                    this.$emit("tagPrices", {
                        priceIds: priceIds,
                        tag: promo.tag,
                    });
                }
            }

            this.$emit("success");
        },
        clearPromo() {
            this.$emit("clearPromo", this.activePromos);
            this.activePromos = [];
            if (this.$refs.checkout) this.$refs.checkout.promoCode = null;
        },
        checkRecipientQuantities() {
            if (!this.productsInCart.length) return;
            if (this.guestEmail) return true;
            if (
                !this.selectedClient?.relationships?.length ||
                Object.values(this.productsInCart).every((e) => e.physical)
            ) {
                this.validCheckout = true;
                return true;
            }
            let products = this.productsInCart.filter((e) => !e.physical);

            if (!products.length) return true;

            let recipients = products
                .map((e) => e.recipients.length)
                .reduce((a, b) => a + b);
            let quantity = products
                .map((e) => e.quantity)
                .reduce((a, b) => a + b);

            this.validCheckout = recipients == quantity;
        },
    },
    computed: {
        signedContracts() {
            if (!this.contracts || this.skipContracts) {
                this.checkout = true;
                return true;
            }
            let signed = this.contracts.list.every((e) => e.signed);
            if (this.$refs.checkout) {
                if (signed || !this.contracts.contract) {
                    this.checkout = true;
                } else {
                    this.checkout = false;
                }
            }
            return signed;
        },
        selectPaymentMethod() {
            if (!this.selectedClient) return;
            return this.selectedClient.defaultMethod
                ? "default"
                : this.swipedCard
                ? "capture"
                : null;
        },
        subtotal() {
            if (this.productsInCart.length == 0) return 0;
            let prices = this.productsInCart.map((e) => {
                if (e.proration) {
                    return Number(e.proration) * e.quantity;
                }

                return Number(e.finalPrice) * e.quantity;
            });
            return prices.reduce((a, b) => a + b, 0);
        },
        getTaxes() {
            if (this.productsInCart.length == 0) return 0;
            const prices = this.productsInCart.filter((e) => e.taxable);
            if (!prices.length) return 0;
            const rate = this.taxRate.percentage / 100;
            const taxes = prices.map((e) =>
                Math.round(rate * e.finalPrice * e.quantity)
            );
            const totalTaxes = taxes.reduce((a, b) => a + b, 0);
            return totalTaxes;
        },
        totalDiscount() {
            return this.activePromos.length > 0
                ? this.activePromos.map((e) => e.amount).reduce((a, b) => a + b)
                : 0;
        },
        finalPrice() {
            if (this.productsInCart.length == 0) return 0;
            if (this.subtotal + this.getTaxes - this.totalDiscount < 0)
                return 0;
            let total = this.subtotal + this.getTaxes - this.totalDiscount;
            if (this.showClientBalance) {
                total = total - this.calculateClientBalance;
            }
            return total;
        },
        getClientBalance() {
            return this.clientBalance
                ? (this.clientBalance * -1).toFixed(2)
                : 0;
        },
        showClientBalance() {
            return this.calculateClientBalance > 0 && this.subtotal > 0;
        },
        calculateClientBalance() {
            if (this.totalDiscount >= this.subtotal + this.getTaxes) {
                return 0;
            } else if (
                this.subtotal + this.getTaxes - this.getClientBalance >
                0
            ) {
                return this.getClientBalance;
            } else {
                return this.subtotal + this.getTaxes;
            }
        },
        requiresContract() {
            return !!this.productsInCart.find((e) => {
                return e.contract;
            });
        },
    },
    mounted() {
        if (!this.selectedClient) return;
        if (
            !this.selectedClient.locations
                .map((e) => e.id)
                .includes(this.$page.props.auth.user.selected_location.id)
        ) {
            this.locationWarning = true;
            return;
        }
        this.getStripeClient();
        this.cartCopy = JSON.parse(JSON.stringify(this.productsInCart));
    },
    watch: {
        productsInCart: {
            handler: function (cart) {
                this.getContracts();
                this.checkRecipientQuantities();
                // check if any cart products have changed quantity
                if (
                    cart.map((e) => e.quantity).reduce((a, b) => a + b, 0) !==
                        this.cartCopy
                            .map((e) => e.quantity)
                            .reduce((a, b) => a + b, 0) &&
                    this.activePromos.length
                ) {
                    console.log(true);
                    this.activePromos = [];
                    this.$refs.checkout?.applyPromo();
                }
                this.cartCopy = JSON.parse(JSON.stringify(this.productsInCart));
            },
            deep: true,
        },
        validCheckout() {
            this.openCheckout();
        },
    },
};
</script>

<style scoped>
.checkout-open {
    padding-bottom: 300px;
}

.moveToTop {
    z-index: 12;
}
</style>
