<template>
    <div class="caption soli-grey flex whitespace-nowrap">
        <div class="overflow-hidden clip-text">
            {{ `${lesson.teacher.name}&nbsp` }}
        </div>
        · {{ lesson.online ? "Online" : lesson.room.title }} ·
        {{ lesson.duration }} min
    </div>
</template>

<script>
export default {
    props: {
        lesson: {
            type: Object,
            default: () => {},
        },
        hideDuration: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.clip-text {
    text-overflow: ellipsis;
    max-width: 96px;
}
</style>
