<template>
    <!-- removed     @transitionend="closeAvail" -->
    <v-navigation-drawer
        v-model="dialog"
        :width="
            $vuetify.breakpoint.width < 800 ? '-webkit-fill-available' : 300
        "
        :bottom="$vuetify.breakpoint.width < 800 ? true : false"
        :right="$vuetify.breakpoint.width >= 800 ? true : false"
        app
        @input="closeAvail"
        mobile-breakpoint="799"
        disable-resize-watcher
        :class="[
            'shadow-left safe-bottom-margin',
            $vuetify.breakpoint.width < 800 ? 'peek soli-round' : null,
        ]"
        touchless
        style="z-index: 10 !important; max-height: 100%"
        id="manageAssign"
    >
        <v-list
            class="px-4 soli-round"
            style="
                position: fixed;
                background: white;
                width: -webkit-fill-available;
                z-index: 11px;
            "
        >
            <div class="soli-caption">
                Room Assignment
                <v-btn icon class="cancel-btn mr-1" @click="closeAvail(false)"
                    ><v-icon>close</v-icon></v-btn
                >
            </div>
            <v-list-item-title class="soli-subtitle">
                <Link :href="`/profile/${teacherId}/availability`">{{
                    teacherName
                }}</Link>
            </v-list-item-title>
        </v-list>
        <div
            :class="[
                'sidebar-fullcalendar',
                showEditing
                    ? deleteAssign
                        ? 'pad-bottom-delete '
                        : 'pad-bottom'
                    : null,
            ]"
            style="
                position: absolute;
                height: calc(100% - 64px);
                width: 100%;
                top: 64px;
            "
        >
            <v-overlay :value="apiLoading" style="z-index: 12">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <FullCalendar
                id="assignFC"
                ref="fullCalendar"
                :options="calendarOptions"
                class="animate__animated animate__fadeIn"
                ><template v-slot:eventContent="arg">
                    <div
                        :class="arg.timeText ? 'pt-1 pl-2' : null"
                        v-if="arg.event.display !== 'background'"
                    >
                        <b
                            v-if="arg.timeText"
                            :class="
                                arg.isMirror || arg.event.extendedProps.temp
                                    ? 'soli-grey'
                                    : null
                            "
                            >{{ arg.timeText }}
                            <v-btn
                                v-if="
                                    !arg.isMirror &&
                                    !arg.event.extendedProps.temp &&
                                    !arg.event.extendedProps.lessons.length
                                "
                                class="float-right"
                                icon
                                @click="deleteAssign = true"
                            >
                                <v-icon dense small class="white--text"
                                    >delete</v-icon
                                ></v-btn
                            ></b
                        ><br />
                        <div
                            v-if="
                                !arg.isMirror && !arg.event.extendedProps.temp
                            "
                        >
                            <div :class="['flex align-center mt-1']">
                                <v-icon x-small color="white" class="mr-1"
                                    >meeting_room</v-icon
                                >
                                <small>{{ arg.event.title }}</small>
                            </div>
                            <div
                                v-if="
                                    arg.event.extendedProps.recurrence_end &&
                                    !arg.event.extendedProps.removed_from_series
                                "
                                class="flex align-center mt-1"
                            >
                                <v-icon x-small color="white" class="mr-1"
                                    >event_busy</v-icon
                                >
                                <small>{{
                                    `Until ${globalMomentDate(
                                        arg.event.extendedProps.recurrence_end
                                    )}`
                                }}</small>
                            </div>
                            <div
                                v-if="
                                    arg.event.extendedProps.removed_from_series
                                "
                                class="flex align-center mt-1"
                            >
                                <v-icon x-small color="white" class="mr-1"
                                    >event_busy</v-icon
                                >
                                <small>Removed from series</small>
                            </div>
                            <div
                                v-if="arg.event.extendedProps.lessons.length"
                                class="mt-1 flex align-center"
                            >
                                <v-icon x-small color="white" class="mr-1"
                                    >timeline</v-icon
                                >
                                <small>{{
                                    `${
                                        /*.filter(
                                                i =>
                                                    globalMomentCompareDate(
                                                        i.starts_at
                                                    ) ==
                                                    globalMomentCompareDate(
                                                        arg.event.start
                                                    )
                                            )*/
                                        arg.event.extendedProps.lessons.length
                                    } lesson(s)`
                                }}</small>
                            </div>
                            <div
                                v-if="arg.event.extendedProps.instruments"
                                class="mt-1 flex align-center"
                            >
                                <v-icon x-small color="white" class="mr-1"
                                    >music_note</v-icon
                                >
                                <small>{{
                                    arg.event.extendedProps.instruments
                                }}</small>
                            </div>
                        </div>
                    </div>
                </template></FullCalendar
            >
        </div>

        <SoliChoice
            @option1="lessonConflict = false"
            title="Uh oh"
            subtitle="You are about to exclude scheduled lessons from this assignment. Please cancel those lessons before making this change."
            button1="Okay"
            v-if="lessonConflict"
            @reset="lessonConflict = false"
        >
        </SoliChoice>

        <SoliChoice
            v-if="showMoveInStudioWarning"
            permanent
            title="Are you sure?"
            :subtitle="`You are moving this assignment in-studio. ${teacherName}'s lessons will remain online for students. ${teacherName}'s online room will no longer be available.`"
            button1="I understand"
            button2="Nevermind"
            @option1="
                [
                    (form.reassignRoom = true),
                    (form.futureEvents = !selectedAssign.removed_from_series),
                    closeModal(() => (showMoveInStudioWarning = false)),
                ]
            "
            @reset="
                [
                    closeModal(() => (showMoveInStudioWarning = false)),
                    (saveChanges = false),
                ]
            "
        />

        <SoliChoice
            v-if="showSubTeacherWarning"
            permanent
            title="Are you sure?"
            :subtitle="`${form.subTeacher?.teacher?.name} will replace ${teacherName} for this assignment and lessons. <br/><br/>
                        <ul>
                        <li> - ${selectedAssign?.teacher?.first_name}'s availability will be canceled </li>
                        <li> - Students and teachers will be notified automatically </li>
                        <li> - Previously canceled lessons will NOT be rescheduled </li>
                        <li> - Affected lessons will be set to teacher-canceled</li>
                        </ul>
                    `"
            button1="I understand"
            button2="Nevermind"
            @option1="
                [
                    (form.futureEvents = false),
                    closeModal(() => (showSubTeacherWarning = false)),
                ]
            "
            @reset="
                [
                    closeModal(() => (showSubTeacherWarning = false)),
                    (form.subTeacher = null),
                    (saveChanges = false),
                ]
            "
        />

        <SoliChoice
            v-if="showSplitAssignmentWarning"
            permanent
            title="Are you sure?"
            :subtitle="`This will split the assignment into two separate assignments. ${teacherName}'s lessons will be divided between the two assignments.`"
            button1="I understand"
            button2="Nevermind"
            @option1="[closeModal(() => (showSplitAssignmentWarning = false))]"
            @reset="
                [
                    closeModal(() => (showSplitAssignmentWarning = false)),
                    (form.splitAssignment = null),
                    (saveChanges = false),
                ]
            "
        />

        <SoliChoice
            v-if="futureConflict"
            @reset="futureConflict = null"
            title="Uh oh"
            :subtitle="`${
                futureConflict.roomConflict
                    ? futureConflict.roomConflict
                    : teacherName
            }${
                futureConflict.recurrence_id
                    ? ` is already assigned at this time, starting ${globalMomentDate(
                          //conflicts[0].starts_at
                          new Date(futureConflict.date)
                      )}. Would you like to schedule ${
                          teacherName.split(' ')[0]
                      } until then?`
                    : ` is unavailable at this time`
            }`"
            :button1="futureConflict.recurrence_id ? 'Ok' : null"
            button2="Go back"
            @option1="
                (form.recurrence_end = futureConflict.date),
                    (form.noConflict = true),
                    editing ? editEvent() : addEvent()
            "
            @option2="
                [
                    cancelAssign(),
                    getEvents(),
                    (showEditing = false),
                    (form.noConflict = false),
                ]
            "
        >
        </SoliChoice>

        <SoliChoice
            v-if="availConflict"
            @reset="availConflict = null"
            title="Hey!"
            :subtitle="`This availability ends on ${globalMomentDate(
                new Date(availability.recurrence.ends_at)
            )}. Would you like to assign ${
                availability.teacher.first_name
            } until then?`"
            button1="Yep!"
            @option1="
                form.recurrence_end = globalMomentDate(
                    new Date(availability.recurrence.ends_at)
                )
            "
        ></SoliChoice>

        <v-bottom-sheet
            id="edit-assignment"
            :value="showEditing"
            v-if="showEditing"
            class="half-round"
            content-class="fixed right-0 half-round"
            :width="
                $vuetify.breakpoint.width < 800 ? '-webkit-fill-available' : 300
            "
            persistent
            hide-overlay
            ><v-card class="half-round">
                <v-btn icon @click="cancelAssign(true)" class="ma-3 soli-close"
                    ><v-icon>clear</v-icon>
                </v-btn>
                <v-card-title class="soli-grey">{{
                    getFormTitle
                }}</v-card-title>
                <v-card-subtitle class="soli-grey pt-2 pb-0 flex"
                    ><v-icon small class="mr-1">today</v-icon>
                    <v-card-text class="pa-0">{{
                        `${form.startDate}${
                            form.recurrence_end
                                ? ` until ${this.globalMomentDate(
                                      new Date(form.recurrence_end)
                                  )}`
                                : " and future events"
                        }`
                    }}</v-card-text></v-card-subtitle
                >
                <v-card-subtitle v-if="!editing" class="soli-grey pb-0 flex"
                    ><v-icon small class="mr-1">repeat</v-icon>
                    <v-card-text class="pa-0">{{
                        `
                                Weekly on ${globalMomentWeekDay(
                                    new Date(form.startDate)
                                )}s`
                    }}</v-card-text></v-card-subtitle
                >
                <v-card-subtitle class="soli-grey pb-0 flex"
                    ><v-icon small class="mr-1">schedule</v-icon>
                    <v-card-text class="pa-0">{{
                        `${globalMomentTime(
                            form.startTime
                        )} to ${globalMomentTime(form.endTime)}`
                    }}</v-card-text></v-card-subtitle
                >
                <v-expansion-panels
                    accordian
                    flat
                    v-model="panel"
                    class="ma-0"
                    v-if="form.affectedLessons.length && editing"
                >
                    <v-expansion-panel :class="['ma-0']">
                        <v-expansion-panel-header
                            expand-icon="keyboard_arrow_down"
                            class="pr-8 pl-2 py-0"
                            style="min-height: 1rem"
                        >
                            <template v-slot:actions>
                                <v-icon color="soli-grey"> $expand </v-icon>
                            </template>
                            <v-card-subtitle class="pb-0 pt-0 soli-grey flex">
                                <v-icon small class="mr-1">timeline</v-icon>
                                <v-card-text class="pa-0">{{
                                    `${form.affectedLessons.length} lesson(s)`
                                }}</v-card-text>
                            </v-card-subtitle>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            class="soli-grey pt-4"
                            style="overflow: auto; max-height: 400px"
                        >
                            <div
                                v-for="lesson in affectedLessons"
                                :key="lesson.id"
                                class="pa-2"
                            >
                                <div class="caption pb-0.5">
                                    {{ lesson.type }}
                                </div>
                                <div
                                    class="subtitle-2 font-bold"
                                    style="line-height: 16px !important"
                                    v-if="lesson.student"
                                >
                                    {{ lesson.student.name }}
                                </div>
                                <small>{{
                                    globalMomentFullDateAtTimeShort(
                                        lesson.starts_at
                                    )
                                }}</small>
                            </div>
                        </v-expansion-panel-content></v-expansion-panel
                    ></v-expansion-panels
                >
                <v-divider class="mx-3 mt-3" />
                <v-container>
                    <v-form
                        v-if="!deleteAssign"
                        :disabled="selectedAssign.cancelled"
                        class="flex flex-col gap-4"
                    >
                        <v-select
                            v-model="form.room"
                            :disabled="
                                !!form.subTeacher || !!form.splitAssignment /*
                                selectedAssign.availability
                                    ? selectedAssign.availability.online_only
                                    : false*/
                            "
                            :items="
                                rooms.filter((e) => {
                                    if (e.user_id) {
                                        return e.user_id == teacherId;
                                    }
                                    return e;
                                    /*if (!getAvailability.online_only) {
                                        return !e.user_id;
                                    } else {
                                        return e.user_id;
                                    }*/
                                })
                            "
                            item-value="id"
                            item-text="title"
                            label="Room"
                            return-object
                            prepend-inner-icon="meeting_room"
                            outlined
                            dense
                            hide-details="auto"
                            class="px-3"
                            @change="
                                [
                                    (saveChanges = true),
                                    selectedAssign.room?.user_id
                                        ? (showMoveInStudioWarning = true)
                                        : null,
                                ]
                            "
                        ></v-select>
                        <v-select
                            v-model="form.subTeacher"
                            label="Sub Teacher"
                            :items="getSubTeachers()"
                            :disabled="
                                form.room?.id !== selectedAssign.room?.id ||
                                !!form.splitAssignment
                            "
                            item-value="id"
                            item-text="teacher.name"
                            return-object
                            prepend-inner-icon="group_add"
                            hide-details="auto"
                            outlined
                            dense
                            noDataText="No teachers available"
                            class="px-3"
                            @change="
                                [
                                    (saveChanges = true),
                                    (showSubTeacherWarning = true),
                                ]
                            "
                        ></v-select>
                        <v-select
                            v-model="form.splitAssignment"
                            :items="splitAssignmentIntervals"
                            :disabled="
                                !!form.subTeacher ||
                                form.room?.id !== selectedAssign.room?.id
                            "
                            label="Split Assignment"
                            item-value="value"
                            item-text="text"
                            prepend-inner-icon="content_cut"
                            outlined
                            dense
                            hide-details="auto"
                            class="px-3"
                            @input="
                                [
                                    (saveChanges = true),
                                    (showSplitAssignmentWarning = true),
                                ]
                            "
                        />
                        <SoliBtn
                            gradient="gradient-orange"
                            class="flex"
                            @click="editing ? editEvent() : addEvent()"
                            :disabled="!saveChanges"
                            :loading="loading"
                            block
                            text="Save"
                            icon="save"
                        ></SoliBtn>
                    </v-form>
                    <div v-if="deleteAssign" class="flex">
                        <v-btn
                            color="secondary"
                            rounded
                            text
                            @click="editEvent()"
                        >
                            Delete!
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            rounded
                            @click="
                                [cancelAssign(true), (deleteAssign = false)]
                            "
                        >
                            No, take me back
                        </v-btn>
                    </div>
                </v-container></v-card
            ></v-bottom-sheet
        >
    </v-navigation-drawer>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AssignRoom from "./AssignRoom";
import { path, isEmpty, pathOr } from "ramda";
import { Link } from "@inertiajs/vue2";
//import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    name: "ManageAssign",
    mixins: [globalMoment],
    props: ["assigns", "errors", "availabilities"],
    components: {
        AssignRoom,
        FullCalendar,
        Link,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [
                    timeGridPlugin,
                    interactionPlugin,
                    //momentTimezonePlugin,
                ],
                //schedulerLicenseKey:
                //    "CC-Attribution-NonCommercial-NoDerivatives",
                initialView: "timeGridDay",
                expandRows: true,
                scrollTimeReset: false,

                //
                dayHeaderFormat: {
                    weekday: "short",
                    month: "numeric",
                    day: "numeric",
                    omitCommas: true,
                },
                //timeZone: this.$page.props.auth.user.timezone,
                longPressDelay: 500,
                windowResize: this.handleWindowResize,
                eventClick: this.handleEventClick,
                dateClick: this.handleDateClick,
                select: this.handleSelect,
                eventDrop: this.handleEventDrop,
                eventResize: this.handleEventResize,
                eventOverlap: this.handleEventOverlap,
                selectOverlap: this.handleSelectOverlap,
                selectMinDistance: 0,
                allDaySlot: false,
                selectMirror: true,
                editable: true,
                selectable: true,
                slotDuration: "00:15:00",
                slotLabelInterval: "01:00",
                height: "100%",
                headerToolbar: false,
                nowIndicator: true,
                titleFormat: {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    weekday: "long",
                },
                events: [],
                slotMinTime: "06:00:00",
                eventResizableFromStart: true,
                //viewDidMount: this.getTitle,
            },
            form: {
                id: null,
                user_id: null,
                availability_id: null,
                startDate: null,
                endTime: null,
                startTime: null,
                cancelled: false,
                room: null,
                affectedLessons: [],
                recurrence_end: null,
                noConflict: false,
                futureEvents: null,
                recurrence_id: null,
                reassignRoom: false,
                subTeacher: null,
                splitAssignment: null,
            },
            futureConflict: null,
            availability: null,
            availConflict: null,
            conflicts: null,
            revertAssign: null,
            selectedAssign: null,
            affectedLessons: [],
            panel: false,
            loading: false,
            apiLoading: null,
            lessonConflict: false,
            deleteAssign: false,
            showMoveInStudioWarning: false,
            rooms: this.$page.props.rooms,
            showEditing: false,
            saveChanges: false,
            teacherName: null,
            teacherId: null,
            dialog: false,
            lessons: null,
            showSubTeacherWarning: false,
            showSplitAssignmentWarning: false,
            rules: {
                start: [
                    (val) => (val || "").length > 0 || "This field is required",
                ],
                endRule: [
                    (val) =>
                        (this.globalMomentGetTime(val) || "") >
                            this.globalMomentGetTime(this.form.startTime) ||
                        "End time must be greater than start time",
                ],
            },
        };
    },
    computed: {
        splitAssignmentIntervals() {
            if (!this.selectedAssign) return [];

            const start = new Date(this.selectedAssign.start);
            start.setMinutes(start.getMinutes() + 15); // Exclude current start

            const end = new Date(this.selectedAssign.end);
            const intervals = [];

            while (start < end) {
                intervals.push({
                    text: this.globalMomentTime(new Date(start)),
                    value: new Date(start),
                });
                start.setMinutes(start.getMinutes() + 15);
            }

            return intervals;
        },
        getAvailability() {
            if (!this.selectedAssign) return null;
            return this.selectedAssign.availability
                ? this.selectedAssign.availability
                : this.availability
                ? this.availability
                : null;
        },
        editing() {
            let editing = this.form.id !== null && this.form.id !== "temp";
            return editing;
        },
        getFormTitle() {
            return this.editing
                ? this.deleteAssign
                    ? "Delete Assignment?"
                    : "Edit Assignment"
                : "Add Assignment";
        },
    },
    methods: {
        getSubTeachers() {
            // availability must completely cover the assignment lessons
            // should this filter out assessments too?
            const availableSubs = this.availabilities.filter((e) => {
                const filteredLessons = this.affectedLessons.filter(
                    (lesson) => {
                        if (
                            !e.instruments
                                .map((instrument) => instrument.id)
                                .includes(lesson.instrument_id) &&
                            lesson.instrument_id
                        ) {
                            return false;
                        }
                        if (
                            lesson.type == "Event" &&
                            !e.lesson_types.includes("Event")
                        ) {
                            return false;
                        }

                        if (!e.lesson_types.includes("Reschedule"))
                            return false;

                        if (e.start > lesson.starts_at) return false;
                        if (e.end < lesson.ends_at) return false;
                        return true;
                    }
                );

                return this.affectedLessons.length == filteredLessons.length;
            });

            return availableSubs;
        },
        handleWindowResize(arg) {
            setTimeout(() => {
                if (!this.dialog) return;
                this.$refs.fullCalendar?.getApi().updateSize();
            }, 500);
        },
        loadForm(assign) {
            //console.log(assign);
            if (this.revertAssign) {
                if (assign.event.id != this.revertAssign.event.id)
                    this.revertAssign = assign;
            } else {
                this.revertAssign = assign;
            }
            this.selectedAssign = this.assigns.find(
                (e) => e.id == assign.event.id
            );
            this.form.startDate = this.globalMomentDate(
                this.selectedAssign.starts_at
            );
            this.form.user_id = this.selectedAssign.user_id;
            if (assign.event.id) this.form.id = assign.event.id;
            this.form.availability_id =
                assign.event.extendedProps.availability_id;
            this.form.noConflict = false;
            //this.form.startDate = this.globalMomentDate(assign.event.start);
            this.form.startTime = assign.event.start;
            this.form.endTime = assign.event.end;
            this.form.affectedLessons = assign.event.extendedProps.lessons.map(
                (e) => e.id
            );
            this.form.room = this.selectedAssign.room;
            this.affectedLessons = assign.event.extendedProps.lessons;
            this.form.recurrence_id = assign.event.extendedProps.recurrence_id;
            this.form.recurrence_end =
                assign.event.extendedProps.recurrence_end;
            this.form.futureEvents =
                !assign.event.extendedProps.removed_from_series;
            this.form.reassignRoom = false;
        },
        handleSelectOverlap(e) {
            return e.display == "background";
        },
        editEvent() {
            this.loading = true;
            this.form.room_id = this.form.room.id;
            this.form.cancelled = this.deleteAssign;
            delete this.form.availability_id;
            (this.form.starts_at =
                this.form.startDate +
                " " +
                this.globalMoment24Time(this.form.startTime)),
                (this.form.ends_at =
                    this.form.startDate +
                    " " +
                    this.globalMoment24Time(this.form.endTime)),
                this.$inertia.put(`/assignment/${this.form.id}`, this.form, {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit("success");
                        this.showEditing = false;
                        this.saveChanges = false;
                        this.loading = false;
                        this.conflicts = null;
                        this.deleteAssign = false;
                        this.closeAvail(false);
                    },
                    onError: (errors) => {
                        console.log(
                            "Form returned errors: " +
                                JSON.stringify(this.errors)
                        );
                        this.loading = false;
                        if (!this.errors.soliError) {
                            this.futureConflict = this.errors;
                        }
                    },
                });
        },
        addEvent() {
            this.loading = true;

            let eventData = {
                starts_at:
                    this.form.startDate +
                    " " +
                    this.globalMoment24Time(this.form.startTime),
                ends_at:
                    this.form.startDate +
                    " " +
                    this.globalMoment24Time(this.form.endTime),
                room_id: this.form.room.id,
                repeating: true,
                availability_id: this.form.availability_id,
                user_id: this.form.user_id,
                recurrence_end: this.form.recurrence_end,
                noConflict: this.form.noConflict,
                recurrence_id: null,
                futureEvents: true,
                rrule_freq: "WEEKLY",
                rrule_int: "1",
            };
            //console.log(eventData);
            this.$inertia.post(`/assignment/`, eventData, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.showEditing = false;
                    this.saveChanges = false;
                    this.loading = false;
                    this.closeAvail(false);
                },
                onError: (errors) => {
                    console.log(
                        "Form returned errors: " + JSON.stringify(this.errors)
                    );
                    this.loading = false;
                    this.futureConflict = this.errors;
                },
            });
        },
        handleEventClick(assign) {
            console.log(assign.event.id);
            if (assign.event.display == "background") return;
            this.cancelAssign();
            this.loadForm(assign);
            this.showEditing = true;
        },
        handleEventDrop(assign) {
            //console.log(assign, "dragged");
            if (assign.event.extendedProps.temp) return;
            this.cancelAssign();
            this.loadForm(assign);

            let assignmentLessons = assign.event.extendedProps.lessons.filter(
                (lesson) => {
                    return lesson.recurrence_id || !lesson.cancelled;
                }
            );

            if (assignmentLessons.length) {
                if (
                    this.globalMoment24Time(assign.event.start) >
                        this.globalMoment24Time(
                            assign.event.extendedProps.lessons[0].starts_at
                        ) ||
                    this.globalMoment24Time(assign.event.end) <
                        this.globalMoment24Time(
                            assign.event.extendedProps.lessons.slice(-1)[0]
                                .ends_at
                        )
                ) {
                    assign.revert();
                    this.lessonConflict = true;
                    return;
                }
            }
            this.showEditing = true;
            this.saveChanges = true;
        },
        handleDateClick(date) {
            //console.log(date);
        },
        handleSelect(assign) {
            //console.log(assign, "creating...");
            if (this.editing) {
                this.$refs.fullCalendar?.getApi().unselect();
                return;
            }
            this.cancelAssign();
            this.selectedAssign = assign;
            this.revertAssign = assign;

            let avail = this.assigns.find((e) => {
                return (
                    new Date(e.availability.starts_at) <=
                        new Date(assign.start) &&
                    new Date(e.availability.ends_at) >= new Date(assign.end)
                );
            });

            if (!avail) {
                this.$refs.fullCalendar?.getApi().unselect();
                return;
            }
            //console.log(avail, "avail");
            this.showEditing = true;
            this.availability = avail.availability;
            this.form.availability_id = avail.availability_id;
            this.form.startDate = this.globalMomentDate(assign.start);
            this.form.user_id = this.assigns[0].user_id;
            this.form.startTime = assign.start;
            this.form.endTime = assign.end;
            this.form.id = null;
            this.checkConflicts();

            this.calendarOptions.events.push({
                id: "temp",
                title: "",
                start: assign.start,
                end: assign.end,
                color: this.assigns[0].color,
                display: "auto",
                className: "temp-assign",
                editable: true,
                extendedProps: { temp: true },
            });
            this.calendarOptions.editable = false;
            this.calendarOptions.selectable = false;
            this.$refs.fullCalendar?.getApi().unselect();
        },
        handleEventResize(assign) {
            //console.log(assign, "resize");

            // move this to on submit and suggest creating another assignment??

            if (assign.event.extendedProps.temp) return;
            this.cancelAssign();
            this.loadForm(assign);
            //console.log(assign.event.extendedProps.lessons);

            let assignmentLessons = assign.event.extendedProps.lessons.filter(
                (lesson) => {
                    return lesson.recurrence_id || !lesson.cancelled;
                }
            );

            if (assignmentLessons.length) {
                if (
                    this.globalMoment24Time(assign.event.start) >
                        this.globalMoment24Time(
                            assign.event.extendedProps.lessons[0].starts_at
                        ) ||
                    this.globalMoment24Time(assign.event.end) <
                        this.globalMoment24Time(
                            assign.event.extendedProps.lessons.slice(-1)[0]
                                .ends_at
                        )
                ) {
                    assign.revert();
                    this.lessonConflict = true;
                    return;
                }
            }

            this.showEditing = true;
            this.saveChanges = true;
        },
        handleEventOverlap(event) {
            if (event.display == "background") {
                return true;
            } else {
                return false;
            }
        },
        cancelAssign(revert) {
            if (revert && this.revertAssign.revert) {
                this.revertAssign.revert();
                //this.getEvents();
            }
            this.revertAssign = null;

            if (this.calendarOptions.events.find((e) => e.id == "temp")) {
                this.calendarOptions.events.pop();
            }
            this.affectedLessons = [];
            this.showEditing = false;
            this.saveChanges = false;
            this.calendarOptions.editable = true;
            this.calendarOptions.selectable = true;
            Object.keys(this.form).forEach((e) => (this.form[e] = null));
            this.form.noConflict = false;
            this.form.affectedLessons = [];
            //this.$refs.fullCalendar.$el.style.setProperty("height", "100%");
        },
        getAssignedLessons(event) {
            return this.lessons.filter((e) => {
                return e.assignment_id == event.id;
                /*if (event.removed_from_series) {
                    return (
                        !e.cancelled &&
                        moment(new Date(e.starts_at)).format("M/D/YY") ==
                            moment(new Date(event.starts_at)).format("M/D/YY")
                    );
                } else {
                    return e.assignment_id == event.assignment_id;
                }*/
            });
        },
        getEvents() {
            this.calendarOptions.events = [];
            this.$refs.fullCalendar
                ?.getApi()
                .gotoDate(new Date(this.assigns[0].starts_at));

            // if passing in assignments
            this.assigns.map((event) => {
                //console.log(event.color);
                this.calendarOptions.events.push({
                    id: event.id,
                    title: event.room.title,
                    start: event.starts_at,
                    end: event.ends_at,
                    color: event.cancelled ? "#cccccc" : event.color,
                    display: "auto",
                    classNames: [
                        "soli-round",
                        event.recurrence ? null : "single-assign",
                    ],
                    extendedProps: {
                        lessons: this.getAssignedLessons(event),
                        instruments: event.instruments,
                        availability_id: event.availability_id,
                        removed_from_series: event.removed_from_series,
                        recurrence_end:
                            event.recurrence && !event.removed_from_series
                                ? path(["recurrence", "ends_at"], event)
                                : moment(new Date(event.ends_at)),
                        recurrence_id: pathOr(
                            null,
                            ["recurrence", "id"],
                            event
                        ),
                    },
                    constraint: {
                        start: event.availability.starts_at,
                        end: event.availability.ends_at,
                    },
                });
                this.calendarOptions.events.push({
                    id: event.availability.id,
                    title: "",
                    start: event.availability.starts_at,
                    end: event.availability.ends_at,
                    display: "background",
                    color: event.color,
                    extendedProps: {
                        availability: event.availability,
                    },
                });
            });
            this.teacherName = this.assigns[0].teacher.name;
            this.teacherId = this.assigns[0].teacher.id;
            this.sizeAndScroll();
        },
        closeAvail(arg) {
            //console.log(arg);
            if (arg) return;
            this.dialog = false;
            this.calendarOptions.events = [];
            this.$emit("reset");
        },
        sizeAndScroll() {
            this.$nextTick(() => {
                let time = this.globalMoment24Time(this.assigns[0].starts_at);
                this.$refs.fullCalendar?.getApi().scrollToTime(time);
            });
        },
        checkConflicts() {
            // ******* check for avail conflicts *******
            if (this.availability.recurrence.ends_at) {
                console.log(
                    new Date(this.availability.recurrence.ends_at),
                    "availConflict"
                );

                this.availConflict = true;
                this.form.recurrence_end = this.availability.recurrence.ends_at;
            }
        },
    },
    created() {
        setTimeout(() => {
            if (this.apiLoading == null) this.apiLoading = true;
        }, 500);
        return axios
            .get("/api/assignments/unique-lessons", {
                params: {
                    assignment_id: this.assigns.map((e) => e.id),
                },
            })
            .then((resp) => {
                this.lessons = resp.data;
                this.$nextTick(() => {
                    this.getEvents();
                });
                this.apiLoading = false;
            });
    },
    mounted() {
        setTimeout(() => {
            this.dialog = true;
        }, 1);
        this.form.startDate = this.globalMomentDate(this.assigns[0].starts_at);
        this.form.user_id = this.assigns[0].user_id;
        //this.getEvents();
    },
};
</script>

<style>
.pad-bottom {
    padding-bottom: 180px;
}

.pad-bottom-delete {
    padding-bottom: 150px;
}

.sidebar-fullcalendar .fc-timegrid-slot {
    height: 0.2em !important;
}

.sidebar-fullcalendar .fc-grid-height {
    height: 0.2em !important;
}

.sidebar-fullcalendar .fc-timegrid-slot-minor {
    border-top: white !important;
}

.sidebar-fullcalendar .fc-timegrid-event-harness {
    margin-left: 5px !important;
}

.sidebar-fullcalendar .fc-event-mirror {
    border: 2px solid #2aabe6 !important;
    background: transparent !important;
    border-radius: 12px !important;
    box-shadow: 1px 4px 4px 0 rgba(120, 178, 233, 0.25) !important;
}

/*.v-overlay__scrim {
    z-index: 999 !important;
}*/

.temp-assign {
    border: 2px solid #2aabe6 !important;
    background: transparent !important;
    border-radius: 12px !important;
    box-shadow: 1px 4px 4px 0 rgba(120, 178, 233, 0.25) !important;
}

.peek {
    height: calc(100% - 48px) !important;
    top: 48px !important;
}
</style>
