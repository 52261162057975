<template>
    <AppLayout
        ref="layout"
        page="/schedule"
        pageTitle="Schedule"
        :errors="errors"
        hasFab
        @fabAction="addLesson"
        @refreshSchedule="fetchEvents()"
    >
        <ManageAssign
            v-if="can('Manage assignments') && sendAssigns"
            ref="manageAssign"
            :assigns="sendAssigns"
            :availabilities="availabilities"
            @reset="closeManageAssign(true)"
            @success="showMessage()"
            :errors="errors"
        ></ManageAssign>

        <v-overlay :value="loading" style="z-index: 12">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card
            :class="[
                $vuetify.breakpoint.mdAndUp ? 'soli-round' : null,
                'md:pa-1 animate__animated animate__fadeIn',
            ]"
            :flat="$vuetify.breakpoint.smAndDown"
        >
            <v-toolbar flat :class="['schedule-toolbar']">
                <v-row class="align-center justify-around" style="height: 72px">
                    <v-col cols="4" class="flex justify-start">
                        <v-btn
                            :small="$vuetify.breakpoint.smAndDown"
                            icon
                            :elevation="$vuetify.breakpoint.smAndDown ? 2 : 0"
                            @click="prev"
                            ><v-icon color="dark">chevron_left</v-icon></v-btn
                        >
                        <v-btn
                            :small="$vuetify.breakpoint.smAndDown"
                            icon
                            :elevation="$vuetify.breakpoint.smAndDown ? 2 : 0"
                            class="ml-1"
                            @click="next"
                            ><v-icon color="dark">chevron_right</v-icon></v-btn
                        ></v-col
                    >

                    <v-col cols="4" class="flex justify-center">
                        <component
                            :is="datePicker.comp"
                            v-bind="{ ...datePicker.props }"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="font-bold px-0 soli-grey"
                                    style="
                                        cursor: pointer;
                                        color: #4b5563;
                                        white-space: nowrap;
                                        z-index: 100;
                                    "
                                >
                                    {{ getViewTitle }}
                                </div>
                            </template>
                            <v-date-picker
                                @click:date="jumpDate"
                                v-model="getTitleDate"
                                :full-width="$vuetify.breakpoint.smAndDown"
                                next-icon="chevron_right"
                                prev-icon="chevron_left"
                                :class="{
                                    'safe-bottom':
                                        $vuetify.breakpoint.smAndDown,
                                }"
                            ></v-date-picker>
                        </component>
                        <small
                            style="padding-top: 21px; position: absolute"
                            v-if="showTimezone"
                        >
                            {{ showTimezone }}
                        </small>
                    </v-col>
                    <v-col cols="4" class="justify-end flex">
                        <v-btn
                            color="soli-grey"
                            icon
                            :small="$vuetify.breakpoint.smAndDown"
                            :elevation="$vuetify.breakpoint.smAndDown ? 2 : 0"
                            class="mr-1"
                            @click="setToday"
                            ><v-icon color="dark">today</v-icon></v-btn
                        >
                        <v-menu
                            left
                            :close-on-content-click="false"
                            offset-y
                            max-width="250"
                            v-model="scheduleOptions"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    :elevation="
                                        $vuetify.breakpoint.smAndDown ? 2 : 0
                                    "
                                    :small="$vuetify.breakpoint.smAndDown"
                                    color="dark"
                                    ><v-badge
                                        :value="
                                            teacherView ||
                                            eventView ||
                                            instrumentView ||
                                            hideCancelled
                                        "
                                        color="primary"
                                        overlap
                                        dot
                                        ><v-icon>more_vert</v-icon></v-badge
                                    ></v-btn
                                >
                            </template>
                            <v-list>
                                <v-list-item-title
                                    class="soli-grey px-5 py-2 font-bold"
                                    >Calendar Options</v-list-item-title
                                >
                                <v-list-item v-if="!eventView">
                                    <SoliTeachers
                                        hideDetails
                                        clearable
                                        @clear="
                                            [
                                                (teacherView = null),
                                                instrumentView
                                                    ? fetchEvents()
                                                    : dayView(),
                                            ]
                                        "
                                        :filterLocation="
                                            $page.props.auth.user
                                                .selected_location.id
                                        "
                                        :hideIcon="true"
                                        v-model="teacherView"
                                        :teacher="
                                            can('Book student assessments')
                                                ? null
                                                : $page.props.auth.user
                                        "
                                        @input="
                                            [
                                                teacherView
                                                    ? weekView()
                                                    : fetchEvents(),
                                            ]
                                        "
                                    ></SoliTeachers>
                                </v-list-item>
                                <v-list-item v-if="!eventView">
                                    <!--<SoliInstruments
                                        hideDetails
                                        :multiple="false"
                                        clearable
                                        @clear="
                                            [
                                                (instrumentView = null),
                                                dayView(),
                                                (scheduleOptions = false),
                                            ]
                                        "
                                        hideIcon
                                        v-model="instrumentView"
                                        @input="fetchEvents()"
                                        :filter="teacherView ? assignments.map(e => e.availability.instruments.map(e => e.id)).flat() : []"
                                        :key="teacherView?.id"
                                    ></SoliInstruments>-->
                                    <SoliUserInstruments
                                        :teacher="teacherView"
                                        showAll
                                        hideDetails
                                        :multiple="false"
                                        clearable
                                        @clear="
                                            [
                                                (instrumentView = null),
                                                teacherView
                                                    ? fetchEvents()
                                                    : dayView(),
                                            ]
                                        "
                                        hideIcon
                                        v-model="instrumentView"
                                        @input="fetchEvents()"
                                        :key="teacherView?.id + selectedView"
                                    />
                                </v-list-item>
                                <v-list-item
                                    v-if="!teacherView && !instrumentView"
                                >
                                    <v-select
                                        @hook:mounted="getApiGroupEvents()"
                                        hideDetails
                                        clearable
                                        @click:clear.self="
                                            [
                                                (eventView = null),
                                                (instrumentView = null),
                                                dayView(),
                                                (scheduleOptions = false),
                                            ]
                                        "
                                        label="Events"
                                        outlined
                                        dense
                                        :items="apiGroupEvents"
                                        item-text="title"
                                        item-value="id"
                                        return-object
                                        v-model="eventView"
                                        @input="
                                            [eventView ? monthView() : null]
                                        "
                                    ></v-select> </v-list-item
                                ><v-list-item
                                    v-if="teacherView || instrumentView"
                                >
                                    <v-select
                                        hideDetails
                                        :items="['Day', 'Week', 'Month']"
                                        item-text="text"
                                        return-object
                                        v-model="selectedView"
                                        label="View"
                                        outlined
                                        dense
                                        @input="[currentView.method()]"
                                    ></v-select
                                ></v-list-item>
                                <v-list-item class="h-0" @click="setCancelled()"
                                    ><v-switch
                                        color="primary"
                                        v-model="hideCancelled"
                                        dense
                                        label="Hide Cancelled Events"
                                    >
                                    </v-switch></v-list-item></v-list></v-menu
                    ></v-col>
                </v-row>
            </v-toolbar>

            <AssignRoom
                ref="assignRoom"
                v-if="availToday && getRoom"
                :assignToday="assignToday"
                :availToday="availToday"
                :searchDate="searchDate"
                :assignments="assignments"
                :label="assignLabel"
                :disabled="assignDisabled"
                :getRoom="getRoom"
                :errors="errors"
                @success="handleSuccess"
                @reset="availToday = null"
                @hook:mounted="closeManageAssign(false)"
            ></AssignRoom>

            <ReassignRoom
                ref="reassignRoom"
                v-if="reassignEvent && can('Manage assignments')"
                :lessons="affectedLessons"
                :assignments="affectedAssignments"
                :reassignEvent="reassignEvent"
                :errors="errors"
                @success="handleSuccess"
                @reset="reassignEvent = null"
                @hook:mounted="closeManageAssign(false)"
            ></ReassignRoom>

            <LessonDetail
                v-if="clickedLessonDetails"
                :errors="$page.props.errors"
                :auth="$page.props.auth"
                :lessonId="clickedLessonDetails"
                @rescheduleLesson="
                    [
                        setClickData($event),
                        (bookingForm = true),
                        (bookingSource.isReschedule = true),
                        (clickedLessonDetails = null),
                    ]
                "
                @reset="clickedLessonDetails = null"
                @refresh="refresh()"
                @success="handleSuccess"
                @showMessage="showMessage()"
                @hook:mounted="closeManageAssign(false)"
            />

            <!--<NewLessonDialog
                ref="newLessonDialog"
                v-if="bookingForm"
                :event="clickData"
                @success="handleSuccess"
                :fail="$page.props.failureMessage"
                @reset="reset"
                :errors="errors"
                :rooms="location.rooms"
                :assignments="assignments"
                :lessons="lessons"
                :bookingSource="bookingSource"
                :tempLessons="apiTempLessons"
                @hook:mounted="closeManageAssign(false)"
                :calendarDate="calendarRange.start"
                :teacherView="teacherView"
                :getEventStudent="
                    bookingSource.isCalendar ? clickData.student : null
                "
            >
            </NewLessonDialog>-->

            <CreateLesson
                v-if="bookingForm"
                :errors="errors"
                :event="clickData"
                :bookingSource="bookingSource"
                @hook:mounted="closeManageAssign(false)"
                @reset="reset()"
                @success="handleSuccess"
            />

            <!-- Schedule PopOver Menu -->
            <v-menu
                v-model="showMenu"
                content-class="soli-round"
                :activator="getMirror"
                :left="popOverLeft"
                offset-x
                :transition="null"
                :close-on-content-click="false"
                min-width="300"
                ><v-card class="soli-round" style="width: 300px">
                    <v-btn
                        icon
                        @click="reset"
                        class="ma-3 float-right white--text"
                        ><v-icon>clear</v-icon></v-btn
                    >
                    <div class="soli-blue white--text half-round">
                        <v-card-title>Book Appointment</v-card-title>
                        <v-card-subtitle
                            ><v-switch
                                v-if="can('Book student assessments')"
                                class="mt-0 px-1 pt-0 w-full white--text"
                                hide-details
                                color="white"
                                v-model="overrideServices"
                                dense
                                label="Override Teacher Services"
                                dark
                            ></v-switch>
                        </v-card-subtitle>
                    </div>
                    <v-container>
                        <SoliLessonTypes
                            v-if="clickData"
                            :key="clickData.dateStr"
                            :event="clickData"
                            :bgEventClicked="bgEventClicked"
                            :overrideServices="overrideServices"
                            @input="
                                [
                                    (bookingForm = true),
                                    (clickData.type = $event),
                                    (showMenu = false),
                                ]
                            "
                        /> </v-container></v-card
            ></v-menu>
            <div
                :class="
                    $vuetify.breakpoint.width < 800 && hideCalendar
                        ? 'hide'
                        : null
                "
            >
                <FullCalendar
                    ref="fullCalendar"
                    id="fc-schedule"
                    :options="calendarOptions"
                >
                    <template v-slot:eventContent="arg">
                        <AssignmentEvent v-if="arg.event.allDay" :arg="arg" />
                        <LessonEvent
                            v-if="!arg.event.allDay"
                            :arg="arg"
                            :hideLessons="hideLessons"
                        />
                    </template>
                </FullCalendar>
            </div>
        </v-card>
        <SoliChoice
            v-if="alertSixWeeks"
            title="Sorry!"
            subtitle="Unable to schedule lessons more than six weeks from today"
            button1="Okay"
            @reset="alertSixWeeks = false"
        ></SoliChoice>
        <SoliChoice
            v-if="alertCannotReschedule"
            title="Sorry!"
            subtitle="This lesson is unable to be rescheduled"
            button1="Okay"
            @reset="alertCannotReschedule = false"
        ></SoliChoice>
    </AppLayout>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import AssignRoom from "./../Components/Teachers/AssignRoom";
import ReassignRoom from "./../Components/Teachers/ReassignRoom";
import ManageAssign from "./../Components/Teachers/ManageAssign";
import CreateLesson from "./../Components/Booking/CreateLesson";
import globalMoment from "../Mixins/GlobalMoment";
import AssignmentEvent from "./../Components/Schedule/AssignmentEvent";
import LessonEvent from "./../Components/Schedule/LessonEvent";
import LessonDetail from "./../Components/Lessons/LessonDetail.vue";

import { Link } from "@inertiajs/vue2";
//import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { VMenu, VBottomSheet } from "vuetify/lib";

import { path, isEmpty } from "ramda";

import AppLayout from "./../Layouts/AppLayout";

export default {
    mixins: [globalMoment],
    props: {
        availabilities: { type: Array, default: () => [] },
        assignments: {},
        teacherRoomEvents: {},
        lessons: {},
        errors: {},
        rooms: {},
        auth: {},
        // locations: {},
        calendarRange: {},
        initialTeacher: {},
        initialInstrument: {},
        initialView: {},
        initialEvent: {},
        hideLessons: { type: Boolean, default: false },
    },
    components: {
        AppLayout,
        AssignRoom,
        ReassignRoom,
        FullCalendar,
        ManageAssign,
        Link,
        VMenu,
        VBottomSheet,
        CreateLesson,
        AssignmentEvent,
        LessonEvent,
        LessonDetail,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    interactionPlugin,
                    resourceDayGridPlugin,
                    resourceTimeGridPlugin,
                    scrollGridPlugin,
                    //momentTimezonePlugin,
                ],
                schedulerLicenseKey: "0181748285-fcs-1702213304",
                initialView: this.getInitialView(),
                initialDate: this.calendarRange.start,
                //timeZone: this.$page.props.auth.user.timezone,
                longPressDelay: 500,
                windowResize: this.handleWindowResize,
                scrollTimeReset: false,
                eventClick: this.handleEventClick,
                eventDrop: this.handleEventDrop,
                select: this.handleSelect,
                dateClick: this.handleDateClick,
                eventResize: function (arg) {
                    arg.revert();
                },
                slotDuration: "00:15:00",
                eventOrder: "start",
                selectOverlap: this.getSelectOverlap,
                eventOverlap: function (stillEvent, movingEvent) {
                    return (
                        stillEvent.display == "background" ||
                        stillEvent.allDay ||
                        stillEvent.extendedProps.cancelled
                    );
                },
                dayMinWidth: 175,
                //snapDuration: "00:30:00", // this doesn't work when slotDuration is smaller than snapDuration. known issue in FC, no timeline for correction
                slotLabelInterval: { hours: 1 },
                selectable: true,
                selectMirror: true,
                selectMinDistance: this.$vuetify.breakpoint.xs ? 0 : 20,
                allDaySlot: true,
                allDayText: "Teacher",
                height: !this.$vuetify.breakpoint.smAndDown
                    ? "calc(100vh - 176px)"
                    : "auto",
                stickyHeaderDates: false,
                headerToolbar: false,
                nowIndicator: true,
                editable: true,
                titleFormat: {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                },
                eventSources: [],
                refetchResourcesOnNavigate: true,
                resources: (fetchInfo, successCallback, failureCallback) => {
                    let resources = this.rooms.filter(
                        (e) =>
                            !e.user_id ||
                            this.availabilities.find(
                                (a) =>
                                    a.user_id == e.user_id &&
                                    a.online_only &&
                                    this.globalMomentCompareDate(a.starts_at) ==
                                        this.globalMomentCompareDate(
                                            fetchInfo.start
                                        )
                            ) ||
                            this.assignments.find((a) => {
                                return (
                                    a.user_id == e.user_id &&
                                    a.availability.online_only &&
                                    this.globalMomentCompareDate(a.starts_at) ==
                                        this.globalMomentCompareDate(
                                            fetchInfo.start
                                        )
                                );
                            })
                    );
                    successCallback(resources);
                },

                resourceOrder: "sort, created_at",
                slotMinTime: "06:00:00",
                //slotMaxTime: "23:00:00",
                //height: "100%",
                //aspectRatio: 1,
                viewDidMount: this.getTitle,
                navLinks: true,
                navLinkDayClick: (event) => {
                    this.jumpDate(event);
                    if (this.teacherView) {
                        this.dayTeacherView();
                    } else if (this.eventView) {
                        this.eventView = null;
                        this.dayView();
                    } else {
                        this.dayView();
                    }
                },
            },
            //scroller: null,
            //fcScrollEl: null,
            teacherView: this.initialTeacher,
            eventView: this.initialEvent,
            instrumentView: this.initialInstrument,
            selectedView: this.initialView ? this.initialView : "Day",
            groupEventLoading: false,
            apiGroupEvents: [],
            viewOptions: [
                { name: "Day", method: this.dayView },
                { name: "Week", method: this.weekView },
                { name: "Month", method: this.monthView },
            ],
            apiTempLessons: [],
            sendAssigns: null,
            sendLessons: null,
            loading: false,
            bookingForm: false,
            showBorders: null,
            scrolled: null,
            stickyRoom: null,
            stickyTeacher: null,
            stickyToolbar: null,
            stickyDivider: null,
            isSafari: null,
            isScrolled: null,
            bgEventClicked: null,
            bookingSource: { isNew: false, isCalendar: false }, //null,
            dragging: false,
            getRoom: null,
            roomName: null,
            affectedLessons: null,
            affectedAssignments: null,
            reassignEvent: null,
            eventType: null,
            clickData: null,
            overrideServices: false,
            clickedLessonDetails: null,
            dialog: null,
            title: null,
            picker: null,
            availToday: null,
            assignToday: null,
            searchDate: null,
            assignLabel: "Available Teachers",
            assignDisabled: null,
            showAvailability: false,
            //keepScroll: null,
            hideCalendar: false,
            scheduleOptions: false,
            hideCancelled: false,
            colors: [
                "#FF6363",
                "#E26B8A",
                "#C472B1",
                "#FF9954",
                "#FFCF45",
                "#8980FF",
                "#26C1D2",
                "#2CA9D7",
                "#30A89A",
                "#00C88C",
            ],
            shadows: [
                "all-day-shadow-1",
                "all-day-shadow-2",
                "all-day-shadow-3",
                "all-day-shadow-4",
                "all-day-shadow-5",
                "all-day-shadow-6",
                "all-day-shadow-7",
                "all-day-shadow-8",
                "all-day-shadow-9",
                "all-day-shadow-10",
            ],
            getCalendarDate: null,
            scheduleToolbar: [{ icon: "visibility" }],
            toggle_exclusive: null,
            selectLocation: this.$page.props.auth.user.selected_location,
            showMenu: false,
            popOverLeft: false,
            getMirror: null,
            alertSixWeeks: false,
            alertCannotReschedule: false,
        };
    },
    methods: {
        canReschedule(lesson) {
            if (lesson.student?.attendance.status == "Attended") {
                return false;
            }
            if (lesson.group_event) {
                if (lesson.cancelled) {
                    return (
                        lesson.series.n >= lesson.group_event.occurrences ||
                        true
                    );
                }
                if (lesson.students.length) return false;
            }

            if (!lesson.student) return false;

            const inRescheduleWindow = moment(lesson.starts_at).isBetween(
                moment().add(lesson.rescheduleWindow, "hours"),
                moment().add(2, "weeks")
            );

            if (
                !inRescheduleWindow &&
                !lesson.cancelled &&
                !this.can("Schedule within 24 hours") &&
                !this.can("Schedule outside of two weeks")
            ) {
                return false;
            }

            if (!lesson.cancelled) return true;

            const hasRescheduleMinutes =
                lesson.student?.attendance?.reschedule_minutes;
            const hasReschedulePass =
                lesson.student?.attendance?.reschedule_pass;

            return hasRescheduleMinutes || hasReschedulePass;
        },
        setClickData(data) {
            this.reset(null, true);
            this.clickData = data;
        },
        showMessage() {
            this.$refs.layout.showMessage = true;
        },
        getApiGroupEvents(type) {
            this.groupEventLoading = true;
            axios
                .get("/api/lessons/all-group-events")
                .then((resp) => {
                    this.apiGroupEvents = resp.data;
                    this.groupEventLoading = false;
                })
                .catch((error) => {
                    this.axiosError = error;
                });
        },
        getInitialView() {
            if (
                !this.initialTeacher &&
                !this.initialEvent &&
                !this.initialInstrument
            ) {
                return "resourceTimeGridDay";
            }
            if (this.initialView == "Month") return "dayGridMonth";
            if (this.initialView == "Week") return "timeGridWeek";
            if (this.initialView == "Day") {
                return "timeGridDay";
            }
        },
        /*toggleCancelled() {
            setTimeout(() => {
                this.hideCancelled = !this.hideCancelled;
                this.setCancelled();
            }, 300);
        },*/
        setCancelled() {
            if (!this.hideCancelled) {
                let el = document.querySelectorAll(".cancelled");
                el.forEach((e) => e.classList.remove("hide"));
            } else {
                let el = document.querySelectorAll(".cancelled");
                el.forEach((e) => e.classList.add("hide"));
            }
        },
        getApiTempLessons() {
            if (this.hideLessons) {
                this.apiTempLessons = [];
                this.getEvents();
                return;
            }
            return axios
                .get("/api/placeholder-lessons", {
                    params: {
                        /*date: moment(this.calendarRange.start)
                            .tz("UTC")
                            .format(),*/
                        start: moment(this.calendarRange.start)
                            .tz("UTC")
                            .format(),
                        end: moment(this.calendarRange.end).tz("UTC").format(),
                        location:
                            this.$page.props.auth.user.selected_location.id,
                        teacher: this.teacherView ? this.teacherView.id : null,
                    },
                })
                .then((resp) => {
                    this.apiTempLessons = resp.data;
                    this.getEvents();
                });
        },
        fetchEvents() {
            this.loading = true;

            this.$inertia.visit(
                this.route("schedule.index", [
                    this.$page.props.auth.user.selected_location.id,
                    this.getTitleDate,
                ]),
                {
                    data: {
                        teacher: this.teacherView ? this.teacherView.id : null,
                        instrument: this.instrumentView?.id,
                        view: this.selectedView,
                        event: this.eventView ? this.eventView.id : null,
                    },
                    only: [
                        "lessons",
                        "availabilities",
                        "assignments",
                        "teacherRoomEvents",
                        "calendarRange",
                        "hideLessons",
                        "view",
                        "rooms",
                    ],
                    preserveState: true,
                    preserveScroll: true,
                    onFinish: () => {
                        this.getEvents();
                        this.getApiTempLessons();
                        this.$refs.fullCalendar?.getApi().refetchResources();
                        this.loading = false;
                    },
                }
            );
        },
        closeManageAssign(refresh) {
            this.hideCalendar = false;
            let timeout = this.$vuetify.breakpoint.width < 800 ? 300 : 0;
            let timeoutFromMountedHook = refresh ? 200 : 500;
            setTimeout(() => {
                this.sendAssigns = null;
            }, timeout);
            setTimeout(() => {
                this.$refs.fullCalendar?.getApi().updateSize();
                if (refresh) this.getEvents();
            }, timeoutFromMountedHook);
        },
        handleWindowResize(size) {
            setTimeout(() => {
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.$refs.fullCalendar
                        .getApi()
                        .setOption("height", "auto");
                } else {
                    this.$refs.fullCalendar
                        .getApi()
                        .setOption("height", "calc(100vh - 176px)");
                }
                this.$refs.fullCalendar?.getApi().updateSize();
                this.handleStickyHeader;
            }, 300);
        },
        getSelectOverlap(event) {
            return (
                event.classNames.includes("fc-assign") ||
                event.allDay ||
                event.classNames.includes("cancelled") ||
                event.display == "background"
            );
        },
        refresh() {
            this.fetchEvents();
            this.getApiTempLessons();
        },
        handleSuccess(event) {
            this.showMessage();
            setTimeout(() => {
                this.reset();
            }, 300);
            this.fetchEvents();
            this.getApiTempLessons();
        },
        addLesson() {
            this.reset(null, true);
            this.bookingSource.isNew = true;
            this.bookingForm = true;
        },
        addMinutes(date, minutes) {
            return new Date(new Date(date).getTime() + minutes * 60000);
        },
        handleSelect(arg) {
            if (this.hideLessons) {
                this.$refs.fullCalendar?.getApi().unselect();
                return;
            }
            if (arg.allDay) {
                this.getAvailableTeachers(arg);
                return;
            }
            if (this.dragging) {
                this.$refs.fullCalendar?.getApi().unselect();
                this.showMenu = false;
                return;
            }
            this.dragging = true;
            if (arg.jsEvent) {
                arg.date = arg.start; // pass correct obj key to newLessonDialog
                if (
                    !this.can("Schedule outside of six weeks") &&
                    moment(new Date(arg.date)).toISOString(true) >
                        moment().endOf("day").add(6, "weeks").toISOString(true)
                ) {
                    this.alertSixWeeks = true;
                    this.dragging = false;
                    return;
                }
                arg.duration = moment
                    .duration(moment(arg.end).diff(moment(arg.start)))
                    .as("minutes");
                setTimeout(() => {
                    this.showPopOver(arg);
                }, 1);
            }
        },
        handleDateClick: function (arg) {
            if (this.hideLessons) return;
            if (this.eventView) {
                return;
            }

            if (arg.resource?.extendedProps?.registration_room) return;
            if (arg.resource?.extendedProps?.waitlist_room) return;

            if (arg.allDay) {
                if (!this.can("Manage assignments")) return;
                return this.getAvailableTeachers(arg);
            }

            if (
                !this.can("Schedule outside of six weeks") &&
                moment(new Date(arg.date)).toISOString(true) >
                    moment().endOf("day").add(6, "weeks").toISOString(true)
            ) {
                this.alertSixWeeks = true;
                return;
            }
            //if (this.$vuetify.breakpoint.xs) return;
            arg.end = this.addMinutes(arg.date, 30);
            arg.duration = 30;
            // stop from clicking on space with existing lesson -> change this to the checkConflicts function
            let lessons = this.lessons
                .filter(
                    (e) =>
                        e.resourceId == arg.resource?.id &&
                        !e.extendedProps.cancelled
                )
                .find(
                    (e) =>
                        (new Date(e.start) <= arg.date &&
                            new Date(e.end) > arg.date) ||
                        (new Date(e.start) < arg.end &&
                            new Date(e.end) >= arg.end)
                );

            if (lessons) return;
            // Continue

            // select 30 minutes if clicked ** FC bug workaround **
            if (!this.dragging) {
                this.$refs.fullCalendar?.getApi().select({
                    start: arg.date,
                    end: this.addMinutes(arg.date, 30),
                    allDay: false,
                    resourceId: arg.resource?.id,
                });
                this.showPopOver(arg);
            }
        },
        showPopOver(arg) {
            //console.log(arg);
            this.getMirror = this.$el.querySelector(".fc-event-mirror");
            arg.teacher = this.teacherView ?? null;
            arg.teacherView = !!this.teacherView;
            arg.instrumentView = !!this.instrumentView;
            arg.instrument = this.instrumentView ?? null;

            arg.assignment =
                this.assignments.find((e) => {
                    let matchRoom = arg.resource?.id;
                    let matchTeacher = arg.teacher?.id;

                    return (
                        (e.room?.id == matchRoom ||
                            e.teacher?.id == matchTeacher) &&
                        new Date(e.start) <= arg.date &&
                        new Date(e.end) >= arg.end &&
                        !e.cancelled
                    );
                }) ?? null;

            arg.availabilities =
                this.availabilities.filter((e) => {
                    return (
                        new Date(e.start) <= arg.date &&
                        new Date(e.end) >= arg.end &&
                        e.pivot.location_id ==
                            this.$page.props.auth.user.selected_location.id &&
                        !e.cancelled
                    );
                }) ?? null;

            arg.location = this.$page.props.auth.user.selected_location;

            this.setClickData(arg);

            arg.jsEvent.clientX > this.$vuetify.breakpoint.width - 250
                ? (this.popOverLeft = true)
                : (this.popOverLeft = false);
            this.$nextTick(() => {
                this.showMenu = true;
            });
            this.bookingSource.isCalendar = true;
        },
        reset(data, instant) {
            this.clickData = null;
            this.showMenu = false;
            this.bgEventClicked = null;
            this.reassignEvent = null;
            this.availToday = null;
            this.clickedLessonDetails = null;
            instant
                ? (this.bookingForm = false)
                : this.closeModal(() => (this.bookingForm = false));
            Object.keys(this.bookingSource).forEach((e) => {
                this.bookingSource[e] = false;
            });
            if (data == "restoreSource") {
                this.bookingSource.isCalendar = true;
            }
        },
        handleEventClick: function (arg) {
            if (this.hideLessons) return;
            // *** if clicking on teacherEvent ***
            if (arg.event.allDay) {
                //console.log(arg);
                let room_id = Number(arg.event.extendedProps.room_id);
                // find assignment that was clicked
                this.sendAssigns = null;
                let assigns = this.calendarOptions.eventSources[0].filter(
                    (e) => {
                        if (e.teacher)
                            return (
                                this.globalMomentCompareDate(e.start) ==
                                    this.globalMomentCompareDate(
                                        arg.event.start
                                    ) &&
                                e.teacher.id ==
                                    arg.event.extendedProps.teacher.id
                            );
                    }
                );

                this.$nextTick(() => {
                    this.sendAssigns = assigns;
                });

                setTimeout(() => {
                    this.hideCalendar = true;
                }, 500);
            } else {
                // *** if clicking on assignment line ***
                if (arg.event.display == "background") {
                    this.bgEventClicked = arg.event.title;
                    return;
                }

                if (arg.event.extendedProps.type == "Pending") {
                    return console.log(arg.event.id);
                }

                // *** if clicking on lesson ***
                if (!this.can("Book student assessments")) {
                    //console.log(arg);

                    let studentId = arg.event.extendedProps.student?.id;
                    let teacherId = arg.event.extendedProps.teacher.id;
                    if (
                        teacherId !== this.auth?.user?.id &&
                        studentId !== this.auth?.user?.id &&
                        !this.auth?.user?.relationships
                            .map((e) => e.id)
                            .includes(studentId)
                    ) {
                        return;
                    }
                }

                this.clickedLessonDetails = arg.event.id;
            }
        },
        handleEventDrop: function (arg) {
            //console.log(arg);
            if (this.hideLessons) return arg.revert();
            // if new resource and no availability then
            if (
                arg.event.allDay &&
                this.can("Manage assignments") &&
                !this.teacherView
            ) {
                let room_id = Number(arg.oldResource.id);
                let assignments = this.assignments.filter(
                    (e) =>
                        e.room_id == room_id &&
                        e.teacher.id == arg.event.extendedProps.teacher.id &&
                        this.globalMomentCompareDate(e.start) ==
                            this.globalMomentCompareDate(arg.event.start)
                );
                this.affectedAssignments = assignments;
                this.reassignEvent = arg;
            } else {
                //arg.revert();
                //  WIP: started to allow drag and drop reschedule,
                // but need to get the purchase option from the original lesson
                // and handle all of the allowances and edge cases
                this.handleDragDropReschedule(arg);
            }
        },
        async handleDragDropReschedule(arg) {
            this.loading = true;

            const lesson = await axios
                .get(`/api/lessons/find/${arg.event.id}`)
                .then((resp) => {
                    this.loading = false;
                    return resp.data;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });

            if (!this.canReschedule(lesson)) {
                this.alertCannotReschedule = true;
                arg.revert();
                return;
            }

            const bookingData = {};

            bookingData.teacher = this.teacherView ?? null;
            bookingData.teacherView = !!this.teacherView;
            bookingData.instrumentView = !!this.instrumentView;
            bookingData.instrument = this.instrumentView ?? null;
            bookingData.date = arg.event.start;

            bookingData.assignment =
                this.assignments.find((e) => {
                    let matchRoom = (arg.newResource ?? lesson.room)?.id;
                    let matchTeacher = bookingData.teacher?.id;

                    return (
                        (e.room?.id == matchRoom ||
                            e.teacher?.id == matchTeacher) &&
                        new Date(e.start) <= arg.event.start &&
                        new Date(e.end) >= arg.event.start &&
                        !e.cancelled
                    );
                }) ?? null;

            bookingData.availabilities =
                this.availabilities.filter((e) => {
                    return (
                        new Date(e.start) <= arg.event.start &&
                        new Date(e.end) >= arg.event.end &&
                        e.pivot.location_id ==
                            this.$page.props.auth.user.selected_location.id &&
                        !e.cancelled
                    );
                }) ?? null;

            const type = arg.event.extendedProps.type;

            bookingData.location = this.$page.props.auth.user.selected_location;
            bookingData.student = lesson.student;
            bookingData.credit = lesson;
            bookingData.duration = lesson.duration;
            bookingData.resource = arg.newResource ?? lesson.room;
            bookingData.id = lesson.id;
            //bookingData.teacher = lesson.teacher;
            //bookingData.instrument = lesson.instrument;
            bookingData.purchaseOption =
                lesson.student.attendance.purchase_option;
            bookingData.type = {
                name: type == "Weekly Lesson" ? "Reschedule" : type,
                originalType: type == "Reschedule" ? "Weekly Lesson" : type,
                reschedule: true,
                disabled: false,
                recurring: false,
            };
            //console.log(bookingData);
            this.setClickData(bookingData);
            this.bookingSource.isDragDropReschedule = true;
            this.bookingForm = true;
            arg.revert();
        },
        /*getAssignmentLessons(assignments, start, room_id) {
            return axios.get("/api/assignments/recurrence", {
                params: {
                    recurrence_id: assignments
                        .filter((e) => e.recurrence_id != null)
                        .map((e) => e.recurrence_id), //.map((e) => e.recurrence_id),
                    starts_at: this.title,
                },
            });
        },*/
        getEvents() {
            this.calendarOptions.eventSources = [];
            let merged = [].concat(this.assignments, this.teacherRoomEvents);
            this.getColors(merged);
            this.calendarOptions.eventSources.push(
                merged,
                this.lessons,
                this.apiTempLessons
            );
            this.$nextTick(() => {
                this.setCancelled();
            });
        },
        getAvailableTeachers(arg) {
            this.searchDate = arg.date;
            this.getRoom = arg.resource;
            let availToday = this.availabilities;
            this.availToday = availToday.sort((a, b) => a.user_id - b.user_id);
            this.assignToday = availToday;

            if (this.assignToday.length > 0) {
                this.assignDisabled = false;
                this.assignLabel = "Available Teachers";
            } else {
                this.assignLabel = "No Teachers Available";
                this.assignDisabled = true;
            }
        },
        groupBy(arr, prop) {
            const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
            arr.forEach((obj) => map.get(obj[prop]).push(obj));
            return Array.from(map.values());
        },
        getColors(merged) {
            let dates = this.groupBy(merged, "startDay");
            let teachers = dates.map((date, idx) => {
                return this.groupBy(dates[idx], "user_id");
            });
            teachers.map((teacher) => {
                teacher.map((event, idx) => {
                    event.map((addColor) => {
                        addColor.color = this.colors[idx];
                        addColor.title == ""
                            ? null
                            : addColor.classNames.push(this.shadows[idx]);
                    });
                });
            });
        },
        setToday() {
            this.$refs.fullCalendar?.getApi().today();
            this.scrollToTime();
            this.getTitle();
            this.fetchEvents();
        },
        prev() {
            this.$refs.fullCalendar?.getApi().prev();
            this.scrollToTime();
            this.getTitle();
            this.fetchEvents();
        },
        next() {
            this.$refs.fullCalendar?.getApi().next();
            this.scrollToTime();
            this.getTitle();
            this.fetchEvents();
        },
        getTitle() {
            this.title = moment(
                this.$refs.fullCalendar?.getApi().currentData.currentDate
            ).tz("UTC");
        },
        monthView() {
            this.calendarOptions.eventSources = [];
            this.$refs.fullCalendar?.getApi().changeView("dayGridMonth");
            this.getTitle();
            this.selectedView = "Month";
            this.fetchEvents();
        },
        dayView() {
            if (this.teacherView) {
                return this.dayTeacherView();
            }
            this.calendarOptions.eventSources = [];
            this.$refs.fullCalendar?.getApi().changeView("resourceTimeGridDay");
            this.getTitle();
            this.selectedView = "Day";
            this.fetchEvents();
        },
        dayTeacherView() {
            this.calendarOptions.eventSources = [];
            this.$refs.fullCalendar?.getApi().changeView("timeGridDay");
            this.getTitle();
            this.selectedView = "Day";
            this.fetchEvents();
        },
        weekView() {
            this.calendarOptions.eventSources = [];
            this.$refs.fullCalendar?.getApi().changeView("timeGridWeek");
            this.getTitle();
            this.selectedView = "Week";
            this.fetchEvents();
        },
        jumpDate(date) {
            this.calendarOptions.eventSources = [];
            this.$refs.fullCalendar?.getApi().gotoDate(date);
            this.getTitle();
            this.fetchEvents();
        },
        scrollToTime(event) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                let time;
                if (event) {
                    time = event + ":00:00";
                } else {
                    time = new Date().getHours() + ":00:00";
                }
                this.$refs.fullCalendar?.getApi().scrollToTime(time);
                return;
            }

            const nowIndicator = document.querySelector(
                ".fc-timegrid-now-indicator-line"
            );

            if (!nowIndicator) return;

            setTimeout(() => {
                const nowIndicator = document.querySelector(
                    ".fc-timegrid-now-indicator-line"
                );

                const indicatorPosition = nowIndicator.offsetTop;
                window.scrollTo({
                    top: indicatorPosition,
                    behavior: "smooth",
                });
            }, 300);
        },
        handleStickyHeader() {
            let headerRow = this.$el.querySelectorAll(
                ".fc-scrollgrid-section-header"
            )[0];
            headerRow.classList.add("sticky-calendar-header");
            let teacherRow = this.$el.querySelectorAll(
                ".fc-scrollgrid-section-body"
            )[0];
            teacherRow.classList.add("sticky-calendar-header");

            this.stickyToolbar = this.$el.querySelector(".schedule-toolbar");
            this.stickyRoom = headerRow;
            this.stickyTeacher = teacherRow;

            this.stickyDivider =
                this.selectedView == "Month"
                    ? this.$el.querySelector(".fc-daygrid-header")
                    : this.$el.querySelector(".fc-timegrid-divider");

            this.stickyRoom.classList.add("top-room", "ease-top");
            this.stickyTeacher.classList.add("top-teacher", "ease-top");

            ////////////////
            let roomHeight = 55;
            let teacherHeight = 78;
            let dividerHeight = 129;

            this.stickyToolbar.style.setProperty("top", "55px");
            this.stickyRoom.style.setProperty("top", `${roomHeight * 2}px`);
            this.stickyTeacher.style.setProperty(
                "top",
                `${teacherHeight + roomHeight}px`
            );
            this.stickyDivider.style.setProperty(
                "top",
                `${dividerHeight + roomHeight}px`
            );

            this.stickyRoom.childNodes.forEach((e) => {
                e.classList.add("sticky-room-border");
            });
            this.stickyTeacher.childNodes.forEach((e) => {
                e.classList.add("sticky-teacher-border");
            });

            this.$el
                .querySelector("#fc-schedule .fc-scrollgrid")
                .style.setProperty("border", "none");
        },
    },
    created() {
        this.getEvents();
        this.getApiTempLessons();
    },
    mounted() {
        document.querySelectorAll(".fc-timegrid-slot").forEach((el) => {
            el.classList.add("fc-grid-height");
        });
        this.$nextTick(() => {
            this.$refs.fullCalendar?.getApi().updateSize();
            this.scrollToTime();
        });

        if (this.$vuetify.breakpoint.smAndDown) this.handleStickyHeader();
    },
    beforeDestroy() {
        this.calendarOptions.eventSources = [];
        this.reset;
    },
    watch: {
        showMenu(menu) {
            if (!menu) {
                this.dragging = false;
                this.bgEventClicked = null;
                this.overrideServices = false;
            }
        },
    },
    computed: {
        showTimezone() {
            let tz = this.$page.props.auth.user.timezone;
            if (tz == "America/New_York") return false;
            return this.globalMomentTz(tz);
        },
        datePicker() {
            let mobile = this.$vuetify.breakpoint.smAndDown;
            return {
                comp: mobile ? "v-bottom-sheet" : "v-menu",
                props: {
                    "close-on-content-click": false,
                    "nudge-left": 62.5,
                },
            };
        },
        currentView() {
            let state = Object.values(this.viewOptions).find(
                (e) => e.name == this.selectedView
            );
            return state;
        },
        getViewTitle() {
            if (!this.title) return null;
            if (this.selectedView == "Week") {
                return (
                    moment(this.title).startOf("week").format("MMM DD") +
                    " - " +
                    moment(this.title).endOf("week").format("MMM DD, YYYY")
                );
            }

            if (this.selectedView == "Month") {
                return moment(this.calendarRange.start).format("MMM YYYY");
            }

            return moment(this.title).format("ddd, MMM DD, YYYY");
        },
        getTitleDate: {
            get() {
                return new Date(this.title).toISOString().substr(0, 10);
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style>
.fc-event {
    overflow: hidden !important;
    display: inherit !important;
}

.fc-day-today {
    background-color: white !important;
}
.fc-scrollgrid-shrink-cushion {
    font-size: 14px;
}
.fc-scrollgrid {
    border-left: none !important;
}

#fc-schedule .fc-col-header-cell-cushion {
    font-size: 14px;
}

#fc-schedule .fc-timegrid-slot {
    height: 3em !important;
}

#fc-schedule .fc-grid-height {
    height: 3em !important;
}

.fc-assign {
    width: 5px;
    opacity: 90% !important;
    border-radius: 50px !important;
    margin: 1px 0px;
}

.fc-assign-wide {
    opacity: 90% !important;
    border-radius: 12px;
}

.fc-avail {
    border: 1px solid white;
}

.fc-lesson {
    margin: 0px 0px 2px 4px;
}

.fc-lesson-edit {
    opacity: 70%;
}

.fc-event-main-frame {
    padding-left: 5px;
    padding-top: 2px;
}

.fc-no-padding .fc-event-main-frame {
    padding: 0px !important;
}

.fc-col-header-cell-cushion {
    font-weight: normal;
}

#fc-schedule .fc-event-mirror {
    border: 2px solid #2aabe6;
    /*color: #4b6876 !important;*/
    background: transparent;
    border-radius: 12px;
    box-shadow: 1px 4px 4px 0 rgba(120, 178, 233, 0.25) !important;
    margin: 2px 2px 2px 6px !important;
}

.fc-event-mirror .fc-event-time {
    color: #4b6876;
    font-size: inherit;
}

.weekly-placeholder {
    border: 2px solid #4b6876 !important;
    background: transparent !important;
    border-radius: 12px;
    box-shadow: none !important;
    margin: 4px 6px 2px 6px !important;
    line-height: 18px !important;
    /*padding: 8px 8px !important;*/
}

.pending-color {
    background: #d2d6dc !important;
    box-shadow: none !important;
    border: none !important;
    cursor: default !important;
}

.fc-highlight {
    background: transparent !important;
}

.single-assign {
    opacity: 75% !important;
}

#fc-schedule .fc .fc-scrollgrid-section > td {
    border-right: none;
}

#fc-schedule .fc .fc-scrollgrid-section:last-of-type > td {
    border-bottom: none;
}

.fc-scroller-harness-liquid {
    border-radius: 12px;
}

.schedule-toolbar {
    position: sticky;
    top: 0px;
    z-index: 5;
    overflow: hidden;
}

.fc-scrollgrid-section > td:first-of-type {
    border-right: 1px solid #d2d6dc;
}

.sticky-calendar-header {
    position: sticky;
    z-index: 6;
    background: white;
}

#fc-schedule .fc .fc-scrollgrid-section-header.sticky-calendar-header {
    top: 56px;
}

#fc-schedule .fc .fc-scrollgrid-section-body.sticky-calendar-header {
    top: 82px;
}

/*.mobile-schedule {
  box-shadow: none !important;
  border-radius: 0px !important;
  /*width: -webkit-fill-available;
}*/

/*
.safe-bottom-margin {
  margin-bottom: env(safe-area-inset-bottom);
}

.safe-top-margin {
  margin-top: env(safe-area-inset-top);
}

.safe-top {
   padding-top: env(safe-area-inset-bottom);
}*/

.sticky-room-border {
    border-top: 1px solid lightgrey !important;
}

.sticky-teacher-border {
    border-top: 1px solid lightgrey !important;
}

/*
.ease-top {
   transition: top 0.1s ease;
}*/

.fc-timegrid-divider {
    position: sticky;
    z-index: 6;
}

.fc .fc-timegrid-slot-label-cushion {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.5px;
    color: #4b6876 !important;
}

.fc .fc-col-header-cell-cushion {
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 1.5px;
    color: #4b6876 !important;
}

.stack-btn .v-btn__content {
    flex-direction: column;
}

.fc-container {
    position: sticky;
    height: calc(100% - 56px);
    width: 100%;
    top: 56px;
}

.mobile-height {
    height: calc(100vh - 118px) !important;
    max-height: -webkit-fill-available;
}

.fc-card {
    margin: 0px;
}

.hide {
    display: none !important;
}

.weekly-color {
    box-shadow: 3px 3px 10px 3px rgba(50, 192, 144, 0.5) !important;
    background: #32c090;
    border-color: #32c090;
    /*box-shadow: 3px 3px 10px 3px rgba(42, 171, 230, 0.5) !important;
    background: #228cbd;
    border-color: #228cbd;*/
}

.grace-color {
    background: #66aea7;
}

.weekly-color.online-event {
    box-shadow: 3px 3px 10px 3px rgba(42, 171, 230, 0.5) !important;
    background: #228cbd;
    border-color: #228cbd;
}

.reschedule-color {
    box-shadow: 3px 3px 10px 3px rgba(123, 177, 224, 0.5) !important;
    background: #7bb1e0;
    border-color: #7bb1e0;
}

.drop-in-color {
    box-shadow: 3px 3px 10px 3px rgba(186, 123, 224, 0.5) !important;
    background: #ba7be0;
    border-color: #ba7be0;
}

.hold-color {
    box-shadow: 3px 3px 10px 3px rgba(75, 104, 118, 0.5) !important;
    background: #4b6876;
    border-color: #4b6876;
}

.assessment-color {
    box-shadow: 3px 3px 10px 3px rgba(245, 141, 105, 0.5) !important;
    background: #f58d69;
    border-color: #f58d69;
}

.group-color {
    box-shadow: 3px 3px 10px 3px rgba(105, 192, 198, 0.5) !important;
    background: #69c0c6;
    border-color: #69c0c6;
}

.cancelled {
    background: #cccccc !important;
    border: none;
    box-shadow: none !important;
    overflow: hidden;
    z-index: 0 !important;
}

.all-day-event {
    width: min-content;
    height: 22px;
    border-radius: 50px !important;
}

.all-day-shadow-1 {
    box-shadow: 2px 2px 10px 1px rgba(255, 99, 99, 0.25);
}

.all-day-shadow-2 {
    box-shadow: 2px 2px 10px 1px rgba(226, 107, 138, 0.25);
}

.all-day-shadow-3 {
    box-shadow: 2px 2px 10px 1px rgba(196, 114, 177, 0.25);
}

.all-day-shadow-4 {
    box-shadow: 2px 2px 10px 1px rgba(255, 153, 84, 0.25);
}

.all-day-shadow-5 {
    box-shadow: 2px 2px 10px 1px rgba(255, 207, 69, 0.25);
}
.all-day-shadow-6 {
    box-shadow: 2px 2px 10px 1px rgba(137, 128, 255, 0.25);
}
.all-day-shadow-7 {
    box-shadow: 2px 2px 10px 1px rgba(38, 193, 210, 0.25);
}
.all-day-shadow-8 {
    box-shadow: 2px 2px 10px 1px rgba(44, 169, 215, 0.25);
}
.all-day-shadow-9 {
    box-shadow: 2px 2px 10px 1px rgba(48, 168, 154, 0.25);
}
.all-day-shadow-10 {
    box-shadow: 2px 2px 10px 1px rgba(0, 200, 140, 0.25);
}

.fc-col-header-cell {
    font-size: 10px !important;
}
</style>
