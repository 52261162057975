<template>
    <v-card class="soli-round mx-auto mb-3" style="max-width: 600px">
        <v-container :class="{ 'pa-5': $vuetify.breakpoint.smAndUp }">
            <v-form
                ref="form"
                v-model="valid"
                id="get-started-submit"
                style="overflow: hidden !important"
                @submit.prevent="submit"
            >
                <v-list style="overflow: hidden !important">
                    <v-list-item
                        dense
                        class="soli-grey font-weight-bold text-start"
                        v-if="false"
                        >Who will be learning?</v-list-item
                    >
                    <v-list-item v-if="false">
                        <v-radio-group
                            v-model="form.has_child"
                            mandatory
                            class="mt-0"
                        >
                            <v-radio label="Me" :value="false" />
                            <v-radio label="My Child" :value="true" />
                        </v-radio-group>
                    </v-list-item>
                    <div v-if="includeOnline">
                        <v-list-item
                            dense
                            class="soli-grey font-weight-bold text-start"
                        >
                            Do you want lessons online or in-person?
                        </v-list-item>
                        <v-list-item>
                            <v-radio-group
                                v-model="form.online"
                                mandatory
                                class="mt-0"
                            >
                                <v-radio label="In-person" :value="false" />
                                <v-radio label="Online" :value="true" />
                            </v-radio-group>
                        </v-list-item>
                    </div>
                    <v-list-item
                        v-if="form.has_child"
                        dense
                        class="soli-grey font-weight-bold text-start px-5"
                        >Your Information</v-list-item
                    >
                    <v-list-item class="mb-3">
                        <div v-if="requireLastNames" class="w-full">
                            <v-row class="my-0">
                                <v-col cols="12" sm="6" class="py-1">
                                    <SoliText
                                        v-model="form.first_name"
                                        icon="person"
                                        label="Your First Name"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Your first name is required',
                                        ]"
                                        hideDetails="auto"
                                        :error="errors.first_name"
                                        :class="{
                                            'mb-3': $vuetify.breakpoint.xs,
                                        }"
                                /></v-col>
                                <v-col cols="12" sm="6" lg="6" class="py-1">
                                    <SoliText
                                        v-model="form.last_name"
                                        icon="person"
                                        label="Your Last Name"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Your last name is required',
                                        ]"
                                        hideDetails="auto"
                                        :error="errors.last_name" /></v-col
                            ></v-row>
                        </div>
                        <SoliText
                            v-else
                            v-model="form.first_name"
                            icon="person"
                            label="Your First Name"
                            :rules="[
                                (v) => !!v || 'Your first name is required',
                            ]"
                            hideDetails="auto"
                            :error="errors.first_name"
                            :class="{ 'mb-3': $vuetify.breakpoint.xs }"
                        />
                    </v-list-item>
                    <v-list-item class="mb-3">
                        <BookingEmail
                            hideDetails="auto"
                            v-model="form.email"
                            :error="form.email"
                        />
                    </v-list-item>
                    <v-list-item class="mb-3">
                        <SoliPhone
                            hideDetails="auto"
                            v-model="form.phone"
                            :error="form.phone"
                        />
                    </v-list-item>

                    <v-list-item class="mb-3" v-if="false">
                        <v-select
                            v-model="form.referrer"
                            dense
                            hide-details="auto"
                            label="How did you find us?"
                            solo
                            outlined
                            prepend-inner-icon="celebration"
                            flat
                            clearable
                            :items="[
                                'From a Student or Parent',
                                'From a Teacher',
                                'Event',
                                'Web Search',
                                'Social Media',
                                'Other',
                            ]"
                        />
                    </v-list-item>

                    <div v-if="form.has_child">
                        <v-list-item
                            class="soli-grey font-weight-bold text-start px-5"
                        >
                            Child Information:
                        </v-list-item>
                        <v-list-item class="mb-3">
                            <div v-if="requireLastNames" class="w-full">
                                <v-row class="my-0">
                                    <v-col cols="12" sm="6" class="py-1">
                                        <SoliText
                                            v-model="form.child_first_name"
                                            icon="person"
                                            label="Child's First Name"
                                            :rules="
                                                form.has_child
                                                    ? [
                                                          (v) =>
                                                              !!v ||
                                                              `Your child's first name is required`,
                                                      ]
                                                    : []
                                            "
                                            hideDetails="auto"
                                            :error="errors.child_first_name"
                                            :class="{
                                                'mb-3': $vuetify.breakpoint.xs,
                                            }"
                                            :key="form.has_child"
                                    /></v-col>
                                    <v-col cols="12" sm="6" class="py-1">
                                        <SoliText
                                            v-model="form.child_last_name"
                                            icon="person"
                                            label="Child's Last Name"
                                            :rules="
                                                form.has_child
                                                    ? [
                                                          (v) =>
                                                              !!v ||
                                                              `Your child's last name is required`,
                                                      ]
                                                    : []
                                            "
                                            hideDetails="auto"
                                            :key="form.has_child"
                                            :error="
                                                errors.child_last_name
                                            " /></v-col
                                ></v-row>
                            </div>
                            <SoliText
                                v-else
                                v-model="form.child_first_name"
                                icon="person"
                                label="Child's First Name"
                                :rules="
                                    form.has_child
                                        ? [
                                              (v) =>
                                                  !!v ||
                                                  `Your child's first name is required`,
                                          ]
                                        : []
                                "
                                hideDetails="auto"
                                :error="errors.child_first_name"
                                :class="{ 'mb-3': $vuetify.breakpoint.xs }"
                                :key="form.has_child"
                            />
                        </v-list-item>
                        <v-list-item>
                            <SoliBirthday
                                v-model="form.child_birthday"
                                label="Child's Birthday"
                                :required="form.has_child"
                                :key="form.has_child"
                            ></SoliBirthday>
                        </v-list-item>
                    </div>
                </v-list>
                <p
                    v-if="Object.keys(errors).length"
                    class="caption red--text mx-5 mb-6 flex justify-center"
                >
                    {{ Object.values(errors)[0] }}
                </p>
                <v-container class="pt-0" v-if="!hideSubmit">
                    <SoliBtn
                        type="submit"
                        text="Let's Go!"
                        :loading="loading"
                        gradient="gradient-orange"
                        block
                        color="soli-status-grey"
                /></v-container>
            </v-form> </v-container
    ></v-card>
</template>

<script>
import BookingEmail from "../Booking/BookingEmail.vue";

export default {
    components: {
        BookingEmail,
    },
    props: {
        errors: {},
        requireLastNames: {
            type: Boolean,
            default: false,
        },
        includeOnline: {
            type: Boolean,
            default: false,
        },
        contactOnly: {
            type: Boolean,
            default: false,
        },
        guest: {
            type: Object,
            default: () => {},
        },
        child: {
            type: Object,
            default: () => {},
        },
        instruments: {
            type: Array,
            default: () => [],
        },
        locations: {
            type: Array,
            default: () => [],
        },
        location_id: {
            type: Number,
            default: null,
        },
        instrument_id: {
            type: Number,
            default: null,
        },
        hasChild: {
            type: Boolean,
            default: null,
        },
        hideSubmit: {
            type: Boolean,
            default: false,
        },
        promo: {
            type: String,
            default: null,
        },
        source: {
            type: String,
            default: null,
        },
    },
    /*remember: {
        data: ["form"],
    },*/
    data() {
        return {
            form: this.$inertia.form({
                first_name: this.guest?.first_name,
                last_name: this.guest?.last_name,
                email: this.guest?.email,
                phone: this.guest?.phone,
                instrument_id: this.instrument_id ?? this.guest?.instrument_id,
                location_id: this.location_id ?? this.guest?.location_id,
                has_child: this.hasChild ?? this.guest?.has_child,
                online: this.guest?.online ?? false,
                child_first_name: this.hasChild ? this.child?.first_name : null,
                child_last_name: this.hasChild ? this.child?.last_name : null,
                child_birthday: this.hasChild
                    ? this.child?.birthday
                        ? moment(new Date(this.child.birthday)).format(
                              "MM/DD/YYYY"
                          )
                        : null
                    : null,
                relation: "Parent",
                inverse: "Child",
                contact_only: this.contactOnly,
                include_online: this.includeOnline,
                lesson_id: this.guest?.lesson_id,
                referrer: this.guest?.referrer,
                promo: this.promo,
                source: this.source,
                timezone: this.guest?.timezone ?? moment.tz.guess(),
                virtual_client: this.guest?.virtual_client ?? false,
            }),
            valid: false,
            loading: false,
            showLocations: false,
            showInstruments: false,
        };
    },
    methods: {
        submit() {
            if (!this.$refs.form.validate()) return;

            this.loading = true;

            try {
                fbq(
                    "track",
                    "Lead",
                    { currency: "USD", value: 250 },
                    { eventID: `Lead-${this.form.email}` }
                );
            } catch (e) {
                console.log(e.message);
            }

            this.form.post(route("getStarted.storeGuest"), {
                preserveState: true,
                preserveScroll: false,
                onSuccess: () => {
                    this.loading = false;
                },
                onError: (errors) => {
                    this.loading = false;
                    console.log(
                        "Form returned errors: " + JSON.stringify(errors)
                    );
                    if (errors.contactSupport) return;
                    if (errors.soliError) return;
                },
            });
        },
        reset() {
            this.$refs.form.reset();
            this.form.contact_only = this.contactOnly;
        },
    },
    computed: {
        formsValid() {
            if (!this.form.has_child) return this.valid;
            if (!this.form.child_birthday && this.form.has_child) return false;
            return this.valid;
        },
    },
    watch: {
        form: {
            handler: function (val) {
                if (val.phone?.length < 12 && this.valid) {
                    this.$refs.form.validate();
                }
                if (!val.child_birthday && this.form.has_child && this.valid) {
                    this.$refs.form.validate();
                }
                if (val.email !== this.guest?.email) this.form.lesson_id = null;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.animate__animated.animate__slideInDown {
    --animate-duration: 0.3s;
}
.appear {
    max-height: 224px;
    transition: max-height 0.3s ease-out;
}
.disappear {
    max-height: 0px;
    transition: max-height 0.3s ease-out;
}
</style>
