<template>
    <div class="contents">
        <!-- Student attendance history menu -->
        <v-menu
            left
            v-if="event && menu && !inline"
            v-model="openMenu"
            :activator="`#attendanceActivator${event.id}`"
        >
            <v-list>
                <v-list-item
                    :disabled="
                        !can('Override attendance') ||
                        (event.group_event_id &&
                            option.name == 'Courtesy-cancel')
                    "
                    v-for="option in options"
                    :key="option.name"
                    :class="[
                        'soli-round',
                        adminStatus == option.name ? 'soli-status-blue' : null,
                    ]"
                    @click="adminUpdate(option.name, null)"
                >
                    <v-list-item-title
                        :class="
                            adminStatus == option.name ? 'white--text' : null
                        "
                        >{{ option.name }}</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- card footer attendance buttons -->
        <v-card
            v-if="event && !menu && !inline"
            :class="/*$vuetify.breakpoint.xs ? 'fixed' : 'absolute'*/ ''"
            elevation="3"
            class="w-full safe-bottom"
            tile
            light
            style="bottom: 0px"
        >
            <div class="flex justify-end mr-3">
                <v-btn-toggle group v-model="attendance" class="soli-round">
                    <v-btn
                        v-if="
                            showNow &&
                            !groupEvent &&
                            (!attendance ||
                                attendance == 'No-show' ||
                                groupEvent)
                        "
                        value="No-show"
                        :disabled="!!attendance"
                        class="soli-round soli-grey"
                        @click="
                            !attendance || attendance == 'Attended'
                                ? (noShowChoice = true)
                                : checkIn('Status')
                        "
                        >No-show</v-btn
                    >
                    <v-btn
                        value="Cancelled"
                        v-if="attendance !== 'No-show' || groupEvent"
                        :disabled="!!attendance"
                        class="soli-round soli-red--text"
                        @click="
                            !attendance || attendance == 'Attended'
                                ? groupEvent
                                    ? (cancelEventChoice = true)
                                    : (cancelChoice = true)
                                : checkIn('Status')
                        "
                        >{{
                            attendance == "Cancelled"
                                ? adminStatus || "Cancel"
                                : "Cancel"
                        }}</v-btn
                    >
                    <v-btn
                        v-if="showNow && !groupEvent"
                        :value="'Attended'"
                        :disabled="!!attendance"
                        class="soli-round soli-green--text"
                        @click="
                            getPaymentStatus == false
                                ? (showPaymentReminder = true)
                                : !attendance || event.cancelled
                                ? (showLessonNotes = true)
                                : checkIn('Status')
                        "
                        ><v-icon v-if="getPaymentStatus == false" color="error"
                            >error</v-icon
                        >Attended</v-btn
                    >
                </v-btn-toggle>
                <v-btn
                    v-if="event.group_event"
                    :disabled="
                        event.students.length == 0 ||
                        groupStatus == 'Teacher-cancel'
                    "
                    text
                    class="soli-round soli-green--text my-2.5"
                    @click="[$emit('showStudentList')]"
                    >Attendance List
                </v-btn>
            </div>
        </v-card>

        <!-- Group Event attendance list -->
        <div v-if="event && !menu && inline">
            <v-btn-toggle
                group
                v-model="groupAttendance"
                class="soli-round items-center"
            >
                <v-btn
                    v-if="showNow"
                    :value="'No-show'"
                    :loading="loading && clicked == 1"
                    :disabled="
                        !['Attended', 'Early-cancel', null, undefined].includes(
                            groupAttendance
                        )
                    "
                    class="soli-round soli-grey btn-stack"
                    @click="
                        [
                            groupAttendance == 'No-show'
                                ? checkIn('Status')
                                : (noShowChoice = true),
                            (clicked = 1),
                        ]
                    "
                    ><v-icon small>person_off</v-icon
                    ><small class="text-none caption">No-show</small></v-btn
                >
                <v-btn
                    :value="notice"
                    :loading="loading && clicked == 2"
                    :disabled="
                        !['Attended', 'Early-cancel', null, undefined].includes(
                            groupAttendance
                        )
                    "
                    class="soli-round soli-red--text btn-stack"
                    @click="
                        [
                            groupAttendance == notice
                                ? checkIn('Status')
                                : (cancelChoice = true),
                            (clicked = 2),
                        ]
                    "
                    ><v-icon small>cancel</v-icon
                    ><small class="text-none caption">Cancel</small></v-btn
                >
                <v-btn
                    :value="'Attended'"
                    v-if="showNow"
                    @click="
                        [
                            groupAttendance == 'Attended'
                                ? checkIn('Status')
                                : checkIn('Attended'),
                            (clicked = 3),
                        ]
                    "
                    class="soli-round soli-green--text btn-stack"
                    :loading="loading && clicked == 3"
                    ><v-icon
                        small
                        :color="
                            getPaymentStatus == false
                                ? 'error'
                                : 'soli-green--text'
                        "
                        >{{
                            getPaymentStatus == false ? "error" : "check_circle"
                        }}</v-icon
                    ><small class="text-none caption">Attended</small></v-btn
                >
            </v-btn-toggle>
        </div>

        <SoliChoice
            ref="cancelChoice"
            v-if="cancelChoice && event"
            :title="event.group_event ? 'Are you sure?' : 'Who is cancelling?'"
            :subtitle="
                notice == 'Early-cancel'
                    ? `This lesson is more than ${event.rescheduleWindow.display} from now and will be able to be rescheduled.`
                    : `This lesson is in less than ${event.rescheduleWindow.display} and will not be able to be rescheduled, unless due to a teacher absence.`
            "
            loading1
            :loading2="!event.group_event"
            :button1="event.group_event ? 'Confirm' : 'Student'"
            :button2="event.group_event ? 'Nevermind' : 'Teacher'"
            persistent
            permanent
            @option1="checkIn(notice)"
            @option2="
                event.group_event
                    ? reset(true, true)
                    : checkIn('Teacher-cancel')
            "
            @reset="reset()"
        ></SoliChoice>

        <SoliChoice
            v-if="noShowChoice"
            title="They didn't show up?"
            subtitle="This lesson cannot be rescheduled. We'll notify the student to check in. You should too!"
            loading1
            button1="Confirm"
            button2="Nevermind"
            persistent
            permanent
            @option1="checkIn('No-show')"
            @option2="reset(true, true)"
            @reset="reset()"
        ></SoliChoice>

        <SoliChoice
            v-if="showPaymentReminder"
            title="Membership is Past Due"
            subtitle="This student's credit card has likely expired. Please collect new payment information or contact your Community Manager."
            persistent
            permanent
            button1="Okay"
            @option1="showLessonNotes = true"
            @reset="
                !!$event
                    ? reset(true, true)
                    : closeModal(() => (showPaymentReminder = false))
            "
        ></SoliChoice>

        <SoliChoice
            v-if="confirmCredit"
            title="Cancel-credit"
            :subtitle="`Cancel-credited lessons are unable to be altered. Would you like to automatically credit this user's account? This will add ${
                creditPricing ?? '(loading...)'
            }.`"
            button1="Update and Credit Account"
            button2="Update Attendance Only"
            left
            @option1="adminUpdate('Cancel-credit', true)"
            @option2="adminUpdate('Cancel-credit', false)"
            @reset="reset()"
            @hook:mounted="getCreditPricing()"
        />

        <SoliChoice
            v-if="cancelEventChoice"
            title="Are you sure?"
            subtitle="This class will be cancelled. Students will be notified and will be able to join another class using this purchase option. The teacher will not be paid."
            button1="Confirm"
            button2="Nevermind"
            loading1
            permanent
            persistent
            @option1="checkIn('Teacher-cancel')"
            @option2="reset(true)"
            @reset="reset(true)"
        ></SoliChoice>

        <SoliChoice
            v-if="Object.keys($page.props.errors).includes('rescheduled')"
            @option1="attendance = storeAttendance"
            button1="Okay"
            title="Error"
            :subtitle="$page.props.errors.rescheduled"
        ></SoliChoice>

        <SoliResponsiveDialog
            v-if="showLessonNotes"
            ref="lessonNotes"
            persistent
            title="Tell us about this lesson"
            subtitle="This note will be shared with students and parents"
            :mobileScroll="false"
            @reset="reset(true, true)"
            ><template #body>
                <v-form
                    ref="attendanceNotesForm"
                    @submit.prevent="checkIn('Attended')"
                >
                    <v-container class="flex flex-col gap-4">
                        <div class="mb-4">
                            <div
                                class="text-subtitle-2 font-weight-bold soli-grey px-2 mb-4"
                            >
                                {{
                                    `What did you and ${event.student.first_name} work on?`
                                }}
                            </div>
                            <TipTap
                                ref="lessonNoteTipTap"
                                v-model="lesson_note"
                                hideSubmit
                                breakOnEnter
                                :characterLimit="512"
                                @input="saveDraft($event)"
                            />
                            <v-input
                                :error-messages="lessonNoteErrors"
                                hide-details="auto"
                                class="px-6"
                            />
                        </div>
                        <div class="mb-4">
                            <div
                                class="text-subtitle-2 font-weight-bold soli-grey px-2 mb-4"
                            >
                                {{
                                    `What should ${event.student?.first_name} work on at home?`
                                }}
                            </div>
                            <TipTap
                                ref="practiceNoteTipTap"
                                v-model="practice_note"
                                hideSubmit
                                breakOnEnter
                                :autofocus="false"
                                :characterLimit="512"
                                @input="saveDraft($event)"
                            />
                            <v-input
                                :error-messages="practiceNoteErrors"
                                hide-details="auto"
                                class="px-6"
                            />
                        </div>
                        <div v-if="showDraft" class="caption px-2">
                            {{
                                `(draft) last saved: ${globalMomentDateAtTime(
                                    event.student.attendance.updated_at
                                )}`
                            }}
                        </div>
                        <SoliBtn
                            gradient="gradient-orange"
                            :loading="loading"
                            type="submit"
                            text="Save"
                            icon="save"
                            block
                        />
                    </v-container>
                </v-form> </template
        ></SoliResponsiveDialog>
    </div>
</template>
<script>
import { allowSelection } from "@fullcalendar/vue";
import globalMoment from "../../Mixins/GlobalMoment.js";
import TipTap from "../Editor/TipTap.vue";

export default {
    name: "SoliAttendance",
    mixins: [globalMoment],
    components: {
        TipTap,
    },
    props: {
        eventId: {},
        studentId: {},
        event: {},
        groupEvent: {
            type: Boolean,
            default: false,
        },
        menu: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        groupStatus: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {},
    },
    data: function () {
        return {
            //selected: this.statusMap["Check-in"],
            loading: false,
            attendance: null,
            cancelChoice: false,
            noShowChoice: false,
            confirmCredit: false,
            showLessonNotes: false,
            lesson_note: this.event.student?.attendance?.lesson_note ?? null,
            practice_note:
                this.event.student?.attendance?.practice_note ?? null,
            storeAttendance: null,
            openMenu: true,
            adminStatus: null,
            showPaymentReminder: false,
            cancelEventChoice: false,
            groupAttendance: this.groupStatus,
            cancelTokens: [],
            draftTimeout: null,
            creditPricing: null,
            clicked: null,
            lessonNoteErrors: [],
            practiceNoteErrors: [],
            noteRules: [
                (v) => !!v || "You must provide details about this lesson!",
                (v) => (v || "").length <= 5 || "Character limit reached",
                (v) =>
                    (v && !!v.trim()) ||
                    "You must provide details about this lesson!",
            ],
            options: [
                {
                    name: "Status",
                },
                {
                    name: "Attended",
                },
                {
                    name: "Early-cancel",
                },
                {
                    name: "Late-cancel",
                },
                {
                    name: "No-show",
                },
                {
                    name: "Teacher-cancel",
                },
                {
                    name: "Cancel-credit",
                },
                {
                    name: "Courtesy-cancel",
                },
            ],
        };
    },
    methods: {
        getCreditPricing() {
            let lessonUser = this.event.students.find(
                (e) => e.id == this.studentId
            ).attendance.id;
            axios
                .get(`/api/lessons/credit-pricing/${lessonUser}`)
                .then((resp) => {
                    this.creditPricing = this.formatUnitAmount(resp.data);
                });
        },
        reset(wait) {
            if (this.event.group_event) {
                this.groupAttendance = this.storeAttendance;
            }
            this.attendance = this.storeAttendance;
            setTimeout(
                () => {
                    this.cancelChoice = false;
                    this.noShowChoice = false;
                    this.cancelEventChoice = false;
                    this.showLessonNotes = false;
                    this.showPaymentReminder = false;
                    this.confirmCredit = false;
                },
                wait ? 300 : 0
            );
        },
        adminUpdate(select, confirmed) {
            if (select == this.adminStatus) return;
            if (select == "Cancel-credit" && confirmed === null)
                return (this.confirmCredit = true);
            this.loading = true;
            let status = {
                student_id: this.studentId,
                attendance: select,
                cancel: this.event.group_event_id
                    ? this.event.cancelled
                    : select !== "Attended" && select !== "Status",
                admin: true,
                creditAccount: confirmed,
            };
            this.$inertia.put(`/lesson/${this.eventId}`, status, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                },
                onError: (errors) => {
                    console.log(JSON.stringify(errors));
                },
            });
        },
        toggleMenu(status) {
            if (!status) {
                this.openMenu = false;
                if (this.showLessonNotes || this.cancelChoice) return;
                setTimeout(() => {
                    this.$emit("close");
                }, 300);
            }
        },
        checkIn(select) {
            /* if (this.$refs.attendanceNotesForm) {
                if (!this.$refs.attendanceNotesForm.validate()) return;
            }*/

            this.loading = true;

            let status = {
                student_id: this.studentId,
                attendance: select,
                cancel:
                    select !== "Attended" &&
                    select !== "Status" &&
                    (this.event.group_event
                        ? select == "Teacher-cancel"
                        : true),
                lesson_note: this.lesson_note,
                practice_note: this.practice_note,
            };

            this.$inertia.put(`/lesson/${this.eventId}`, status, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    /*if (this.$refs.lessonNotes && !this.groupEvent) {
                        this.$refs.lessonNotes.show = false;
                    }*/
                    if (this.$refs.cancelChoice) {
                        this.$refs.cancelChoice.dialog = false;
                    }

                    this.closeModal(() => {
                        this.cancelChoice = false;
                        this.noShowChoice = false;
                    });

                    this.$emit("success");
                    this.loading = false;
                },
                onError: (errors) => {
                    /*if (this.$refs.lessonNotes) {
                        this.$refs.lessonNotes.loading = false;
                    }*/
                    console.log(JSON.stringify(errors));
                    this.lessonNoteErrors = errors.lesson_note;
                    this.practiceNoteErrors = errors.practice_note;
                    this.groupAttendance = this.groupStatus;
                    this.loading = false;
                },
            });
        },
    },
    computed: {
        showDraft() {
            if (!this.event) return false;
            if (this.event.group_event) return false;
            return this.event.student.attendance.draft;
        },
        notice() {
            let cancelled = [
                "Early-cancel",
                "Late-cancel",
                "Cancel-credit",
                "Teacher-cancel",
                "Courtesy-cancel",
            ];
            if (cancelled.includes(this.adminStatus)) return this.adminStatus;
            if (this.event.group_event) {
                return moment(this.event.starts_at).diff(moment(), "minutes") >=
                    30
                    ? "Early-cancel"
                    : "Late-cancel";
            }
            return moment(this.event.starts_at).diff(moment(), "hours") >= 24
                ? "Early-cancel"
                : "Late-cancel";
        },
        getPaymentStatus() {
            const student = this.event.students.find(
                (e) => e.id == this.studentId
            );
            return student?.attendance.purchase_option.subPaid ?? null;
        },
        showNow() {
            if (!this.event) return;
            return (
                moment(new Date()) >=
                moment(new Date(this.event.starts_at)).subtract(15, "minutes")
            );
        },
    },
    mounted() {
        if (!this.event) return;
        if (!this.event.students.length && !this.groupEvent) return;

        this.event.student = this.event.students.find(
            (e) => e.id == this.studentId
        );

        let status = this.groupEvent
            ? this.groupStatus
            : this.event.student.attendance.status;

        if (!status) return;

        if (status == "Attended") {
            this.attendance = "Attended";
        } else if (status == "No-show") {
            this.attendance = "No-show";
        } else {
            this.attendance = "Cancelled";
        }

        this.storeAttendance = this.groupAttendance
            ? this.groupAttendance
            : this.attendance;
        this.adminStatus = status;
    },
    created() {
        this.saveDraft = this.debounce((value, cancel) => {
            /*if (this.$refs.attendanceNotesForm) {
                if (!this.$refs.attendanceNotesForm.validate()) return;
            }*/

            if (!value?.length) value = null;

            axios
                .put(`/api/lesson/attendance/${this.event.id}`, {
                    lesson_note: this.lesson_note,
                    practice_note: this.practice_note,
                })
                .then((resp) => {
                    this.$emit("refresh", resp.data);
                    this.lessonNoteErrors = [];
                    this.practiceNoteErrors = [];
                })
                .catch((error) => {
                    const errors = error.response.data.errors;
                    console.log(errors);
                    this.lessonNoteErrors = errors.lesson_note;
                    this.practiceNoteErrors = errors.practice_note;
                });
        }, 1000);
    },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
}

.fixed {
    position: fixed !important;
}

.absolute {
    position: absolute !important;
}

::v-deep .btn-stack > .v-btn__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
