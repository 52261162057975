<template>
    <AppLayout page="/explore" pageTitle="Explore" :tabs="true">
        <template #tabs
            ><v-tabs
                background-color="transparent"
                v-model="tab"
                class="white--text"
            >
                <v-tabs-slider color="white"></v-tabs-slider>
                <v-tab class="white--text">Categories</v-tab>
                <v-tab class="white--text">All Events</v-tab>
                <!--<v-tab class="white--text">Teachers</v-tab>-->
            </v-tabs></template
        >

        <v-tabs-items v-model="tab" style="background-color: transparent">
            <v-tab-item :value="0" class="h-full">
                <ExploreCategories
                    :successMessage="successMessage"
                    :errors="errors"
                    @selectClass="selectedClass = $event"
                    @createLesson="bookingForm = true"
                />
            </v-tab-item>

            <v-tab-item :value="1" class="h-full">
                <ExploreAllEvents
                    :calendarMin="calendarMin"
                    :calendarMax="calendarMax"
                    @selectClass="selectedClass = $event"
                />
            </v-tab-item>
            <!--<v-tab-item :value="3" class="h-full">
                <ExploreMusicTeachers :teachers="teachers" />
            </v-tab-item>-->
        </v-tabs-items>
        <SoliForm
            v-if="selectedClass"
            hideBtn
            :title="`Register for ${selectedClass.title}`"
            textSubtitle="Online registration coming soon"
            @reset="selectedClass = null"
            @submit="confirmRegistration"
        >
            <template #body
                ><v-container class="pt-0"
                    ><div class="soli-grey">
                        Please contact
                        <a :href="`mailto:${selectedClass.location.email}`">{{
                            selectedClass.location.email
                        }}</a>
                        to get started
                    </div></v-container
                ></template
            >
        </SoliForm>
        <CreateLesson
            v-if="bookingForm"
            :errors="errors"
            :bookingSource="{ isSelfServe: true }"
            :relationships="relationships"
            @reset="closeModal(() => (bookingForm = null))"
            @success="handleSuccess"
        />
    </AppLayout>
</template>

<script>
import AppLayout from "./../Layouts/AppLayout";

//import scrollGridPlugin from "@fullcalendar/scrollgrid";
//import momentTimezonePlugin from '@fullcalendar/moment-timezone'
//import { VMenu, VBottomSheet } from "vuetify/lib";
import ExploreAllEvents from "./../Components/Explore/ExploreAllEvents.vue";
import ExploreCategories from "./../Components/Explore/ExploreCategories.vue";
import ExploreMusicTeachers from "./../Components/Explore/ExploreMusicTeachers.vue";
import CreateLesson from "./../Components/Booking/CreateLesson.vue";
import confetti from "canvas-confetti";

export default {
    components: {
        AppLayout,
        //VMenu,
        //VBottomSheet,
        ExploreAllEvents,
        ExploreCategories,
        ExploreMusicTeachers,
        CreateLesson,
    },
    props: {
        errors: {},
        successMessage: {},
        calendarMin: {},
        calendarMax: {},
        relationships: {},
        teachers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            tabList: null,
            tab: null,
            selectedClass: null,
            bookingForm: false,
        };
    },

    methods: {
        handleSuccess() {},
        confirmRegistration() {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
            });
            this.selectedClass = null;
        },
        addToCart() {
            console.log("add to cart");
        },
    },
    updated() {
        setTimeout(() => {
            this.$redrawVueMasonry();
        }, 300);
    },
    mounted() {
        /*if (document.querySelector("#lottie")) return;
    let lottie = document.createElement("script");
    lottie.setAttribute(
      "src",
      "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
    );
    lottie.id = "lottie";
    document.head.appendChild(lottie);*/
        //this.getApiGiftCards();
    },
};
</script>

<style>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.fc-allEvents {
    border-radius: 12px !important;
    overflow: hidden;
}
</style>
