<template>
    <v-app-bar
        ref="appBar"
        app
        elevate-on-scroll
        :flat="
            $page.component == 'Schedule' || $page.url.includes('/availability')
        "
        style="background-color: white; z-index: 9"
        prominent
        :height="withTabs ? '128' : '96'"
        v-scroll="onScroll"
        :class="[
            isScrolled
                ? withTabs && keepTabHeader
                    ? 'collapse-tab-nav'
                    : 'collapse-nav'
                : null,
            'nav-bar xs:pl-0 sm:pl-1 lg:pl-2',
            $page.url.includes('/explore')
                ? !isScrolled
                    ? 'transparent'
                    : 'l-blue'
                : 'white',
        ]"
    >
        <!-- Nav Left -->
        <div
            id="navbar-items-left"
            :class="[withTabs ? 'scrollUp' : null, 'flex']"
            style="height: 100%; top: 0px"
        >
            <div
                :class="[
                    isScrolled ? 'pull-up-fade pull-up' : null,
                    ' flex align-center ease-fade ease',
                ]"
                style="height: 48px"
            >
                <Link :href="'/'">
                    <jet-application-logo
                        class="block h-9 w-auto"
                        style="height: 1rem !important"
                        :white="$page.component == 'Explore' && !isScrolled"
                    />
                </Link>
                <h6
                    v-if="!playView"
                    class="text-sm text-gray-600 leading-tight mx-2"
                >
                    {{ $page.props.auth.user.selected_location.name }}
                </h6>
            </div>

            <SoliHeader
                :text="pageTitle"
                :href="$page.props.staffView ? backLink : null"
                :large="!$page.props.staffView"
                style="height: 48px; background: transparent"
                :class="[
                    'align-self-center absolute flex align-center ease',
                    withTabs
                        ? isScrolled
                            ? !keepTabHeader
                                ? 'pull-up-fade pull-up'
                                : 'pb-10'
                            : null
                        : isScrolled
                        ? null
                        : 'push-down',
                ]"
                :headerButton="headerButton"
                :sandwich="sandwich"
                :isScrolled="isScrolled"
                @sandwich="$emit('sandwich')"
                @headerButton="$emit('headerButton')"
                ><template #tools>
                    <div
                        :class="[
                            'flex justify-end pr-3 py-3 custom absolute right-4',
                            isScrolled ? 'top-5' : 'top-9',
                        ]"
                    >
                        <!-- Mobile filter -->
                        <div
                            v-if="
                                ($page.component == 'Store'
                                    ? $vuetify.breakpoint.width < 1150
                                    : $vuetify.breakpoint.xs) && showPageSearch
                            "
                            style="z-index: 12"
                            class="flex align-center"
                        >
                            <v-menu
                                left
                                :close-on-content-click="false"
                                content-class="white"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        icon
                                        class="elevation-3"
                                    >
                                        <v-icon
                                            class="soli-blue-text text-shadow-blue"
                                            >filter_list</v-icon
                                        ></v-btn
                                    >
                                </template>
                                <v-text-field
                                    @input="$emit('pageSearch', $event)"
                                    v-model="searchBinding"
                                    ref="mobilePageSearch"
                                    class="pb-3 mr-3 pl-4 mt-0"
                                    width="200"
                                    style="min-width: 200px"
                                    label="Search"
                                    single-line
                                    clearable
                                    autofocus
                                    :append-icon="
                                        searchBinding ? null : 'filter_list'
                                    "
                                    hide-details
                                ></v-text-field
                            ></v-menu>
                        </div>

                        <!-- Filter-->
                        <div
                            :class="['mr-1', isScrolled ? 'top-5' : 'top-8']"
                            style="z-index: 12; width: 200px"
                            v-if="
                                ($page.component == 'Store'
                                    ? $vuetify.breakpoint.width > 1150
                                    : $vuetify.breakpoint.smAndUp) &&
                                showPageSearch
                            "
                        >
                            <v-text-field
                                @input="$emit('pageSearch', $event)"
                                v-model="searchBinding"
                                style="width: 200px"
                                class="pl-6"
                                append-icon="filter_list"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </div>

                        <!-- People options -->
                        <div
                            class="flex align-center"
                            v-if="
                                $page.component == 'People' &&
                                $page.url.includes('students')
                            "
                        >
                            <v-menu
                                left
                                :close-on-content-click="false"
                                content-class="white"
                                v-model="peopleOptions"
                                max-width="200"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        small
                                        class="elevation-3 ml-2"
                                        style="z-index: 12"
                                        ><v-badge
                                            :value="!showAllStudents"
                                            color="primary"
                                            dot
                                            overlap
                                            style="
                                                position: absolute !important;
                                                top: 0px;
                                                right: 0px;
                                            "
                                        ></v-badge>
                                        <v-icon
                                            class="soli-blue-text text-shadow-blue"
                                            >more_vert</v-icon
                                        >
                                    </v-btn></template
                                >
                                <v-list>
                                    <v-list-item>
                                        <v-btn
                                            :ripple="false"
                                            @click.stop="
                                                handleShowAllStudents(true)
                                            "
                                            outlined
                                            class="flex soli-round soli-grey"
                                        >
                                            <small class="mr-2">{{
                                                "Active Students"
                                            }}</small>
                                            <v-icon
                                                :color="
                                                    showAllStudents
                                                        ? null
                                                        : 'accent'
                                                "
                                                >{{
                                                    showAllStudents
                                                        ? "toggle_off"
                                                        : "toggle_on"
                                                }}</v-icon
                                            ></v-btn
                                        ></v-list-item
                                    ><v-list-item>
                                        <v-select
                                            dense
                                            class="soli-round"
                                            label="Retention Group"
                                            hide-details
                                            outlined
                                            clearable
                                            :items="[
                                                '< 90 days',
                                                '3 months',
                                                '6 months',
                                                '12 months',
                                                '24 months',
                                                '36 months',
                                            ]"
                                            :value="retentionGroup"
                                            @input="
                                                handleRetentionGroup($event)
                                            "
                                        ></v-select>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>

                        <div
                            class="flex align-center"
                            v-if="
                                $page.component == 'People' &&
                                $page.url.includes('teachers')
                            "
                        >
                            <v-menu
                                left
                                :close-on-content-click="false"
                                content-class="white"
                                v-model="peopleOptions"
                                max-width="212"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        small
                                        class="elevation-3 ml-2"
                                        style="z-index: 12"
                                        ><v-badge
                                            :value="
                                                (nearMe &&
                                                    can(
                                                        'Access all locations'
                                                    )) ||
                                                (selectedMarket &&
                                                    can(
                                                        'Access all locations'
                                                    )) ||
                                                !showArchived
                                            "
                                            color="primary"
                                            dot
                                            overlap
                                            style="
                                                position: absolute !important;
                                                top: 0px;
                                                right: 0px;
                                            "
                                        ></v-badge>
                                        <v-icon
                                            class="soli-blue-text text-shadow-blue"
                                            >more_vert</v-icon
                                        >
                                    </v-btn></template
                                >
                                <v-list
                                    ><v-list-item
                                        ><v-btn
                                            :ripple="false"
                                            @click.stop="handleShowArchived()"
                                            outlined
                                            class="flex soli-round soli-grey"
                                        >
                                            <small class="mr-2">
                                                Active Teachers
                                            </small>
                                            <v-icon
                                                :color="
                                                    showArchived
                                                        ? null
                                                        : 'accent'
                                                "
                                                >{{
                                                    showArchived
                                                        ? "toggle_off"
                                                        : "toggle_on"
                                                }}</v-icon
                                            ></v-btn
                                        ></v-list-item
                                    >
                                    <v-list-item
                                        v-if="can('Access all locations')"
                                    >
                                        <v-btn
                                            :ripple="false"
                                            @click.stop="handleNearMe(true)"
                                            outlined
                                            class="flex soli-round soli-grey"
                                        >
                                            <small class="mr-2">{{
                                                $page.props.auth.user
                                                    .selected_location.name
                                            }}</small>
                                            <v-icon
                                                :color="
                                                    nearMe ? 'accent' : null
                                                "
                                                >{{
                                                    nearMe
                                                        ? "toggle_on"
                                                        : "toggle_off"
                                                }}</v-icon
                                            ></v-btn
                                        ></v-list-item
                                    >
                                    <v-list-item
                                        v-if="can('Access all locations')"
                                        ><v-select
                                            outlined
                                            class="soli-round"
                                            label="Market"
                                            hide-details
                                            dense
                                            :items="marketOptions"
                                            v-model="selectedMarket"
                                            @input="handleNearMe(false)"
                                            item-text="name"
                                            item-value="id" /></v-list-item
                                ></v-list>
                            </v-menu>
                        </div>
                    </div>

                    <!-- MySchedule Combobox Filter -->
                    <v-col
                        sm="6"
                        v-if="showFilter"
                        :class="[
                            'flex justify-end mr-3 custom absolute top-8 right-0',
                            $vuetify.breakpoint.xs && activateFilter
                                ? 'col-10'
                                : 'col-2',
                        ]"
                        style="z-index: 12"
                    >
                        <v-combobox
                            :value="filterData ? filterData : []"
                            :items="filterData ? filterData : []"
                            item-text="name"
                            item-value="id"
                            chips
                            dense
                            multiple
                            hide-details
                            :menu-props="{
                                auto: true,
                                maxHeight: 'unset',
                            }"
                            append-icon=""
                            label="Filter"
                            single-line
                            flat
                            @change="$emit('filterResults', $event)"
                            reverse
                            color="dark"
                            background-color="transparent"
                            filled
                            class="align-self-start custom"
                            ref="comboFilter"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-if="$vuetify.breakpoint.smAndUp"
                                    :key="JSON.stringify(data.item.id)"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    @click:close="
                                        [
                                            data.parent.selectItem(data.item),
                                            data.parent.blur(),
                                            (activateFilter = false),
                                        ]
                                    "
                                    close
                                    small
                                >
                                    {{ data.item.first_name }}
                                </v-chip>
                            </template></v-combobox
                        ><v-btn
                            icon
                            small
                            @click="toggleFilter()"
                            color="primary"
                            class="align-self-center shadow-blue plain mr-2"
                            ><v-icon>filter_list</v-icon></v-btn
                        >
                    </v-col>
                </template></SoliHeader
            >
        </div>

        <div
            :class="[
                'absolute',
                isScrolled && !keepTabHeader
                    ? 'align-self-center'
                    : 'align-self-end',
                $page.component == 'Store' && $vuetify.breakpoint.xs
                    ? 'filter-space'
                    : 'w-full',
            ]"
        >
            <slot name="tabs"></slot>
        </div>

        <!-- Nav Center -->
        <div
            v-if="
                playView &&
                !$vuetify.breakpoint.smAndDown &&
                $page.component !== 'Login' &&
                $page.props.auth.user
            "
            id="navbar-items-center"
            class="flex absolute w-full justify-center"
        >
            <div class="py-1">
                <Link
                    class="text-lg ml-1.5 pb-3"
                    href="/explore"
                    v-if="!$page.props.auth.user.virtual_client"
                    ><v-btn
                        rounded
                        text
                        style="
                            text-transform: none !important;
                            letter-spacing: normal;
                        "
                        :color="
                            $page.component == 'Explore' && !isScrolled
                                ? 'white'
                                : 'primary'
                        "
                        :class="[
                            $page.component == 'Explore'
                                ? 'font-weight-bold'
                                : 'font-weight-light',
                        ]"
                        class="px-2"
                        ><span class="text-lg">Explore</span></v-btn
                    ></Link
                >
                <Link class="text-lg ml-1.5 pb-3" href="/myschedule"
                    ><v-btn
                        rounded
                        text
                        style="
                            text-transform: none !important;
                            letter-spacing: normal;
                        "
                        :color="
                            $page.component == 'Explore' && !isScrolled
                                ? 'white'
                                : 'primary'
                        "
                        :class="[
                            $page.component == 'MySchedule'
                                ? 'font-weight-bold'
                                : 'font-weight-light',
                        ]"
                        class="px-2"
                        ><span class="text-lg">Lessons</span></v-btn
                    ></Link
                >
                <!--<Link class="text-lg ml-1.5 pb-3" href="/gifts"
                    ><v-btn
                        rounded
                        text
                        style="
                            text-transform: none !important;
                            letter-spacing: normal;
                        "
                        :color="
                            $page.component == 'Explore' && !isScrolled
                                ? 'white'
                                : 'primary'
                        "
                        :class="[
                            $page.component == 'Gifts'
                                ? 'font-weight-bold'
                                : 'font-weight-light',
                        ]"
                        class="px-2"
                        ><span class="text-lg">Gift Cards</span></v-btn
                    ></Link
                >-->
                <Link class="text-lg ml-1.5 pb-3" href="/inbox"
                    ><v-btn
                        rounded
                        text
                        style="
                            text-transform: none !important;
                            letter-spacing: normal;
                        "
                        :color="
                            $page.component == 'Explore' && !isScrolled
                                ? 'white'
                                : 'primary'
                        "
                        :class="[
                            $page.component == 'Inbox'
                                ? 'font-weight-bold'
                                : 'font-weight-light',
                        ]"
                        class="px-2"
                    >
                        <v-badge
                            :content="$page.props.unreadMessageCount"
                            :color="
                                $page.props.unreadMessageCount
                                    ? 'soli-red'
                                    : null
                            "
                            offset-y="8"
                            ><span class="text-lg">Inbox</span></v-badge
                        ></v-btn
                    ></Link
                >
                <Link class="text-lg ml-1.5 pb-3" href="/help-categories"
                    ><v-btn
                        rounded
                        text
                        style="
                            text-transform: none !important;
                            letter-spacing: normal;
                        "
                        :color="
                            $page.component == 'Explore' && !isScrolled
                                ? 'white'
                                : 'primary'
                        "
                        :class="[
                            $page.component == 'HelpCategory/Index'
                                ? 'font-weight-bold'
                                : 'font-weight-light',
                        ]"
                        class="px-2"
                        ><span class="text-lg">Help</span></v-btn
                    ></Link
                >
            </div>
        </div>

        <!-- Nav Right -->
        <div
            id="navbar-items-right"
            class="flex justify-end align-center -mr-2 z-40 flex-grow-0"
            :class="{
                'gap-2': $vuetify.breakpoint.smAndUp,
                'align-self-center': !keepTabHeader && isScrolled,
                'pa-1': playView,
                white: $page.component !== 'Explore',
            }"
        >
            <SoliBtn
                v-if="!$page.props.auth.user"
                href="/login"
                color="white"
                textColor="soli-grey"
                class="my-2.5"
                >Login</SoliBtn
            >
            <v-dialog
                v-model="showSearch"
                ref="mobileSearch"
                max-width="400"
                v-if="
                    $page.props.staffView &&
                    ($vuetify.breakpoint.smAndDown ||
                        $page.component == 'Store') &&
                    ($page.component !== 'Store' ||
                        ($page.component == 'Store' && !isScrolled))
                "
                ><template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        medium
                        class="items-center"
                        v-on="on"
                        v-bind="attrs"
                    >
                        <v-icon class="soli-blue-text text-shadow-blue"
                            >search</v-icon
                        >
                    </v-btn></template
                >
                <GlobalSearch
                    v-if="showSearch"
                    ref="globalSearch"
                    :showMacros="showMacros"
                    mobile
                    style="position: absolute"
                    class="px-3 top-9 left-0"
                ></GlobalSearch>
            </v-dialog>
            <GlobalSearch
                v-if="
                    $page.props.staffView &&
                    $vuetify.breakpoint.mdAndUp &&
                    $page.component !== 'Store'
                "
                :showMacros="showMacros"
                ref="globalSearch"
                class="mx-2"
            ></GlobalSearch>

            <v-btn
                v-if="
                    can('Work') &&
                    !playView &&
                    can('Manage user locations') &&
                    ($page.component !== 'Store' ||
                        ($page.component == 'Store' && !isScrolled))
                "
                icon
                medium
                @click="userCreate = true"
            >
                <v-icon class="soli-blue-text text-shadow-blue"
                    >person_add</v-icon
                >
            </v-btn>
            <Notifications
                v-if="
                    $page.props.auth.user &&
                    ($page.component !== 'Store' ||
                        ($page.component == 'Store' && !isScrolled))
                "
            />
            <!--<Inbox
                v-if="
                    $page.props.auth.user &&
                    ($page.component !== 'Store' ||
                        ($page.component == 'Store' && !isScrolled))
                "
            />-->
            <v-btn
                v-if="
                    can('Work') &&
                    !playView &&
                    $page.component == 'Store' &&
                    cart > 0 &&
                    $vuetify.breakpoint.smAndDown
                "
                icon
                medium
                @click="$emit('showCart')"
            >
                <v-badge color="accent" :content="cart" overlap bordered>
                    <v-icon class="soli-blue-text text-shadow-blue"
                        >local_offer</v-icon
                    >
                </v-badge>
            </v-btn>

            <!---<Link
                v-if="!$page.props.auth.user"
                class="flex sm:items-center ml-3 justify-end my-2 mr-6"
            >
                <v-card style="border-radius: 50px">
                    <button
                        v-if="$page.props.jetstream.managesProfilePhotos"
                        class="flex align-center text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    >
                        <div
                            class="mx-1 soli-grey flex align-center"
                            style="line-height: 12px"
                        >
                            Login
                        </div>
                    </button></v-card
                ></Link
            >-->

            <!-- Settings Dropdown -->
            <div
                v-show="$vuetify.breakpoint.mdAndUp && $page.props.auth.user"
                id="profile-settings-dropdown"
                class="ml-3"
            >
                <jet-dropdown align="right" width="56">
                    <template #trigger>
                        <v-card style="border-radius: 50px">
                            <button
                                v-if="
                                    $page.props.jetstream.managesProfilePhotos
                                "
                                class="flex align-center text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                            >
                                <img
                                    class="h-8 w-8 rounded-full object-cover"
                                    :src="
                                        $page.props.auth.user.profile_photo_path
                                            ? `/api/image-service/40x40/${$page.props.auth.user.profile_photo_path}`
                                            : $page.props.auth.user
                                                  .profile_photo_url
                                    "
                                    :alt="$page.props.auth.user.name"
                                />
                                <div
                                    class="mx-1 soli-grey flex align-center"
                                    style="line-height: 12px"
                                >
                                    {{ $page.props.auth.user.first_name }}
                                </div>
                                <v-icon dense class="soli-grey"
                                    >expand_more</v-icon
                                >
                            </button>

                            <button
                                v-else
                                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                            >
                                <div>
                                    {{ $page.props.auth.user.name }}
                                </div>

                                <div class="ml-1">
                                    <svg
                                        class="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </v-card>
                    </template>

                    <template #content>
                        <!-- Account Management -->
                        <div v-if="is('Admin') && !playView">
                            <a
                                href="/admin"
                                class="soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            >
                                Admin Panel
                            </a>
                            <v-divider />
                        </div>
                        <jet-dropdown-link
                            :href="`/profile/${$page.props.auth.user.id}/account`"
                        >
                            View Profile
                        </jet-dropdown-link>
                        <jet-dropdown-link v-if="playView" as="button"
                            ><a href="/update-billing">
                                Manage Payment Methods</a
                            >
                        </jet-dropdown-link>
                        <v-divider />
                        <jet-dropdown-link
                            v-if="$page.props.staffView && is('Teacher')"
                            :href="`/profile/${$page.props.auth.user.id}/availability`"
                        >
                            Manage Availability
                        </jet-dropdown-link>

                        <div v-if="$page.props.canWorkSummary">
                            <div
                                v-if="
                                    $page.props.canWorkSummary &&
                                    $page.props.auth.user.locations.length > 1
                                "
                                @click="$emit('locationSelect')"
                            >
                                <a
                                    @click="$emit('locationSelect')"
                                    class="flex soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                >
                                    {{
                                        $page.props.auth.user.selected_location
                                            .name
                                    }}
                                    <v-spacer />
                                    <v-icon small color="primary"
                                        >my_location</v-icon
                                    >
                                    <div v-if="showMacros" class="px-1">
                                        + L
                                    </div></a
                                >
                            </div>

                            <div @click="setView" v-if="can('Work')">
                                <a
                                    class="flex soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                >
                                    Student View
                                    <v-spacer />

                                    <v-icon
                                        style="height: 20px"
                                        :color="toggleView ? 'dark' : 'accent'"
                                        >{{
                                            toggleView
                                                ? "toggle_off"
                                                : "toggle_on"
                                        }}</v-icon
                                    >
                                    <div v-if="showMacros" class="px-1">
                                        + M
                                    </div></a
                                >
                            </div>

                            <div
                                @click="showTimeClock = true"
                                v-if="
                                    can('Use time clock') &&
                                    $page.props.staffView
                                "
                            >
                                <a
                                    class="flex align-center soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                >
                                    Time Clock
                                    <v-icon
                                        v-if="$page.props.currentTimeClock"
                                        class="animate__animated animate__pulse animate__repeat-3 justify-end flex"
                                        right
                                        small
                                        color="error"
                                        >timer</v-icon
                                    >
                                </a>
                            </div>

                            <v-divider />
                        </div>
                        <!--<a
                            href="/help-articles"
                            class="soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            >Help</a
                        >-->
                        <div @click="composeEmail = true">
                            <a
                                class="soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            >
                                Share Feedback</a
                            >
                        </div>
                        <a
                            href="https://musicologie.app/privacy"
                            target="_blank"
                            class="soli-round block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            >Privacy Policy</a
                        >
                        <v-divider />

                        <!-- Authentication -->
                        <form @submit.prevent="logout">
                            <jet-dropdown-link as="button" class="error--text">
                                Logout
                            </jet-dropdown-link>
                        </form>
                    </template>
                </jet-dropdown>
            </div>
        </div>
        <UserCreate
            v-if="userCreate"
            @reset="reset"
            @success="reset"
            :errors="$page.props.errors"
        ></UserCreate>
        <TimeClock
            v-if="showTimeClock"
            :user="$page.props.auth.user"
            @success="$emit('success')"
            @reset="closeModal(() => (showTimeClock = false))"
        />
        <ComposeEmail
            v-if="composeEmail"
            :emails="['feedback@musicologie.com']"
            :errors="errors"
            :user="$page.props.auth.user"
            @success="emailSuccess()"
            @reset="closeModal(() => (composeEmail = false))"
        />
    </v-app-bar>
</template>

<script>
import JetApplicationLogo from "./../Jetstream/ApplicationLogo";
import JetApplicationMark from "./../Jetstream/ApplicationMark";
import JetDropdown from "./../Jetstream/Dropdown";
import JetDropdownLink from "./../Jetstream/DropdownLink";
import JetNavLink from "./../Jetstream/NavLink";
import JetResponsiveNavLink from "./../Jetstream/ResponsiveNavLink";
//import SoliForm from "./../Components/Base/SoliForm";
import UserCreate from "./Users/UserCreate";
import GlobalSearch from "./GlobalSearch";
import { Link } from "@inertiajs/vue2";
//import SoliHeader from "./../Components/Base/SoliHeader";
import globalMoment from "../Mixins/GlobalMoment.js";
import ComposeEmail from "./Users/ComposeEmail.vue";
import Notifications from "./Notifications.vue";
import Inbox from "./Inbox/Inbox.vue";
import TimeClock from "./../Components/TimeClock.vue";

export default {
    name: "navbar",
    mixins: [globalMoment],
    props: [
        "errors",
        "pageTitle",
        "backLink",
        "tabs",
        "cart",
        "filterData",
        "sandwich",
        "nearMe",
        "market",
        "retentionGroup",
        "showArchived",
        "showAllStudents",
        "headerButton",
        "showMacros",
    ],
    data() {
        return {
            showingNavigationDropdown: false,
            isScrolled: null,
            userCreate: false,
            pageFilter: null,
            activateFilter: false,
            showSearch: false,
            searchBinding: null,
            peopleOptions: false,
            marketOptions: [
                { id: 1942628, name: "Columbus" },
                { id: 1942550, name: "Cleveland" },
                { id: 1942551, name: "Cincinnati" },
            ],
            selectedMarket: this.market,
            setNearMe: this.nearMe,
            setShowArchived: this.showArchived,
            setShowAllStudents: this.showAllStudents,
            showTimeClock: false,
            composeEmail: false,
            toggleView: this.$page.props.staffView,
            /*snowOptions: {
                show: [10, 11].includes(new Date().getMonth()),
                size: 8,
                fall_speed: 2,
                //density: 100,
                //color: "#F1F8FE",
            },*/
        };
    },
    components: {
        JetApplicationLogo,
        JetApplicationMark,
        JetDropdown,
        JetDropdownLink,
        JetNavLink,
        JetResponsiveNavLink,
        UserCreate,
        GlobalSearch,
        Link,
        ComposeEmail,
        Notifications,
        Inbox,
        TimeClock,
    },
    methods: {
        emailSuccess() {
            this.$emit("success");
            this.closeModal(() => (this.composeEmail = false));
        },
        handleShowAllStudents(setShowAllStudents) {
            if (setShowAllStudents) {
                this.setShowAllStudents = !this.setShowAllStudents;
            } else {
                this.setShowAllStudents = false;
            }
            this.$inertia.visit(this.path, {
                data: {
                    showAllStudents: Number(this.setShowAllStudents),
                },
            });
        },
        handleNearMe(setNearMe) {
            if (setNearMe) {
                this.setNearMe = !this.setNearMe;
                this.selectedMarket = null;
            } else {
                this.setNearMe = false;
            }
            this.$inertia.visit(this.path, {
                data: {
                    nearMe: this.setNearMe,
                    market: this.selectedMarket,
                    showArchived: this.setShowArchived,
                },
            });
        },
        handleRetentionGroup(group) {
            this.$inertia.visit(this.path, {
                data: {
                    showAllStudents: Number(this.setShowAllStudents),
                    retentionGroup: group,
                },
            });
        },
        handleShowArchived() {
            this.setShowArchived = !this.setShowArchived;
            this.$inertia.visit(this.path, {
                data: {
                    nearMe: this.setNearMe,
                    market: this.selectedMarket,
                    showArchived: this.setShowArchived,
                },
            });
        },
        logout() {
            axios.get("/logout").then((response) => {
                window.location = "/";
            });
        },
        toggleFilter() {
            this.activateFilter = !this.activateFilter;
            if (this.activateFilter) {
                this.$refs.comboFilter.focus();
                this.$refs.comboFilter.activateMenu();
            } else {
                this.$refs.comboFilter.blur();
            }
        },
        reset() {
            this.userCreate = false;
        },
        onScroll() {
            this.isScrolled = window.scrollY > 20;

            let toolbarContent = this.$el.querySelector(".v-toolbar__content");
            if (this.isScrolled) {
                let height = this.keepTabHeader ? "96px" : "56px";
                toolbarContent.style.setProperty("height", height);
                //toolbarContent.style.setProperty("transition", "height 0.05s ease");
            } else {
                toolbarContent.style.setProperty(
                    "height",
                    this.withTabs ? "128px" : "96px"
                );
            }
        },
        setView() {
            this.toggleView = !this.toggleView;
            this.$inertia.get(`/mode`);
        },
        setNavContainer() {
            let toolbar = this.$el.querySelector(".v-toolbar__content");
            if (!this.$page.props.staffView) {
                toolbar.classList.add("container", "py-0");
            } else {
                toolbar.classList.remove("container", "py-0");
            }
        },
    },
    computed: {
        showFilter() {
            let comp = this.$page.component;
            return comp == "MySchedule" && this.filterData
                ? this.filterData.length > 1
                : false; //)
        },
        showPageSearch() {
            let comp = this.$page.component;
            return comp == "People" || comp == "Store";
        },
        playView() {
            return !this.$page.props.staffView;
        },
        getView() {
            /*if (this.is("Lobby Computer")) {
                if (this.can("Elevate lobby mode")) return "Lobby Mode";
                return "Manager Mode";
            }*/
            return this.$page.props.staffView ? "toggle_off" : "toggle_on";
            return this.$page.props.staffView ? "Student View" : "Staff View";
        },
        path() {
            return window.location.pathname;
        },

        withTabs() {
            return this.tabs ? true : false;
        },
        keepTabHeader() {
            return (
                this.$page.component == "ShowUser" ||
                this.$page.component == "People"
            );
        },
        isMobile() {
            return this.$vuetify.breakpoint.xs ? true : false;
        },
    },
    updated() {
        this.setNavContainer();
    },
    mounted() {
        this.setNavContainer();
    },
};
</script>

<style>
.email {
    padding-right: 10px;
}
.brand-logo {
    margin-left: 60px;
}

.search {
    width: 25%;
    min-width: 200px;
    max-width: 300px;
    border-radius: 30px;
    padding-left: 10px;
    height: 37px;
}

.ease {
    transition: transform 0.1s ease;
    -webkit-transition: transform 0.1s ease;
}

.ease-fade {
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
}

.push-down {
    transform: translateY(14px);
    -webkit-transform: translateY(14px);
}

.collapse-nav {
    height: 56px !important;
    transition: height 0s ease;
    -webkit-transition: height 0s ease;
}

.collapse-tab-nav {
    height: 96px !important;
    transition: height 0s ease;
    -webkit-transition: height 0s ease;
}

.pull-up-fade {
    transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    opacity: 0;
}

.pull-up {
    transform: translateY(-35px) !important;
    -webkit-transform: translateY(-35px) !important;
    transition: transform 0.2s ease;
    -webkit-transition: transform 0.2s ease;
}

.white-indicator {
    border-bottom: 2px white solid;
}

.blue-indicator {
    border-bottom: 2px #2aabe6 solid;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none !important;
}

.custom.v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none !important;
}

.filter-space {
    width: calc(100vw - 40px);
}
</style>
