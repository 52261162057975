<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="6" v-if="$page.props.staffView">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title class="soli-grey">
                            Activity Log
                        </v-card-title>
                        <v-card-subtitle class="caption">
                            Last Online:
                            {{
                                user.last_seen
                                    ? globalMomentFullDateAtTimeShort(
                                          new Date(user.last_seen)
                                      )
                                    : null
                            }}
                        </v-card-subtitle>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader
                                    type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
                                    class="mb-6"
                                    boilerplate
                                    v-if="!activities"
                                ></v-skeleton-loader>
                                <v-simple-table>
                                    <tbody>
                                        <tr
                                            v-for="item in activities"
                                            :key="item.id"
                                        >
                                            <td>
                                                {{
                                                    globalMomentDateAtTimeDots(
                                                        item.created_at
                                                    )
                                                }}
                                            </td>
                                            <td>{{ item.description }}</td>

                                            <td>
                                                {{
                                                    item.date
                                                        ? globalMomentDateAtTimeDots(
                                                              item.date
                                                          )
                                                        : ""
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <!--<Link
                    class="float-right caption pt-3"
                    :href="`/students/${user.id}/activities`"
                    >View All</Link
                  >-->
                            </v-col>
                        </v-row>
                    </v-container></v-card
                >
            </v-col>
            <v-col cols="12" xs="12" sm="6" v-if="$page.props.staffView">
                <ContactHistory
                    :receivedHistory="receivedHistory"
                    :sentHistory="sentHistory"
                    :user="user"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";
import ContactHistory from "./ContactHistory.vue";
export default {
    mixins: [globalMoment],
    props: ["user", "activities", "receivedHistory", "sentHistory"],
    components: { ContactHistory },
    data: function () {
        return {};
    },
};
</script>

<style>
.simple-table-scroll-height {
    max-height: 700px;
    overflow-y: auto;
}
</style>
