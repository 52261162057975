@tailwind base;
@tailwind components;
@tailwind utilities;

.gap-2 {
    gap: 0.5rem;
}

.l-blue {
    background-color: #2aabe6 !important;
}

.soli-blue {
    background-color: #228cbd !important;
}

.soli-blue-text {
    color: #228cbd !important;
}

.soli-blue--text {
    color: #228cbd !important;
}

.m-orange {
    background-color: #f38c68 !important;
}

.m-orange-text {
    color: #f38c68 !important;
}

.soli-green-text {
    color: #48ad24 !important;
}

.chat-orange {
    background-color: rgba(243, 140, 104, 0.25) !important;
}

.chat-blue {
    background-color: rgba(120, 178, 233, 0.25) !important;
}

.shadow-orange {
    box-shadow: 1px 4px 4px 0 rgba(243, 140, 104, 0.25) !important;
}

.shadow-orange-xl {
    box-shadow: 0 3px 5px -1px rgb(243 140 104 / 50%),
        0 5px 8px 0 rgb(243 140 104 / 35%), 0 1px 14px 0 rgb(243 140 104 / 25%) !important;
}

.shadow-blue {
    box-shadow: 1px 4px 8px 0 rgba(120, 178, 233, 0.25) !important;
}

.text-shadow-blue {
    text-shadow: rgba(34, 140, 189, 0.25) 0px 4px 4px;
}

.text-3xl {
    font-size: 1.8rem;
}

.soli-round {
    border-radius: 20px !important;
}

.soli-partial-round {
    border-radius: 12px !important;
}

.full-round {
    border-radius: 50px !important;
}

.half-round {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.bottom-round {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.right-round {
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.gradient-orange {
    background: rgb(243, 140, 104);
    background: linear-gradient(
        100deg,
        rgba(243, 140, 104, 1) 2%,
        /* rgba(235, 81, 120, 1) 100%*/ rgba(235, 81, 81, 1) 100%
    );
    box-shadow: 1px 4px 4px 0 rgba(243, 140, 104, 0.25) !important;
}

.gradient-orange-default-shadow {
    background: linear-gradient(
        100deg,
        rgba(243, 140, 104, 1) 2%,
        /* rgba(235, 81, 120, 1) 100%*/ rgba(235, 81, 81, 1) 100%
    );
    box-shadow: none;
}

.shadow-orange-xl {
    box-shadow: 0 3px 5px -1px rgb(243 140 104 / 50%),
        0 5px 8px 0 rgb(243 140 104 / 35%), 0 1px 14px 0 rgb(243 140 104 / 25%) !important;
}

.gradient-blue {
    background: rgb(42, 171, 230);
    background: linear-gradient(
        100deg,
        rgba(42, 171, 230, 1) 2%,
        rgba(0, 139, 203, 1) 100%
    );
    box-shadow: 1px 4px 4px 0 rgba(42, 171, 230, 0.25) !important;
}

.v-text-field--outlined,
.v-text-field--solo {
    border-radius: 20px !important;
}

.cancel-btn {
    position: fixed !important;
    right: 0px;
}

.shadow-left {
    box-shadow: -4px 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.tBlue {
    background-color: rgba(34, 140, 189, 0.5);
}

.soli-red {
    background-color: #e07b7b !important;
}

.soli-red-text {
    color: #e07b7b !important;
}

.soli-caption {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.5px;
    color: #4b6876;
}

.soli-body {
    font-size: 16px;
    letter-spacing: normal;
    color: #4b6876;
    font-weight: 700;
}

.soli-subtitle {
    font-size: 1.3rem !important;
    /*font-family: Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";*/
    font-weight: 400;
}

.soli-grey {
    /*color: #4b6876 !important;*/
    color: #46606d !important;
}

.grey-text {
    color: #9e9e9e !important;
}

.soli-text-shadow {
    text-shadow: 1px 4px 4px rgba(0, 0, 0, 0.2);
}

.soli-body {
    font-size: 16px;
    letter-spacing: 1.2px;
    line-height: 3rem;
}

.soli-close {
    position: absolute !important;
    top: 0px;
    right: 0px;
}

.soli-icon-text {
    display: flex;
    align-items: center;
    width: 100px !important;
}

.soli-status-blue {
    background-color: #7bb1e0 !important;
}

.soli-status-orange {
    background-color: #f38c68 !important;
}

.soli-status-yellow {
    background-color: #e3b345 !important;
}

.soli-status-green {
    background-color: #32c090 !important;
}

.soli-status-green--text {
    color: #32c090 !important;
}

.soli-status-purple {
    background-color: #ba7be0 !important;
}

.soli-status-pink {
    background-color: #e07bb1 !important;
}

.soli-status-purple--text {
    color: #ba7be0 !important;
}

.soli-status-grace {
    background-color: #66aea7 !important;
}

.soli-status-grace--text {
    color: #66aea7 !important;
}

.soli-status-red {
    background-color: #e07b7b !important;
}

.soli-status-red--text {
    color: #e07b7b !important;
}

.soli-status-grey {
    background-color: #cccccc !important;
}

.soli-status-group {
    background-color: #69c0c6 !important;
}

.soli-status-group--text {
    color: #69c0c6 !important;
}

.soli-attendance-green {
    background-color: #48ad24 !important;
}

.soli-attendance-grey {
    background-color: #4b6876 !important;
}

.soli-attendance-red {
    background-color: #e07b7b !important;
}

a {
    outline: none !important;
}

button {
    outline: none !important;
}

.v-tab {
    text-transform: none !important;
    min-width: 50px !important;
    padding: 0px !important;
    margin-right: 15px !important;
}

.v-tab--active.v-tab:not(:focus):before {
    opacity: 0 !important;
}

.hide-card {
    box-shadow: none !important;
}

.no-link:not(.v-tab--active) {
    color: #4b6876 !important;
}

.v-menu__content {
    border-radius: 20px !important;
    z-index: 300 !important;
}

.assessment-shadow {
    box-shadow: 3px 3px 10px 3px rgba(123, 224, 169, 0.25) !important;
}

.group-shadow {
    box-shadow: 3px 3px 10px 3px rgba(105, 192, 198, 0.25) !important;
}

.grace-shadow {
    box-shadow: 3px 3px 10px 3px rgba(102, 174, 167, 0.25) !important;
}

.cancelled-shadow {
    box-shadow: 3px 3px 10px 3px rgba(204, 204, 204, 0.25) !important;
}

.membership-shadow {
    box-shadow: 3px 3px 10px 3px rgba(123, 177, 224, 0.25) !important;
}

.pass-shadow {
    box-shadow: 3px 3px 10px 3px rgba(186, 123, 224, 0.25) !important;
}

.shadow-grey {
    box-shadow: 3px 3px 10px 3px rgba(75, 104, 118, 0.25) !important;
}

.unpaid-shadow {
    box-shadow: 3px 3px 10px 3px rgba(224, 123, 123, 0.25) !important;
}

.click-events {
    pointer-events: all !important;
}

.no-click-events {
    pointer-events: none;
}

.upper-third {
    transform: translate(0, 15%);
}

.panel-text {
    position: absolute;
    top: 24px;
}

.tab-h-full {
    height: 100vh;
}

.desktop-scroll {
    overflow-y: auto !important;
    max-height: 100vh;
}

.mobile-scroll {
    padding-bottom: 64px !important;
}

.schedule-list-items {
    padding-left: 10px !important;
    height: 85px !important;
}

.soli-link {
    cursor: pointer;
    color: #2aabe6;
}

.custom-tabs .v-slide-group__prev {
    display: none !important;
}

.custom-tabs .v-slide-group__next {
    display: none !important;
}

.avatar-btn {
    letter-spacing: normal !important;
    height: 66px !important;
    border-radius: 50px !important;
}

.avatar-image {
    min-width: fit-content;
}

.blue-avatar-border {
    color: #228cbd !important;
    border: solid 3px;
}

.white-avatar-border {
    color: #ffffff !important;
    border: solid 3px;
}

.progress-text {
    font-size: 10px;
    letter-spacing: 1px;
}

.text-align-center {
    text-align: center;
    text-align: -webkit-center;
}

.ma-05 {
    margin: 2px 2px;
}

.mb-05 {
    margin-bottom: 2px;
}

.mx-05 {
    margin: 0px 2px;
}

.py-05 {
    padding: 2px 0px;
}

.px-05 {
    padding: 0px 2px;
}

.v-text-field--outlined.full-round {
    border-radius: 50px !important;
}

.bg-gradient-a {
    background: linear-gradient(
        110deg,
        rgba(0, 139, 203, 1) 100%,
        rgba(42, 171, 230, 1) 2%
    ) !important;
}

.bg-gradient-b {
    background: linear-gradient(
        110deg,
        rgba(25, 73, 114, 1),
        rgba(93, 142, 189, 1)
    ) !important;
}

.stripe-shadow {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%) !important;
}

.text-align-start {
    text-align: start;
}

.text-align-end {
    text-align: end;
}

.v-card__title {
    word-break: break-word !important;
}

.grey-bg {
    background: rgb(245, 245, 247);
}

.soli-shadow {
    box-shadow: 2px 4px 12px rgb(0 0 0 / 8%) !important;
}

::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

.fc-scroller {
    overflow-y: auto;
}

.sticky-col {
    /*position: sticky;
    left: 0;
    background: white;
    z-index: 1;*/
}

.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #f2f2f2 !important;
}

.v-data-table.table-nowrap {
    white-space: nowrap;
}

/*.soli-bg {
    background: #f2f2f2
}

.theme--light.v-tabs-items {
    background-color: #f2f2f2 !important;
}*/

.v-date-picker-table .v-btn:not(.v-btn--round).v-size--default {
    border-radius: 20px !important;
}

.v-date-picker-table--month td {
    padding: 0px 2px;
}

.v-date-picker-table--date td {
    padding: 1px 0px;
}

.stacked-avatar {
    box-sizing: border-box;
    border: 1px solid white;
}

.soli-round.v-list-item--link:before {
    border-radius: 20px;
}

.min-h-dynamic {
    min-height: 100vh;
    min-height: 100dvh;
}

.min-h-smallest {
    min-height: 100vh;
    min-height: 100svh;
}

.v-dialog__content {
    overscroll-behavior: contain;
}

.custom-stepper .v-stepper__wrapper {
    overflow: visible !important;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.max-w-min {
    max-width: min-content;
}

.modal-close-btn {
    position: absolute !important;
    right: 12px;
    top: 12px;
    z-index: 100;
}

.dialog-open-fix {
    position: fixed;
    height: 100%;
    overflow-y: hidden;
}
