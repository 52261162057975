<template>
    <component
        :is="componentStyle.comp"
        v-bind="{ ...componentStyle.props }"
        v-on="componentStyle.on"
        v-model="show"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
                ><v-badge
                    :content="totalCombined"
                    :color="totalCombined ? 'soli-red' : null"
                    overlap
                    :bordered="!!totalCombined"
                >
                    <v-icon
                        class="text-shadow-blue"
                        :color="
                            $page.component == 'Explore' ? 'white' : 'accent'
                        "
                        >chat_bubble_outline</v-icon
                    ></v-badge
                ></v-btn
            >
        </template>
        <v-sheet>
            <v-container>
                <!--<v-row>
                    <v-card-title class="soli-grey pb-2">Inbox</v-card-title>
                    <v-card-subtitle
                            v-if="!untouchedNotifications"
                            class="soli-grey"
                            >You're all caught up!</v-card-subtitle
                        >

                    <v-spacer />
                    <v-btn
                        icon
                        color="dark"
                        small
                        class="ma-4"
                        :href="`/profile/${$page.props.auth.user.id}/preferences`"
                    >
                        <v-icon small>settings</v-icon>
                    </v-btn>
                </v-row>-->

                <v-tabs v-model="tab" class="px-1" grow
                    ><v-tab
                        ><v-badge
                            v-if="$page.props.unreadInboxTotal.messages"
                            :content="$page.props.unreadInboxTotal.messages"
                            color="soli-red"
                            >Messages</v-badge
                        >
                        <div v-else>Messages</div></v-tab
                    ><v-tab
                        ><v-badge
                            v-if="$page.props.unreadInboxTotal.notifications"
                            :content="
                                $page.props.unreadInboxTotal.notifications
                            "
                            color="soli-red"
                            >Notifications</v-badge
                        >
                        <div v-else>Notifications</div></v-tab
                    ></v-tabs
                >

                <v-tabs-items v-model="tab">
                    <v-tab-item> <InboxMessages /> </v-tab-item>
                    <v-tab-item>
                        <InboxNotifications />
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-sheet>
    </component>
</template>

<script>
import axios from "axios";
import { VMenu, VBottomSheet } from "vuetify/lib";
import InboxNotifications from "./InboxNotifications.vue";
import InboxMessages from "./InboxMessages.vue";

export default {
    components: {
        VMenu,
        VBottomSheet,
        InboxNotifications,
        InboxMessages,
    },
    data: function () {
        return {
            show: false,
            tab: 0,
            notifications: {},
            unreadNotifications: 0,
            loading: false,
        };
    },
    mounted() {
        this.fetchNotifications();

        const userId = this.$page.props.auth.user.id;
        if (userId) {
            Echo.private(`App.Models.User.${userId}`).notification((event) => {
                if (event.message) return;
                this.fetchNotifications();
            });
        }
    },
    computed: {
        totalCombined() {
            return (
                this.$page.props.unreadInboxTotal.messages +
                this.$page.props.unreadInboxTotal.notifications
            );
        },
        untouchedNotifications() {
            if (!this.notifications.data) return false;
            return !!this.notifications.data.filter((e) => !e.interacted_at)
                .length;
        },
        componentStyle() {
            let mobileDevice =
                this.$vuetify.breakpoint.xs || this.bottomSheetOnly;
            return {
                comp: mobileDevice ? "v-bottom-sheet" : "v-menu",
                props: mobileDevice
                    ? {
                          class: "soli-round mx-1",
                          "content-class":
                              "half-round overflow-hidden safe-bottom blur white",
                          height: "90vh",
                      }
                    : {
                          left: true,
                          "offset-y": true,
                          "min-width": 400,
                          "max-width": mobileDevice ? null : 400,
                          "close-on-content-click": false,
                          "content-class": "soli-round blur",
                      },
            };
        },
    },
    methods: {
        onScroll(scroll) {
            if (this.loading) return;
            let position = scroll.target.scrollTop;
            let scrollHeight = scroll.target.scrollHeight;
            let totalHeight = scroll.target.offsetHeight;
            let target = scrollHeight - totalHeight;
            if (position < target) return;
            this.loadMore();
        },
        markAsRead() {
            axios
                .put(
                    `/api/notifications/${this.$page.props.auth.user.id}/mark-as-read`
                )
                .then((resp) => {
                    this.unreadNotifications = 0;
                });
        },
        handleClick(notification) {
            if (!notification.interacted_at && notification.id) {
                axios
                    .put(
                        `/api/notifications/${this.$page.props.auth.user.id}/${notification.id}/interact`
                    )
                    .then((resp) => {
                        if (JSON.parse(resp.data.data).link) return;
                        this.fetchNotifications();
                    });
            }
            if (!notification.data.link) return;
            this.$inertia.visit(notification.data.link);
        },
        fetchNotifications() {
            if (this.show) this.markAsRead();
            axios
                .get(
                    `/api/notifications/${this.$page.props.auth.user.id}/index`
                )
                .then((resp) => {
                    this.notifications = resp.data.notifications;
                    this.unreadNotifications = resp.data.unreadNotifications;
                });
        },
        loadMore() {
            if (!this.notifications.next_page_url) return;
            this.loading = true;
            axios.get(this.notifications.next_page_url).then((resp) => {
                this.notifications = {
                    ...resp.data.notifications,
                    data: [
                        ...this.notifications.data,
                        ...resp.data.notifications.data,
                    ],
                };
                this.loading = false;
            });
        },
    },
    watch: {
        show(val) {
            if (val) {
                if (this.unreadNotifications) this.markAsRead();
            }
        },
    },
};
</script>

<style>
.ws-wrap {
    white-space: normal;
}

.unread {
    /*background: rgba(120, 178, 233, 0.2);*/
    border-left: 4px solid #228cbd;
}
</style>
