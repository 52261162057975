<template>
    <div>
        <v-container fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12">
                    <v-card-title
                        class="soli-grey py-0 justify-center font-weight-bold"
                        >{{ getTitle }}</v-card-title
                    >
                    <div
                        v-if="this.$page.props.guest"
                        class="flex justify-center"
                    >
                        <v-menu
                            v-model="timezoneMenu"
                            max-height="400"
                            min-width="375"
                            content-class="white"
                            offset-y
                            auto
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    rounded
                                    text
                                    x-small
                                    color="dark"
                                    class="soli-grey caption text-capitalize"
                                    >{{ timezoneForm.timezone
                                    }}<v-icon right x-small color="secondary"
                                        >keyboard_arrow_down</v-icon
                                    ></v-btn
                                >
                            </template>
                            <v-list dense>
                                <v-list-item-group
                                    v-model="timezoneForm.timezone"
                                    @change="submitTimezone()"
                                >
                                    <v-list-item :value="guessTz"
                                        ><v-list-item-title
                                            >Recommended:
                                            {{ guessTz }}</v-list-item-title
                                        ></v-list-item
                                    >
                                    <v-divider />
                                    <v-list-item
                                        v-for="(timezone, idx) in timezones"
                                        :key="idx"
                                        :value="timezone"
                                        ><v-list-item-title>{{
                                            timezone
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                    <v-card-subtitle
                        v-else
                        class="soli-grey caption flex justify-center pb-0 pt-4"
                        ><v-icon x-small color="dark" class="mr-2"
                            >public</v-icon
                        >{{
                            this.$page.props.auth?.user?.timezone ||
                            this.selectedLocation.timezone
                        }}</v-card-subtitle
                    >
                </v-col>
            </v-row>
        </v-container>

        <v-skeleton-loader
            class="mx-auto my-3"
            max-width="300"
            type="list-item, list-item, list-item"
            v-if="loading && !getEvents.length"
        ></v-skeleton-loader>

        <v-row justify="center" no-gutters>
            <v-col
                cols="12"
                :sm="getStartedContext ? 8 : false"
                :md="getStartedContext ? 6 : false"
                class="my-8 pa-3"
                v-if="!getEvents.length && !loading"
            >
                <v-card
                    flat
                    outlined
                    class="soli-round pa-3"
                    style="border: 3px solid #228cbd"
                    v-if="getStartedContext"
                >
                    <v-card-title
                        class="soli-grey pt-0 justify-center font-weight-bold"
                        >Don't Panic!</v-card-title
                    >
                    <v-card-subtitle class="soli-grey"
                        >{{
                            `I'm sure we can find something for you! Take a look
                        around at any other nearby Musicologie using the filters
                        above, or contact us for help${
                            getStartedContext
                                ? ` getting
                        started.`
                                : "."
                        }`
                        }}
                    </v-card-subtitle></v-card
                >
            </v-col>
            <v-slide-group
                v-show="!loading || getEvents.length"
                ref="scrollDate"
                center-active
                v-model="selectedEvent"
                mandatory
                :show-arrows="$vuetify.breakpoint.smAndUp"
            >
                <v-slide-item
                    v-for="(event, idx) in getEvents"
                    :key="idx"
                    v-slot="{ active, toggle }"
                    :value="globalMomentDate(event)"
                >
                    <v-btn
                        @click="toggle"
                        :loading="active && intervalsLoading"
                        :color="active ? 'primary' : 'dark'"
                        small
                        :text="!active"
                        :class="{
                            'shadow-blue': active,
                            'ml-3': idx == 0,
                            'mr-3': idx == getEvents.length - 1,
                        }"
                        height="55"
                        width="55"
                        class="round mx-1 my-2"
                        ><div class="flex-col">
                            <div
                                class="font-weight-black subtitle-1"
                                style="line-height: 20px"
                            >
                                {{ globalMomentDay(event) }}
                            </div>
                            <div
                                class="font-weight-bold"
                                style="text-transform: none"
                            >
                                {{ globalMomentWeekDayShort(event) }}
                            </div>
                        </div></v-btn
                    >
                </v-slide-item>
            </v-slide-group></v-row
        >

        <v-container>
            <v-skeleton-loader
                class="mx-auto my-5"
                max-width="350"
                type="list-item-avatar-three-line"
                v-if="loading && getEvents.length"
            ></v-skeleton-loader>
            <v-row
                v-if="!loading && !selectedTeacher && getEvents.length"
                class="mt-1 justify-center"
            >
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    class="flex justify-center"
                    style="min-width: 390px; max-width: 400px"
                    v-for="(intervalGroup, idx) in intervals"
                    :key="intervalGroup.teacher.id"
                    ><TeacherAvatarCard
                        expand
                        :teacher="intervalGroup.teacher"
                        :selectedInstrument="selectedInstrument"
                        :intervalGroup="intervalGroup.intervals"
                        :loading="loading"
                        disabled
                        :simStudentView="simStudentView"
                        :hideDivider="idx == intervals.length - 1"
                        :floating="floating"
                        @inputTime="
                            $emit('inputTime', {
                                teacher: $event.teacher,
                                assignment_id: $event.assignment_id,
                                time: $event.time,
                            })
                        "
                        :value="value"
                /></v-col>
            </v-row>
            <v-row
                v-if="!loading && selectedTeacher && getEvents.length"
                class="mt-1 justify-center"
            >
                <v-col
                    v-for="intervalGroup in intervals"
                    :key="intervalGroup.teacher.id"
                >
                    <div
                        v-for="ints in intervalGroup.intervals"
                        :key="ints.assignment_id"
                    >
                        <SoliTime
                            v-if="selectedTeacher"
                            label="Start Time"
                            ref="soliTime"
                            :narrow="$vuetify.breakpoint.xs"
                            :teacher="intervalGroup.teacher"
                            :selectedInstrument="selectedInstrument"
                            :intervals="ints.openTimes"
                            :assignment_id="ints.assignment_id"
                            :loading="loading"
                            :select="false"
                            @input="
                                $emit('inputTime', {
                                    teacher: intervalGroup.teacher,
                                    assignment_id: ints.assignment_id,
                                    time: $event,
                                })
                            "
                        />
                    </div>
                </v-col>
            </v-row>
            <SoliChoice
                v-if="membershipEndAlert"
                title="Sorry!"
                :subtitle="`There are no available options before your membership ends on ${globalMomentDateDots(
                    membershipEndAlert
                )}.`"
                @reset="membershipEndAlert = null"
                button1="Okay"
                @option1="$emit('reset')"
            ></SoliChoice>
        </v-container>

        <v-card
            v-if="$page.component == 'MySchedule' && selectedTeacher"
            class="soli-round ma-3"
            style="overflow: hidden"
            flat
            outlined
            @click="$emit('somethingNew', true)"
        >
            <v-card-subtitle>
                Can't find something that works?
                <u class="soli-link">Try something new</u> by booking with a
                different teacher, or on a different instrument
            </v-card-subtitle>
        </v-card>
    </div>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";
import TeacherAvatarCard from "../GetStarted/TeacherAvatarCard.vue";

export default {
    mixins: [globalMoment],
    components: {
        TeacherAvatarCard,
    },
    data: function () {
        return {
            picker: null,
            activePicker: null,
            scrollRow: null,
            membershipEndAlert: null,
            selectedEvent: this.value,
            active: null,
            timezoneMenu: false,
            timezoneForm: this.$inertia.form({
                timezone:
                    this.$page.props.auth?.user?.timezone ??
                    this.$page.props.guest?.timezone ??
                    moment.tz.guess(),
                guestId: this.$page.props.guest?.id ?? null,
            }),
        };
    },
    props: {
        events: {
            type: Array,
            default: [],
        },
        value: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: true,
        },
        future: {
            type: Boolean,
            default: false,
        },
        max: {
            type: String,
            default: null,
        },
        intervals: {
            type: Array,
            default: null,
        },
        selectedTeacher: {
            type: Boolean,
            default: false,
        },
        selectedInstrument: {
            type: Number,
            default: null,
        },
        selectedLocation: {
            type: Object,
            default: () => {},
        },
        stickyDates: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: true,
        },
        simStudentView: {
            type: Boolean,
            default: false,
        },
        intervalsLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        submitTimezone() {
            if (!this.$page.props.guest) return;
            this.timezoneForm.put(
                route("guestSession.update", this.$page.props.guest.hash_id),
                {
                    preserveState: false,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$inertia.reload();
                    },
                }
            );
        },
    },
    watch: {
        selectedEvent(newVal, oldVal) {
            if (newVal) this.$emit("input", newVal);
        },
    },
    computed: {
        guessTz() {
            return moment.tz.guess();
        },
        timezones() {
            return moment.tz.zonesForCountry("US").includes(this.guessTz)
                ? moment.tz.zonesForCountry("US")
                : moment.tz.names();
        },
        getStartedContext() {
            return this.$page.component == "GetStarted/BookNow";
        },
        getOverflow() {
            return this.events.length > 4;
        },
        getEvents() {
            let events = [
                ...new Set(
                    this.events
                        .filter((e) => {
                            if (this.tomorrow) {
                                //return moment(e).isAfter(this.tomorrow); // this was excluding entire availabilities, when really just the times should be excluded
                                return moment(e).isAfter(new Date());
                            } else {
                                return e;
                            }
                        })
                        .filter((e) => {
                            if (this.max) {
                                return moment(e).isBefore(moment(this.max));
                            } else if (
                                this.can("Schedule outside of six weeks")
                            ) {
                                return e; //moment(e).isBefore(this.sixWeeks);
                            } else {
                                return moment(e).isBefore(this.twoWeeks);
                            }
                        })
                ),
            ];
            if (!events.length && this.max) this.membershipEndAlert = this.max;
            return events;
        },
        getTitle() {
            if (!this.events.length && !this.loading) {
                return "No Available Dates";
            }
            let firstMonth = this.globalMomentMonth(this.events[0]);
            let last = this.events[this.events.length - 1];
            let lastMonth = this.globalMomentMonth(last);
            let lastYear = this.globalMomentYear(last);
            let showLast = firstMonth !== lastMonth;
            return `${firstMonth} ${
                showLast ? `- ${lastMonth} ` : ""
            }${lastYear}`;
        },
        twoWeeks() {
            return moment()
                .add(2, "weeks")
                .endOf("day")
                .tz("UTC")
                .toISOString(true);
        },
        sixWeeks() {
            return moment()
                .add(6, "weeks")
                .endOf("day")
                .tz("UTC")
                .toISOString(true);
        },
        tomorrow() {
            if (this.future && this.$page.component == "Store") {
                return moment(new Date()).startOf("month").format("YYYY-MM-DD");
            }
            if (
                this.can("Schedule within 24 hours") &&
                this.$page.props.staffView &&
                !this.simStudentView
            ) {
                return null;
            }
            return this.reschedule || this.future
                ? moment().add(24, "hours").tz("UTC").toISOString(true)
                : null;
        },
    },
    mounted() {
        this.$emit("mounted");
    },
};
</script>

<style scoped>
.scrollDate {
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.scrollDate::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
.round {
    border-radius: 50% !important;
}

.sticky-btn {
    position: absolute !important;
}

.sticky-dates {
    position: sticky;
    top: 128px;
    background: white;
    z-index: 100;
}
</style>
