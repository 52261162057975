<template>
    <v-app class="min-h-screen" v-resize="setHero">
        <!-- Google Tag Manager (noscript) (Musicologie) -->
        <noscript>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-MFH878M"
                height="0"
                width="0"
                style="display: none; visibility: hidden"
            >
            </iframe>
        </noscript>
        <noscript>
            <img
                height="1"
                width="1"
                style="display: none"
                src="https://www.facebook.com/tr?id=220594841032999&ev=PageView&noscript=1"
            />
        </noscript>
        <v-system-bar
            v-if="false"
            app
            class="gradient-orange white--text justify-center"
            height="64"
            ><div>
                <v-card-subtitle>
                    Network error detected. No offline access.
                </v-card-subtitle>
            </div>
        </v-system-bar>
        <v-system-bar
            v-if="$page.props.version?.outage"
            app
            class="gradient-orange white--text justify-center"
            height="64"
            ><div>
                <v-card-subtitle>
                    Temporary service outage. This app might not work as
                    expected. Thanks!
                </v-card-subtitle>
            </div>
        </v-system-bar>
        <v-system-bar
            v-if="
                $page.props.impersonating?.impersonatingUser &&
                !showBanner?.some((e) => e.show)
            "
            app
            class="gradient-blue white--text justify-center"
            height="64"
            ><div class="flex justify-center">
                <v-card-subtitle class="align-self-center">
                    {{ `Impersonating ${this.$page.props.auth.user.name}` }}
                </v-card-subtitle>
                <v-btn
                    class="my-3"
                    color="white"
                    plain
                    @click="stopImpersonating()"
                >
                    Return
                </v-btn>
            </div>
        </v-system-bar>
        <v-app-bar
            app
            ref="appBar"
            color="white"
            height="64"
            elevate-on-scroll
            :hide-on-scroll="hideNavOnScroll"
            :class="{ 'px-6': $vuetify.breakpoint.smAndUp }"
            scroll-threshold="124"
        >
            <Link :href="$page.props.auth.user ? '/' : '/get-started'">
                <jet-application-logo
                    class="block w-auto"
                    :class="$vuetify.breakpoint.xs ? 'h-6' : 'h-9'"
                />
            </Link>
            <v-spacer />
            <div class="flex justify-end align-center">
                <div v-if="!!location" class="mr-4">
                    <a
                        class="v-btn--text full-round"
                        :href="'tel:' + location.phone"
                        ><div v-if="$vuetify.breakpoint.smAndUp">
                            {{ location.phone }}
                        </div>
                        <v-icon v-if="$vuetify.breakpoint.xs" color="dark"
                            >phone</v-icon
                        >
                    </a>
                </div>
                <SoliBtn
                    v-if="!$page.props.auth.user"
                    color="gradient-orange"
                    href="/login"
                    style="color: white !important"
                    >Login</SoliBtn
                >
                <SoliProfileDropdown @success="showMessage = true" />
            </div>
        </v-app-bar>
        <v-img
            id="bg-stars"
            :class="{ 'bg-gradient-b': bgGradient }"
            :src="img"
        >
            <div
                id="toolbar"
                :class="{ 'px-6': $vuetify.breakpoint.smAndUp }"
                class="absolute w-full"
                style="top: 64px; z-index: 100"
            >
                <v-row v-if="breadcrumbs" class="px-4" no-gutters>
                    <v-breadcrumbs :items="getBreadcrumbs" dark class="px-0"
                        ><template v-slot:item="{ item }"
                            ><li>
                                <a
                                    class="bread-link"
                                    :href="item.href"
                                    :class="{
                                        'v-breadcrumbs__item--disabled':
                                            item.disabled,
                                    }"
                                >
                                    {{ item.text }}</a
                                >
                            </li></template
                        ></v-breadcrumbs
                    >
                    <div class="flex align-center justify-end">
                        <slot name="toggleFilter"></slot>
                        <!--<div>
                            <div class="flex flex-col">
                                <v-btn outlined dark rounded small
                                    >{{ recipient?.first_name
                                    }}<v-icon>arrow_drop_down</v-icon></v-btn
                                >
                            </div>
                        </div>-->
                    </div>
                </v-row>
            </div>
            <v-container
                :fill-height="fillHeight"
                :fluid="fluid"
                :class="{ testimonial: review }"
            >
                <v-main>
                    <v-row
                        align="center"
                        class="white--text animate__animated animate__fadeIn py-3"
                        :class="{ 'mt-6': breadcrumbs }"
                        justify="center"
                    >
                        <div
                            v-if="title"
                            :class="{
                                'text-h6': $vuetify.breakpoint.xs,
                                'text-h5': $vuetify.breakpoint.sm,
                                'text-h4': $vuetify.breakpoint.mdAndUp,
                            }"
                            class="white--text justify-center pa-6 w-full flex-col text-center font-weight-bold"
                        >
                            <div
                                v-html="title"
                                style="white-space: pre; line-height: 1.6rem"
                            />
                            <div
                                v-if="subtitle"
                                :class="[
                                    $vuetify.breakpoint.smAndDown
                                        ? 'text-body-1'
                                        : 'text-h6',
                                    'pt-0.5',
                                ]"
                                v-html="subtitle"
                            ></div>
                            <slot name="titleAction"></slot>
                        </div>
                        <slot ref="main"></slot>
                    </v-row>

                    <div class="py-3 justify-center flex" v-if="needHelp">
                        <v-btn
                            class="text-capitalize soli-round"
                            small
                            text
                            color="white"
                            large
                            @click="showContact()"
                            >Need help? Contact Us</v-btn
                        >
                    </div>
                </v-main>
            </v-container>
            <v-container id="review" v-if="review" class="flex justify-center">
                <div
                    class="background-circle"
                    :class="{ 'mobile-circle': $vuetify.breakpoint.smAndDown }"
                ></div>
                <SoliReview :review="review"
            /></v-container>
        </v-img>

        <SoliResponsiveDialog
            ref="locationContact"
            v-if="showLocationsForm"
            @reset="closeModal(() => (showLocationsForm = false))"
            title="Contact Us!"
            ><template #body>
                <SoliLocations
                    :parentLocations="location ? [location] : locations"
                    :value="location"
                    iterator
                    showAddress
                    hideDetails
                    expandable
            /></template>
        </SoliResponsiveDialog>

        <ShortContactForm
            ref="shortContactForm"
            v-if="showContactForm"
            :programInquiry="programInquiry"
            :guest="guest"
            :child="child"
            :errors="errors"
            @submit="closeModal(() => (showContactForm = false))"
            @reset="closeModal(() => (showContactForm = false))"
        ></ShortContactForm>

        <SoliChoice
            v-if="!!errors.soliError"
            title="Uh oh"
            :subtitle="errors.soliError"
            button1="Okay"
            :link1="errors.soliError.includes('exists') ? '/login' : null"
            @option1="
                closeModal(() => {
                    const pageErrors = $page.props.errors;
                    const { ['soliError']: unused, ...errors } = pageErrors;
                    $page.props.errors = errors;
                })
            "
        ></SoliChoice>

        <SoliChoice
            v-if="!!errors.contactSupport"
            title="Uh oh"
            :subtitle="errors.contactSupport"
            button1="Contact Us!"
            @option1="
                [
                    (showLocationsForm = true),
                    closeModal(() => {
                        const pageErrors = $page.props.errors;
                        const { ['contactSupport']: unused, ...errors } =
                            pageErrors;
                        $page.props.errors = errors;
                    }),
                ]
            "
        ></SoliChoice>

        <SoliChoice
            v-if="!!errors.expired"
            title="Sorry!"
            :subtitle="errors.expired"
            button1="Okay"
            @option1="closeModal(() => ($page.props.errors.expired = null))"
        ></SoliChoice>

        <SoliSnack
            v-if="showMessage"
            @hide="showMessage = false"
            :text="$page.props.successMessage"
        ></SoliSnack>
        <SiteFooter
            :locations="locations"
            :hideFab="hideFab"
            :fixedFab="fixedFab"
        />
    </v-app>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import JetApplicationLogo from "./../Jetstream/ApplicationLogo.vue";
import CheckForUpdates from "../Mixins/CheckForUpdates.js";
import CreateGuestForm from "../Components/GetStarted/CreateGuestForm.vue";
import ShortContactForm from "../Components/GetStarted/ShortContactForm.vue";
import SiteFooter from "../Layouts/Components/SiteFooter.vue";

export default {
    mixins: [CheckForUpdates],
    components: {
        Link,
        JetApplicationLogo,
        CreateGuestForm,
        ShortContactForm,
        SiteFooter,
    },
    props: {
        errors: {},
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        img: {
            type: String,
            default: "/blue-stars-bg.webp",
        },
        fluid: {
            type: Boolean,
            default: false,
        },
        showBanner: {},
        guest: {
            type: Object,
            default: () => {},
        },
        child: {
            type: Object,
            default: () => {},
        },
        locations: {
            type: Array,
            default: () => [],
        },
        bgGradient: {
            type: Boolean,
            default: false,
        },
        fillHeight: {
            type: Boolean,
            default: true,
        },
        noContainer: {
            type: Boolean,
            default: false,
        },
        containerHeight: {
            type: [String, Number],
            default: null,
        },
        backLink: {
            type: Object,
            default: null,
        },
        breadcrumbs: {
            type: Array,
            default: null,
        },
        helpForm: {
            type: Boolean,
            default: true,
        },
        hideFab: {
            type: Boolean,
            default: true,
        },
        fixedFab: {
            type: Boolean,
            default: true,
        },
        hideNavOnScroll: {
            type: Boolean,
            default: false,
        },
        review: {
            type: Object,
            default: null,
        },
        needHelp: {
            type: Boolean,
            default: true,
        },
        location: {
            type: Object,
            default: () => {},
        },
        programInquiry: {
            type: String,
            default: null,
        },
    },
    data: function () {
        return {
            showBookingForm: false,
            showContactForm: false,
            loading: false,
            showMessage: false,
            showLocationsForm: false,
            helpPanels: [],
            showQuickContact: false,
        };
    },
    methods: {
        showContact() {
            if (this.helpForm) return (this.showContactForm = true);
            return (this.showLocationsForm = true);
        },
        setHero() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        },
        stopImpersonating() {
            this.$inertia.visit("/stop-impersonation");
        },
    },
    computed: {
        getBreadcrumbs() {
            return [
                {
                    text: "Get Started",
                    disabled: this.$page.url == "/get-started",
                    href: `/get-started?learner=${
                        this.guest?.has_child ? "child" : "adult"
                    }&location=${this.guest?.location?.slug}&instrument=${
                        this.guest?.instrument?.name ?? null
                    }`,
                },
                {
                    text: "Programs",
                    disabled: this.$page.url.includes("programs"),
                    href: route("getStarted.programs", this.child?.hash_id),
                },
                {
                    text: "Book Now",
                    disabled: this.$page.url.includes("book-now"),
                    href: route("getStarted.bookNow", this.child?.hash_id),
                },
                {
                    text: "Confirm",
                    disabled: true,
                },
                {
                    text: "Home",
                    disabled: false,
                    href: this.$page.props.auth.user ? "/" : "/get-started",
                },
                {
                    text: "Refer A Friend",
                    disabled: true,
                },
                {
                    text: "Student Survey",
                    disabled: true,
                },
                {
                    text: "Summer Camps",
                    href: route("eventRegistration.index", {
                        program_slug: "summer-camps",
                    }),
                    disabled: false,
                },
                {
                    text: "Register",
                    disabled: true,
                },
                {
                    text: "Gifts",
                    disabled: true,
                },
                {
                    text: "Goodbye 🙁",
                    disabled: true,
                },
                {
                    text: "Founders Club",
                    disabled: true,
                },
                {
                    text: "Checkout",
                    disabled: true,
                },
                {
                    text: "Share Musicologie",
                    disabled: true,
                },
            ].filter((e) => this.breadcrumbs.includes(e.text));
        },
        appBarHidden() {
            return this.$refs.appBar.hideShadow;
        },
    },
};
</script>

<style scoped>
.breadcrumbs {
    transform: translateY(80px);
}

.bread-link {
    color: #ffffffbf !important;
}

.background-circle {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 22vh;
    border-radius: 50% 50% 0 0;
    background: rgba(52, 110, 174, 0.5);
    transform: scaleX(1.5);
    z-index: -1;
}

#review {
    height: 22vh;
    align-items: center !important;
    display: flex;
}

.testimonial.container.fill-height {
    height: calc(100% - 22vh) !important;
}

.mobile-circle {
    transform: scaleX(2.2);
}

#bg-stars {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
}
</style>
