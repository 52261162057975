<template>
    <div id="chat-toolbar">
        <div v-if="conversation.create" class="flex flex-col px-4 pt-5">
            <div
                v-if="conversation.create"
                class="soli-grey px-3 text-lg flex"
                :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
            >
                New Message
            </div>
            <SoliStudents
                v-if="conversation.create"
                autocomplete
                multiple
                hasEmail
                chips
                deletableChips
                smallChips
                closeOnClick
                onDemand
                filled
                autofocus
                :dense="false"
                hideDetails="auto"
                single-line
                :disabled="disabled"
                :icon="false"
                label="To:"
                :value="value"
                :error="error"
                :rules="rules"
                :includeTeachers="can('Work')"
                class="custom-autocomplete"
                menuWidth="-webkit-fill-available"
                @input="$emit('input', $event)"
            />
            <v-btn
                icon
                v-if="$vuetify.breakpoint.smAndDown && conversation.create"
                @click="close()"
                class="ma-4 soli-close"
                ><v-icon>clear</v-icon></v-btn
            >
        </div>
        <div
            v-if="!conversation.create"
            class="flex align-center"
            :class="[$vuetify.breakpoint.smAndDown ? 'pa-3' : 'py-2 px-4']"
        >
            <Link
                v-if="$vuetify.breakpoint.smAndDown"
                :href="route('conversation.show')"
                preserve-state
                :only="[
                    'unreadMessageCount',
                    'index',
                    'conversation',
                    'messages',
                    'messageCount',
                ]"
                class="ml-4"
                ><v-btn icon @click="close()"
                    ><v-icon>chevron_left</v-icon></v-btn
                ></Link
            >
            <div
                v-if="!conversation.name"
                class="flex mr-4"
                :class="[$vuetify.breakpoint.smAndDown ? 'ml-4' : 'ml-2']"
                style="flex: 0"
            >
                <v-badge
                    v-if="conversation.profiles.length > 1"
                    :content="conversation.profiles.length"
                    overlap
                    bottom
                    color="dark"
                    offset-y="20"
                >
                    <v-avatar>
                        <v-img
                            :src="conversation.profiles[0].profile_photo_url"
                        /> </v-avatar
                ></v-badge>
                <v-avatar v-else size="48">
                    <v-img :src="conversation.profiles[0].profile_photo_url" />
                </v-avatar>
            </div>
            <div
                class="soli-grey text-lg pt-0.5 font-bold text-xl px-1"
                :class="[
                    conversation.name
                        ? $vuetify.breakpoint.smAndDown
                            ? 'ml-4'
                            : 'ml-2'
                        : null,
                ]"
            >
                {{
                    conversation.name
                        ? conversation.name
                        : conversation.profiles.length > 1
                        ? conversation.firstNameList
                        : conversation.fullNameList
                }}
            </div>
            <v-spacer />

            <v-menu left offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item @click="conversationUpdate = true"
                        >Rename</v-list-item
                    >
                </v-list>
            </v-menu>
        </div>
        <ConversationUpdate
            v-if="conversationUpdate"
            :conversation="conversation"
            @reset="closeModal(() => (conversationUpdate = false))"
        />
    </div>
</template>

<script>
import ConversationUpdate from "./ConversationUpdate.vue";
import { Link } from "@inertiajs/vue2";

export default {
    components: {
        ConversationUpdate,
        Link,
    },
    props: {
        conversation: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            required: false,
            default: () => [],
        },
        error: {
            type: Object,
            required: false,
            default: () => {},
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            show: true,
            conversationUpdate: false,
            form: this.$inertia.form({
                content: "",
                conversation_id: this.conversation?.id,
                recipients: [],
            }),
            conversationForm: this.$inertia.form({
                name: this.conversation?.name ?? this.conversation.list,
            }),
        };
    },
    methods: {
        close() {
            this.show = false;
            this.$emit("close");
        },
    },
};
</script>

<style>
#chat-toolbar {
    flex: 0;
}

.custom-autocomplete .v-text-field--outlined fieldset {
    border: none !important;
}

.custom-autocomplete .v-input__append-inner {
    display: none !important;
}

.custom-autocomplete {
    width: 100%;
}

.custom-autocomplete .v-select__selections {
    max-height: 64px;
    overflow-y: auto;
}
</style>
