<template>
    <div>
        <v-skeleton-loader
            v-if="!events"
            class="mx-auto"
            type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-expansion-panels
            accordian
            flat
            focusable
            v-model="panel"
            v-if="events"
        >
            <v-expansion-panel
                v-for="(item, index) in events"
                :key="item.id"
                :class="['mb-2 mt-0', `panel-${index}`]"
                disabled
            >
                <v-expansion-panel-header
                    expand-icon="keyboard_arrow_down"
                    :class="['soli-round mb-2 pa-0 click-events']"
                    @click="clickedLessonDetails = item.id"
                    :disabled="!!clickedLessonDetails"
                >
                    <v-row
                        :class="[
                            'align-center',
                            item.cancelled ? 'grey-text' : 'soli-grey',
                        ]"
                    >
                        <v-col class="col-3 flex justify-center">
                            <SoliStatusAvatar
                                :event="item"
                                :showGroupStatus="item.type == 'Event'"
                                :purchaseOption="
                                    item.student?.attendance.purchase_option
                                "
                                noShadow
                            ></SoliStatusAvatar>
                        </v-col>
                        <v-col class="pl-0">
                            <v-list class="mx-2">
                                <div class="caption">
                                    {{
                                        `${globalMomentFullDateAtTimeShort(
                                            item.starts_at
                                        )} · ${item.duration} min
                                                    `
                                    }}
                                </div>
                                <div v-if="!item.group_event">
                                    <div v-if="can('View statistical reports')">
                                        <div
                                            :class="
                                                $vuetify.breakpoint.xsOnly
                                                    ? 'body-2'
                                                    : 'body-1'
                                            "
                                        >
                                            {{ item.student?.name }}
                                        </div>
                                        <div class="caption">
                                            {{ item.instrument.name }}
                                            · {{ item.teacher.name }} ·
                                            {{ item.room.title }} ·
                                            {{
                                                item.online
                                                    ? "Online"
                                                    : "In-studio"
                                            }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            :class="
                                                $vuetify.breakpoint.xsOnly
                                                    ? 'body-2'
                                                    : 'body-1'
                                            "
                                        >
                                            {{ item.type }} w/
                                            {{ item.student.name }}
                                        </div>
                                        <div class="caption">
                                            {{ item.instrument.name }}
                                            · {{ item.location.name }} ·
                                            {{ item.room.title }} ·
                                            {{
                                                item.online
                                                    ? "Online"
                                                    : "In-studio"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div
                                        :class="
                                            $vuetify.breakpoint.xsOnly
                                                ? 'body-2'
                                                : 'body-1'
                                        "
                                    >
                                        {{ item.group_event.title }}
                                    </div>
                                    <div class="caption">
                                        {{ item.teacher.name }} ·
                                        {{ item.room.title }} ·
                                        {{
                                            item.online ? "Online" : "In-studio"
                                        }}
                                    </div>
                                </div>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="justify-around align-center py-5">
                        <v-btn icon small color="dark">
                            <v-icon>change_circle</v-icon>
                            <small class="mt-1 panel-text">Reschedule</small>
                        </v-btn>
                        <v-btn icon small color="dark">
                            <v-icon>cancel</v-icon>
                            <small class="mt-1 panel-text">Cancel</small>
                        </v-btn>
                        <v-btn
                            icon
                            small
                            color="dark"
                            v-if="
                                !getPaymentStatus(item) &&
                                can('Manage user locations')
                            "
                        >
                            <v-icon>mail_outline</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Payment Link</small
                            >
                        </v-btn>
                        <v-btn
                            icon
                            small
                            color="dark"
                            v-if="!getPaymentStatus(item)"
                        >
                            <v-icon>add_task</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Attendance</small
                            >
                        </v-btn>
                        <!--<v-btn icon small color="dark">
                            <v-icon>restore</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Refund</small
                            >
                        </v-btn>-->
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <LessonDetail
            v-if="clickedLessonDetails"
            ref="details"
            :errors="$page.props.errors"
            :auth="$page.props.auth"
            :lessonId="clickedLessonDetails"
            @reset="clickedLessonDetails = null"
            @success="handleSuccess"
            @rescheduleLesson="
                [
                    (clickData = $event),
                    (bookingForm = true),
                    (clickedLessonDetails = null),
                ]
            "
            @showMessage="$emit('success')"
        />

        <CreateLesson
            v-if="bookingForm"
            :errors="errors"
            :event="clickData"
            :bookingSource="{
                isNew: false,
                isCalendar: false,
                isMySchedule: false,
                isReschedule: true,
            }"
            @reset="closeModal(() => (bookingForm = false))"
            @success="handleSuccess"
        />
    </div>
</template>

<script>
import LessonDetail from "./../Lessons/LessonDetail.vue";
import CreateLesson from "./../Booking/CreateLesson.vue";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: ["events", "errors"],
    components: {
        LessonDetail,
        CreateLesson,
    },
    data: function () {
        return {
            panel: false,
            clickedLessonDetails: null,
            bookingForm: false,
            clickData: null,
            location: this.$page.props.auth.user.selected_location,
        };
    },
    methods: {
        getPaymentStatus(item) {
            if (!item.purchase_option) return true;
            return item.purchase_option.paid;
        },
        handleSuccess() {
            this.$emit("success");
            setTimeout(() => {
                this.clickedLessonDetails = null;
                this.bookingForm = false;
            }, 300);
        },
    },
};
</script>

<style scoped>
.date-indicator {
    width: 12px;
    height: 85px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.v-list {
    color: unset !important;
}

.text-align-center {
    text-align: center;
}
</style>
