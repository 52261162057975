<template>
    <div>
        <v-skeleton-loader
            type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
            class="mb-6"
            boilerplate
            v-if="loading"
        ></v-skeleton-loader>
        <div v-if="!loading">
            <div
                v-if="billing.balance"
                class="absolute"
                style="top: 31px; right: 16px"
            >
                <small>Account Balance</small>
                <div class="soli-grey flex justify-end">
                    ${{ getAccountBalance }}
                </div>
            </div>
        </div>
        <v-expansion-panels
            v-if="!loading"
            flat
            v-model="panel"
            @change="$emit('change')"
        >
            <v-expansion-panel
                v-for="(item, idx) in billing.transactions"
                :key="item.id"
                :class="['soli-round mb-2 mt-0', `panel-${idx}`]"
            >
                <v-expansion-panel-header
                    expand-icon="keyboard_arrow_down"
                    focusable
                    :class="['soli-round mb-2 py-0']"
                    @click="$log(item.id)"
                >
                    <v-row :class="['align-center soli-grey']">
                        <v-col class="pl-0">
                            <v-list class="mx-2">
                                <!--<div class="caption">
                  {{
                    item.paid
                      ? "Paid"
                      : `Due on
                   ${item.due_date}`
                  }}
                </div>-->
                                <div class="caption">
                                    {{
                                        globalMomentFullDateAtTimeShort(
                                            item.date
                                        )
                                    }}
                                </div>
                                <div
                                    :class="[
                                        'flex align-center',
                                        $vuetify.breakpoint.xsOnly
                                            ? 'body-2'
                                            : 'body-1',
                                    ]"
                                >
                                    <v-chip
                                        x-small
                                        :class="[
                                            'mr-3 white--text font-bold first-letter',
                                            item.color,
                                        ]"
                                    >
                                        {{ item.status }}
                                    </v-chip>
                                    {{ formatUnitAmount(item.amount) }}
                                </div>
                                <div class="caption flex align-center gap-1">
                                    <v-icon small>storefront</v-icon>
                                    {{
                                        `${item.location.name} ${
                                            item.payment
                                                ? " | " + item.payment
                                                : ""
                                        }`
                                    }}
                                </div>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="justify-around align-center">
                        <div
                            v-if="item.lastAttempt && item.failed"
                            class="flex-col justify-start flex pt-3 px-3"
                        >
                            <v-list-item-subtitle
                                class="soli-grey font-weight-bold align-center"
                            >
                                Last Attempt:
                                <v-chip
                                    x-small
                                    right
                                    :class="[
                                        'mx-1 white--text font-bold soli-status-red',
                                    ]"
                                    style="transform: translateY(-1px)"
                                >
                                    Failed
                                </v-chip>
                                {{
                                    item.lastAttempt.method
                                }}</v-list-item-subtitle
                            >
                            <small class="soli-grey">{{
                                `${globalMomentDateAtTimeDots(
                                    item.lastAttempt.date
                                )}`
                            }}</small>
                        </div>
                        <div class="w-full body-2 pa-3">
                            <v-list-item-subtitle
                                class="soli-grey font-weight-bold"
                                >Purchases</v-list-item-subtitle
                            >

                            <div
                                class="soli-grey"
                                v-for="(line, i) in item.description"
                                :key="i"
                            >
                                · {{ line }}
                            </div>
                            <v-list-item-subtitle
                                v-if="item.discounts.length"
                                class="soli-grey font-weight-bold mt-1"
                                >Discounts</v-list-item-subtitle
                            >

                            <div
                                class="soli-grey"
                                v-for="(discount, idx) in item.discounts"
                                :key="discount + idx"
                            >
                                · {{ discount }}
                            </div>
                        </div>
                        <div class="w-full flex pa-3 mb-4 justify-space-around">
                            <v-btn
                                icon
                                small
                                color="dark"
                                :disabled="
                                    !item.paid ||
                                    item.refunded ||
                                    item.amount == 0 ||
                                    !item.charge ||
                                    !viewAtTransactionLocation(item)
                                "
                                v-if="can('Issue refunds and credits')"
                                @click="
                                    [
                                        (refundForm.charge = item.charge),
                                        (refundForm.amount =
                                            (item.amount - item.refunds) / 100),
                                        (refundMax =
                                            (item.amount - item.refunds) / 100),
                                    ]
                                "
                            >
                                <v-icon>restore</v-icon>
                                <small class="mt-1 panel-text">Refund</small>
                            </v-btn>
                            <v-btn
                                :href="item.receipt_url"
                                icon
                                small
                                color="dark"
                                :disabled="!item.paid"
                            >
                                <v-icon style="display: block"
                                    >visibility_on</v-icon
                                >
                                <small class="mt-1 panel-text">View</small>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                color="dark"
                                :disabled="
                                    item.paid || item.upcoming || item.void
                                "
                                @click="
                                    $page.props.staffView
                                        ? (showPaymentOptions = item)
                                        : visitInvoiceUrl(item)
                                "
                            >
                                <v-icon>payments</v-icon>
                                <small
                                    class="mt-1 panel-text"
                                    style="white-space: normal"
                                    >Pay Now</small
                                >
                            </v-btn>
                        </div>
                    </v-row>
                </v-expansion-panel-content>
                <!--<v-divider v-if="idx !== user.transactions.length - 1" />-->
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-if="user.billingPortal" no-gutters>
            <a
                class="caption flex justify-end py-3 px-6"
                :href="user.billingPortal"
                >View All</a
            >
        </v-row>
        <SoliForm
            v-if="refundForm.charge"
            title="Refund"
            textSubtitle="Refunds take 5-10 days to appear on a customer's statement."
            @submit="refundTransaction"
            @reset="resetForm(refundForm)"
            ref="refundForm"
            text="Process Refund"
            icon="save"
            ><template #body>
                <v-currency-field
                    label="Amount"
                    v-model="refundForm.amount"
                    :rules="refundRules"
                    outlined
                    dense
                    class="soli-round"
                    error-message="errors.amount"
                ></v-currency-field>
                <v-select
                    :items="refundReasons"
                    v-model="refundForm.reason"
                    item-text="text"
                    item-value="value"
                    label="Reason"
                    class="soli-round"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'You must provide a reason']"
                ></v-select>
                <!--<v-textarea
                    v-if="refundForm.reason.text == 'Other'"
                    outlined
                    dense
                    class="soli-round"
                    label="Add a reason for this refund"
                    v-model="refundForm.reason.value"
                    :rules="[v => !!v || 'You must provide a reason']"
                ></v-textarea>-->
            </template></SoliForm
        >
        <SoliForm
            v-if="showCreditForm"
            title="Adjust Account Balance"
            @submit="addCredit"
            @reset="showCreditForm = false"
            @hook:mounted="
                [(creditForm.customer = user.id), (creditForm.adjust = true)]
            "
            ref="creditForm"
            :text="chargeCustomer ? 'Charge' : 'Save'"
            :icon="chargeCustomer ? 'credit_card' : 'save'"
            ><template #body>
                <div class="px-2 mb-6 soli-round">
                    <small>Current Balance</small>
                    <div class="soli-grey soli-subtitle">
                        ${{ getAccountBalance }}
                    </div>
                </div>
                <v-select
                    :items="[
                        { text: 'Credit', value: true },
                        { text: 'Debit', value: false },
                    ]"
                    v-model="creditForm.adjust"
                    label="Credit/Debit"
                    outlined
                    :prepend-inner-icon="
                        creditForm.adjust ? 'savings' : 'credit_card'
                    "
                    dense
                    :rules="[(v) => v !== null || 'This field is required']"
                ></v-select>
                <v-currency-field
                    label="Amount"
                    v-model="creditForm.amount"
                    :rules="creditRules"
                    outlined
                    dense
                    prepend-inner-icon="attach_money"
                    error-message="errors.amount"
                    class="soli-round"
                ></v-currency-field>
                <v-textarea
                    v-if="!chargeCustomer"
                    v-model="creditForm.note"
                    outlined
                    dense
                    label="Add a note for this adjustment"
                    prepend-inner-icon="notes"
                    counter="255"
                    :rules="[
                        (v) => !!v || 'You must provide a reason',
                        (v) =>
                            v?.length < 255 ||
                            'Note must be less than 255 characters',
                    ]"
                    class="soli-round"
                ></v-textarea>
                <v-text-field
                    v-if="chargeCustomer"
                    v-model="creditForm.note"
                    outlined
                    dense
                    label="Invoice Item Description"
                    prepend-inner-icon="short_text"
                    counter="50"
                    :rules="[
                        (v) =>
                            !!v ||
                            'You must provide a short description for the charge' ||
                            v.length < 50 ||
                            'Description must be less than 50 characters',
                    ]"
                    class="soli-round"
                ></v-text-field>
                <div v-if="chargeCustomer" class="soli-grey text-subtitle-2">
                    <p class="px-1 mb-4">
                        {{
                            `This amount exceeds the client balance. This will
                        immediately create an invoice for the customer and
                        charge their default payment method ${chargeAmount}`
                        }}.
                    </p>
                    <v-checkbox
                        v-model="creditForm.understand"
                        :rules="[
                            (v) =>
                                !!v ||
                                `Please confirm that you want to charge the customer's default payment method`,
                        ]"
                        label="I Understand"
                        hide-details="auto"
                        class="mt-0"
                    /></div></template
        ></SoliForm>
        <SoliForm
            v-if="showGiftForm"
            title="Redeem Gift or Promotion"
            @submit="redeemGift"
            @reset="showGiftForm = false"
            ref="giftForm"
        >
            <template #body>
                <SoliText
                    label="Redemption Code"
                    v-model="giftForm.redemptionCode"
                    :errors="errors.code || errors.promo"
                    hide-details="auto"
                    :rules="[
                        (val) =>
                            (val || '').length > 0 || 'This field is required',
                    ]"
                    >Redemption Code</SoliText
                >
            </template>
        </SoliForm>
        <SoliChoice
            v-if="showPaymentOptions"
            title="Payment Options"
            button1="Pay Online"
            button2="Pay By Check"
            :link1="showPaymentOptions.invoice_url"
            @option2="getCheckNumber = showPaymentOptions"
            @reset="closeModal((e) => (showPaymentOptions = null))"
        />
        <SoliForm
            v-if="getCheckNumber"
            title="Record check number"
            @reset="closeModal((e) => (getCheckNumber = null))"
            @submit="payByCheck(getCheckNumber)"
            ><template #body
                ><SoliText
                    label="Check #"
                    number
                    v-model="getCheckNumber.checkNumber"
                    :rules="[
                        (val) =>
                            (val || '').length > 0 || 'This field is required',
                    ]"
                ></SoliText>
            </template>
        </SoliForm>
    </div>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: ["user", "errors"],
    data: function () {
        return {
            panel: false,
            refundReasons: [
                {
                    value: "requested_by_customer",
                    text: "Requested by customer",
                },
                {
                    value: "duplicate",
                    text: "Duplicate",
                },
                {
                    value: "fraudulent",
                    text: "Fraudulent",
                },
                /*{
                    value: "",
                    text: "Other"
                }*/
            ],
            refundForm: {
                charge: null,
                amount: null,
                reason: null,
            },
            giftForm: {
                redemptionCode: null,
            },
            showGiftForm: false,
            refundMax: null,
            refundRules: [
                (v) => !!v || "This field is required",
                (v) => (v && v > 0) || "Refund must be greater than $0",
                (v) =>
                    (v && v <= this.refundMax) ||
                    "Refund cannot be more than " + this.refundMax,
            ],
            creditRules: [
                (v) => !!v || "This field is required",
                (v) => v > 0 || v < 0 || "Enter an amount to credit or debit",
                (v) =>
                    this.creditForm.adjust ||
                    this.chargeCustomer ||
                    v <= Number(this.getAccountBalance) ||
                    "Cannot debit more than current account balance",
            ],
            showRefundAmount: false,
            showCreditForm: false,
            creditForm: {
                customer: null,
                adjust: null,
                amount: null,
                note: null,
                understand: false,
            },
            billing: null,
            loading: false,
            showPaymentOptions: null,
            canPayByCheck: null,
            getCheckNumber: null,
        };
    },
    computed: {
        getAccountBalance() {
            if (!this.billing.balance) return 0;
            return ((this.billing.balance * -1) / 100).toFixed(2);
        },
        chargeCustomer() {
            return (
                this.can("Create client charges") &&
                !this.creditForm.adjust &&
                this.creditForm.amount > this.getAccountBalance
            );
        },
        chargeAmount() {
            return (
                "$" +
                (this.creditForm.amount - this.getAccountBalance).toFixed(2)
            );
        },
    },
    methods: {
        visitInvoiceUrl(invoice) {
            window.location.href = invoice.invoice_url;
        },
        payByCheck(item) {
            console.log(item);
            if (!item.checkNumber) return (this.getCheckNumber = item);

            let invoice = {
                invoice: item.id,
                checkNumber: item.checkNumber,
            };

            this.$inertia.post(route("invoice.payByCheck"), invoice, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                    this.getApiBilling();
                    this.getCheckNumber = null;
                },
                onError: (errors) => {
                    console.log(errors);
                    this.getCheckNumber = null;
                },
            });
        },
        viewAtTransactionLocation(transaction) {
            return (
                transaction.location.id ==
                this.$page.props.auth.user.selected_location.id
            );
        },
        redeemGift() {
            this.$inertia.post(
                route("gift.redeem"),
                {
                    billingUser: this.user.id,
                    redemptionCode: this.giftForm.redemptionCode,
                },
                {
                    onSuccess: (result) => {
                        setTimeout(() => {
                            this.$refs.giftForm.dialog = false;
                            this.$emit("success");
                            this.showGiftForm = false;
                            this.resetForm(this.giftForm);
                        }, 300);
                        this.getApiBilling();
                    },
                    onError: (errors) => {
                        console.log(errors);
                        this.$refs.giftForm.loading = false;
                    },
                }
            );
        },
        refundTransaction() {
            if (!this.$refs.refundForm.$refs.form.validate()) return;
            this.$inertia.post("/transactions/refund", this.refundForm, {
                onSuccess: () => {
                    this.$refs.refundForm.dialog = false;
                    setTimeout(() => {
                        this.$emit("success");
                        this.resetForm(this.refundForm);
                    }, 300);
                    this.getApiBilling();
                },
                onError: () => {
                    console.log(this.$page.errors);
                },
            });
        },
        resetForm(form) {
            Object.keys(form).forEach((e) => (form[e] = null));
        },
        addCredit() {
            if (!this.$refs.creditForm.$refs.form.validate()) return;
            this.$inertia.post("/transactions/credit", this.creditForm, {
                onSuccess: () => {
                    this.$refs.creditForm.dialog = false;
                    setTimeout(() => {
                        this.$emit("success");
                        this.showCreditForm = false;
                        this.resetForm(this.creditForm);
                    }, 300);
                    this.getApiBilling();
                },
                onError: () => {
                    console.log(this.$page.errors);
                    this.$refs.creditForm.loading = false;
                },
            });
        },
        getApiBilling() {
            this.loading = true;
            this.$emit("billingLoaded", false);
            axios.get(`/api/stripe/billing/${this.user.id}`).then((resp) => {
                this.billing = resp.data.billing;
                this.loading = false;
                this.$emit("billingLoaded", true);
            });
        },
    },
    created() {
        this.getApiBilling();
    },
    mounted() {
        this.loading = true;
    },
};
</script>

<style scoped>
.date-indicator {
    width: 12px;
    height: 85px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.v-list {
    color: unset !important;
}

.text-align-center {
    text-align: center;
}

.first-letter {
    text-transform: capitalize;
}
</style>
