<template>
    <SoliForm
        ref="form"
        :title="contracts ? contracts.contract.data.name + ' Agreement' : null"
        :mobileScroll="$vuetify.breakpoint.smAndDown ? true : false"
        isSignature
        :subtitle="[]"
        :permanent="permanent"
        :saveChanges="signed"
        :screenCapture="screenCapture"
        :textSubtitle="
            contracts.total > 1
                ? contracts.index + ' of ' + contracts.total
                : null
        "
        :loading="loading"
        eager
        large
        persistent
        text="I Agree"
        icon="thumb_up"
        class="safe-bottom"
        @reset="$emit('reset')"
        @submit="[saveSignature()]"
        ><template #body>
            <!--<v-card-title>{{ title }}</v-card-title>-->
            <v-row
                ><v-col class="pt-0">
                    <v-list v-if="contracts">
                        <v-list-item
                            v-for="term in contracts.contract.contract_items"
                            :key="term.id"
                            ><v-list-item-content>
                                <v-list-item-title
                                    style="white-space: normal"
                                    >{{
                                        `${term.item}. ${getTextVariables(
                                            term.title
                                        )}`
                                    }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                    style="
                                        white-space: normal;
                                        min-height: fit-content;
                                    "
                                    class="pa-1"
                                    >{{
                                        getTextVariables(term.body)
                                    }}</v-list-item-subtitle
                                >
                            </v-list-item-content></v-list-item
                        >
                    </v-list></v-col
                ></v-row
            ><v-container>
                <v-row v-if="loadSignature">
                    <v-col>
                        <VueSignaturePad
                            class="signature"
                            width="100%"
                            height="100px"
                            ref="signaturePad"
                            :options="{ onEnd }"
                        />
                        <v-row class="flex justify-center soli-blue-text mb-3">
                            <div class="white" style="white-space: nowrap">
                                Sign here
                            </div>
                        </v-row>
                        <v-chip
                            v-if="!screenCapture"
                            @click="undoSignature"
                            small
                            color="white"
                            style="
                                position: absolute;
                                right: 16px;
                                transform: translateY(-15px);
                            "
                            class="soli-blue-text"
                            ><v-icon>undo</v-icon>Undo</v-chip
                        >
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </SoliForm>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";

import VueSignaturePad from "vue-signature-pad";
import Vue from "vue";
Vue.use(VueSignaturePad);

export default {
    props: {
        contracts: Object,
        purchaseOption: Object,
        client: Object,
        permanent: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [globalMoment],
    data() {
        return {
            loadSignature: false,
            loading: false,
            signed: false,
            screenCapture: false,
            user_id: this.client?.id,
            contractId: this.contracts.contract?.id,
            startDate: this.contracts.contract.data.startDate
                ? this.globalMomentDate(
                      new Date(this.contracts.contract.data.startDate)
                  )
                : this.globalMomentDate(new Date()),
            nextMonth: this.globalMomentDate(
                new Date(this.contracts.contract.data.nextMonth)
            ),
            monthlyRecurring: this.contracts.contract.data.monthlyRecurring,
            name: this.contracts.contract.data.name,
            product: this.contracts.contract.data.name,
            description: this.contracts.contract.data.description,
            studio: this.$page.props.auth.user.selected_location.email,
            futureDateMembership:
                this.contracts.contract.data.futureDateMembership,
            prorationDueDate:
                this.contracts.contract.data.prorationDueDate ?? "immediately",
        };
    },
    methods: {
        getTextVariables(str) {
            let reg = /\{\{(.*?)\}\}/; // this had a 'g' at the end
            let found;
            while ((found = reg.exec(str))) {
                if (this.$data[found[1]]) {
                    str = str.replace(found[0], this.$data[found[1]]);
                } else {
                    str = str.replace(found[0], "--");
                }
            }
            return str;
        },
        onEnd() {
            this.signed = true;
        },

        saveSignature() {
            this.loading = true;

            const { isEmpty, data } =
                this.$refs.signaturePad.saveSignature("image/png");
            setTimeout(() => {
                this.submit(data);
            }, 500);
        },
        undoSignature() {
            this.$refs.signaturePad.undoSignature();
            if (this.$refs.signaturePad.isEmpty()) {
                this.signed = false;
            }
        },
        submit(signature) {
            this.$inertia.post(
                `/contract/${this.purchaseOption?.id ?? ""}`,
                { file: signature, ...this.$data },
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: (page) => {
                        if (!this.purchaseOption) {
                            const contractUrl = page.props.successData;
                            this.contracts.contract.signed = contractUrl;
                        }
                        this.$emit("success");
                        this.$refs.form.reset();
                        this.loading = false;
                    },
                }
            );
        },
    },
    mounted() {
        this.$refs.form.dialog = true;
        setTimeout(() => {
            this.loadSignature = true;
        }, 50);
    },
};
</script>

<style>
.signature {
    border: double 2px transparent;
    border-radius: 20px;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, #2aabe6, #228cbd);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
</style>
