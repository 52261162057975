<template>
    <v-list>
        <v-text-field
            v-if="showSearch"
            v-model="search"
            @input="setSearchTimer()"
            :loading="searchLoading"
            label="Search Messages"
            append-icon="search"
            outlined
            rounded
            dense
            hide-details
            clearable
            class="py-1 chat-search"
        ></v-text-field>

        <v-list v-if="showLoading" class="w-full">
            <v-skeleton-loader
                class="w-full"
                type="list-item-avatar-three-line@6"
        /></v-list>

        <v-list-item-group
            v-if="search.length"
            :class="{ 'conversation-scroll': $vuetify.breakpoint.mdAndUp }"
            class="py-4"
        >
            <v-list-item
                v-for="item in results"
                :key="item.message_id"
                :value="item.id"
                @click="$emit('input', item)"
                class="soli-round chat-items"
            >
                <ConversationPreview :item="item" />
            </v-list-item>
        </v-list-item-group>

        <v-list-item-group
            v-if="!search.length && !showLoading"
            :value="value?.id ?? null"
            :class="{
                'conversation-scroll':
                    $vuetify.breakpoint.mdAndUp && index.length,
            }"
            class="py-4"
        >
            <v-list-item class="px-6" v-if="!index.length">
                <v-list-item-title class="soli-grey"
                    >No Conversations Found</v-list-item-title
                >
            </v-list-item>
            <v-list-item
                v-for="item in index"
                :key="item.id"
                :value="item.id"
                @click="handleSelect(item)"
                class="soli-round chat-items"
            >
                <ConversationPreview :item="item" />
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import axios from "axios";
import ConversationPreview from "./ConversationPreview.vue";

export default {
    props: {
        index: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => {},
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            search: "",
            results: [],
            searchLoading: false,
            searchTimeout: null,
            selecting: false,
        };
    },
    components: { ConversationPreview },
    methods: {
        searchMessages() {
            axios
                .get("/messages/search", {
                    params: {
                        search: this.search,
                    },
                })
                .then((resp) => {
                    this.results = resp.data;
                    this.clearSearchTimer();
                });
        },
        setSearchTimer() {
            if (!this.search) this.search = "";
            if (!this.search.length) return (this.results = []);

            this.searchLoading = true;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.searchMessages();
            }, 750);
        },
        clearSearchTimer() {
            clearTimeout(this.searchTimeout);
            this.searchLoading = false;
        },
        /**
         * Delay selection to offset modal transition on mobile, allows for proper loading indicator
         * @param item
         */
        handleSelect(item) {
            this.selecting = true;
            this.$emit("input", item);
            setTimeout(() => {
                this.selecting = false;
            }, 300);
        },
    },
    computed: {
        showLoading() {
            if (this.searchLoading) return true;
            if (this.selecting) return false;
            return this.$root.loading && this.$vuetify.breakpoint.smAndDown;
        },
    },
    watch: {
        index: {
            handler: function (val) {
                if (!val || !this.value) return;
                const selected = val.find((e) => e.id == this.value.id);

                if (
                    selected.conversation_user.draft !==
                    this.value.conversation_user.draft
                ) {
                    this.$emit("input", selected);
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.chat-search {
    flex: 0;
}

.conversation-scroll {
    flex: 1 1 0;
    flex-direction: column;
    display: flex;
    position: relative;
    overflow-y: auto;
}

.unread-avatar {
    border: 2px solid #228cbd;
}
</style>
