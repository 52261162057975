<template>
    <div class="w-full">
        <v-select
            v-if="!autocomplete && !iterator"
            :multiple="multiple"
            :items="getInstruments"
            :value="value"
            v-on:input="$emit('input', $event)"
            @focus="$emit('focus')"
            item-text="name"
            item-value="id"
            :label="label ?? `Instrument${multiple ? 's' : ''}`"
            :prepend-inner-icon="hideIcon ? null : 'music_note'"
            :rules="rules"
            outlined
            :clearable="clearable"
            dense
            :hide-details="hideDetails"
            @click:clear="$emit('clear')"
            :return-object="!multiple && returnObject"
            :disabled="disabled"
            :menu-props="{
                auto: true,
                contentClass: 'mx-2',
            }"
            :readonly="readonly"
            ref="select"
        >
        </v-select>
        <v-autocomplete
            v-if="autocomplete"
            :value="value"
            :disabled="disabled"
            :items="getInstruments"
            :rules="rules"
            :error-messages="error"
            :hide-details="hideDetails"
            :autofocus="autofocus"
            :label="label ?? `Instrument${multiple ? 's' : ''}`"
            :prepend-inner-icon="hideIcon ? null : 'music_note'"
            item-text="name"
            item-value="id"
            outlined
            dense
            class="soli-blue-text"
            @input="$emit('input', $event)"
            @keydown.enter.prevent
        >
            <template v-slot:item="data" style="max-height: 56px">
                <v-list-item-avatar size="56" class="shadow-blue">
                    <img
                        v-if="data.item.photo_url"
                        :src="`${data.item.photo_url}/m/80x80`"
                    />
                    <v-icon v-if="data.item.icon" color="accent">{{
                        data.item.icon
                    }}</v-icon>
                    <img
                        v-if="!data.item.photo_url && !data.item.icon"
                        src="/musicologie-m-white.png"
                    />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title
                        v-html="data.item.name"
                        class="font-weight-bold body-2"
                    ></v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-skeleton-loader
            v-if="iterator && loading"
            class="mx-auto my-3"
            type="list-item-avatar, list-item-avatar, list-item-avatar"
        ></v-skeleton-loader>
        <v-data-iterator
            v-if="iterator && !loading"
            :items="getInstruments"
            disable-pagination
            :search="search"
            hide-default-footer
        >
            <template v-slot:header
                ><v-sheet light style="position: sticky; top: 0px; z-index: 10"
                    ><SoliText
                        v-model="search"
                        class="pa-3"
                        icon="music_note"
                        :autofocus="autofocus"
                        label="Search Instruments"
                        hideDetails
                        fullRound /></v-sheet
            ></template>
            <template v-slot:item="data">
                <v-list class="py-0 px-3 soli-round">
                    <SoliAvatarBtn
                        @click="
                            $emit(
                                'input',
                                returnObject ? data.item : data.item.id
                            )
                        "
                        class="w-full"
                        blueShadow
                        :avatar="
                            data.item.icon
                                ? null
                                : data.item.photo_url
                                ? `${data.item.photo_url}/m/80x80`
                                : '/musicologie-m-white.png'
                        "
                        :outlined="false"
                        :title="data.item.name"
                        :icon="data.item.icon ?? null" /></v-list></template
        ></v-data-iterator>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        rules: {},
        error: {},
        multiple: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        returnObject: { type: Boolean, default: true },
        clearable: { type: Boolean, default: false },
        hideIcon: { type: Boolean, default: false },
        hideDetails: { type: [Boolean, String], default: false },
        filter: { type: Array, default: () => [] },
        label: { type: String },
        notSure: { type: Boolean, default: false },
        autocomplete: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        iterator: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        parentInstruments: {
            type: Array,
            default: () => [],
        },
    },
    data: function () {
        return {
            instruments: this.parentInstruments,
            search: null,
            selectedInstrument: this.value,
            loading: false,
        };
    },
    mounted() {
        if (this.parentInstruments.length) return;
        this.loading = true;

        axios.get("/api/instruments").then((resp) => {
            this.instruments = resp.data;
            this.loading = false;
        });
    },
    computed: {
        getInstruments() {
            let instruments = this.filter.length
                ? this.instruments.filter((e) => !this.filter.includes(e.id))
                : this.instruments;

            if (this.notSure)
                instruments = [
                    { name: "I'm not sure", id: 0, icon: "question_mark" },
                    ...instruments,
                ];
            return instruments;
        },
    },
};
</script>

<style scoped>
.v-list-item--link:before {
    border-radius: 20px !important;
}
</style>
