<template>
    <div :class="[mobile ? 'w-full justify-center flex' : null]">
        <v-autocomplete
            :items="items"
            :loading="isLoading"
            :item-text="getSearchText"
            item-value="id"
            :hide-no-data="hideNoData"
            :no-data-text="`No ${unit}s found`"
            hide-details
            return-object
            :search-input.sync="search"
            @keydown.down.prevent
            :menu-props="
                mobile
                    ? {
                          left: true,
                      }
                    : {
                          'max-width': width,
                          'min-width': width,
                      }
            "
            @input="visit"
            single-line
            rounded
            :filled="!mobile || !users"
            :solo="mobile || !users"
            :dense="(!mobile || !users) && !tall"
            :label="showMacros ? '+ Enter' : label"
            :class="[
                'soli-blue-text justify-self-center',
                round ? 'round-search' : null,
                limitWidth ? 'limit-width' : null,
                users ? null : 'white',
                elevated ? 'elevation-1' : null,
            ]"
            ref="globalSearch"
            append-icon="search"
            @hook:mounted="getWidth()"
        >
            <template v-slot:item="data">
                <v-list-item-avatar v-if="users">
                    <img
                        :src="
                            data.item.profile_photo_path
                                ? `/api/image-service/40x40/${data.item.profile_photo_path}`
                                : data.item.profile_photo_url
                        "
                    />
                </v-list-item-avatar>
                <v-list-item-content class="flex flex-fill max-h-16">
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="users">{{
                        data.item.email
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                        v-if="unit == 'article'"
                        class="font-weight-bold"
                    >
                        {{
                            `${data.item.category.title}${
                                data.item.program
                                    ? " > " + data.item.program.title
                                    : ""
                            }`
                        }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                        v-if="unit == 'article'"
                        class="font-weight-black"
                        >{{ data.item.title }}</v-list-item-subtitle
                    >

                    <!--<v-list-item-subtitle
                        v-if="unit == 'article'"
                        v-html="data.item.content"
                    ></v-list-item-subtitle>-->
                </v-list-item-content>
                <div
                    v-if="
                        users &&
                        !data.item.locations
                            .map((e) => e.id)
                            .includes(
                                $page.props.auth.user.selected_location.id
                            )
                    "
                    class="flex flex-col align-end my-auto"
                >
                    <v-icon color="dark">wrong_location</v-icon>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
        round: {
            type: Boolean,
            default: false,
        },
        tall: {
            type: Boolean,
            default: false,
        },
        limitWidth: { type: Boolean, default: true },
        label: {
            type: String,
            default: "Search Clients",
        },
        users: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
            default: "/api/users/global-search",
        },
        unit: {
            type: String,
            default: "user",
        },
        elevated: {
            type: Boolean,
            default: false,
        },
        showMacros: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: null,
            searchValue: null,
            items: [],
            isLoading: false,
            timeout: [],
            hideNoData: true,
            width: null,
            visiting: false,
        };
    },
    methods: {
        getSearchText(item) {
            if (!this.search) return;
            if (!this.users)
                return (
                    item.title +
                    item.category.title +
                    item.program?.title +
                    item.content
                );
            if (!item.email) return item.name;
            return item.name + ", " + item.email;
        },
        visit(item) {
            this.visiting = true;
            if (this.users) this.$inertia.visit(`/profile/${item.id}/account`);
            if (this.unit == "article")
                this.$inertia.visit(`/help-articles/${item.id}`);
        },
        getWidth() {
            if (!this.$refs.globalSearch) return null;
            this.width = this.$refs.globalSearch.$el.offsetWidth + "px";
        },
        getApiSearch() {
            if (this.visiting) return;
            axios
                .get(this.url, {
                    params: {
                        search: this.searchValue,
                    },
                })
                .then((resp) => {
                    this.items = resp.data;
                    this.clearApiTimer();
                    if (!this.items.length) this.hideNoData = false;
                })
                .catch((error) => {
                    this.clearApiTimer();
                    this.hideNoData = true;
                    if (error.message) {
                        alert(error.message);
                    }
                });
        },
        setApiTimer() {
            this.isLoading = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getApiSearch();
            }, 750);
        },
        clearApiTimer() {
            clearTimeout(this.timeout);
            this.isLoading = false;
        },
    },
    mounted() {
        if (this.mobile && this.users) {
            this.$nextTick(() => {
                this.$refs.globalSearch.focus();
                this.$refs.globalSearch.activateMenu();
            });
        }
    },
    watch: {
        search(val) {
            this.hideNoData = true;

            // Items have already been loaded
            if (!val || !val.length || val.trim().length === 0) return;

            //if (val.length > 20) val = val.substring(0, 1);
            this.searchValue = val;
            // Items have already been requested
            if (this.isLoading) {
                this.setApiTimer();
                return;
            }

            this.setApiTimer();
        },
    },
};
</script>

<style>
.notranslate {
    transform: none !important;
}

.limit-width {
    overflow: hidden;
    max-width: 375px !important;
}

.round-search {
    border-radius: 36px !important;
}
</style>
