<template>
    <v-col
        cols="12"
        class="flex justify-center px-0 h-min-content"
        style="max-width: 420px"
    >
        <v-card
            :max-width="width"
            light
            :elevation="$vuetify.breakpoint.smAndUp && recommended ? 6 : 0"
            class="w-full soli-round"
            :class="{
                'mx-3': $vuetify.breakpoint.smAndUp,
                'mx-auto': $vuetify.breakpoint.xs,
                //'outline-selection': recommended,
                //'soli-round': $vuetify.breakpoint.smAndUp,
            }"
        >
            <!--<div class="text-body-2" v-if="recommended">
                <v-icon left color="accent">verified</v-icon>Recommend for you
            </div>-->
            <v-container
                class="flex flex-col founders-card-min-height"
                :class="{ 'founders-card-selected': recommended }"
            >
                <div
                    class="flex justify-center text-title font-weight-bold soli-grey align-center pt-2"
                    v-if="recommended"
                >
                    <v-icon large left color="accent">verified</v-icon
                    >Recommended for you
                </div>
                <div class="flex justify-center pa-2 pb-0 align-center gap-4">
                    <v-avatar size="96">
                        <v-img :src="image" />
                    </v-avatar>
                    <div class="flex px-2">
                        <div class="flex flex-col justify-center">
                            <div
                                class="accent--text uppercase flex flex-col"
                                style="line-height: 22px"
                            >
                                <div class="text-sm">{{ heading }}</div>
                                <div
                                    class="font-weight-bold contents"
                                    :class="{ 'text-2xl': title.length < 10 }"
                                >
                                    {{ title }}
                                </div>
                            </div>
                            <div
                                class="soli-grey caption"
                                style="line-height: 1rem"
                            >
                                {{ subtitle }}
                            </div>
                            <v-btn
                                text
                                x-small
                                rounded
                                outlined
                                color="primary"
                                class="mt-1"
                                @click="$emit('learnMore', membership)"
                                >Learn More</v-btn
                            >
                        </div>
                    </div>
                </div>

                <v-card-text class="px-2 flex flex-col flex-grow-1">
                    <!--<div class="px-5 mb-3">
                        <div class="flex gap-2">
                            <div class="accent--text font-semibold">
                                SAVE {{ membership.discount }}
                            </div>
                            <div
                                class="soli-grey"
                                style="text-decoration: line-through"
                            >
                                {{ membership.original_amount }}
                            </div>
                        </div>

                        <div class="flex gap-1 align-end soli-grey">
                            <div class="text-2xl font-semibold">
                                {{ membership.amount }}
                            </div>
                            <div class="text-xs">per month</div>
                        </div>
                        <div class="soli-grey text-sm whitespace-no-wrap">
                            {{ membership.package }}
                        </div>
                    </div>-->
                    <FoundersClubCardPrices
                        :membership="membership"
                        :button2="button2"
                        :selected="selected"
                        @input="selected = $event"
                    />
                    <div
                        class="description-content soli-grey py-2"
                        v-html="description"
                    ></div>
                </v-card-text>
                <v-card-actions class="flex flex-col gap-2 pb-0 flex-grow-0">
                    <SoliBtn
                        :class="{ 'mb-2': !button2 }"
                        block
                        gradient="gradient-orange"
                        large
                        @click="$emit('input', selected)"
                    >
                        {{ button1 }}</SoliBtn
                    >
                    <v-btn
                        v-if="button2"
                        block
                        text
                        rounded
                        color="dark"
                        @click="$emit('input', false)"
                    >
                        {{ button2 }}</v-btn
                    >
                </v-card-actions></v-container
            >
        </v-card>
    </v-col>
</template>

<script>
import FoundersClubCardPrices from "./FoundersClubCardPrices.vue";

export default {
    components: {
        FoundersClubCardPrices,
    },
    props: {
        membership: {
            type: Object,
            default: () => {},
        },
        button1: {
            type: String,
            default: null,
        },
        button2: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        heading: {
            type: String,
            default: "Founders",
        },
        description: {
            type: String,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        pricingDetails: {
            type: Boolean,
            default: true,
        },
        recommended: {
            type: Boolean,
            default: false,
        },
        selection: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            hover: false,
            selected: {
                id: this.membership.id,
                price_id: this.membership.price_id,
            },
        };
    },
};
</script>

<style>
.custom-card-round.v-card--link:focus:before {
    border-radius: 20px;
}

.custom-heading-lg-line-height {
    line-height: 28px !important;
}

.custom-heading-sm-line-height {
    line-height: 26px !important;
}

.h-min-content {
    height: min-content;
}

.outline-selection {
    outline: 2px solid #f38c68;
    outline-offset: 4px;
}

.founders-card-min-height {
    min-height: 472px;
}

.founders-card-selected {
    min-height: 500px;
}
</style>
