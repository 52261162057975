<template>
    <v-expansion-panels
        accordian
        flat
        v-model="panel"
        class="mt-1 animate__animated animate__fadeIn"
    >
        <v-expansion-panel
            v-for="(item, index) in lessons"
            :key="item.id + item.student.id"
            :class="['mb-2 mt-0 px-1', `panel-${index}`]"
        >
            <v-expansion-panel-header
                focusable
                expand-icon="keyboard_arrow_down"
                :class="[
                    'soli-round schedule-list-items elevation-4 mb-1',
                    item.attendance.status == 'Early-cancel'
                        ? 'click-events'
                        : null,
                ]"
                @click="[(selectedLesson = item), $log(item.id)]"
            >
                <v-row
                    :class="[
                        'align-center',
                        item.cancelled ? 'grey-text' : 'soli-grey',
                    ]"
                >
                    <div
                        class="date-indicator"
                        :class="[
                            'mr-5',
                            item.cancelled
                                ? 'soli-attendance-grey shadow-l'
                                : item.attendance.status == 'Attended'
                                ? 'soli-status-green'
                                : 'soli-blue',
                        ]"
                    ></div>
                    <v-col class="col-auto align-self-center text-align-center">
                        <div class="caption">
                            {{ globalMomentMonth(item.starts_at) }}
                        </div>
                        <div class="title">
                            {{ globalMomentDay(item.starts_at) }}
                        </div>
                    </v-col>
                    <v-col>
                        <v-list class="mx-2">
                            <div class="caption">
                                {{
                                    `${globalMomentDateAtTime(
                                        item.starts_at
                                    )} · ${item.duration} min
                                                    `
                                }}
                            </div>
                            <div
                                :class="
                                    $vuetify.breakpoint.xsOnly
                                        ? 'body-2'
                                        : 'body-1'
                                "
                                v-if="!item.group_event"
                            >
                                {{
                                    item.type == "Weekly Lesson"
                                        ? `${
                                              hasFamily
                                                  ? `${item.student.first_name}'s `
                                                  : ""
                                          }${item.instrument.name} Lesson`
                                        : `${
                                              hasFamily
                                                  ? `${item.student.first_name}'s `
                                                  : ""
                                          }${item.instrument.name} ${item.type}`
                                }}
                            </div>
                            <div
                                v-else
                                :class="
                                    $vuetify.breakpoint.xsOnly
                                        ? 'body-2'
                                        : 'body-1'
                                "
                            >
                                {{
                                    `${item.student.first_name}'s ${item.group_event.title}`
                                }}
                            </div>
                            <div class="caption">
                                with
                                {{ item.teacher.first_name }}
                                ·
                                {{
                                    item.online ? "Online" : item.location.name
                                }}
                            </div>
                        </v-list>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-list
                    v-if="
                        item.attendance.status ||
                        getStatusWarning ||
                        item.attendance.purchase_option?.ends_at
                    "
                    class="pt-5"
                    ><v-list-item
                        class="align-start pb-3"
                        v-if="item.attendance.purchase_option?.ends_at"
                    >
                        <v-icon color="error" class="mr-3">block</v-icon>
                        <div>
                            <span class="font-bold">Membership ends on:</span
                            ><br />
                            {{
                                globalMomentDate(
                                    item.attendance.purchase_option.ends_at
                                )
                            }}
                        </div>
                    </v-list-item>
                    <v-list-item
                        v-if="item.attendance.status || getStatusWarning"
                    >
                        <v-icon
                            class="mr-3"
                            :color="
                                getStatusWarning
                                    ? 'soli-status-red'
                                    : 'soli-status-green'
                            "
                            >{{
                                getStatusWarning ? "error" : "check_circle"
                            }}</v-icon
                        >
                        <div>
                            {{ getStatusWarning ?? item.attendance.status }}
                        </div>
                    </v-list-item>
                    <v-list-item
                        v-if="item.attendance.lesson_note"
                        class="align-start pb-3"
                    >
                        <v-icon class="soli-blue-text mr-3">notes</v-icon>
                        <div>
                            <span class="font-bold"
                                >What we worked on in class:</span
                            ><br />
                            {{ item.attendance.lesson_note }}
                        </div>
                    </v-list-item>
                    <v-list-item
                        v-if="item.attendance.practice_note"
                        class="align-start pb-3"
                    >
                        <v-icon class="soli-blue-text mr-3">task_alt</v-icon>
                        <div>
                            <span class="font-bold"
                                >What you should practice:</span
                            ><br />
                            {{ item.attendance.practice_note }}
                        </div>
                    </v-list-item>
                </v-list>
                <v-row
                    v-if="
                        canChange(item) || !pastLesson(item) || inProgress(item)
                    "
                    class="justify-around align-center my-5"
                >
                    <v-btn
                        v-if="!pastLesson(item, true)"
                        icon
                        small
                        class="soli-red-text"
                        @click="cancelBtn(item)"
                        :disabled="item.cancelled ? true : false"
                    >
                        <v-icon>cancel</v-icon>
                        <small class="mt-1 panel-text">Cancel</small>
                    </v-btn>
                    <v-btn
                        icon
                        small
                        class="m-orange-text"
                        @click="rescheduleBtn(item)"
                        v-if="
                            canChange(item) ||
                            !pastLesson(item) ||
                            inProgress(item)
                        "
                        :disabled="!canChange(item)"
                    >
                        <v-icon>change_circle</v-icon>
                        <small class="mt-1 panel-text">Reschedule</small>
                    </v-btn>
                    <v-btn
                        icon
                        small
                        class="soli-green-text"
                        :href="route('prepay')"
                        v-if="
                            item.type == 'Assessment' &&
                            !item.attendance?.purchase_option?.paid
                        "
                    >
                        <v-icon>attach_money</v-icon>
                        <small class="mt-1 panel-text">Pay Now</small>
                    </v-btn>
                    <v-btn
                        v-if="!pastLesson(item) || inProgress(item)"
                        icon
                        small
                        class="soli-blue-text"
                        @click="$emit('move', item)"
                        :disabled="
                            item.cancelled ||
                            !!item.group_event ||
                            (item.online &&
                                /*!!item.availability?.online_only*/ !!item.room
                                    .user_id) ||
                            (item.online && !canChange(item))
                        "
                    >
                        <v-icon>{{
                            item.online ? "storefront" : "videocam"
                        }}</v-icon>
                        <small v-if="!item.online" class="mt-1 panel-text"
                            >Move Online</small
                        >
                        <small v-if="item.online" class="mt-1 panel-text"
                            >Move <br />
                            In-person</small
                        >
                    </v-btn>
                    <v-btn
                        icon
                        small
                        v-if="
                            item.online &&
                            item.teacher.zoom_url &&
                            showNow(item)
                        "
                        class="soli-blue-text"
                        :href="item.teacher.zoom_url"
                        target="_blank"
                    >
                        <v-icon>logout</v-icon>
                        <small
                            class="mt-1 panel-text flex"
                            style="white-space: normal"
                            >Join Zoom</small
                        >
                    </v-btn>
                    <v-btn
                        v-if="false /*item.attendance.lesson_note*/"
                        icon
                        small
                        class="soli-blue-text"
                        @click="$emit('notes', item)"
                    >
                        <v-icon>notes</v-icon>
                        <small
                            class="mt-1 panel-text"
                            style="white-space: normal"
                            >View Notes</small
                        >
                    </v-btn>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-btn
            text
            color="primary"
            class="soli-round"
            v-if="lessons.length > 10 && !loadMore"
            :loading="$root.loading"
        >
            <Link
                :preserveState="true"
                :preserveScroll="true"
                :data="{ loadMore: 1 }"
                :only="[isPast ? 'pastLessons' : 'lessons', 'loadMore']"
                >Load More</Link
            ></v-btn
        >
    </v-expansion-panels>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        lessons: {
            type: Array,
            default: null,
        },
        activeMemberships: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        relationships: {
            type: Array,
            default: () => [],
        },
        loadMore: {
            type: Boolean,
            default: false,
        },
        isPast: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Link,
    },
    data() {
        return {
            panel: [],
            selectedLesson: null,
        };
    },
    computed: {
        getStatusWarning() {
            if (!this.selectedLesson) return null;

            let status = this.selectedLesson.attendance?.status;
            if (status == "Late-cancel") {
                return "This lesson was cancelled with less than 24 hours notice and cannot be rescheduled";
            }
            if (status == "Cancel-credit") {
                return "This lesson was credited to your billing account balance and cannot be rescheduled";
            }
            /*if (status == "Withdraw-cancel") {
                return "This lesson is after the end of your membership and cannot be rescheduled";
            }*/
            if (this.inProgress(this.selectedLesson)) {
                return "This lesson is in progress and cannot be rescheduled";
            }
            if (!status && this.pastLesson(this.selectedLesson, true)) {
                return "Your teacher has not taken attendance for this lesson.";
            }
            if (this.rescheduleHorizon(this.selectedLesson)) {
                return "This lesson is more than 6 weeks away cannot yet be rescheduled";
            }
            if (this.selectedLesson.group_event?.can_reschedule === false) {
                return "This event type is not eligible for rescheduling.";
            }

            if (
                !this.selectedLesson.attendance?.purchase_option?.paid &&
                this.selectedLesson.attendance?.status !== "Attended" &&
                !this.activeMemberships.filter(
                    (e) =>
                        e.recipient.id == this.selectedLesson.student?.id &&
                        e.paid
                ).length
            ) {
                return "This lesson is unpaid and cannot be rescheduled";
            }
        },

        hasFamily() {
            return !!this.relationships;
        },
    },
    methods: {
        showNow(event) {
            if (!event) return;
            return (
                moment(new Date()) >=
                moment(new Date(event.starts_at)).subtract(15, "minutes")
            );
        },
        pastLesson(event, useEnd = false) {
            return moment(useEnd ? event.ends_at : event.starts_at) < moment();
        },
        rescheduleHorizon(event) {
            return moment(event.starts_at).diff(moment(), "weeks") > 6;
        },
        canChange(event) {
            if (
                !event.attendance?.purchase_option?.paid &&
                !this.activeMemberships.filter(
                    (e) =>
                        e.recipient.id == this.selectedLesson?.student?.id &&
                        e.paid
                ).length
            )
                return false;
            if (this.rescheduleHorizon(event)) return false;

            if (
                (event.recurrence_id && !this.activeMemberships?.length) ||
                this.disabled
            ) {
                return false;
            }

            if (event.group_event) {
                if (!event.group_event.can_reschedule) return false;
            }

            if (!event.cancelled) {
                return (
                    event.canReschedule &&
                    moment(event.starts_at).diff(moment(), "minutes") >=
                        event.rescheduleWindow?.value
                );
            }

            if (this.inProgress(event)) return false;

            return event.canReschedule;
        },
        inProgress(event) {
            if (event.cancelled) return false;
            return (
                moment(event.starts_at).diff(moment(), "seconds") <= 0 &&
                !this.pastLesson(event, true)
            );
        },
        cancelBtn(event) {
            let getStatus =
                moment(event.starts_at).diff(moment(), "minutes") <
                event.rescheduleWindow.value
                    ? "Late-cancel"
                    : "Early-cancel";

            let confirmCancel = {};
            let timeWindow = event.rescheduleWindow.display;

            if (event.group_event?.can_reschedule === false) {
                confirmCancel.warning =
                    "This lesson is not eligible for rescheduling. Are you sure you want to cancel?";
            } else {
                getStatus == "Late-cancel"
                    ? (confirmCancel.warning = `This lesson is less than ${timeWindow} from now. If you cancel, you'll lose this lesson and won't be able to reschedule.`)
                    : (confirmCancel.warning = `This lesson is more than ${timeWindow} from now so you'll be able to reschedule it at a later date. Remember: Rescheduling is up to you. There's no guarantee there will be an opening that works for you.`);
            }

            confirmCancel.event = event;

            confirmCancel.status = {
                student_id: event.student?.id,
                attendance: getStatus,
                cancel: event.group_event ? false : true,
            };

            this.$emit("cancel", confirmCancel);
        },
        rescheduleBtn(item) {
            let clickData = Object.fromEntries(Object.entries(item));

            clickData.date = new Date(item.starts_at);
            clickData.resource = item.room;
            clickData.type = {
                name: item.type == "Weekly Lesson" ? "Reschedule" : item.type,
                originalType:
                    item.type == "Reschedule" ? "Weekly Lesson" : item.type,
                reschedule: true,
                disabled: false,
                recurring: false,
            };
            this.$emit("reschedule", clickData);
        },
    },
};
</script>

<style scoped>
.green-outline {
    border: solid 1px #48ad24;
}

.orange-outline {
    border: solid 1px #f38c68;
}

.red-outline {
    border: solid 1px #e07b7b;
}

.date-indicator {
    width: 12px;
    height: 85px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    align-self: center;
}

.v-list {
    color: unset !important;
}

.text-align-center {
    text-align: center;
}

.body-1 {
    overflow: hidden;
    max-height: 1.5rem;
}

.body-2 {
    overflow: hidden;
    max-height: 1.25rem;
}
</style>
