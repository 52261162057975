<template>
    <component
        :is="componentStyle.comp"
        v-bind="{ ...componentStyle.props }"
        v-model="show"
        @click:outside="close()"
    >
        <v-form
            ref="form"
            @submit.prevent="validate()"
            class="contents"
            v-model="valid"
        >
            <ConversationToolbar
                :conversation="conversation"
                v-model="form.recipients"
                :rules="rules.recipients"
                :disabled="form.processing || messageSending"
                :error="errors.recipients"
                @close="close()"
            />

            <v-divider />

            <div
                v-if="loading"
                class="w-full h-full flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <ConversationMessages
                ref="messages"
                :conversation="conversation"
                :messages="messages"
                :messageCount="messageCount"
                :newMessageAvailable="newMessageAvailable"
                :profiles="conversation?.profiles"
                :key="conversation?.id + conversation?.message_id"
                @loading="loading = $event"
            />

            <div
                v-if="newMessageAvailable"
                class="w-full flex justify-center"
                style="position: absolute; bottom: 76px; z-index: 60"
            >
                <v-chip pill color="primary" @click="viewLatest()"
                    ><v-icon small class="mr-1">arrow_downward</v-icon
                    ><b>New Message</b></v-chip
                >
            </div>

            <div id="chat-box" class="px-4 mt-4 safe-bottom">
                <!--<TinyEditor v-if="false" v-model="form.content" />-->
                <TipTap
                    ref="content"
                    v-model="form.content"
                    :processing="form.processing || messageSending"
                    :valid="valid"
                    :characterLimit="2000"
                    formatToggle
                    @input="saveDraft($event)"
                    @submit="validate()"
                />
                <v-input
                    :rules="rules.content"
                    :value="form.content"
                    :error-messages="draftErrors"
                    hide-details="auto"
                    class="px-6"
                />

                <!--<v-textarea
                    v-model="form.content"
                    ref="content"
                    class="soli-grey align-self-end w-full"
                    outlined
                    dense
                    rounded
                    hide-details="auto"
                    auto-grow
                    rows="1"
                    label="Write your message here"
                    single-line
                    @input="saveDraft($event, 1000)"
                    :rules="rules.content"
                    :error="errors.content"
                    :disabled="form.processing || messageSending"
                    @keypress.enter="enterKey($event)"
                    @blur="saveDraft($event.target.value, 0)"
                >
                    <template #append>
                        <v-icon
                            v-if="!form.processing && !messageSending"
                            @click="validate()"
                            style="color: inherit"
                            >send</v-icon
                        ><v-progress-circular
                            v-else
                            indeterminate
                            color="primary"
                            :size="24"
                        ></v-progress-circular>
                    </template>
                </v-textarea>-->
            </div>
        </v-form>
    </component>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import { VBottomSheet } from "vuetify/lib";
import ConversationToolbar from "./ConversationToolbar.vue";
import ConversationMessages from "./ConversationMessages.vue";
import axios from "axios";
//import TinyEditor from "./../TinyEditor.vue";
import TipTap from "../Editor/TipTap.vue";

export default {
    components: {
        Link,
        VBottomSheet,
        ConversationToolbar,
        ConversationMessages,
        //TinyEditor,
        TipTap,
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        conversation: {
            type: Object,
            default: () => {},
        },
        messages: {
            type: Array,
            default: () => [],
        },
        messageCount: {
            type: Number,
            default: 0,
        },
        newMessageAvailable: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            show: true,
            loading: true,
            messageSending: false,
            draftErrors: [],
            rules: {
                content: [
                    (v) => !!this.stripHtmlTags(v) || "Message required",
                    (v) =>
                        this.stripHtmlTags(v).length < 2000 || "Limit reached",
                ],
                recipients: [(v) => !!v.length || "Recipient(s) required"],
            },
            form: this.$inertia.form({
                content: this.conversation.conversation_user?.draft ?? null,
                conversation_id: this.conversation?.id ?? null,
                recipients: [],
            }),
            valid: false,
        };
    },
    methods: {
        stripHtmlTags(html) {
            const doc = new DOMParser().parseFromString(html, "text/html");
            return doc.body.textContent || "";
        },
        close() {
            document.body.classList.remove("dialog-open-fix");
            this.show = false;
            this.$nextTick(() => {
                this.$emit("reset");
            });
        },
        scrollToBottom() {
            let el = document.getElementById("scroller");
            if (!el) return;
            el.scroll({ behavior: "instant", top: el.scrollHeight });
        },
        validate() {
            if (!this.$refs.form.validate()) return;
            this.saveDraft(null, true);
            this.scrollToBottom();
            this.sendMessage();
        },
        messageReceived(message) {
            this.$refs.messages.messageReceived(message);
        },
        viewLatest() {
            this.$inertia.visit(
                route("conversation.show", this.conversation.id),
                {
                    preserveState: false,
                    only: [
                        "newMessageAvailable",
                        "conversation",
                        "messages",
                        "unreadMessageCount",
                        "index",
                        "messageCount",
                    ],
                }
            );
        },
        sendMessage() {
            this.form
                .transform((data) => ({
                    ...data,
                    recipients: data.recipients.map((r) => r.id),
                    //content: data.content.trim(),
                }))
                .post(route("message.store"), {
                    preserveState:
                        !this.conversation.create &&
                        !this.conversation.message_id,
                    preserveScroll: true,
                    only:
                        this.conversation.create || this.conversation.message_id
                            ? [
                                  "conversation",
                                  "index",
                                  "messages",
                                  "messageCount",
                              ]
                            : ["messages"],
                    onSuccess: () => {
                        this.$refs.form?.reset();
                        this.form.reset();
                        this.rules = {};

                        if (this.$refs.content) {
                            this.$refs.content.editor.commands.clearContent();
                            this.$nextTick(() => {
                                this.$refs.content.editor.commands.focus();
                            });
                        }
                    },
                    onError: (errors) => {
                        console.log(errors);
                    },
                    onFinish: () => {
                        if (
                            this.conversation.create ||
                            this.conversation.message_id
                        ) {
                            return;
                        }

                        this.$inertia.reload({
                            only: ["conversation", "index", "messageCount"],
                        });

                        this.$refs.messages.previousMessages = [];
                        this.$refs.messages.nextMessages = [];
                    },
                });
        },
    },
    computed: {
        componentStyle() {
            let mobileDevice = this.$vuetify.breakpoint.smAndDown;
            return {
                comp: mobileDevice ? "v-bottom-sheet" : "div",
                props: mobileDevice
                    ? {
                          fullscreen: true,
                          persistent: true,
                          "content-class":
                              "overflow-hidden safe-bottom white flex flex-column pb-4",
                      }
                    : {
                          style: "display: contents",
                      },
            };
        },
    },
    created() {
        this.saveDraft = this.debounce((value, cancel) => {
            if (!this.$refs.form.validate()) return;
            if (this.conversation.create) return;
            if (this.messageSending) return;
            if (this.form.processing) return;
            if (!value?.length) value = null;
            if (this.conversation?.conversation_user.draft == value) return;

            axios
                .put(
                    `/conversation-user/${this.conversation.conversation_user.id}`,
                    {
                        draft: value,
                    }
                )
                .then(() => {
                    this.draftErrors = [];
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.draftErrors = error.response.data.errors.draft;
                });
        }, 1000);
    },
    mounted() {
        if (this.$vuetify.breakpoint.smAndDown) {
            document.body.classList.add("dialog-open-fix");
        }
    },
};
</script>

<style scoped>
#chat-box {
    position: relative;
}
</style>
