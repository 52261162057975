<template>
    <AppLayout
        page="/help-categories"
        :pageTitle="article.title"
        :class="$page.props.staffView ? null : 'container'"
        :backLink="route('help-categories.show', article.help_category_id)"
        :tabs="true"
        ref="layout"
    >
        <template #tabs>
            <v-row
                align="center"
                :no-gutters="$vuetify.breakpoint.mdAndUp"
                class="mr-8"
            >
                <v-btn
                    v-if="$vuetify.breakpoint.smAndDown"
                    icon
                    color="dark"
                    class="my-3"
                    @click="drawer = !drawer"
                >
                    <v-icon>menu</v-icon></v-btn
                >
                <v-breadcrumbs
                    :items="breadcrumbs"
                    :class="{ 'custom-breadcrumbs': $vuetify.breakpoint.xs }"
                    class="px-1 py-3 whitespace-nowrap overflow-hidden"
                ></v-breadcrumbs>
                <v-spacer />
                <v-btn
                    v-if="can('Edit help articles')"
                    :href="`/admin/help-article/${article.id}/edit`"
                    x-small
                    color="error"
                    outlined
                    class="soli-round"
                    >Edit <v-icon right x-small>edit</v-icon></v-btn
                ></v-row
            ></template
        >
        <v-navigation-drawer
            v-if="article.toc"
            v-model="drawer"
            :app="$vuetify.breakpoint.smAndDown"
            :absolute="$vuetify.breakpoint.mdAndUp"
            :permanent="$vuetify.breakpoint.mdAndUp"
            :class="{
                'pt-12': $vuetify.breakpoint.smAndDown,
                'overflow-unset': $vuetify.breakpoint.mdAndUp,
            }"
            class="toc elevation-2 mt-1 right-round"
            floating
        >
            <div style="position: sticky; top: 64px">
                <v-card-title>Table of Contents</v-card-title>
                <v-card-text
                    @click="drawer = false"
                    v-html="article.toc"
                    class="toc-content prose"
                /></div
        ></v-navigation-drawer>
        <v-container
            fluid
            class="py-0"
            :class="[
                $vuetify.breakpoint.mdAndUp && article.toc ? 'offset' : null,
            ]"
        >
            <v-row dense>
                <!--<v-col :cols="12" v-if="article.toc.length > 0">
                    <v-card class="soli-round">
                        <v-card-title>Table of Contents</v-card-title>
                        <v-card-text v-html="article.toc" />
                    </v-card>
                </v-col>-->
                <v-col :cols="12">
                    <v-card-text
                        :class="{
                            'prose md:prose-lg lg:prose-xl': false,
                            'wysiwyg-display': true,
                        }"
                        v-html="article.content"
                    />
                    <!--<v-card-text>
                        <TipTapPage :value="article.content" />
                    </v-card-text>-->
                </v-col>
            </v-row>
        </v-container>
    </AppLayout>
</template>

<script>
import TipTapPage from "../../Components/Editor/TipTapPage.vue";
import AppLayout from "../../Layouts/AppLayout.vue";
import { Link } from "@inertiajs/vue2";

export default {
    name: "HelpArticles",
    props: {
        article: Object,
    },
    components: {
        AppLayout,
        Link,
        TipTapPage,
    },
    data() {
        return {
            drawer: false,
            processedContent: this.article.content,
        };
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Help",
                    href: route("help-categories.index"),
                },
                {
                    text: this.article.category.title,
                    href: route(
                        "help-categories.show",
                        this.article.help_category_id
                    ),
                },
                { text: this.article.title, hide: this.$vuetify.breakpoint.xs },
            ].filter((e) => !e.hide);
        },
    },
    mounted() {
        return;
        this.processContent();
    },
    methods: {
        processContent() {
            const oembedRegex = /<oembed\s+url="([^"]+)"\s*><\/oembed>/g;
            this.processedContent = this.article.content.replace(
                oembedRegex,
                (match, url) => {
                    return `<div class="oembed-wrapper"><iframe src="${url} class="oembed-content" ></iframe></div>`;
                }
            );
        },
    },
};
</script>

<style>
.wysiwyg-display h2:first-of-type {
    margin-top: 0px;
}

.edit-btn {
    position: absolute;
    right: 12px;
}

.sandwich-btn {
    position: absolute;
    left: 12px;
}

.toc {
    width: 300px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.overflow-unset {
    overflow: unset;
}

.toc-content {
    max-height: calc(100vh - 192px);
    overflow-y: auto;
    /*font-family: arial;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;*/
    padding-bottom: 96px;
}

.toc-mobile {
    max-height: calc(100vh - 192px);
}

.toc-desktop {
    max-height: calc(100vh - 128px);
}

.toc ul {
    list-style-position: outside !important;
    list-style: disc inside;
}

.offset {
    padding-left: 300px;
}

:target::before {
    content: "";
    display: block;
    height: 60px; /* fixed header height*/
    margin: -60px 0 0; /* negative fixed header height */
}

.v-navigation-drawer__content {
    overflow: unset !important;
}

.v-breadcrumbs.custom-breadcrumbs li {
    max-width: 148px;
}

/*.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
    color: #2aabe6 !important;
}*/

.wysiwyg-display p {
    font-family: arial;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
}

.wysiwyg-display h1 {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.5em;
    margin: 20px 0px 20px 0;
    color: #2aabe6;
}
.wysiwyg-display h2 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5em;
    margin: 20px 0px 20px 0;
    color: #2aabe6;
}
.wysiwyg-display h3 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5em;
    margin: 20px 0px 20px 0;
    color: #2aabe6;
}
.wysiwyg-display h4 {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.5em;
}

.wysiwyg-display h5 {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.2em;
}

.wysiwyg-display h6 {
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
}

.wysiwyg-display ul {
    font-family: arial;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    list-style-position: outside !important;
    list-style: disc inside;
    margin: revert !important;
}
.wysiwyg-display ol {
    font-family: arial;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
    list-style-position: outside !important;
    list-style: decimal inside;
}

.wysiwyg-display table {
    border: 1px double #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
}

.wysiwyg-display tbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.wysiwyg-display th,
.wysiwyg-display td {
    border: 1px solid #b3b3b3; /* Light purple border */
    padding: 10px;
    text-align: left;
}

.oembed-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    max-width: 900px;
    height: 0;
    overflow: hidden;
}

.oembed-content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
}

.wysiwyg-display .media {
    max-width: 900px;
}
</style>
