<template>
    <header class="bg-white w-full pr-3">
        <div class="mx-auto py-0 flex">
            <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.xs && sandwich"
                @click.stop="$emit('sandwich')"
            ></v-app-bar-nav-icon>
            <h1
                :class="[
                    'leading-tight font-bold align-center flex z-40',
                    large && $vuetify.breakpoint.smAndUp
                        ? 'large-header'
                        : 'medium-header',
                    text.length > 18 && $vuetify.breakpoint.xs
                        ? 'small-header'
                        : null,
                    $page.component == 'Explore' ? 'white--text' : 'soli-grey',
                ]"
            >
                <Link
                    style="margin-left: -18px"
                    v-bind="$attrs"
                    v-if="$attrs.href"
                    preserve-scroll
                    ><v-btn icon><v-icon>chevron_left</v-icon></v-btn></Link
                >
                {{ text }}
                <v-btn
                    v-if="
                        !isScrolled &&
                        $page.props.staffView &&
                        can('Impersonate students') &&
                        $page.component == 'ShowUser' &&
                        $page.props.profile?.loginable
                    "
                    @click="impersonate()"
                    icon
                >
                    <v-icon class="soli-blue-text">visibility</v-icon>
                </v-btn>
                <!--<v-btn
                    v-if="
                        !isScrolled &&
                        $page.props.staffView &&
                        can('Manage inventory') &&
                        $page.component == 'Store'
                    "
                    @click="$inertia.visit('/locations/manage')"
                    icon
                >
                    <v-icon class="soli-grey">settings</v-icon>
                </v-btn>-->
                <v-btn
                    v-if="
                        !isScrolled &&
                        $page.props.staffView &&
                        is('Admin') &&
                        $page.component == 'ShowUser' &&
                        $page.props.profile.stripe_id
                    "
                    :href="`https://dashboard.stripe.com${
                        $page.props.version.dev ? '/test' : ''
                    }/customers/${$page.props.profile.stripe_id}`"
                    target="_blank"
                    icon
                >
                    <v-img contain :width="64" src="/stripe_logo.png" />
                </v-btn>
            </h1>

            <slot name="tools" />
        </div>
        <v-col
            v-if="headerButton && !isScrolled"
            cols="12"
            md="4"
            class="absolute"
            style="z-index: 50; left: -16px"
        >
            <div class="flex mt-5">
                <v-spacer />
                <!--<v-btn @click="$emit('headerButton')" color="accent" icon large>
                    <v-icon>{{ headerButton.icon }}</v-icon>
                </v-btn>-->
                <SoliBtn
                    round
                    :icon="headerButton.icon"
                    gradient="gradient-orange"
                    style="height: 36px; width: 36px"
                    @click="$emit('headerButton')"
                />
            </div>
        </v-col>
    </header>
</template>

<script>
import { Link } from "@inertiajs/vue2";

export default {
    components: { Link },
    name: "SoliHeader",
    props: ["text", "large", "sandwich", "isScrolled", "headerButton"],
    methods: {
        impersonate() {
            this.$inertia.visit(`/impersonate/${this.$page.props.profile.id}`);
        },
    },
};
</script>

<style scoped>
.medium-header {
    font-size: 1.8rem !important;
}

.large-header {
    font-size: 2.4rem !important;
}

.small-header {
    font-size: 1.3rem !important;
}
</style>
