<template>
    <div>
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="list-item, list-item, list-item"
            v-if="apiLoading"
        ></v-skeleton-loader>
        <v-list v-if="buttons">
            <v-list-item
                v-for="student in getStudents"
                :key="student.id"
                class="px-0"
            >
                <SoliStudent
                    :student="student"
                    @input="$emit('input', student)"
                ></SoliStudent> </v-list-item
        ></v-list>
        <v-select
            v-if="!autocomplete && !buttons"
            :items="getStudents"
            :value="value"
            v-on:input="$emit('input', $event)"
            item-text="name"
            item-value="id"
            :label="label"
            return-object
            prepend-inner-icon="people"
            outlined
            dense
            class="gap-1 flex"
        ></v-select>
        <v-autocomplete
            v-if="
                autocomplete &&
                !buttons &&
                !apiLoading /* && (!loader || students.length >= 1 || getStudentCredits)*/
            "
            :loading="loading"
            @input="$emit('input', $event)"
            @keydown.enter.prevent
            :value="value"
            :items="students"
            :hide-details="hideDetails"
            :hide-no-data="hideNoData"
            no-data-text="No users found"
            :search-input.sync="search"
            :multiple="multiple"
            :chips="chips"
            :deletable-chips="deletableChips"
            :item-text="onDemand && !value ? getSearchText : 'name'"
            item-value="id"
            :single-line="singleLine"
            return-object
            :label="label"
            :placeholder="singleLine ? label : null"
            outlined
            class="soli-blue-text"
            :disabled="disabled"
            :prepend-inner-icon="icon ? icon : null"
            :dense="dense"
            :small-chips="smallChips"
            :menu-props="{
                maxWidth: menuWidth,
                closeOnContentClick: closeOnClick,
            }"
            :error-messages="error"
            :rules="rules"
            :autofocus="autofocus"
        >
            <template v-slot:item="data">
                <v-list-item-avatar>
                    <img
                        :src="
                            data.item.profile_photo_path
                                ? `/api/image-service/40x40/${data.item.profile_photo_path}`
                                : data.item.profile_photo_url
                        "
                    />
                </v-list-item-avatar>
                <v-list-item-content
                    :class="{ 'disabled-item': data.item.disabled }"
                >
                    <v-list-item-title
                        v-html="data.item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-if="can('Work') && $page.props.staffView"
                        v-html="data.item.email"
                    ></v-list-item-subtitle>
                </v-list-item-content>
                <div
                    v-if="
                        data.item.locations &&
                        !data.item.locations
                            ?.map((e) => e.id)
                            .includes(
                                $page.props.auth.user.selected_location.id
                            )
                    "
                    class="flex flex-col align-end my-auto"
                >
                    <v-icon color="dark">wrong_location</v-icon>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import SoliStudent from "./SoliStudent.vue";

export default {
    components: {
        SoliStudent,
    },
    props: {
        value: [Object, Array],
        rules: {},
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        getStudentCredits: Array,
        targetDuration: [Number, String],
        label: {
            type: String,
            default: "Students",
        },
        error: {},
        hasEmail: {
            type: Boolean,
            default: false,
        },
        loader: {
            type: Boolean,
            default: true,
        },
        user: {
            type: Object,
            default: () => {},
        },
        filter: {
            type: Array,
            default() {
                return [];
            },
        },
        only: {
            type: Array,
            default() {
                return [];
            },
        },
        lessonType: {
            type: String,
            default: null,
        },
        onDemand: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        chips: {
            type: Boolean,
            default: false,
        },
        deletableChips: {
            type: Boolean,
            default: false,
        },
        smallChips: {
            type: Boolean,
            default: false,
        },
        enrolled: {
            type: Array,
            default() {
                return [];
            },
        },
        guests: {
            type: Array,
            default() {
                return [];
            },
        },
        guestPasses: {
            type: Boolean,
            default: false,
        },
        groupEventId: {
            type: Number,
            default: null,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        closeOnClick: {
            type: Boolean,
            default: false,
        },
        apiLoading: {
            type: Boolean,
            default: false,
        },
        buttons: {
            type: Boolean,
            default: false,
        },
        relationships: {
            type: Array,
            default: null,
        },
        includeSelf: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: [String, Boolean],
            default: "people",
        },
        menuWidth: {
            type: [Number, String],
            default: 345,
        },
        includeTeachers: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            students: [],
            loading: this.onDemand ? false : true,
            search: null,
            count: null,
            timeout: null,
            hideNoData: true,
        };
    },
    methods: {
        reset() {
            this.$emit("reset");
        },
        findStudents() {
            let ids = this.getStudentCredits
                .filter((e) => e.credits >= this.targetDuration)
                .map((e) => e.id);
            let result = this.students.filter((e) => ids.includes(e.id));
            return result;
        },
        getApiSearch() {
            axios
                .get("/api/users/client-search", {
                    params: {
                        search: this.search || this.value?.email,
                        hasEmail: Number(this.hasEmail ? true : null),
                        includeSelf: Number(this.includeSelf ? true : null),
                        includeTeachers: Number(
                            this.includeTeachers ? true : null
                        ),
                        targetDuration: this.targetDuration,
                        lessonType: this.lessonType,
                    },
                })
                .then((resp) => {
                    let results = resp.data.filter((e) => {
                        return this.filter.length
                            ? !this.filter.includes(e.id)
                            : e;
                    });
                    if (this.multiple) {
                        this.students = [...this.value, ...results];
                    } else {
                        this.students = results;
                    }
                    this.clearApiTimer();
                    if (!this.students.length) this.hideNoData = false;
                })
                .catch((error) => {
                    this.clearApiTimer();
                    this.hideNoData = true;
                    if (error.message) {
                        alert(error.message);
                    }
                });
        },
        getGroupEventUsers() {
            axios
                .get("/api/users/group-event-users", {
                    params: {
                        lessonType: this.lessonType ? this.lessonType : null,
                        enrolled: this.enrolled,
                        guests: this.guests,
                        guestPasses: Number(this.guestPasses),
                        groupEventId: this.groupEventId,
                    },
                })
                .then((resp) => {
                    if (this.user) {
                        // filter out auth user
                        this.students = resp.data.filter(
                            (e) => e.id !== this.user.id
                        );
                    } else {
                        this.students = resp.data;
                    }
                    this.students.forEach((e) => {
                        if (this.filter.length) {
                            this.students = this.students.filter(
                                (e) => !this.filter.includes(e.id)
                            );
                        }
                        if (this.only.length) {
                            this.students = this.students.filter((e) =>
                                this.only.includes(e.id)
                            );
                        }
                        e.validated = true;
                    });
                    this.students.map((student) => {
                        let findInValues = this.value.find(
                            (e) => e.id === student.id
                        );
                        if (findInValues) {
                            student.disabled =
                                !!findInValues.attendance?.status;
                        }
                    });
                    this.loading = false;
                    this.$emit("loaded");
                });
        },
        setApiTimer() {
            this.loading = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getApiSearch();
            }, 750);
        },
        clearApiTimer() {
            clearTimeout(this.timeout);
            this.loading = false;
        },
        getApiStudents() {
            if (this.lessonType == "Appointment") {
                this.loading = false;
                return;
            }
            if (this.onDemand) return;
            if (this.relationships) return;
            this.loading = true;
            axios
                .get("/api/users/client-search", {
                    params: {
                        hasEmail: this.hasEmail ? true : null,
                        targetDuration: this.targetDuration
                            ? this.targetDuration
                            : null,
                        lessonType: this.lessonType ? this.lessonType : null,
                        enrolled: this.enrolled,
                        guestPasses: Number(this.guestPasses),
                        groupEventId: this.groupEventId,
                        only: this.only,
                    },
                })
                .then((resp) => {
                    if (this.user) {
                        // filter out auth user
                        this.students = resp.data.filter(
                            (e) => e.id !== this.user.id
                        );
                    } else {
                        this.students = resp.data;
                    }
                    this.students.forEach((e) => {
                        if (this.filter.length) {
                            this.students = this.students.filter(
                                (e) => !this.filter.includes(e.id)
                            );
                        }
                        if (this.only.length) {
                            this.students = this.students.filter((e) =>
                                this.only.includes(e.id)
                            );
                        }
                    });
                    this.loading = false;
                    this.$emit("loaded");
                });
        },
        getSearchText(item) {
            if (!this.search) return;
            if (!this.onDemand) return;
            if (!item.email) return item.name;
            return item.name + ", " + item.email;
        },
    },
    computed: {
        getStudents() {
            let students = this.getStudentCredits
                ? this.findStudents()
                : this.students;
            if (!students.length) return [];
            //if (!this.filter.length) return students;
            return students.filter((e) => !this.filter.includes(e.id));
        },
    },
    mounted() {
        if (this.onDemand) {
            if (this.groupEventId) this.getGroupEventUsers();
            if (!this.value) return;

            this.search = this.value.email;
        } else {
            if (!this.relationships) {
                if (this.groupEventId) {
                    this.getGroupEventUsers();
                } else {
                    this.getApiStudents();
                }
            } else {
                this.students = this.relationships;
            }
        }
    },
    watch: {
        value(newVal, oldVal) {
            if (!newVal) return this.reset();
            if (newVal.id) return;

            let newIds = newVal.map((e) => e.id);
            let oldIds = oldVal.map((e) => e.id);

            let selection = newIds?.filter((e) => !oldIds.includes(e));
            let student = newVal?.find((e) => e.id == selection);

            let removeSelection = oldIds?.filter((e) => !newIds.includes(e));
            let remove = oldVal?.find((e) => e.id == removeSelection);

            if (student) this.$emit("select", student);
            if (remove) this.$emit("remove", remove);
        },
        purchaseOption(oldVal, newVal) {
            if (oldVal !== newVal) this.getApiStudents();
        },
        lessonType(oldVal, newVal) {
            if (oldVal !== newVal) this.getApiStudents();
        },
        targetDuration(oldVal, newVal) {
            if (oldVal !== newVal) this.getApiStudents();
        },
        search(val) {
            this.hideNoData = true;
            if (!this.onDemand) return;
            // If search is empty
            if (!val || !val.length || val.trim().length === 0) {
                // specifically to clear shopping cart if user has been selected, but search is cleared
                if (this.value) return this.reset();
                return;
            }

            if (val == this.value?.name) return;

            // Items have already been requested
            if (this.loading) {
                this.setApiTimer();
                return;
            }

            this.setApiTimer();
        },
    },
};
</script>

<style>
.disabled-item {
    color: rgba(0, 0, 0, 0.38) !important;
}
</style>
