<template>
    <div>
        <v-banner
            v-if="user.hasRoles.includes('Archived Teacher')"
            color="soli-status-red"
            ><div class="white--text flex justify-center">
                This teacher has been archived
            </div></v-banner
        >
        <v-card
            :class="[
                $vuetify.breakpoint.mdAndUp ? 'soli-round' : 'mobile-scroll',
            ]"
            :flat="$vuetify.breakpoint.smAndDown"
            style="margin: 0px"
        >
            <v-toolbar
                flat
                :class="[scrolled ? 'avail-toolbar' : false]"
                style="top: 0px"
                v-scroll="handleScroll"
            >
                <v-col class="col-12 col-sm-2 col-md-4">
                    <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        color="soli-grey"
                        icon
                        class="stack-btn"
                        @click="setToday"
                        ><v-icon>today</v-icon
                        ><small style="font-size: 8px"> Today </small></v-btn
                    >
                    <!-- Date Nav for Mobile -->
                    <v-row
                        v-if="$vuetify.breakpoint.xs"
                        class="align-center justify-around"
                        style="height: 72px"
                    >
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon medium> chevron_left </v-icon>
                        </v-btn>
                        <v-bottom-sheet
                            :close-on-content-click="true"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="mx-3 font-bold"
                                    style="cursor: pointer; color: #4b5563"
                                >
                                    {{ globalMomentDateShort(new Date(title)) }}
                                </div>
                            </template>
                            <v-date-picker
                                @click:date="jumpDate"
                                v-model="getTitleDate"
                                full-width
                            ></v-date-picker>
                        </v-bottom-sheet>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon medium> chevron_right </v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
                <!-- Date Nav for Desktop -->
                <v-col
                    class="col-12 col-sm-8 col-md-4 justify-center align-center d-flex"
                    v-if="!$vuetify.breakpoint.xs"
                >
                    <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> chevron_left </v-icon>
                    </v-btn>

                    <v-menu
                        :close-on-content-click="true"
                        :nudge-left="62.5"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-toolbar-title
                                v-on="on"
                                class="mx-10"
                                style="cursor: pointer; color: #4b5563"
                            >
                                {{ title }}
                            </v-toolbar-title>
                        </template>
                        <v-date-picker
                            @click:date="jumpDate"
                            v-model="getTitleDate"
                        ></v-date-picker>
                    </v-menu>

                    <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> chevron_right </v-icon>
                    </v-btn>
                </v-col>
                <v-col class="col-12 col-sm-2 col-md-4">
                    <!--Schedule Toolbar-->
                    <div
                        v-if="!$vuetify.breakpoint.xs"
                        align="center"
                        justify="space-around"
                        class="soli-toolbar mt-0 pr-3 pl-1 float-right"
                    >
                        <!--<v-menu bottom right class="soli-round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="$vuetify.breakpoint.smAndUp"
                                color="soli-grey"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="stack-btn"
                                @click="soliForm = true"
                                ><v-icon>event_busy</v-icon
                                ><small style="font-size: 8px"
                                    >Time-off</small
                                ></v-btn
                            >
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title
                                    >Schedule Day-off</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title
                                    >Schedule Vacation</v-list-item-title
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>-->
                    </div>
                </v-col>
            </v-toolbar>

            <ManageAvail
                @reset="reset($event)"
                @success="$emit('success')"
                :errors="errors"
                :type="eventType"
                :teacher="user"
                :avail="clickData"
                :avails="availabilities"
                :calendarDate="getCalendarDate"
                v-if="clickData"
            ></ManageAvail>

            <!--<SoliChoice
            v-if="requestAssignment !== null"
            @option1="handleRequestAssignment"
            @option2="requestAssignment = null"
            title="Uh-oh"
            subtitle="Unable to move availability with in-studio assignments or lessons. Would you like to request a new assignment from your CM?"
            :button1="
                `Request Assignment on ${globalMomentWeekDay(
                    requestAssignment
                )}s`
            "
            button2="Nevermind"
            @reset="requestAssignment = null"
        >
        </SoliChoice>-->
            <SoliChoice
                v-if="requestAssignment !== null"
                @option1="requestAssignment = null"
                title="Uh-oh"
                subtitle="Unable to move availability with in-studio assignments or lessons."
                button1="Okay"
                @reset="requestAssignment = null"
            >
            </SoliChoice>

            <FullCalendar
                id="fc-teacher-avail"
                ref="fullCalendar"
                :options="calendarOptions"
                class="fc-teacher-avail"
            >
                <template v-slot:eventContent="arg">
                    <div class="pt-1 pl-2">
                        <b
                            v-if="arg.timeText"
                            :class="arg.isMirror ? 'soli-grey' : null"
                            >{{ arg.timeText }}
                        </b>
                        <div
                            v-if="arg.event.extendedProps.recurrence_id"
                            class="flex align-center mt-1"
                        >
                            <v-icon x-small color="white" class="mr-1"
                                >repeat</v-icon
                            >
                            <small> Weekly </small>
                        </div>
                        <div
                            v-if="
                                arg.event.extendedProps.recurrence_end &&
                                !arg.event.extendedProps.removed_from_series
                            "
                            class="flex align-center mt-1"
                        >
                            <v-icon x-small color="white" class="mr-1"
                                >event_busy</v-icon
                            >
                            <small>{{
                                `Until ${globalMomentDate(
                                    arg.event.extendedProps.recurrence_end
                                )}`
                            }}</small>
                        </div>
                        <div
                            v-if="arg.event.extendedProps.removed_from_series"
                            class="flex align-center mt-1"
                        >
                            <v-icon x-small color="white" class="mr-1"
                                >event_busy</v-icon
                            >
                            <small>Removed from series</small>
                        </div>
                        <div class="flex align-center mt-1">
                            <v-icon x-small color="white" class="mr-1"
                                >people</v-icon
                            >
                            <small v-if="arg.event.extendedProps.lessons">{{
                                `${
                                    arg.event.extendedProps.lessons.filter(
                                        (e) => !e.cancelled
                                    ).length
                                } lesson(s)`
                            }}</small>
                        </div>

                        <div class="flex align-center mt-1">
                            <v-icon x-small color="white" class="mr-1"
                                >storefront</v-icon
                            >
                            <small
                                v-if="
                                    arg.event.extendedProps.assignments?.length
                                "
                                >{{
                                    `${arg.event.extendedProps.assignments
                                        .map((e) => e.location.name)
                                        .filter(
                                            (value, index, self) =>
                                                self.indexOf(value) === index
                                        )}`
                                }}</small
                            >
                            <small
                                v-if="
                                    !arg.event.extendedProps.assignments?.length
                                "
                                >Unassigned</small
                            >
                        </div>
                        <div
                            class="flex align-center mt-1"
                            v-if="arg.event.extendedProps.online_only"
                        >
                            <v-icon x-small color="white" class="mr-1"
                                >videocam</v-icon
                            >

                            <small>Online Only</small>
                        </div>
                    </div>
                </template>
            </FullCalendar>
        </v-card>
    </div>
</template>

<script>
import ManageAvail from "./ManageAvail";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import globalMoment from "../../Mixins/GlobalMoment.js";
//import scrollGridPlugin from "@fullcalendar/scrollgrid";
//import momentTimezonePlugin from "@fullcalendar/moment-timezone";

export default {
    name: "TeacherAvailabilities",
    mixins: [globalMoment],
    props: {
        errors: {},
        availabilities: {
            type: Array,
            default: null,
        },
        user: {},
        //"assignments",
        //"lessons",
        range: {},
    },
    components: { ManageAvail, FullCalendar },
    data() {
        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    interactionPlugin,
                    resourceDayGridPlugin,
                    resourceTimeGridPlugin,
                    //momentTimezonePlugin,
                    //scrollGridPlugin,
                ],
                schedulerLicenseKey:
                    "CC-Attribution-NonCommercial-NoDerivatives",
                initialView: this.$vuetify.breakpoint.xs
                    ? "timeGridDay"
                    : "timeGridWeek",
                views: {
                    timeGridDay: {
                        // name of view
                        titleFormat: {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        },
                    },
                    timeGridWeek: {
                        titleFormat: { year: "numeric", month: "long" },
                    },
                },
                //timeZone: this.$page.props.auth.user.timezone,
                initialDate: this.range,
                longPressDelay: 500,
                scrollTimeReset: false,
                windowResize: this.handleWindowResize,
                expandRows: true,
                allDaySlot: false,
                eventClick: this.handleEventClick,
                eventDrop: this.handleEventDrop,
                select: this.handleSelect,
                eventResize: this.handleEventResize,
                eventStartEditable: true,
                eventResizableFromStart: true,
                selectOverlap: this.getSelectOverlap,
                slotLabelInterval: { hours: 1 },
                selectable: true,
                selectMirror: true,
                selectMinDistance: 0,
                height: !this.$vuetify.breakpoint.smAndDown
                    ? "calc(100vh - 220px)"
                    : "auto",
                stickyHeaderDates: true,
                headerToolbar: false,
                nowIndicator: true,
                editable: true,
                events: [],
                eventSources: [],
                slotMinTime: "06:00:00",
                viewDidMount: this.getTitle,
            },

            showBorders: null,
            scrolled: null,
            formTitle: "Add Availability",

            dragging: false,

            clickData: null,
            clickedLessonDetails: null,
            dialog: null,
            title: null,
            getCalendarDate: null, //new Date(),
            viewLabel: "Day",
            picker: null,
            availToday: [],
            scrolled: false,
            //keepScroll: null,

            getMirror: null,

            // teacherAvail
            value: "",
            events: [],
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            eventType: null,
            eventTitle: null,
            title: null,
            viewLabel: "View",
            picker: null,
            avail: null,
            requestAssignment: null,
            loading: false,
            //futureConflict: null
        };
    },
    methods: {
        handleScroll() {
            window.scrollY > 0
                ? (this.scrolled = true)
                : (this.scrolled = false);
        },
        /*refreshEvents() {
            this.$inertia.reload({
                only: ["avails"],
                onSuccess: () => {
                    this.getEvents();
                },
            });
        },*/
        /*fetchEvents(date) {
            this.loading = true;
            let range = moment(new Date(date)).format("YYYY-MM-DD");
            this.$inertia.visit(
                //`/users/${this.teacher.id}/${range}/#availability`,
                `/teachers/${this.teacher.id}/${range}/#availability`,
                {
                    only: ["avails", "dateRange"],
                    preserveState: true,
                    preserveScroll: true,
                    onFinish: () => {
                        this.getEvents();
                        this.loading = false;
                    },
                }
            );
            //}
        },*/
        updateRange(date) {
            this.loading = true;
            this.$inertia.get(
                `/profile/${this.user.id}/availability/${moment(new Date(date))
                    .tz("UTC")
                    .format("YYYY-MM-DD")}`,
                {
                    dayView: Number(this.$vuetify.breakpoint.xs),
                },
                {
                    preserveState: true,
                    preserveScroll: false,
                    onSuccess: () => {
                        this.getEvents();
                        this.loading = false;
                    },
                }
            );
        },
        handleWindowResize(size) {
            setTimeout(() => {
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.$refs.fullCalendar
                        .getApi()
                        .setOption("height", "auto");
                } else {
                    this.$refs.fullCalendar
                        .getApi()
                        .setOption("height", "calc(100vh - 220px)");
                }
                this.$refs.fullCalendar.getApi().updateSize();
            }, 300);
        },
        handleRequestAssignment(arg) {
            //TODO
            //this.form.assignmentRequested = this.arg.starts_at;
            console.log("send notification to CM for " + this.arg.event.start);
        },
        getSelectOverlap(event) {
            return event.classNames.includes("cancelled");
        },
        reset(revert) {
            if (revert && this.clickData && this.clickData.revert) {
                this.clickData.revert();
            }
            this.clickData = null;
            this.formTitle = "Add Availability";
        },
        handleEventClick: function (arg) {
            console.log(arg.event.id);
            if (arg.event.extendedProps.cancelled) return;
            arg.start = arg.event.start;
            arg.end = arg.event.end;
            arg.eventClick;
            this.clickData = arg;
        },
        handleEventDrop(arg) {
            //console.log(arg);
            return arg.revert(); // temporarily disabled
            if (arg.event.extendedProps.cancelled) {
                arg.revert();
                return;
            }
            if (arg.event.extendedProps.lessons.length > 0) {
                this.requestAssignment = arg.event.start;
                arg.revert();
            } else {
                arg.start = arg.event.start;
                arg.end = arg.event.end;
                arg.eventDrag = true;
                this.clickData = arg;
            }
        },
        handleEventResize(arg) {
            //console.log(arg);
            if (arg.event.extendedProps.cancelled) {
                arg.revert();
                return;
            }
            arg.start = arg.event.start;
            arg.end = arg.event.end;

            arg.eventResize = true;
            this.clickData = arg;
        },
        handleSelect(arg) {
            arg.newSelect = true;
            this.clickData = arg;
        },
        getEvents() {
            this.calendarOptions.eventSources = [];
            this.calendarOptions.eventSources.push(this.availabilities);
        },

        setToday() {
            this.$refs.fullCalendar.getApi().today();
            this.getTitle();
            this.updateRange(this.getCalendarDate);
        },
        prev() {
            this.$refs.fullCalendar.getApi().prev();
            this.getTitle();
            this.updateRange(this.getCalendarDate);
        },
        next() {
            this.$refs.fullCalendar.getApi().next();
            this.getTitle();
            this.updateRange(this.getCalendarDate);
        },
        getTitle() {
            this.title = this.$refs.fullCalendar.getApi().currentData.viewTitle;
            //console.log(this.$refs.fullCalendar.getApi().currentData);
            this.getCalendarDate = moment(
                this.$refs.fullCalendar.getApi().currentData.viewApi
                    .currentStart
            ).format("YYYY-MM-DD");
        },
        dayView() {
            this.$refs.fullCalendar.getApi().changeView("timeGridDay");
        },
        weekView() {
            this.$refs.fullCalendar.getApi().changeView("timeGridWeek");
        },
        jumpDate(date) {
            this.$refs.fullCalendar.getApi().gotoDate(date);
            this.getTitle();
            this.updateRange(this.getCalendarDate);
        },
        scrollToTime() {
            let time = new Date().getHours() + ":00";
            this.$refs.fullCalendar.getApi().scrollToTime(time);
        },
    },
    updated() {
        this.getMirror = this.$el.querySelector(".fc-event-mirror");
        this.$vuetify.breakpoint.xs ? this.dayView() : this.weekView();
        this.getTitle();
    },
    computed: {
        getLesson() {
            return this.clickedLessonDetails
                ? this.lessons.filter(
                      (e) => e.id == this.clickedLessonDetails
                  )[0]
                : null;
        },
        getTitleDate: {
            get() {
                return this.getCalendarDate ? this.getCalendarDate : null;
                //return new Date(this.title).toISOString().substr(0, 10);
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted() {
        //if (this.$vuetify.breakpoint.xs) return;
        this.$nextTick(() => {
            this.$refs.fullCalendar.getApi().updateSize();
            this.scrollToTime();
            this.getEvents();
            //this.refreshEvents();
        });
    },
    beforeDestroy() {
        this.calendarOptions.eventSources = [];
    },
    watch: {
        availabilities() {
            this.getEvents();
        },
    },
};
</script>

<style>
.fc-teacher-avail .fc-day-today {
    background-color: rgba(42, 171, 230, 0.05) !important;
}

.fc-teacher-avail .fc-timegrid-slot-minor {
    border-top: white !important;
}

.fc-teacher-avail .fc-timegrid-event-harness {
    margin-left: 5px !important;
}

.fc-teacher-avail .fc-col-header-cell-cushion {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.5px;
    color: #4b6876 !important;
}

.fc-teacher-avail .fc-event-mirror {
    border: 2px solid #2aabe6;
    /*color: #4b6876 !important;*/
    background: transparent;
    border-radius: 12px;
    box-shadow: 1px 4px 4px 0 rgba(120, 178, 233, 0.25) !important;
}

.fc-teacher-avail .fc-event-mirror .fc-event-time {
    color: #4b6876 !important;
    font-size: inherit;
}

.fc-teacher-avail .fc-timegrid-event-harness {
    margin-left: 0px !important;
}

.fc-teacher-avail .fc-scrollgrid {
    border-radius: 12px !important;
}

.fc-teacher-avail.fc .fc-scrollgrid-section > td {
    border-right: none;
    /*border-bottom: none;*/
}

.avail-color {
    box-shadow: 3px 3px 10px 3px rgba(120, 178, 233, 0.5);
    background: #228cbd;
    border-color: #228cbd;
    border-radius: 12px;
}

.fc-teacher-avail .cancelled {
    opacity: 70%;
    cursor: unset;
}

#fc-teacher-avail
    .fc-teacher-avail
    .fc
    .fc-scrollgrid-section:last-of-type
    > td {
    border-bottom: none !important;
}

.v-window {
    overflow: unset;
}

.v-window-item {
    overflow: hidden;
}

.availability {
    overflow: unset !important;
}

@media (max-width: 540px) {
    .fc-teacher-avail .fc-scrollgrid-section-sticky > * {
        /*top: 150px !important;*/
        display: none;
    }
}

@media (min-width: 540px) {
    .fc-teacher-avail .fc-scrollgrid-section-sticky > * {
        top: 150px !important;
    }
}

.avail-toolbar {
    position: sticky;
    top: 94px !important;
    position: sticky;
    z-index: 5;
    overflow: hidden;
}
</style>
