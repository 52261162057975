<template>
    <v-app>
        <div
            :class="[
                $vuetify.breakpoint.xs
                    ? 'gradient-blue flex-col items-center'
                    : 'bg-white',
                'min-h-screen flex sm:justify-center overflow-hidden',
            ]"
        >
            <div class="my-12">
                <v-img
                    v-bind:src="'/Musicologie-White-Wordmark.png'"
                    max-width="200"
                    v-if="$vuetify.breakpoint.xs"
                />
            </div>
            <div
                :class="[
                    'max-w-3xl w-full px-6',
                    $vuetify.breakpoint.xs ? null : 'upper-third',
                ]"
            >
                <div
                    :class="[
                        'w-full bg-white custom-shadow soli-round overflow-hidden',
                    ]"
                >
                    <v-container class="ma-0 flex justify-center"
                        ><v-row :class="$vuetify.breakpoint.xs ? null : 'tall'"
                            ><v-col
                                v-if="$vuetify.breakpoint.smAndUp"
                                class="col-6 gradient-blue flex align-center justify-center"
                                style="background-color: #228cbd"
                                ><v-img
                                    v-bind:src="'/Musicologie-White-Wordmark.png'"
                                    max-width="275"
                            /></v-col>
                            <v-col class="xs:col-12 sm:col-6 align-self-center">
                                <v-card-title class="soli-grey">
                                    {{
                                        `${
                                            hasErrors
                                                ? " Uh-oh"
                                                : "Confirm Password"
                                        }`
                                    }}
                                </v-card-title>
                                <v-card-subtitle class="error--text">
                                    {{ form.errors.password }}
                                </v-card-subtitle>

                                <form @submit.prevent="submit">
                                    <v-container>
                                        <SoliText
                                            name="password"
                                            ref="password"
                                            v-model="form.password"
                                            label=""
                                            required
                                            :password="true"
                                            :show="show"
                                            @append="show = !show"
                                        ></SoliText>
                                        <v-row>
                                            <v-col
                                                class="justify-end flex align-center"
                                            >
                                                <SoliBtn
                                                    href="/dashboard"
                                                    gradient="gradient-blue"
                                                    text="Lobby"
                                                    :icon="null"
                                                    class="ml-3"
                                                ></SoliBtn>
                                                <SoliBtn
                                                    gradient="gradient-orange"
                                                    text="Continue"
                                                    :icon="null"
                                                    :loading="form.processing"
                                                    class="ml-3"
                                                    type="submit"
                                                ></SoliBtn>
                                            </v-col> </v-row
                                    ></v-container></form></v-col></v-row
                    ></v-container>
                </div>
            </div></div
    ></v-app>
</template>

<script>
export default {
    props: ["user"],
    data() {
        return {
            show: false,
            form: this.$inertia.form({
                email: this.$page.props.auth.user.email,
                password: "",
            }),
        };
    },
    methods: {
        submit() {
            this.form.post(this.route("confirm.continue"), {
                onSuccess: () => this.form.reset("password"),
            });
        },
    },
    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
};
</script>

<style scoped>
.v-dialog {
    border-radius: 20px !important;
}

.tall {
    height: 500px;
}

.quarter {
    transform: translateY(-25%);
}

@media (max-width: 599px) {
    .theme--light.v-application {
        background: rgb(42, 171, 230) !important;
        background: linear-gradient(
            100deg,
            rgba(42, 171, 230, 1) 2%,
            rgba(0, 139, 203, 1) 100%
        ) !important;
        box-shadow: 1px 4px 4px 0 rgba(42, 171, 230, 0.25) !important;
    }
}

.custom-shadow {
    box-shadow: 0 25px 50px 0px rgb(0 0 0 / 25%);
}
</style>
