<template>
    <v-app
        :class="[
            'app min-h-smallest bg-white xs:px-0 sm:px-1 mdAndUp:px-3',
            $page.component == 'Explore' ? 'bg-gradient-a' : null,
        ]"
    >
        <v-system-bar
            v-if="
                $page.props.impersonating?.impersonatingUser &&
                !showBanner?.some((e) => e.show)
            "
            app
            class="gradient-blue white--text justify-center"
            height="64"
            ><div class="flex justify-center">
                <v-card-subtitle class="align-self-center">
                    {{ `Impersonating ${this.$page.props.auth.user.name}` }}
                </v-card-subtitle>
                <v-btn
                    class="my-3"
                    color="white"
                    plain
                    @click="stopImpersonating()"
                >
                    Return
                </v-btn>
            </div>
        </v-system-bar>
        <!--<v-system-bar
            v-if="$page.props.lobbyMode"
            app
            class="gradient-blue white--text justify-center"
            height="64"
            ><div class="flex justify-center">
                <v-card-subtitle class="align-self-center">
                    Lobby Mode
                </v-card-subtitle>
            </div>
        </v-system-bar>-->
        <v-system-bar
            v-if="showBanner ? showBanner.some((e) => e.show) : false"
            app
            class="gradient-orange white--text justify-center"
            height="128"
            ><div>
                <v-card-subtitle class="pb-0">
                    {{ showBanner.find((e) => e.show).message }}
                </v-card-subtitle>
                <v-card-actions class="justify-end">
                    <v-btn
                        color="white"
                        text
                        @click="
                            showBanner.find((e) => e.show).addCreditCard
                                ? addCreditCard()
                                : pastDueInvoice(
                                      showBanner.find((e) => e.show).invoice
                                  )
                        "
                    >
                        {{ showBanner.find((e) => e.show).button }}
                    </v-btn>
                </v-card-actions>
            </div>
        </v-system-bar>
        <v-system-bar
            v-if="false"
            app
            class="gradient-orange white--text justify-center"
            height="64"
            ><div>
                <v-card-subtitle>
                    Network error detected. No offline access.
                </v-card-subtitle>
            </div>
        </v-system-bar>
        <v-system-bar
            v-if="$page.props.version?.outage"
            app
            class="gradient-orange white--text justify-center"
            height="64"
            ><div>
                <v-card-subtitle>
                    Temporary service outage. This app might not work as
                    expected. Thanks!
                </v-card-subtitle>
            </div>
        </v-system-bar>
        <v-system-bar
            v-if="
                !$page.props.profileRequired &&
                !$page.props.impersonating?.impersonatingUser &&
                $page.props.auth.user &&
                $page.component !== 'Store'
            "
            app
            class="gradient-orange white--text justify-center"
            height="64"
            ><Link
                :href="`/profile/${$page.props.auth?.user?.id}/personal-info`"
                style="cursor: pointer"
                class="flex align-center justify-center"
            >
                <v-progress-circular
                    :size="42"
                    :value="$page.props.profileComplete"
                    color="white"
                >
                    <small>{{ $page.props.profileComplete }}%</small>
                </v-progress-circular>
                <v-card-subtitle class="white--text">
                    Complete your profile<v-icon color="white" large
                        >chevron_right</v-icon
                    >
                </v-card-subtitle>
            </Link>
        </v-system-bar>

        <Sidenav
            v-if="
                $vuetify.breakpoint.mdAndUp &&
                page !== '/login' &&
                $page.props.staffView
            "
            :page="page"
            :showMacros="showMacros"
        ></Sidenav>

        <v-app-bar
            app
            color="white"
            height="100"
            elevate-on-scroll
            :class="[
                'justify-end align-center flex px-0 py-0',
                !$page.props.staffView ? 'container' : null,
            ]"
            v-if="siteHeader"
        >
            <a href="https://musicologielessons.com">
                <jet-application-logo class="block h-9 w-auto"
            /></a>
            <v-spacer />
            <SoliBtn
                gradient="gradient-orange"
                href="/login"
                style="color: white !important"
                >Login</SoliBtn
            >
        </v-app-bar>

        <Navbar
            class="xs:px- sm:px-1 lg:px-2"
            v-if="!siteHeader && page !== '/profile' && page !== '/login'"
            :pageTitle="pageTitle"
            :backLink="backLink"
            :tabs="tabs"
            ref="navBar"
            :cart="cart"
            @showCart="$emit('showCart')"
            @locationSelect="locationSelect = true"
            :filterData="filterData"
            :headerButton="headerButton"
            @headerButton="$emit('headerButton')"
            @pageSearch="$emit('pageSearch', $event)"
            @filterResults="[(filter = $event), $emit('filterResults', $event)]"
            @quickLinks="quickLinks = true"
            :sandwich="sandwich"
            @sandwich="$emit('sandwich')"
            :nearMe="nearMe"
            :market="market"
            :retentionGroup="retentionGroup"
            :showArchived="showArchived"
            :showAllStudents="showAllStudents"
            :showMacros="showMacros"
            @success="showMessage = true"
        >
            <template slot="tabs"><slot name="tabs"></slot></template
        ></Navbar>

        <v-main
            :class="[
                $page.component == 'Schedule' ||
                $page.url.includes('/availability')
                    ? 'no-bottom-padding'
                    : null,
            ]"
        >
            <div
                fluid
                :class="[
                    'h-full xs:px-0 lg:px-2 xs:py-0 md:py-1',
                    page == '/schedule' || $page.url.includes('/availability')
                        ? 'sm:px-0'
                        : 'sm:px-1',
                ]"
            >
                <!-- Page Content -->
                <slot ref="main"></slot>
            </div>
        </v-main>

        <v-footer v-if="siteFooter" class="justify-center" height="50">
            <div class="subtitle-center">
                &copy; {{ new Date().getFullYear() }} · Musicologie
            </div>
        </v-footer>

        <v-slide-y-reverse-transition>
            <v-footer
                fixed
                v-show="showFooter"
                style="height: auto; z-index: 9"
                :class="['safe-bottom']"
                ><div
                    :class="[
                        'justify-end align-center flex',
                        !$page.props.staffView ? 'container' : null,
                    ]"
                    style="width: 100%; height: 56px"
                >
                    <v-spacer />
                    <SoliBtn
                        color="grey darken-1"
                        text="Cancel"
                        icon="cancel"
                        class="mr-4"
                        @click="$emit('cancel')"
                    ></SoliBtn>
                    <SoliBtn
                        gradient="gradient-orange"
                        text="Save Changes"
                        icon="save"
                        :loading="submitProfileLoading"
                        @click="$emit('save')"
                    ></SoliBtn>
                </div>
            </v-footer>
        </v-slide-y-reverse-transition>
        <BottomNav
            v-if="
                $vuetify.breakpoint.smAndDown &&
                page !== '/login' &&
                $page.props.auth.user
            "
            :page="page"
            ref="bottom-nav"
            @fabAction="$emit('fabAction')"
            :selectedPeople="selectedPeople"
            :showFab="showFab"
            :fabLoading="fabLoading"
            @locationSelect="locationSelect = true"
            @success="showMessage = true"
        ></BottomNav>

        <SoliBtn
            v-if="!$vuetify.breakpoint.smAndDown && showFab"
            fab
            fixed
            right
            bottom
            round
            :loading="fabLoading"
            :icon="selectedPeople.length ? 'email' : 'add'"
            :gradient="
                selectedPeople.length ? 'gradient-blue' : 'gradient-orange'
            "
            :class="[
                selectedPeople.length ? 'shadow-blue-xl' : 'shadow-orange-xl',
                fabClasses,
            ]"
            @click="$emit('fabAction')"
        ></SoliBtn>

        <SoliResponsiveDialog
            v-if="locationSelect"
            :title="
                studentLocations ? 'Available Locations' : 'Select Location'
            "
            @reset="
                [
                    (locationSelect = false),
                    (locationLoading = false),
                    (studentLocations = null),
                ]
            "
            ><template #body>
                <SoliUserLocation
                    ref="userLocation"
                    v-model="location"
                    :locations="
                        studentLocations
                            ? studentLocations
                            : $page.props.auth.user.locations
                    "
                    :loading="locationLoading"
                    iterator
                    autofocus
                    @input="updateSelectedLocation" /></template
        ></SoliResponsiveDialog>
        <SoliSnack
            v-if="showMessage"
            @hide="showMessage = false"
            :time="2000"
            :text="$page.props.successMessage"
        ></SoliSnack>
        <SoliSnack
            v-if="$page.props.errors.permissions"
            :text="$page.props.errors.permissions"
        ></SoliSnack>
        <SoliSnack
            v-if="updateAvailable"
            @hide="updateAvailable = false"
            text="Update available!"
        ></SoliSnack>
        <SoliChoice
            v-if="!!$page.props.errors.contactSupport"
            title="Contact feedback@musicologie.com"
            :subtitle="$page.props.errors.contactSupport"
            button1="Okay"
            @option1="clearSupportError()"
        ></SoliChoice>
        <SoliChoice
            v-if="!!$page.props.errors.soliError"
            title="Uh oh"
            :subtitle="$page.props.errors.soliError"
            button1="Okay"
            @option1="closeModal(() => ($page.props.errors.soliError = null))"
        ></SoliChoice>
        <SoliChoice
            v-if="$page.props.successData?.reminders"
            title="Reminder"
            :subtitle="$page.props.successData?.reminders.title"
            bold
            button1="Okay"
            @option1="closeModal(() => ($page.props.successData = null))"
            permanent
            ><template
                ><v-list-item
                    class="soli-grey flex body-2"
                    dense
                    v-for="(reminders, idx) in $page.props.successData.reminders
                        .data"
                    :key="idx"
                    ><v-icon color="primary" class="mr-3">task_alt</v-icon
                    >{{ reminders }}</v-list-item
                ></template
            ></SoliChoice
        >
        <SoliChoice
            v-if="!!$root.networkError"
            title="Uh oh"
            subtitle="A network error has occurred"
            button1="Okay"
            @option1="closeModal(() => ($root.networkError = false))"
        ></SoliChoice>
        <SoliForm
            v-if="confirmTimezone"
            title="Confirm Timezone"
            textSubtitle="Your timezone can now be set from your profile."
            persistent
            permanent
            @submit="submitTimezone()"
        >
            <template #body>
                <v-select
                    :items="timezones"
                    v-model="updateTimezone"
                    label="Timezone"
                    prepend-inner-icon="watch"
                    outlined
                    :rules="[(v) => !!v || 'Timezone is required']"
                    dense
                >
                </v-select></template
        ></SoliForm>
        <SoliForm
            v-if="showNewFeatures"
            ref="features"
            @reset="showNewFeatures = false"
            @submit="closeNewFeatures()"
            persistent
            permanent
            title="Recent Updates and Features"
            text="Finished"
            :hideBtn="featureStep !== newFeatures.length"
            :showCancelBtn="featureStep !== newFeatures.length"
        >
            <template #body>
                <v-stepper v-model="featureStep" class="soli-round">
                    <v-stepper-content
                        v-for="feature in newFeatures"
                        :key="feature.text"
                        :step="feature.step"
                    >
                        <v-img
                            :src="feature.img"
                            eager
                            class="soli-round"
                        ></v-img>
                        <div class="font-bold soli-grey pa-5">
                            {{ feature.text }}
                        </div>
                    </v-stepper-content>
                </v-stepper>
            </template>
            <template
                v-slot:cancelBtn="attrs"
                v-if="featureStep !== newFeatures.length"
            >
                <SoliBtn
                    text="Next"
                    v-bind="attrs"
                    gradient="gradient-blue"
                    @click="featureStep++"
                ></SoliBtn>
            </template>
        </SoliForm>
        <QuickLinks v-if="quickLinks" @reset="closeQuickLinks()"></QuickLinks>
        <v-snackbar
            v-model="successCard.show"
            vertical
            bottom
            right
            light
            :timeout="6000"
            content-class="soli-grey pa-0"
        >
            <v-card-title>{{ successCard.title }}</v-card-title>
            <v-card-subtitle>{{ successCard.subtitle }}</v-card-subtitle>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    color="dark"
                    rounded
                    text
                    @click="successCard.show = false"
                >
                    Close
                </v-btn>
                <v-btn
                    v-bind="attrs"
                    :href="successCard.href"
                    color="primary"
                    rounded
                    text
                >
                    View
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import Sidenav from "./../Components/Sidenav";
import Navbar from "./../Components/Navbar";
import BottomNav from "./../Components/BottomNav";
import QuickLinks from "./../Components/QuickLinks";
import JetApplicationLogo from "./../Jetstream/ApplicationLogo";
import CheckForUpdates from "../Mixins/CheckForUpdates.js";
import SoliResponsiveDialog from "../Components/Base/SoliResponsiveDialog.vue";

export default {
    mixins: [CheckForUpdates],
    props: {
        successMessage: {},
        errors: {},
        page: {},
        pageTitle: {},
        backLink: {},
        tabs: {},
        showFooter: {},
        cart: {},
        submitProfileLoading: {},
        filterData: {},
        showBanner: {
            type: Array,
            default: () => [],
        },
        selectedPeople: {
            type: Array,
            default: () => [],
        },
        sandwich: {
            type: Boolean,
            default: false,
        },
        nearMe: {
            type: Boolean,
            default: true,
        },
        market: {
            type: Number,
            default: null,
        },
        retentionGroup: {
            type: String,
            default: null,
        },
        showArchived: {
            type: Boolean,
            default: false,
        },
        showAllStudents: {
            type: Boolean,
            default: false,
        },
        siteFooter: {
            type: Boolean,
            default: false,
        },
        siteHeader: {
            type: Boolean,
            default: false,
        },
        headerButton: {
            type: Object,
            default: () => {},
        },
        hasFab: {
            type: Boolean,
            default: false,
        },
        fabClasses: {
            type: String,
            default: "",
        },
        fabLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Sidenav,
        Navbar,
        BottomNav,
        QuickLinks,
        JetApplicationLogo,
        SoliResponsiveDialog,
        Link,
    },
    data() {
        return {
            bottomNavHidden: false,
            location: this.$page.props.auth?.user?.selected_location ?? null,
            locationSelect: false,
            locationLoading: false,
            showMessage: false,
            filter: null,
            updateAvailable: false,
            locationRedirect: this.$page.url,
            quickLinks: false,
            studentLocations: null,
            successCard: { show: false },
            testSnack: true,
            showMacros: false,
        };
    },
    methods: {
        stopImpersonating() {
            this.$inertia.visit("/stop-impersonation");
        },
        clearSupportError() {
            setTimeout(() => {
                this.$page.props.errors.contactSupport = null;
            }, 300);
        },
        closeQuickLinks() {
            setTimeout(() => {
                this.quickLinks = false;
            }, 300);
        },

        addCreditCard() {
            window.location.href = route("stripe.update");
        },
        pastDueInvoice(invoice) {
            this.$inertia.get(route("invoice.past_due"), invoice, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {},
                onError: (errors) => {
                    console.log(errors);
                },
            });
        },
        updateSelectedLocation(location) {
            this.locationLoading = true;
            this.$inertia.post(
                route("auth.location", location.id),
                {
                    redirect:
                        this.$page.component == "Schedule"
                            ? route("schedule")
                            : this.locationRedirect,
                },
                {
                    preserveState: false,
                    onError: () => {
                        this.locationSelect = false;
                        this.locationLoading = false;
                        this.locationRedirect = this.$page.url;
                        console.log(JSON.stringify(this.$page.props.errors));
                    },
                }
            );
        },
        addToCurrentLocation(user) {
            this.$inertia.put(
                `/users/${user.id}/add-to-location`,
                {},
                {
                    preserveState: false,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.showMessage = true;
                    },
                    onError: (errors) => {
                        console.log(
                            "Form returned errors: " + JSON.stringify(errors)
                        );
                    },
                }
            );
        },
        setViewHeight: function () {
            let vh = window.innerHeight * 0.01;
            return vh;
        },
        subscribeToMessages() {
            Echo.private(
                `App.Models.User.${this.$page.props.auth.user.id}`
            ).notification((event) => {
                if (!event.message_id) return;
                if (this.$root.loading) return; // prevent refresh firing before any form submissions, otherwise page refresh will cancel redirect()->back() success

                if (this.$page.component == "Inbox") {
                    this.$emit("messageReceived", event);
                } else {
                    this.$inertia.reload({
                        preserveState: true,
                        only: [
                            "index",
                            "conversation",
                            "unreadMessageCount",
                            "messages",
                            "messageCount",
                        ],
                    });
                }
            });

            Echo.private(
                `App.Models.User.${this.$page.props.auth.user.id}`
            ).listen("ProgressUpdated", (event) => {
                this.$emit("eventProgress", {
                    progress: event.progress ?? 0,
                    error: event.error,
                });
            });

            Echo.private(
                `App.Models.User.${this.$page.props.auth.user.id}`
            ).listen("EmailSuccess", (event) => {
                if (event.activity) {
                    this.successCard = {
                        title: `Email Successful`,
                        subtitle: `View your sent messages here`,
                        href: `/profile/${this.$page.props.auth.user.id}/logs#sent`,
                        show: true,
                    };
                }
                if (event.error) {
                    this.$page.props.errors.soliError = event.error;
                }
            });
        },
        unsubscribeFromMessages() {
            Echo.leave(`App.Models.User.${this.$page.props.auth.user.id}`);
        },
        hideMacros(event) {
            if (!["ShiftLeft", "AltLeft"].includes(event.code)) return;
            this.showMacros = false;
        },
        /**
         * @param event
         * @todo move this to a mixin
         */
        macros(event) {
            // Arrow inputs
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                this.$refs.userLocation?.handleArrowNavigation(event);
                return;
            }

            if (!event.altKey || !event.shiftKey) return;

            this.showMacros = true;

            // Session
            if (event.code == "KeyM") {
                event.preventDefault();
                return this.$inertia.get(`/mode`);
            }
            if (event.code == "KeyL") {
                event.preventDefault();
                return (this.locationSelect = true);
            }
            // Navigation
            if (event.code == "KeyD") {
                event.preventDefault();
                return this.$inertia.get(`/dashboard`);
            }
            if (event.code == "KeyI") {
                event.preventDefault();
                return this.$inertia.get(`/inbox`);
            }
            if (event.code == "KeyP") {
                event.preventDefault();
                return this.$inertia.get(`/people`);
            }
            if (event.code == "KeyS") {
                event.preventDefault();
                return this.$inertia.get(`/schedule`);
            }
            if (event.code == "KeyC") {
                event.preventDefault();
                return this.$inertia.get(`/store`);
            }
            if (event.code == "KeyH") {
                event.preventDefault();
                return this.$inertia.get(`/help-categories`);
            }
            if (event.key === "Enter") {
                event.preventDefault();
                if (this.$refs.navBar.$refs?.mobileSearch) {
                    this.$refs.navBar.showSearch = true;
                }
                return this.$refs.navBar.$refs?.globalSearch?.$refs?.globalSearch?.focus();
            }
        },
    },
    computed: {
        showFab() {
            if (this.hasFab) return true;
            if (this.$page.component == "People") {
                if (this.$page.url.includes("staff")) {
                    return (
                        this.can("Manage staff") || this.selectedPeople.length
                    );
                }
                if (this.$page.url.includes("teachers")) {
                    return (
                        this.can("Manage teachers") ||
                        this.selectedPeople.length
                    );
                }
                if (this.$page.url.includes("students")) {
                    return (
                        this.can("Create students") ||
                        this.selectedPeople.length
                    );
                }
            }
            return false;
        },
    },
    mounted() {
        if (this.page == "/login") return;
        this.setViewHeight();
        window.addEventListener("resize", () => {
            this.setViewHeight();
        });

        this.subscribeToMessages();

        if (this.can("Work")) {
            window.addEventListener("keydown", this.macros);
            window.addEventListener("keyup", this.hideMacros);
        }
    },
    beforeDestroy() {
        if (this.can("Work")) {
            this.unsubscribeFromMessages();
            window.removeEventListener("keydown", this.macros);
            window.removeEventListener("keyup", this.hideMacros);
        }
    },
};
</script>

<style>
.safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
}

.safe-bottom-margin {
    margin-bottom: env(safe-area-inset-bottom);
}

.safe-top-margin {
    margin-top: env(safe-area-inset-top);
}

.v-application--wrap {
    min-height: -webkit-fill-available !important;
}

.no-bottom-padding {
    padding-bottom: 0px !important;
}

.v-data-table-header th {
    white-space: nowrap !important;
}

.store-fab {
    right: 412px !important;
}

.v-snack__wrapper {
    border-radius: 20px !important;
}
</style>
