<template>
    <div>
        <v-menu left v-model="menu" :disabled="!can('Override attendance')">
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-on="on"
                    v-bind="attrs"
                    small
                    outlined
                    class="soli-round"
                >
                    <div class="soli-grey caption">
                        {{ attendance.status ?? "Status" }}
                    </div>
                    <v-icon right small color="secondary">
                        arrow_drop_down_circle
                    </v-icon>
                </v-chip>
            </template>
            <v-list>
                <v-list-item
                    :disabled="
                        !can('Override attendance') ||
                        (attendance.lesson.group_event_id &&
                            option == 'Courtesy-cancel')
                    "
                    v-for="(option, idx) in options"
                    :key="idx"
                    :class="[
                        'soli-round',
                        attendance.status == option ? 'soli-status-blue' : null,
                    ]"
                    @click="adminUpdate(option, null)"
                >
                    <v-list-item-title
                        :class="
                            attendance.status == option ? 'white--text' : null
                        "
                        >{{ option }}</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu>

        <SoliChoice
            v-if="confirmCredit"
            title="Cancel-credit"
            :subtitle="`Cancel-credited lessons are unable to be altered. What would you like to do?`"
            @reset="
                closeModal(
                    () => ((confirmCredit = false), (creditSelection = null))
                )
            "
            button1="Confirm"
            button2="Nevermind"
            :disabled1="!creditSelection"
            @option1="creditSelection.confirm()"
            @hook:mounted="getCreditPricing()"
            ><slot>
                <v-container>
                    <v-select
                        v-model="creditSelection"
                        :items="
                            [
                                {
                                    text: 'Update Attendance Only',
                                    confirm: () =>
                                        adminUpdate('Cancel-credit', false),
                                },
                                {
                                    text: `Credit Account ${
                                        creditPricing ?? '(loading...)'
                                    }`,
                                    confirm: () =>
                                        adminUpdate('Cancel-credit', true),
                                },
                                {
                                    text: `Convert to ${
                                        attendance.lesson.group_event
                                            ? 30
                                            : attendance.lesson.duration
                                    } minute Drop-in`,
                                    hidden: !attendance.lesson.canConvertToPass,
                                    confirm: () =>
                                        adminUpdate('Cancel-credit', false, {
                                            name: 'Drop-In Lessons',
                                            duration: attendance.lesson
                                                .group_event
                                                ? 30
                                                : attendance.lesson.duration,
                                        }),
                                },
                                {
                                    text: `Convert to Accelerated Lab Guest Pass`,
                                    hidden: !attendance.lesson.group_event
                                        ?.can_exchange_passes,
                                    confirm: () =>
                                        adminUpdate('Cancel-credit', false, {
                                            name: 'Accelerated Lab Guest Pass',
                                            duration: 60,
                                        }),
                                },
                                {
                                    text: `Convert to Musicologie Junior Guest Pass`,
                                    hidden:
                                        attendance.lesson.group_event?.title !==
                                        'Musicologie Junior',
                                    confirm: () =>
                                        adminUpdate('Cancel-credit', false, {
                                            name: 'Musicologie Junior Guest Pass',
                                            duration: 40,
                                        }),
                                },
                            ].filter((e) => !e.hidden)
                        "
                        item-text="text"
                        return-object
                        hide-details
                        label="Credit Options"
                        outlined
                        dense
                /></v-container>
            </slot>
        </SoliChoice>
    </div>
</template>

<script>
import globalMoment from "../../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        attendance: {
            type: Object,
            default: () => {},
        },
        student: {
            type: Object,
            default: () => {},
        },
        errors: {},
    },
    data: function () {
        return {
            loading: false,
            confirmCredit: false,
            creditSelection: null,
            menu: false,
            showPaymentReminder: false,
            cancelEventChoice: false,
            creditPricing: null,
            options: [
                "Status",
                "Attended",
                "Early-cancel",
                "Late-cancel",
                "No-show",
                "Teacher-cancel",
                "Cancel-credit",
                "Courtesy-cancel",
            ],
        };
    },
    methods: {
        getCreditPricing() {
            axios
                .get(`/api/lessons/credit-pricing/${this.attendance.id}`)
                .then((resp) => {
                    this.creditPricing = this.formatUnitAmount(resp.data);
                });
        },
        adminUpdate(select, confirmed, convertToPass) {
            if (select == this.attendance.status) return;
            if (select == "Cancel-credit" && confirmed === null) {
                return (this.confirmCredit = true);
            }
            this.loading = true;
            let status = {
                student_id: this.student.id,
                attendance: select,
                cancel: this.attendance.lesson.group_event_id
                    ? this.attendance.lesson.cancelled
                    : select !== "Attended" && select !== "Status",
                admin: true,
                creditAccount: confirmed,
                convertToPass: convertToPass,
            };
            this.$inertia.put(`/lesson/${this.attendance.lesson.id}`, status, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                },
                onError: (errors) => {
                    console.log(JSON.stringify(errors));
                },
            });
        },
    },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
}
</style>
