<script setup>
import { ref, computed } from "vue";
import axios from "axios";
import { useVuetify } from "../../Composables/useVuetify.js";
import LessonDetailActions from "@/Components/Lessons/LessonDetailActions.vue";
import LessonDetailUser from "./LessonDetailUser.vue";
import LessonDetailPurchaseOption from "./LessonDetailPurchaseOption.vue";
import LessonDetailNote from "./LessonDetailNote.vue";
import LessonDetailEnrollment from "./LessonDetailEnrollment.vue";
import LessonDetailAttendanceList from "./LessonDetailAttendanceList.vue";
import ResponsiveModal from "../Modals/ResponsiveModal.vue";

// Composables

const breakpoint = useVuetify().breakpoint;

// Props

const props = defineProps({
    auth: {
        type: Object,
        required: true,
    },
    lessonId: {
        type: String,
        required: true,
    },
    errors: {
        type: Object,
        default: () => ({}),
    },
});

// Emits

const emit = defineEmits([
    "showMessage",
    "rescheduleLesson",
    "reset",
    "success",
    "refresh",
]);

// Reactive State

const responsiveModal = ref(null);
const loading = ref(false);
const lesson = ref({});
const capacity = ref(null);
const billingUser = ref({});
const lessonDetailContainer = ref(null);
const lessonDetailList = ref(null);
const expandCover = ref(false);
const showAttendanceList = ref(false);

// Computed

const getDetails = computed(() => {
    if (!lesson.value.id) return [];
    return [
        {
            icon: "storefront",
            text: `Musicologie ${lesson.value.location.name}`,
        },
        {
            icon: "schedule",
            text: `${moment(lesson.value.starts_at).format(
                "h:mma"
            )} to ${moment(lesson.value.ends_at).format("h:mma")} ${
                props.auth.user.timezone !== "America/New_York"
                    ? moment().tz(props.auth.user.timezone).format("z")
                    : ""
            }`,
        },
        {
            icon: "today",
            text: `${moment(lesson.value.starts_at).format("ll")} ${
                lesson.value.recurrence?.ends_at
                    ? "until " +
                      moment(lesson.value.recurrence?.ends_at).format("ll")
                    : ""
            }`,
        },
        {
            icon: "meeting_room",
            text: lesson.value.room.title,
        },
    ];
});

const getTitle = computed(() => {
    if (!lesson.value.id) return "";
    if (!!lesson.value?.group_event) {
        return lesson.value.group_event.title;
    }
    return `${lesson.value.online ? "Online " : ""}${
        lesson.value.instrument?.name ?? ""
    } ${lesson.type == "Weekly Lesson" ? "Lesson" : lesson.value.type}`;
});

/*const isOverflowing = computed(() => {
    if (!lessonDetailContainer.value || !lessonDetailList.value) return false;
    return (
        lessonDetailContainer.value.clientHeight <
        lessonDetailList.value.$el.scrollHeight
    );
});*/

const getPurchaseOption = computed(() => {
    return lesson.value.student?.attendance?.purchase_option ?? null;
});

const getPaymentStatus = computed(() => {
    if (!getPurchaseOption.value) return null;
    const option = getPurchaseOption.value;
    if (!option) return null;

    if (option.pastDue) return "Past Due";

    if (option.ends_at) {
        if (option.ends_at > moment(lesson.value.starts_at).format()) {
            return option.subPaid ? "Paid" : "Unpaid";
        }
        return "Unpaid";
    }

    if (option.category == "memberships") {
        return option.subPaid ? "Paid" : "Unpaid";
    }

    return option.paid ? "Paid" : "Unpaid";
});

// Methods

/**
 * Fetches billing user, if any
 * @todo this could be replaced in the backend
 */
function paidForBy(lessonResponse) {
    if (lessonResponse.group_event || !lessonResponse.students.length) {
        return;
    }

    const relation = lessonResponse.paidForBy;

    if (relation == lessonResponse.student.id) {
        billingUser.value = lessonResponse.student;
        return;
    }

    axios
        .get(`/api/users/contact/${relation}`, {
            params: {
                relation_id: lessonResponse.student.id,
            },
        })
        .then((resp) => (billingUser.value = resp.data))
        .catch((resp) => {
            console.log("Form returned errors: " + resp);
        });
}

function fetchLesson(refresh = false) {
    loading.value = true;
    axios
        .get(`/api/lessons/find/${props.lessonId}`)
        .then((resp) => {
            loading.value = false;
            lesson.value = resp.data;
            capacity.value = lesson.capacity;
            paidForBy(resp.data);
        })
        .catch((error) => {
            console.log(error);
        });
    if (refresh) emit("refresh");
}

function handleSuccess(closeComponent = false) {
    if (closeComponent) {
        responsiveModal.value.reset();
        emit("success");
        return;
    }
    emit("showMessage");
    fetchLesson();
}

/**
 * Refresh attendance notes
 * This will save a full fetchLesson call since SoliAttendance.vue is already fetching the data
 * @param data
 */
function refreshNotes(data) {
    if (!lesson.value) return;
    if (!lesson.value.student) return;
    lesson.value.student.attendance = data;
}

function overflow() {
    expandCover.value = !expandCover.value;
}

// Lifecycle Hooks

fetchLesson();

console.log(props.lessonId);
</script>

<template>
    <div class="lesson-detail">
        <ResponsiveModal
            ref="responsiveModal"
            :title="getTitle"
            :expandCover="expandCover"
            fullscreen
            color="accent"
            @reset="emit('reset')"
        >
            <template #subtitle>
                <v-list class="transparent lesson-detail-list pb-0" dense
                    ><v-list-item
                        v-for="item in getDetails"
                        :key="item.text"
                        class="lesson-detail-items soli-text-shadow"
                    >
                        <v-icon left class="white--text">{{ item.icon }}</v-icon
                        >{{ item.text }}</v-list-item
                    ></v-list
                >
            </template>
            <template #cover>
                <v-card light class="lesson-detail-card half-round w-full">
                    <v-container ref="lessonDetailContainer">
                        <div class="flex w-full justify-center">
                            <div
                                class="drag-handle"
                                @mousedown="overflow()"
                                @touchstart="overflow()"
                            />
                        </div>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto my-5"
                            max-width="300"
                            type="list-item-avatar, list-item, list-item"
                        ></v-skeleton-loader>

                        <LessonDetailActions
                            v-if="!loading"
                            :auth="auth"
                            :lesson="lesson"
                            :billingUser="billingUser"
                            :purchaseOption="getPurchaseOption"
                            :paymentStatus="getPaymentStatus"
                            @success="handleSuccess(true)"
                            @rescheduleLesson="emit('rescheduleLesson', $event)"
                        />

                        <v-list
                            v-if="!loading || lesson.id"
                            v-show="!loading"
                            ref="lessonDetailList"
                            class="divide-y"
                        >
                            <LessonDetailUser
                                v-if="!lesson.group_event && lesson.student"
                                :user="lesson.student"
                                :billingUser="billingUser"
                                large
                                title="Student"
                            />
                            <LessonDetailEnrollment
                                v-if="!!lesson.group_event"
                                :lesson="lesson"
                                @success="handleSuccess()"
                            />
                            <LessonDetailPurchaseOption
                                v-if="!lesson.group_event && lesson.student"
                                :purchaseOption="
                                    lesson.student.attendance.purchase_option
                                "
                                :paymentStatus="getPaymentStatus"
                            />
                            <LessonDetailUser
                                :user="lesson.teacher"
                                title="Teacher"
                            />
                            <LessonDetailNote
                                v-if="lesson.notes"
                                :content="lesson.notes"
                                :title="
                                    lesson.student
                                        ? `About ${lesson.student.first_name}`
                                        : 'Notes'
                                "
                                :hold="lesson.type === 'Hold'"
                            />
                        </v-list>
                    </v-container>
                </v-card>
            </template>
            <template #footer>
                <SoliAttendance
                    v-if="!loading && lesson.type !== 'Hold'"
                    :event="lesson"
                    :eventId="lessonId"
                    :studentId="lesson.student?.id"
                    :groupEvent="!!lesson.group_event"
                    :groupStatus="
                        lesson.group_event && lesson.cancelled
                            ? 'Teacher-cancel'
                            : null
                    "
                    :errors="errors"
                    @showStudentList="showAttendanceList = true"
                    @refresh="refreshNotes($event)"
                    @success="handleSuccess(!lesson.group_event)"
                />
            </template>
        </ResponsiveModal>
        <LessonDetailAttendanceList
            v-if="showAttendanceList"
            :lesson="lesson"
            subMenuModal
            @showMessage="emit('showMessage', 300)"
            @refresh="fetchLesson(true)"
            @reset="showAttendanceList = false"
        />
    </div>
</template>

<style scoped>
::v-deep .lesson-detail-items {
    min-height: 32px;
    padding-left: 0px;
}

.lesson-detail-list {
    min-height: 144px;
}

.lesson-detail-card {
    flex-grow: 1;
    display: flex;
    width: 100%;
    overflow-y: auto;
    margin-top: 0px;
}

.drag-handle {
    height: 4px;
    width: 48px;
    background-color: #9e9e9e;
    cursor: row-resize;
    border-radius: 20px;
}

.drag-handle::before {
    content: "";
    position: absolute;
    top: 0.5rem;
    width: 3rem;
    height: 0.75rem;
    background: transparent;
}
</style>
