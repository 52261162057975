<template>
    <v-btn
        :color="color"
        :disabled="trueDisabled"
        :outlined="outlined || active"
        :block="block"
        :loading="loading"
        :max-height="dense ? size : null"
        :ripple="ripple"
        :inputValue="active"
        text
        :class="[
            'avatar-btn px-0 my-1',
            ($vuetify.breakpoint.width >= 375 && (avatar || icon)) ||
            !autoHideImage
                ? 'justify-start'
                : 'justify-center',
            disabled ? 'custom-btn' : null,
            containBtn ? 'contain-btn' : null,
        ]"
        class="image"
        @click.native="$emit('click', $event), $emit('input', id)"
    >
        <v-list-item-avatar
            v-if="
                ($vuetify.breakpoint.width >= 375 && (avatar || icon)) ||
                !autoHideImage
            "
            :size="size"
            :class="{
                'blue-avatar-border': blue,
                'shadow-blue': blueShadow,
                'mr-6': largeText,
            }"
        >
            <v-icon v-if="icon" large>{{ icon }}</v-icon>
            <v-img v-if="avatar" :src="avatar" :size="size" />
        </v-list-item-avatar>

        <div class="overflow-hidden justify-items-start">
            <v-list-item-subtitle
                v-if="subtitleTop"
                class="flex"
                style="text-transform: none"
                :class="[
                    $vuetify.breakpoint.width < 375 && autoHideImage
                        ? 'justify-center'
                        : 'justify-start',
                    largeText ? 'subtitle-1' : null,
                ]"
                >{{ subtitleTop }}
            </v-list-item-subtitle>

            <v-list-item-title
                v-if="title"
                :class="[
                    'font-bold align-center normal-case max-w-full',
                    $vuetify.breakpoint.width >= 375 || !autoHideImage
                        ? 'flex'
                        : null,
                    largeText ? 'text-h6 font-weight-bold short-line' : null,
                ]"
                >{{ title }}
                <small
                    class="primary--text flex align-center px-2 underline caption"
                    :class="{
                        'justify-center':
                            $vuetify.breakpoint.width < 375 || !autoHideImage,
                    }"
                    style="cursor: pointer"
                    v-if="moreInfo"
                    >More Info</small
                >
            </v-list-item-title>

            <v-list-item-subtitle
                v-if="subtitleBottom"
                :class="[
                    $vuetify.breakpoint.width < 375 && autoHideImage
                        ? 'justify-center'
                        : 'justify-start',
                    largeText ? 'subtitle-1' : null,
                ]"
                class="normal-case max-w-full"
            >
                {{ subtitleBottom }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
                v-if="subtitleBottom2"
                :class="
                    $vuetify.breakpoint.width < 375 && autoHideImage
                        ? 'justify-center'
                        : 'justify-start'
                "
                class="normal-case max-w-full"
            >
                {{ subtitleBottom2 }}
            </v-list-item-subtitle>
            <slot></slot>
        </div>
        <v-icon
            v-if="append"
            class="right-0 px-5"
            :class="{ flip: value }"
            style="position: absolute"
            color="dark"
            >{{ append }}</v-icon
        >
    </v-btn>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null,
        },
        avatar: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        append: {
            type: String,
            default: null,
        },
        value: {},
        title: {
            type: String,
            default: null,
        },
        subtitleTop: {
            type: String,
            default: null,
        },
        subtitleBottom: {
            type: String,
            default: null,
        },
        subtitleBottom2: {
            type: String,
            default: null,
        },
        blue: {
            type: Boolean,
            default: false,
        },
        trueDisabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        ripple: {
            type: Boolean,
            default: true,
        },
        largeText: {
            type: Boolean,
            default: false,
        },
        blueShadow: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        moreInfo: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        block: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "dark",
        },
        size: {
            type: [String, Number],
            default: 64,
        },
        containBtn: {
            type: Boolean,
            default: false,
        },
        hideBorder: {
            type: Boolean,
            default: false,
        },
        autoHideImage: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.v-btn--outlined.custom-btn {
    border: none !important;
}

.custom-btn {
    cursor: default !important;
}

.custom-btn::before {
    background-color: transparent !important;
}

.contain-btn {
    flex: auto;
    overflow: hidden;
}

.short-line {
    line-height: 20px;
}

.flip {
    transform: rotate(180deg) !important;
}

::v-deep .v-btn__content {
    max-width: 100%;
}
</style>
