<template>
    <component
        :is="componentStyle.comp"
        v-bind="{ ...componentStyle.props }"
        v-on="componentStyle.on"
        v-model="dialog"
    >
        <v-container
            class="px-5 pt-5 pb-0"
            v-if="selectionChips.length && relative"
        >
            <v-card-subtitle class="py-0" style="height: 40px"
                ><v-chip-group column
                    ><v-chip
                        outlined
                        v-for="chip in selectionChips"
                        :key="chip.name"
                        close
                        @click:close="$emit('findChip', chip)"
                        elevated
                        color="accent shadow-blue"
                        :ripple="false"
                        class="soli-grey mr-1 animate__animated"
                        :class="chipAnimation ?? null"
                        @click="$emit('findChip', chip)"
                        >{{ chip.selection.data() }}</v-chip
                    ></v-chip-group
                ></v-card-subtitle
            ></v-container
        >
        <v-card
            :class="[relative ? 'v-card--flat soli-round' : 'v-card--tile']"
        >
            <!-- With image -->
            <v-img
                v-if="lessonDetails || imgSrc"
                :src="imgSrc"
                :height="subtitle ? '212px' : '200px'"
                :class="[
                    !subtitle ? 'align-end' : null,
                    !imgSrc ? 'soli-blue' : null,
                ]"
                :gradient="
                    imgSrc
                        ? 'to top right, rgba(76, 135, 201, 1), rgba(123, 177, 224, 0.1)'
                        : null
                "
                :position="imgPosition"
                class="soli-text-shadow"
            >
                <v-btn
                    icon
                    v-if="!permanent"
                    @click="reset()"
                    class="ma-3 soli-close white--text soli-text-shadow"
                    ><v-icon>clear</v-icon></v-btn
                >
                <v-card-title
                    class="white--text ml-4 font-bold soli-text-shadow"
                >
                    {{ title }}
                </v-card-title>
                <v-card-subtitle v-if="subtitle" class="white--text"
                    ><v-list class="transparent" dense
                        ><v-list-item
                            class="theme--dark"
                            v-for="item in subtitle"
                            :key="item.text"
                        >
                            <v-icon class="white--text mr-2">{{
                                item.icon
                            }}</v-icon
                            >{{ item.text }}</v-list-item
                        ></v-list
                    ></v-card-subtitle
                >
            </v-img>
            <!-- Without Image -->
            <div
                ref="screenCapture"
                :class="{
                    white: screenCapture,
                }"
                class="screen-capture"
            >
                <slot name="header"></slot>

                <div
                    v-if="!lessonDetails && !imgSrc && !relative"
                    :class="[
                        'pl-3',
                        cardBody
                            ? 'card-body-min soli-blue pb-2'
                            : 'transparent',
                        lowTitle ? 'flex align-end' : null,
                        relative ? 'half-round' : null,
                    ]"
                >
                    <v-btn
                        v-if="!screenCapture && !permanent"
                        icon
                        @click="reset"
                        style="z-index: 999 !important"
                        :class="[
                            'ma-3 soli-close',
                            cardBody ? 'white--text soli-text-shadow' : 'white',
                        ]"
                        ><v-icon>clear</v-icon></v-btn
                    >
                    <v-card-title
                        :class="[
                            'font-bold animate__animated',
                            cardBody
                                ? 'white--text soli-text-shadow'
                                : 'soli-grey',
                            titleAnimation,
                        ]"
                    >
                        {{ title }}
                    </v-card-title>
                    <v-card-subtitle
                        v-if="textSubtitle"
                        :class="[
                            'pl-4',
                            cardBody ? 'soli-grey-text' : 'soli-grey',
                            limitSubtitle ? 'limit-subtitle pb-0 mb-4' : 'pb-3',
                        ]"
                        >{{ textSubtitle }}</v-card-subtitle
                    >
                    <v-card-subtitle
                        v-if="$slots.subtitle"
                        :class="{ 'pb-0': !!$slots.tabs }"
                    >
                        <slot name="subtitle"></slot>
                    </v-card-subtitle>
                    <slot name="tabs"></slot>
                    <v-card-subtitle
                        v-if="selectionText.length"
                        :class="['px-4 pb-0', cardBody ? 'white--text' : null]"
                        ><v-chip-group column
                            ><div
                                v-for="(text, idx) in selectionText"
                                :key="text.name"
                                @click="$emit('findText', text.name)"
                                class="animate__animated"
                                :class="textAnimation"
                            >
                                {{
                                    `${text.selection.data()}${
                                        idx == selectionText.length - 1
                                            ? ""
                                            : "\u00A0·\u00A0"
                                    }`
                                }}
                            </div></v-chip-group
                        ></v-card-subtitle
                    >
                    <v-card-subtitle
                        v-if="selectionChips.length"
                        :class="['pl-3 py-0', cardBody ? 'white--text' : null]"
                        ><v-chip-group column
                            ><v-chip
                                outlined
                                v-for="chip in selectionChips"
                                :key="chip.name"
                                v-show="!chip.selection.hide"
                                small
                                color="white"
                                :ripple="false"
                                class="soli-grey mr-1 animate__animated"
                                :class="chipAnimation ?? null"
                                @click="$emit('findChip', chip)"
                                >{{ chip.selection.data() }}</v-chip
                            ></v-chip-group
                        ></v-card-subtitle
                    >
                </div>
                <v-form
                    v-model="valid"
                    @submit.prevent="submitForm"
                    :disabled="disabled"
                    ref="form"
                    :class="[
                        cardBody ? 'soli-blue' : null,
                        relative ? 'soli-round' : null,
                    ]"
                >
                    <v-container
                        :class="[
                            limitScroll
                                ? 'limit-scroll'
                                : limitScroll != null
                                ? 'shrink-limit-scroll'
                                : null,
                            mobileScroll ? 'mobile-scroll' : null,
                            cardBody ? 'card-body' : null,
                            noContainer ? 'pa-0' : null,
                            !showCancelBtn && !hideBtn && !isSignature
                                ? 'py-0'
                                : null,
                        ]"
                        class="main-container"
                    >
                        <div :class="subtitle ? '' : 'px-3'">
                            <!-- Location -->
                            <slot name="body" />
                            <p v-if="error" class="caption red--text px-3">
                                {{ error.form }}
                            </p>
                        </div>
                    </v-container>
                </v-form>
            </div>

            <!-- Submit -->
            <!-- TODO Move this container and button to inside of the form container. use prop to pick which submit button to display. inside container if desktop, outside container if mobile-->
            <v-container
                v-if="!hideButtonContainer"
                :class="[
                    extraBtn ? 'pb-5' : null,
                    showCancelBtn ? 'py-5 px-5' : 'py-0',
                    fixedBtn ? 'py-5' : 'py-0',
                    hideBtn ? 'py-0' : 'py-5 px-5',
                    mobileScroll || relative ? 'pt-0' : null,
                    fullscreen ? 'mobile-fixed-btn' : null,
                ]"
                style="background: white"
            >
                <div
                    style="
                        padding-bottom: env(safe-area-inset-bottom) !important;
                    "
                >
                    <slot name="extraBtn" block></slot>

                    <SoliBtn
                        v-if="!hideBtn && !choices"
                        :gradient="gradient"
                        type="submit"
                        :text="text"
                        :icon="icon"
                        block
                        :disabled="!saveChanges"
                        @click="submitForm"
                        :loading="isLoading"
                    ></SoliBtn>
                    <div v-if="choices" class="flex flex-col gap-4">
                        <v-divider />
                        <div class="flex justify-end">
                            <v-btn
                                class="soli-round"
                                color="error"
                                text
                                @click="reset()"
                            >
                                Nevermind
                            </v-btn>
                            <v-btn
                                :loading="loading"
                                class="soli-round"
                                color="primary"
                                :disabled="!saveChanges"
                                text
                                @click="submitForm"
                            >
                                Continue
                            </v-btn>
                        </div>
                    </div>
                    <slot name="cancelBtn" block></slot>
                </div>
            </v-container>
            <slot fixed block large name="footer" />
        </v-card>
    </component>
</template>

<script>
import SoliBtn from "./SoliBtn";

let formRules = {
    input: [(val) => (val || "").length > 0 || "This field is required"],
    select: [(v) => !!v || v === 0 || "Item is required"],
    email: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: [
        (v) => !!v || "Phone number is required",
        (v) =>
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Phone number must be 10 digits",
    ],
    zipCode: [
        (v) => !!v || "Zip code is required",
        (v) => /^\(?([0-9]{5})$/.test(v) || "Zip code must be 5 digits",
    ],
    phoneNotRequired: [
        (v) =>
            !(v || "").length ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v)
                ? true
                : "Phone number must be 10 digits",
    ],
    zipCodeNotRequired: [
        (v) =>
            !(v || "").length || /^\(?([0-9]{5})$/.test(v)
                ? true
                : "Zip code must be 5 digits",
    ],
    textArea: [(v) => (v || "").length <= 1024 || "Character limit reached"],
};

import { VDialog, VCard } from "vuetify/lib";

export default {
    formRules,
    name: "SoliForm",
    components: { VDialog, VCard },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Title",
        },
        subtitle: {
            type: Array,
            default: null,
        },
        textSubtitle: {
            type: String,
            default: null,
        },
        gradient: {
            type: String,
            default: "gradient-orange",
        },
        lessonDetails: {
            type: Boolean,
            default: false,
        },
        imgSrc: {
            type: String,
            default: null,
        },
        imgPosition: {
            type: String,
            default: "top center",
        },
        error: {
            type: [Object, String, Array],
            default: "",
        },
        text: {
            type: String,
        },
        icon: {
            type: String,
        },
        color: {
            type: String,
            default: "primary",
        },
        hideBtn: {
            type: Boolean,
            default: false,
        },
        extraBtn: {
            type: Boolean,
            default: false,
        },
        showCancelBtn: {
            type: Boolean,
            default: false,
        },
        eager: {
            type: Boolean,
            default: false,
        },
        limitScroll: {
            type: Boolean,
            default: null,
        },
        mobileScroll: {
            type: Boolean,
            default: false,
        },
        fixedBtn: {
            type: Boolean,
            default: false,
        },
        reschedule: {
            type: Boolean,
            default: false,
        },
        cardBody: {
            type: Boolean,
            default: false,
        },
        noContainer: {
            type: Boolean,
            default: false,
        },
        lowTitle: {
            type: Boolean,
            default: false,
        },
        disabledChips: {
            type: Array,
            default: null,
        },
        saveChanges: {
            type: Boolean,
            default: true,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        lineTwo: {
            type: String,
            default: null,
        },
        large: {
            type: Boolean,
            default: false,
        },
        screenCapture: {
            type: Boolean,
            default: false,
        },
        permanent: {
            type: Boolean,
            default: false,
        },
        loadingProp: {
            type: Boolean,
            default: null,
        },
        selection: {
            type: Array,
            default: () => [],
        },
        hideButtonContainer: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        relative: {
            type: Boolean,
            default: false,
        },
        peek: {
            type: Boolean,
            default: false,
        },
        limitSubtitle: {
            type: Boolean,
            default: false,
        },
        retainFocus: {
            type: Boolean,
            default: true,
        },
        choices: {
            type: Boolean,
            default: false,
        },
        isSignature: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            dialog: null,
            loading: false,
            titleAnimation: null,
            chipAnimation: null,
            textAnimation: null,
            valid: false,
        };
    },
    methods: {
        submitForm(data) {
            if (this.$refs.form.validate()) {
                this.$nextTick(() => {
                    this.$emit("submit", data);
                    this.loading = true;
                });

                //this.reset();
            }
        },
        reset() {
            this.dialog = false;
            this.loading = false;
            this.$page.props.errors = {};

            setTimeout(() => {
                //this.$refs.form.resetValidation();
                if (this.$refs.form) this.$refs.form.reset();
                this.$emit("reset");
                this.$emit("dialogClose");
            }, 500);
        },
    },
    computed: {
        componentStyle() {
            return {
                comp: this.relative ? "v-card" : "v-dialog",
                on: {
                    "click:outside": (e) => {
                        !this.persistent ? this.reset() : null;
                    },
                    "keydown.enter": () => {
                        this.submitForm;
                    },
                },
                props: this.relative
                    ? {
                          class: "soli-round mx-1",
                          flat: this.flat,
                          retainFocus: this.retainFocus,
                      }
                    : {
                          eager: this.eager,
                          fullscreen: this.fullscreen,
                          "max-width": this.large ? 648 : 425,
                          persistent: this.persistent,
                          "content-class": this.fullscreen
                              ? ("safe-bottom",
                                this.peek ? "half-round peek" : null)
                              : "soli-round white",
                          transition: this.$vuetify.breakpoint.smAndDown
                              ? "dialog-bottom-transition"
                              : "dialog-transition",
                          "hide-overlay": this.flat,
                          retainFocus: this.retainFocus,
                      },
            };
        },
        fullscreen() {
            if (this.relative) return false;
            return this.$vuetify.breakpoint.xs;
        },
        isLoading() {
            return this.loadingProp !== null ? this.loadingProp : this.loading;
        },
        selectionChips() {
            return this.selection.filter((e) => !e.base);
        },
        selectionText() {
            return this.selection.filter((e) => e.base && !e.selection.hide);
        },
    },
    mounted() {
        this.dialog = true;
    },
    watch: {
        lowTitle(newVal, oldVal) {
            if (newVal == oldVal) return;
            this.titleAnimation = newVal
                ? "animate__slideInDown"
                : "animate__slideInUp";
            this.chipAnimation = newVal
                ? "animate__slideInDown delayAnimate"
                : "animate__slideInUp delayAnimate";
            this.textAnimation = newVal
                ? "animate__fadeOut"
                : "animate__fadeIn";
        },
        valid(val) {
            this.$emit("input", val);
        },
    },
};
</script>

<style scoped>
.v-card {
    border: none !important;
}

.photo-overlay {
    background: rgba(123, 177, 224, 0.7);
    border-radius: 20px 20px 0px 0px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
    min-height: 34px;
}

.mobile-fixed-btn {
    position: fixed;
    bottom: 0px;
}

.limit-scroll {
    max-height: 554px;
    min-height: 554px;
    overflow-y: auto;
    transition: min-height 0.3s ease-out;
}

.shrink-limit-scroll {
    min-height: 100px;
    transition: min-height 0.3s ease-out;
}

.card-body {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: white;
    z-index: 10;
}

@media screen and (max-width: 600px) {
    .card-body {
        min-height: -webkit-fill-available;
    }
}

.card-body-min {
    min-height: 142px;
    position: sticky;
    top: 0px;
    z-index: 100;
}

.v-card__title.font-bold {
    font-weight: 600 !important;
}

.v-dialog__content--active {
    backdrop-filter: blur(1px);
}

.animate__animated.animate__fadeIn {
    --animate-duration: 0.3s;
}

.animate__animated.animate__fadeOut {
    --animate-duration: 0.3s;
}

.animate__animated.animate__slideInUp {
    --animate-duration: 0.1s;
}

.animate__animated.animate__slideInRight {
    --animate-duration: 0.1s;
}

.animate__animated.animate__slideInDown {
    --animate-duration: 0.1s;
}

.limit-subtitle {
    max-height: 4rem;
    overflow: auto;
}
</style>
