<template>
    <v-app class="min-h-screen">
        <v-app-bar
            app
            color="white"
            height="64"
            elevate-on-scroll
            :class="$vuetify.breakpoint.smAndUp ? 'px-6' : null"
        >
            <a href="https://musicologielessons.com">
                <jet-application-logo
                    class="block w-auto"
                    :class="$vuetify.breakpoint.xs ? 'h-6' : 'h-9'"
            /></a>
            <v-spacer />
            <div class="flex gap-2 justify-end">
                <v-btn
                    outlined
                    href="/get-started"
                    color="primary"
                    class="white--text soli-round"
                    >Get Started</v-btn
                >
                <SoliBtn
                    gradient="gradient-orange"
                    href="/login"
                    style="color: white !important"
                    >Login</SoliBtn
                >
            </div>
        </v-app-bar>

        <v-main>
            <v-container
                class="text-start"
                :class="$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-4'"
                fluid
            >
                <v-row>
                    <v-col>
                        <h2 class="text-h4 font-weight-bold mb-3 soli-grey">
                            Privacy Policy
                        </h2>
                        <div class="text-body-1 mb-4">
                            At Musicologie we are committed to your privacy.
                            This page discloses the information gathering and
                            dissemination practices for the website
                            musicologie.app
                        </div>

                        <h2 class="text-h5 font-weight-bold mb-3 soli-grey">
                            Personal Information
                        </h2>
                        <div class="text-body-1 mb-4">
                            When you fill out an inquiry form, we ask for your
                            name, email address and phone number. Your email and
                            any other information will only be used to contact
                            you about lessons or classes with us. Your personal
                            information will not be distributed to anyone for
                            any purpose.
                        </div>

                        <h2 class="text-h5 font-weight-bold mb-3 soli-grey">
                            Security
                        </h2>
                        <div class="text-body-1 mb-4">
                            This site has security measures in place to protect
                            the loss, misuse, and alteration of the information
                            under our control.
                        </div>

                        <h2 class="text-h5 font-weight-bold mb-3 soli-grey">
                            Information Automatically Logged
                        </h2>
                        <div class="text-body-1 mb-4">
                            This website uses various web analytics tools (such
                            as Google Analytics) and other measurement tools to
                            help analyze how users use the site. These tools use
                            ‘cookies’, which are text files placed on your
                            computer, to collect standard internet log
                            information and visitor behavior information in an
                            anonymous form. The information generated by the
                            cookie about your use of the website (including your
                            IP address) is transmitted to Google and sometimes
                            other vendors. This information is then used to
                            evaluate visitors use of the website and to compile
                            statistical reports on website activity for
                            yoast.com. <br /><br />
                            We will never (and will not allow any third party)
                            use the statistical analytics tool to track or to
                            collect any personally identifiable information of
                            visitors to our site. The Web Analytics vendors do
                            not associate your IP address with any other data
                            held by them. Neither we nor the Web Analytics
                            Vendors will link, or seek to link, an IP address
                            with the identity of a computer user. We will not
                            associate any data gathered from this site with any
                            personally identifying information from any source,
                            unless you explicitly submit that information via a
                            fill-in form on our website.
                        </div>

                        <h2 class="text-h5 font-weight-bold mb-3 soli-grey">
                            Disabling and enabling cookies
                        </h2>
                        <div class="text-body-1 mb-4">
                            You have the ability to accept or decline cookies by
                            modifying the settings on your browser.
                        </div>

                        <h2 class="text-h5 font-weight-bold mb-3 soli-grey">
                            Contacting Us
                        </h2>
                        <div class="text-body-1 mb-4">
                            If you have any questions about this privacy
                            statement, the practices of this site, or your
                            dealings with this web site, you can contact us at
                            info@musicologielessons.com
                        </div></v-col
                    ></v-row
                >
            </v-container>
        </v-main>

        <SiteFooter />
    </v-app>
</template>

<script>
import JetApplicationLogo from "./../Jetstream/ApplicationLogo";
import SiteFooter from "../Layouts/Components/SiteFooter.vue";

export default {
    components: {
        JetApplicationLogo,
        SiteFooter,
    },
};
</script>

<style scoped>
.v-application a {
    color: #2aabe6 !important;
}

.v-application .display-2 {
    font-family: "Nunito", sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.v-application .display-1 {
    font-family: "Nunito", sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.v-application {
    font-family: "Nunito", sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji" !important;
} /*
.v-application .headline {
  font-family: Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}*/

/*.v-application {
  font-family: "Nunito", sans-serif !important;
}*/
.v-application .primary--text {
    color: #228cbd !important;
    caret-color: #228cbd !important;
}
@media (min-width: 1264px) {
    .v-application .text-lg-h4 {
        font-size: 1.8rem !important;
        line-height: 1.8rem;
        letter-spacing: 0.0073529412em !important;
    }
}
</style>
