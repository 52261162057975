<template>
    <div>
        <v-card class="soli-round">
            <v-container>
                <v-card-title class="soli-grey pb-0">
                    Contact History
                </v-card-title>
                <v-tabs v-model="tab" class="px-4" dense>
                    <v-tab>Received</v-tab>
                    <v-tab>Sent</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-container>
                            <v-skeleton-loader
                                type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
                                class="mb-6"
                                boilerplate
                                v-if="!receivedHistory"
                            ></v-skeleton-loader>
                            <v-simple-table>
                                <tbody>
                                    <tr
                                        v-for="item in receivedHistory"
                                        :key="item.id"
                                    >
                                        <td>
                                            {{
                                                globalMomentDateAtTimeDots(
                                                    item.created_at
                                                )
                                            }}
                                        </td>
                                        <td
                                            :class="
                                                item.properties.message_url
                                                    ? 'soli-link'
                                                    : null
                                            "
                                            v-on:click="
                                                fetchHtmlContent(
                                                    item.properties.message_url
                                                )
                                            "
                                        >
                                            {{ item.properties.subject }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table></v-container
                        >
                    </v-tab-item>
                    <v-tab-item>
                        <v-container>
                            <v-skeleton-loader
                                type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
                                class="mb-6"
                                boilerplate
                                v-if="!sentHistory"
                            ></v-skeleton-loader>
                            <v-simple-table>
                                <tbody>
                                    <tr
                                        v-for="item in sentHistory"
                                        :key="item.id"
                                    >
                                        <td>
                                            {{
                                                globalMomentDateAtTimeDots(
                                                    item.created_at
                                                )
                                            }}
                                        </td>
                                        <td
                                            :class="
                                                item.properties.message_url
                                                    ? 'soli-link'
                                                    : null
                                            "
                                            v-on:click="
                                                fetchHtmlContent(
                                                    item.properties.message_url,
                                                    item.properties
                                                        .copied_recipients
                                                )
                                            "
                                        >
                                            {{ item.properties.subject }}
                                        </td>
                                        <td class="sent-recipients">
                                            {{
                                                item.properties
                                                    .copied_recipients
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table></v-container
                        >
                    </v-tab-item>
                </v-tabs-items>
            </v-container></v-card
        >
        <SoliForm
            v-if="openMail"
            ref="mail"
            :textSubtitle="
                openMail.recipients
                    ? `Recipients: ${openMail.recipients}`
                    : null
            "
            :saveChanges="false"
            title="Preview Message"
            large
            :noContainer="!loading"
            :subtitle="loading ? null : []"
            hideBtn
            @reset="closeMail"
            ><template #body>
                <div
                    v-if="!loading"
                    v-dompurify-html="htmlContent"
                    class="h-full w-full overflow-auto relative custom-html-content"
                />
                <v-skeleton-loader v-else type="image" /> </template
        ></SoliForm>
    </div>
</template>

<script>
import axios from "axios";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    props: {
        receivedHistory: {
            type: Array,
            default: () => [],
        },
        sentHistory: {
            type: Array,
            default: () => [],
        },
        user: {
            type: Object,
            default: () => {},
        },
    },
    mixins: [globalMoment],
    data: () => ({
        tab: 0,
        openMail: null,
        htmlContent: "",
        loading: false,
    }),
    methods: {
        async fetchHtmlContent(path, recipients) {
            this.loading = true;
            this.openMail = {
                path: path,
                recipients: recipients,
            };
            try {
                path = path.includes(".com") ? path.split("com/")[1] : path;
                const response = await axios.get(`/api/render-mail/${path}`, {
                    headers: {
                        Accept: "text/html",
                    },
                    responseType: "text",
                });
                this.htmlContent = response.data;
                this.loading = false;
            } catch (error) {
                console.error("Error fetching HTML content:", error);
            }
        },
        closeMail() {
            this.$refs.mail.dialog = false;
            setTimeout(() => {
                this.openMail = null;
            }, 300);
        },
    },
    mounted() {
        if (this.$page.url.includes("#sent")) this.tab = 1;
    },
};
</script>

<style>
.custom-html-content td * {
    border: none;
}

.sent-recipients {
    width: 120px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
