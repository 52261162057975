<template>
    <v-card-text>
        <div
            v-for="(item, idx) in items"
            :key="idx"
            class="body-1 indent"
            :class="{ 'numbered-indent': numberedList, 'mb-3': spaceBetween }"
            v-html="item"
        ></div>
    </v-card-text>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        spaceBetween: {
            type: Boolean,
            default: false,
        },
        numberedList: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
.indent {
    text-indent: -8px;
}
.numbered-indent {
    text-indent: -19px;
}
</style>
