<template>
    <AppLayout
        page="/inbox"
        pageTitle="Inbox"
        :headerButton="
            can('Create conversations') ? { icon: 'edit_note' } : null
        "
        @headerButton="
            [
                $inertia.visit(route('conversation.show'), {
                    preserveState: true,
                    only: [
                        'conversation',
                        'messages',
                        'newMessageAvailable',
                        'messageCount',
                    ],
                }),
                (selectedConversation = { create: true }),
            ]
        "
        @messageReceived="messageReceived"
        @reload="
            $inertia.reload({
                preserveState: true,
                only: [
                    'newMessageAvailable',
                    'conversation',
                    'messages',
                    'unreadMessageCount',
                    'index',
                    'messageCount',
                ],
            })
        "
    >
        <v-container
            :fluid="$page.props.staffView"
            class="h-full py-0 overflow-hidden"
        >
            <v-row class="h-full">
                <v-col cols="12" :md="4" class="flex">
                    <ConversationIndex
                        id="chat-index"
                        :index="index"
                        v-model="selectedConversation"
                        showSearch
                    />
                </v-col>

                <v-col
                    v-if="selectedConversation"
                    id="chat-window"
                    cols="12"
                    :md="8"
                    class="px-0 pb-0"
                >
                    <ConversationShow
                        ref="conversationShow"
                        :conversation="selectedConversation"
                        :messages="selectedConversation?.create ? [] : messages"
                        :messageCount="messageCount"
                        :errors="errors"
                        :key="selectedConversation?.id"
                        :newMessageAvailable="newMessageAvailable"
                        @reset="closeModal(() => (selectedConversation = null))"
                    />
                </v-col>

                <v-col
                    v-if="!selectedConversation && $vuetify.breakpoint.mdAndUp"
                    cols="12"
                    :md="8"
                    class="flex justify-center align-center"
                >
                    <v-list class="text-align-center">
                        <v-icon x-large class="my-2" color="accent"
                            >chat_bubble_outline</v-icon
                        >
                        <v-list-item-title>No chat selected</v-list-item-title>
                        <v-list-item-subtitle
                            >Please select a conversation</v-list-item-subtitle
                        >
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </AppLayout>
</template>

<script>
import AppLayout from "../Layouts/AppLayout.vue";
import ConversationShow from "../Components/Conversation/ConversationShow.vue";
import ConversationIndex from "../Components/Conversation/ConversationIndex.vue";
import SoliResponsiveDialog from "../Components/Base/SoliResponsiveDialog.vue";
import axios from "axios";

export default {
    components: {
        AppLayout,
        ConversationShow,
        ConversationIndex,
        SoliResponsiveDialog,
    },
    props: {
        index: {
            type: Array,
            default: () => [],
        },
        conversation: {
            type: Object,
            default: () => {},
        },
        messages: {
            type: Array,
            default: () => [],
        },
        newMessageAvailable: {
            type: Boolean,
            default: false,
        },
        messageCount: {
            type: Number,
            default: 0,
        },
        errors: {
            type: Object,
            default: () => {},
        },
    },
    data: function () {
        return {
            search: "",
            selectedConversation:
                this.index.find((e) => e.id == this.conversation?.id) ?? null,
        };
    },
    methods: {
        messageReceived(message) {
            this.$refs.conversationShow?.messageReceived(message);
        },
    },
};
</script>

<style>
#chat-window {
    height: 100%;
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
}

#chat-index {
    height: 100%;
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
</style>
