<template>
    <AppLayout
        page="/dashboard"
        pageTitle="Payroll"
        backLink="/dashboard/reports"
    >
        <v-col cols="12" class="pa-1">
            <Stats class="pb-3" :stats="stats" />
            <v-card class="soli-round">
                <v-container class="px-3" fluid>
                    <ReportHeader
                        filter
                        :legacyDate="payDay"
                        :dateRange="dateRange"
                        :rangeOptions="{
                            interval:
                                $page.props.auth.user.selected_location[
                                    'pay_int'
                                ],
                            frequency:
                                $page.props.auth.user.selected_location[
                                    'pay_freq'
                                ],
                        }"
                        subtitle="Earnings by Pay Period"
                        reportUrl="/dashboard/reports/payroll/"
                        @search="search = $event"
                    >
                        <template #extendedSubtitle>
                            <v-list-item-subtitle
                                v-if="isProcessed"
                                class="font-bold error--text"
                                style="white-space: normal"
                                ><v-icon left small color="error">flag</v-icon
                                >{{
                                    `Processed on ${globalMomentFullDateAtTime(
                                        processed_at
                                    )}`
                                }}</v-list-item-subtitle
                            >
                        </template>
                        <template #filterItem>
                            <v-list dense>
                                <v-list-item>
                                    <v-btn
                                        :href="`${$page.url}?csv=true`"
                                        text
                                        outlined
                                        rounded
                                        block
                                        color="dark"
                                        >Download as CSV<v-icon small right
                                            >download</v-icon
                                        ></v-btn
                                    >
                                </v-list-item>
                                <v-list-item class="flex justify-center pt-1">
                                    <v-switch
                                        dense
                                        class="mt-0"
                                        @change="
                                            groupByStaff
                                                ? (groupByStaff = null)
                                                : (groupByStaff = 'staff_id')
                                        "
                                        :label="`Group by Employee`"
                                        hide-details
                                /></v-list-item>
                            </v-list>
                        </template>
                    </ReportHeader>
                    <v-data-table
                        ref="table"
                        :headers="headers"
                        :items="report"
                        :search="search"
                        hide-default-footer
                        disable-pagination
                        mobile-breakpoint="0"
                        item-key="rate_id"
                        class="table-nowrap"
                        show-expand
                        :expanded.sync="expanded"
                        :group-by="groupByStaff"
                        @click:row="$log($event.rate_id)"
                    >
                        <template
                            v-slot:[`group.header`]="{ items, isOpen, toggle }"
                        >
                            <th :colspan="headers.length">
                                <div class="flex font-weight-regular">
                                    <div
                                        class="flex-col align-self-center mr-2"
                                    >
                                        <v-icon @click="toggle"
                                            >{{ isOpen ? "remove" : "add" }}
                                        </v-icon>
                                    </div>
                                    <SoliListAvatar
                                        :avatar="
                                            items[0].user.profile_photo_url
                                        "
                                        :title="items[0].user.name"
                                        :subtitle="items[0].user.email"
                                        :href="`/profile/${items[0].staff_id}/manage`"
                                    />
                                </div>
                            </th>
                        </template>
                        <template v-slot:[`group.summary`]="{ items }">
                            <div :colspan="headers.length">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-subtitle
                                            class="font-weight-black soli-grey"
                                        >
                                            {{
                                                `Total Pay: ${formatCurrency(
                                                    items
                                                        .map((e) =>
                                                            Number(e.total_pay)
                                                        )
                                                        .reduce((a, b) => a + b)
                                                )}`
                                            }}</v-list-item-subtitle
                                        >
                                    </v-list-item>
                                </v-list>
                            </div>
                        </template>
                        <template v-slot:[`item.user.name`]="{ item }">
                            <SoliListAvatar
                                v-if="!groupByStaff"
                                :avatar="item.user.profile_photo_url"
                                :title="item.user.name"
                                :subtitle="item.user.email"
                                :href="`/profile/${item.staff_id}/manage`"
                            />
                        </template>
                        <template v-slot:[`item.total_pay`]="{ item }">
                            {{ formatCurrency(item.total_pay) }}
                        </template>
                        <template v-slot:[`item.rate_amount`]="{ item }">
                            {{ `${formatCurrency(item.rate_amount)}/hour` }}
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="ma-3">
                                <div class="ma-3">
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th
                                                    v-for="content in expandedContent"
                                                    :key="content.text"
                                                    class="text-left"
                                                >
                                                    {{ content.text }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    v-for="content in expandedContent"
                                                    :key="content.text"
                                                    @click="
                                                        $log(
                                                            JSON.parse(
                                                                item[
                                                                    `${content.value}_data`
                                                                ]
                                                            ).sort(
                                                                (a, b) =>
                                                                    new Date(
                                                                        a[1]
                                                                    ) -
                                                                    new Date(
                                                                        b[1]
                                                                    )
                                                            )
                                                        )
                                                    "
                                                >
                                                    <v-chip
                                                        :class="
                                                            [
                                                                'Time Clock',
                                                                'Group Event',
                                                                'Attended',
                                                                'Late Cancel',
                                                                'No-show',
                                                                'Courtesy Cancel',
                                                            ].includes(
                                                                content.text
                                                            )
                                                                ? 'soli-attendance-green white--text'
                                                                : null
                                                        "
                                                    >
                                                        {{
                                                            item[
                                                                content.value
                                                            ] ?? 0
                                                        }}
                                                    </v-chip>
                                                </td>
                                            </tr>
                                        </tbody></v-simple-table
                                    >
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-col>
    </AppLayout>
</template>

<script>
import AppLayout from "./../../Layouts/AppLayout";
import ReportHeader from "../../Components/Reports/ReportHeader.vue";
import Stats from "../../Components/Reports/Stats.vue";
import globalMoment from "../../Mixins//GlobalMoment";

export default {
    mixins: [globalMoment],
    components: {
        AppLayout,
        ReportHeader,
        Stats,
    },
    props: {
        report: {
            type: Array,
            default: () => [],
        },
        dateRange: {
            type: Array,
            default: () => [],
        },
        payDay: {
            type: String,
            default: "",
        },
        processed_at: {
            type: String,
            default: "",
        },
        total: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            search: "",
            groupByStaff: null,
            expanded: [],
            expandedContent: [
                {
                    text: "Time Clock",
                    value: "time_clock",
                },
                {
                    text: "Group Event",
                    value: "group_event",
                },
                {
                    text: "Attended",
                    value: "attended",
                },
                {
                    text: "Early Cancel",
                    value: "early_cancel",
                },
                {
                    text: "Late Cancel",
                    value: "late_cancel",
                },
                {
                    text: "No-show",
                    value: "no_show",
                },
                {
                    text: "Teacher Cancel",
                    value: "teacher_cancel",
                },
                {
                    text: "Cancel Credit",
                    value: "cancel_credit",
                },
                {
                    text: "Courtesy Cancel",
                    value: "courtesy_cancel",
                },
                {
                    text: "Missed Attendance",
                    value: "missed_attendance",
                },
            ],
        };
    },
    computed: {
        isProcessed() {
            return moment(new Date(this.processed_at)).isBefore(moment());
        },
        stats() {
            return [
                {
                    title: "Pay Date",
                    data: this.globalMomentDateShort(this.payDay),
                    color: "accent",
                    icon: "calendar_today",
                },
                {
                    title: "Total Pay",
                    data: this.formatCurrency(this.total),
                    color: "soli-status-green",
                    icon: "payments",
                },
                /*{
                    title: "report",
                    data: this.report.length,
                    color: "accent",
                    icon: "list",
                },*/
                {
                    title: "People",
                    data: [...new Set(this.report.map((e) => e.staff_id))]
                        .length,
                    color: "secondary",
                    icon: "people",
                },
            ];
        },
        headers() {
            return [
                {
                    text: "Employee",
                    value: "user.name",
                },
                {
                    text: "Pay",
                    value: "total_pay",
                },
                {
                    text: "Event",
                    value: "rate_name",
                },
                {
                    text: "Hourly Rate",
                    value: "rate_amount",
                },

                {
                    text: "Hours",
                    value: "total_paid_hours",
                },
                {
                    text: "Details",
                    width: "100",
                    value: "data-table-expand",
                },
            ].filter((e) => !e.hidden);
        },
    },
};
</script>

<style>
.v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: inset 0px 0px 8px -5px rgb(50 50 50 / 75%),
        inset 0px 0px 8px -5px rgb(50 50 50 / 75%);
}
</style>
