<template>
    <div>
        <v-skeleton-loader
            type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
            class="mb-6"
            boilerplate
            v-if="!sharedSubscriptions"
        ></v-skeleton-loader>
        <v-expansion-panels flat v-model="panel" @change="$emit('change')">
            <v-expansion-panel
                v-for="(item, index) in getSharedSubscriptions.filter(
                    (e) => !e.void
                )"
                :key="item.id"
                :class="['soli-round mb-2 mt-0', `panel-${index}`]"
                :disabled="disablePanel(item)"
            >
                <v-expansion-panel-header
                    expand-icon="keyboard_arrow_down"
                    focusable
                    :class="['soli-round mb-2 py-0 soli-grey']"
                    @click="$log(item.id)"
                >
                    <v-row :class="['align-center']" class="max-w-full">
                        <v-col v-if="false" class="col-3 flex justify-center">
                            <SoliStatusAvatar
                                :purchaseOption="item"
                                :event="item.lesson"
                                noShadow
                                size="56"
                            />
                        </v-col>
                        <v-col
                            :class="[
                                'pl-0',
                                paidForBy(item)
                                    ? 'v-expansion-panel--disabled'
                                    : null,
                            ]"
                        >
                            <v-list class="mx-2">
                                <div class="caption">
                                    {{
                                        item.category == "memberships"
                                            ? globalMomentFullISO(
                                                  new Date(item.starts_at)
                                              ) > globalMomentFullISO()
                                                ? `Starts on ${globalMomentDateDots(
                                                      item.starts_at
                                                  )}`
                                                : !item.ends_at
                                                ? `Auto-renews on ${globalMomentDateDots(
                                                      item.cycle
                                                  )}`
                                                : `Ends on ${globalMomentDateDots(
                                                      // globalMomentUTC(
                                                      item.ends_at
                                                      //)
                                                  )}`
                                            : item.category == "offers"
                                            ? null
                                            : `${
                                                  item.passes > 0
                                                      ? `${item.passes} Visit(s) Remaining`
                                                      : "No remaining visits"
                                              }`
                                    }}
                                </div>
                                <div
                                    :class="
                                        $vuetify.breakpoint.xsOnly
                                            ? 'body-2'
                                            : 'body-1'
                                    "
                                >
                                    {{ item.name }}
                                </div>

                                <div
                                    class="caption flex flex-wrap items-center justify-start gap-x-2"
                                    v-if="!paidForBy(item)"
                                >
                                    <div v-if="getRelated(item.recipient.id)">
                                        <v-avatar size="20" left>
                                            <img
                                                :src="
                                                    getRelated(
                                                        item.recipient.id
                                                    ).profile_photo_path
                                                        ? `/api/image-service/20x20/${
                                                              getRelated(
                                                                  item.recipient
                                                                      .id
                                                              )
                                                                  .profile_photo_path
                                                          }`
                                                        : getRelated(
                                                              item.recipient.id
                                                          ).profile_photo_url
                                                "
                                        /></v-avatar>
                                        {{
                                            getRelated(item.recipient.id)
                                                .first_name
                                        }}
                                    </div>
                                    <v-divider
                                        v-if="getRelated(item.recipient.id)"
                                        vertical
                                    />
                                    <div
                                        class="flex items-center justify-start shrink"
                                    >
                                        <v-icon
                                            v-if="
                                                item.category ==
                                                    'memberships' &&
                                                item.contract_url !== 'admin' &&
                                                !item.contract_url
                                            "
                                            :class="[
                                                'pr-1',
                                                item.contract_url
                                                    ? 'primary--text'
                                                    : 'error--text',
                                            ]"
                                            small
                                            >description</v-icon
                                        >
                                        <v-icon
                                            v-if="
                                                item.category ==
                                                    'memberships' &&
                                                item.contract_url
                                            "
                                            class="pr-1 dark--text"
                                            small
                                            >event_repeat</v-icon
                                        >
                                        {{
                                            `${formatUnitAmount(
                                                item.price.unit_amount
                                            )}`
                                        }}
                                    </div>
                                    <div
                                        v-if="item.price.coupon_id"
                                        class="contents"
                                    >
                                        <v-divider vertical />
                                        <div class="caption flex shrink">
                                            <v-icon
                                                class="pr-1 dark--text"
                                                small
                                                >local_offer</v-icon
                                            >
                                            {{
                                                item.price.expires_at
                                                    ? `Until ${globalMomentDateDots(
                                                          item.price.expires_at
                                                      )}`
                                                    : "Forever"
                                            }}
                                        </div>
                                    </div>
                                    <v-divider vertical />
                                    <div class="caption flex">
                                        <v-icon class="pr-1 dark--text" small
                                            >storefront</v-icon
                                        >
                                        {{ `${item.location.name}` }}
                                    </div>
                                </div>
                                <v-list-item-subtitle
                                    v-if="item.tags?.length"
                                    class="py-1"
                                >
                                    <div
                                        class="flex gap-1 align-center flex-wrap"
                                    >
                                        <v-chip
                                            v-for="tag in item.tags"
                                            :key="tag.id"
                                            x-small
                                            :color="tag.color"
                                            class="white--text font-weight-bold text-capitalize"
                                            >{{ tag.name }}</v-chip
                                        >
                                    </div>
                                </v-list-item-subtitle>
                                <div
                                    class="caption flex error--text"
                                    v-if="item.pendingExit"
                                >
                                    Pending Exit Form
                                </div>
                                <div
                                    class="error--text flex caption"
                                    v-if="
                                        !viewAtOptionLocation(item) &&
                                        panel === index
                                    "
                                >
                                    {{
                                        `Only editiable in ${item.location.name}`
                                    }}
                                </div>

                                <div v-if="paidForBy(item)" class="caption">
                                    <div v-if="relationships.length">
                                        {{
                                            `Paid for by ${
                                                relationships.find(
                                                    (e) => e.id == item.user_id
                                                ).name
                                            }`
                                        }}
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        $page.props.staffView &&
                                        item.lesson &&
                                        panel !== false &&
                                        panel !== undefined
                                    "
                                >
                                    <div
                                        class="soli-grey font-weight-bold py-1"
                                    >
                                        Lesson:
                                    </div>
                                    <div class="caption soli-grey">
                                        {{
                                            `${globalMomentWeekDay(
                                                item.lesson.start
                                            )} at ${globalMomentTime(
                                                item.lesson.start
                                            )}`
                                        }}
                                    </div>
                                    <div class="caption soli-grey">
                                        {{
                                            `${
                                                item.lesson.instrument.name ??
                                                ""
                                            } w/ ${item.lesson.teacher.name}`
                                        }}
                                    </div>
                                </div>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="justify-around align-center py-5">
                        <v-btn
                            v-if="
                                $page.props.staffView &&
                                can('Manage memberships') &&
                                (!item.ends_at || item.gracePeriod)
                            "
                            icon
                            small
                            color="dark"
                            :loading="loading"
                            @click="
                                item.ends_at
                                    ? (confirmResumeMembership = item.id)
                                    : [
                                          (selectedMembership = item),
                                          (editSubscription = true),
                                      ]
                            "
                            :disabled="
                                !viewAtOptionLocation(item) ||
                                (item.passes == 0 &&
                                    item.category == 'passes' &&
                                    !!item.paid)
                            "
                        >
                            <v-icon>{{
                                item.ends_at ? "restart_alt" : "edit"
                            }}</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >{{
                                    item.ends_at ? "Resume Membership" : "Edit"
                                }}</small
                            >
                        </v-btn>
                        <v-btn
                            v-if="$page.props.staffView"
                            icon
                            small
                            color="dark"
                            :disabled="
                                !relationships.length ||
                                item.passes == 0 ||
                                !!item.lesson ||
                                !viewAtOptionLocation(item)
                            "
                            @click="
                                [
                                    (transferOption = item),
                                    (transferForm.user =
                                        relationshipsAndMe.find(
                                            (e) =>
                                                e.id == transferOption.user_id
                                        )),
                                    (transferForm.recipient =
                                        relationshipsAndMe.find(
                                            (e) =>
                                                e.id ==
                                                transferOption.recipient.id
                                        )),
                                    (transferForm.passes =
                                        transferOption.passes),
                                ]
                            "
                        >
                            <v-icon>share</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Transfer</small
                            >
                        </v-btn>
                        <v-btn
                            v-if="
                                $page.props.staffView &&
                                item.category == 'offers'
                            "
                            icon
                            small
                            color="dark"
                            :disabled="!viewAtOptionLocation(item)"
                            @click="
                                [
                                    (creditForm.show = true),
                                    (creditForm.purchaseOptionId = item.id),
                                ]
                            "
                        >
                            <v-icon>redeem</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Redeem</small
                            >
                        </v-btn>
                        <v-btn
                            v-if="canVoid(item)"
                            icon
                            small
                            color="dark"
                            @click="[(confirmRemoveOption = item)]"
                            :disabled="!viewAtOptionLocation(item)"
                        >
                            <v-icon>remove_circle_outline</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Remove</small
                            >
                        </v-btn>
                        <v-btn
                            v-if="!item.paid && !$page.props.staffView"
                            icon
                            small
                            color="soli-status-green"
                            :href="route('prepay')"
                        >
                            <v-icon>attach_money</v-icon>
                            <small
                                class="mt-1 panel-text soli-grey"
                                style="white-space: normal"
                                >Pay Now</small
                            >
                        </v-btn>
                        <a
                            v-if="
                                item.contract_url &&
                                item.contract_url !== 'admin'
                            "
                            target="_blank"
                            :href="route('contract.view', item.hash_id)"
                        >
                            <v-btn icon small color="dark">
                                <v-icon>description</v-icon>
                                <small
                                    class="mt-1 panel-text"
                                    style="white-space: normal"
                                    >View Contract</small
                                >
                            </v-btn>
                        </a>
                        <v-btn
                            v-if="item.unsignedContract"
                            icon
                            small
                            color="dark"
                            @click="loadContract = item"
                        >
                            <v-icon>description</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Sign Contract</small
                            >
                        </v-btn>
                        <v-btn
                            v-if="
                                item.category == 'memberships' &&
                                !$page.props.staffView
                            "
                            icon
                            small
                            color="dark"
                            @click="contactStudio = item"
                        >
                            <v-icon>help</v-icon>
                            <small
                                class="mt-1 panel-text"
                                style="white-space: normal"
                                >Ask a Question</small
                            >
                        </v-btn>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <SoliChoice
            v-if="confirmResumeMembership"
            title="Resume Membership"
            subtitle="This withdrawal will be reversed and billing will resume. You'll still need to schedule new lessons if they were cancelled."
            button1="Confirm"
            button2="Nevermind"
            @option1="resumeMembership(confirmResumeMembership)"
            @option2="
                closeModal(() => [
                    (confirmResumeMembership = false),
                    (editSubscription = null),
                ])
            "
            @reset="
                closeModal(() => [
                    (confirmResumeMembership = false),
                    (editSubscription = null),
                ])
            "
        ></SoliChoice>

        <ManageSubscription
            v-if="editSubscription"
            :purchaseOption="selectedMembership"
            :user="user"
            @success="closeModal(() => $emit('success'))"
            @reset="
                closeModal(() => [
                    (editSubscription = null),
                    (selectedMembership = null),
                ])
            "
            @changePrice="[(showChangePrice = true), (editSubscription = null)]"
            @editDetails="
                [(showEditMembershipDetails = true), (editSubscription = null)]
            "
        />

        <ChangeMembershipPrice
            v-if="showChangePrice"
            :purchaseOption="selectedMembership"
            :user="user"
            @success="closeModal(() => $emit('success'))"
            @reset="
                closeModal(() => [
                    (showChangePrice = null),
                    (selectedMembership = null),
                ])
            "
        />

        <EditMembershipDetails
            v-if="showEditMembershipDetails"
            :purchaseOption="selectedMembership"
            @success="closeModal(() => $emit('success'))"
            @reset="
                closeModal(() => [
                    (showEditMembershipDetails = null),
                    (selectedMembership = null),
                ])
            "
        />

        <SoliForm
            v-if="transferOption"
            :title="`Transfer ${transferOption.name}`"
            @submit="handleTransfer"
            @reset="transferOption = null"
            ref="transferForm"
            ><template #body>
                <div class="flex flex-col gap-4">
                    <v-autocomplete
                        v-model="transferForm.recipient"
                        :items="relationshipsAndMe"
                        no-data-text="No relationships found"
                        item-text="name"
                        item-value="id"
                        label="Relationships"
                        outlined
                        return-object
                        dense
                        class="soli-blue-text"
                        prepend-inner-icon="people"
                        :error-messages="errors.recipient"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'Item is required']"
                    >
                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <img :src="data.item.profile_photo_url" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="data.item.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                    v-html="data.item.email"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <SoliText
                        v-model="transferForm.passes"
                        :min="1"
                        :max="Number(transferOption.passes)"
                        label="Passes to transfer"
                        outlined
                        dense
                        class="soli-round"
                        number
                        hide-details
                        icon="sync_alt"
                    />
                </div>
            </template>
        </SoliForm>

        <SoliForm
            v-if="creditForm.show"
            title="Redeem Offer"
            textSubtitle="Redeem this offer and credit user account?"
            @submit="handleRedeemOffer()"
            @reset="[(creditForm.show = false), (creditForm.amount = null)]"
            ref="creditForm"
            text="Save"
            icon="save"
            ><template #body>
                <v-currency-field
                    label="Amount to credit account"
                    v-model="creditForm.amount"
                    :rules="creditForm.rules"
                    outlined
                    dense
                    class="soli-round"
                    error-message="errors.amount"
                    hide-details="auto"
                ></v-currency-field> </template
        ></SoliForm>

        <!--<SoliForm
            v-if="editOptionForm.show"
            ref="editOptionForm"
            v-model="validEditOptionForm"
            title="Adjust Price"
            choices
            permanent
            persistent
            textSubtitle="This product is a membership. Changing the price will affect future billing."
            :saveChanges="!$refs.editOptionForm?.$refs.input?.hasError"
            @submit=""
            @reset="closeModal(() => (editOptionForm.show = false))"
            ><template #body>
                <div class="flex flex-col gap-4">
                    <v-currency-field
                        ref="inlinePriceText"
                        v-model="editOptionForm.unit_amount"
                        :rules="[
                            (val) => !!val || 'Price is required',
                            (val) =>
                                String(val).replace(/,/g, '') > 0 ||
                                'Price must be greater than $0.00',
                            (val) =>
                                String(val).replace(/,/g, '') <= 500 ||
                                'Price must be not be greater than $500.00',
                        ]"
                        outlined
                        dense
                        class="soli-round"
                        label="Price"
                        error-message="errors.amount"
                        prepend-inner-icon="attach_money"
                        validate-on-blur
                        :allowNegative="false"
                        hide-details="auto"
                    ></v-currency-field>
                    <PriceTags v-model="editOptionForm.tags" />
                </div> </template
        ></SoliForm>-->

        <Contracts
            v-if="loadContract"
            :contracts="loadContract.unsignedContract"
            :client="user"
            :purchaseOption="loadContract"
            @success="$emit('success')"
            @reset="closeModal(() => (loadContract = false))"
        ></Contracts>
        <SoliChoice
            v-if="confirmRemoveOption"
            ref="removeOptionForm"
            title="Are you sure?"
            :subtitle="`This purchase option will be removed from ${
                user.first_name
            }'s account. ${
                confirmRemoveOption.paid
                    ? 'This option is paid and might still need to be refunded.'
                    : ''
            }`"
            button1="Confirm"
            button2="Nevermind"
            loading1
            @reset="closeModal(() => (confirmRemoveOption = null))"
            @option1="handleRemoveOption(confirmRemoveOption)"
            @option2="closeModal(() => (confirmRemoveOption = null))"
        ></SoliChoice>
        <SoliChoice
            v-if="showEoMWarning"
            button1="Continue"
            button2="Nevermind"
            @reset="cancelEditSubscription($event)"
            title="Are you sure?"
            subtitle="This membership will be cancelled without 30 days notice. This is not recommended."
            warning
            @option2="cancelEditSubscription(true)"
        >
        </SoliChoice>
        <SoliResponsiveDialog
            ref="locationContact"
            v-if="contactStudio"
            @reset="closeModal(() => (contactStudio = false))"
            title="Contact Us!"
            ><template #body>
                <SoliLocations
                    :parentLocations="$page.props.auth.user.locations"
                    :value="
                        $page.props.auth.user.locations.find(
                            (e) => e.id == contactStudio.location.id
                        )
                    "
                    iterator
                    showAddress
                    hideDetails
                    expandable
                    hideSearch
            /></template>
        </SoliResponsiveDialog>
    </div>
</template>

<script>
import Contracts from "../Store/Contracts.vue";
import globalMoment from "../../Mixins/GlobalMoment.js";
import ManageSubscription from "./ManageSubscription.vue";
import ChangeMembershipPrice from "./ChangeMembershipPrice.vue";
import EditMembershipDetails from "./EditMembershipDetails.vue";
import { Link } from "@inertiajs/vue2";

export default {
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        errors: {
            type: Object,
            default: () => {},
        },
        relationships: {
            type: Array,
            default: () => [],
        },
        sharedSubscriptions: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [globalMoment],
    components: {
        Contracts,
        ManageSubscription,
        ChangeMembershipPrice,
        EditMembershipDetails,
        Link,
    },
    data: function () {
        return {
            showEoMWarning: false,
            panel: false,
            editSubscription: null,
            dateOfNotice: this.globalMomentDate(),
            anchor: null,
            confirmResumeMembership: false,
            loading: false,
            loadContract: false,
            transferOption: null,
            confirmRemoveOption: null,
            contactStudio: false,
            transferForm: {
                recipient: null,
                passes: null,
            },
            showChangePrice: false,
            showEditMembershipDetails: false,
            selectedMembership: null,
            creditForm: this.$inertia.form({
                amount: null,
                purchaseOptionId: null,
                show: false,
                loading: false,
                rules: [
                    (v) => !!v || "This field is required",
                    (v) => (v && v > 0) || "Credit must be greater than $0",
                ],
            }),
        };
    },
    computed: {
        getSharedSubscriptions() {
            return this.sharedSubscriptions ? this.sharedSubscriptions : [];
        },
        relationshipsAndMe() {
            let arr = [...this.relationships].map((e) => {
                return {
                    name: e.name,
                    id: e.id,
                    profile_photo_url: e.profile_photo_url,
                };
            });
            arr.push(this.user);
            return arr;
        },
    },
    methods: {
        getRelated(id) {
            return this.relationships.find((e) => e.id == id);
        },
        disablePanel(option) {
            if (!this.paidForBy(option)) return false;
            if (option.prepay && !option.paid) {
                return !this.$page.props.staffView;
            }
            return this.$page.props.staffView;
        },
        viewAtOptionLocation(option) {
            if (!this.$page.staffView) return true;
            return (
                option.location.id ==
                this.$page.props.auth.user.selected_location.id
            );
        },
        handleTransfer() {
            if (
                this.transferForm.recipient.id ==
                this.transferOption.recipient.id
            ) {
                this.$page.props.errors = {
                    recipient: "Must select a new recipient",
                };

                this.$nextTick(() => (this.$refs.transferForm.loading = false));
                return;
            }

            this.$inertia.put(
                route("subscription.transfer"),
                {
                    id: this.transferOption.id,
                    user_id: this.transferForm.user.id,
                    recipient_id: this.transferForm.recipient.id,
                    passes: this.transferForm.passes,
                },
                {
                    onSuccess: () => {
                        this.$emit("success");
                        this.transferOption = null;
                    },
                }
            );
        },
        handleRemoveOption(item) {
            this.$inertia.put(
                route("subscription.removeAssessment"),
                {
                    id: item.id,
                },
                {
                    onSuccess: () => {
                        this.$refs.removeOptionForm.reset();
                        this.closeModal(() => this.$emit("success"));
                    },
                }
            );
        },
        resumeMembership(subscription) {
            this.loading = true;
            this.$inertia.put(
                route("subscription.resume"),
                {
                    user: this.user.id,
                    item: this.confirmResumeMembership,
                    notice: moment(new Date(this.dateOfNotice)).format(),
                    anchor: subscription.cycle,
                },
                {
                    onSuccess: () => {
                        this.confirmResumeMembership = null;
                        this.loading = false;
                        this.$emit("success");
                    },
                    onError: () => {
                        this.loading = false;
                    },
                }
            );
        },
        paidForBy(item) {
            return this.relationships.find((e) => e.id !== item.recipient.id) &&
                item.user_id !== this.user.id
                ? true
                : false;
        },
        formatUnitAmount(priceUnit) {
            return `$${(priceUnit / 100).toFixed(2)}`;
        },
        cancelEditSubscription(event) {
            this.showEoMWarning = false;
            if (event) {
                this.$refs.subscriptionForm.dialog = false;
                setTimeout(() => {
                    this.editMode = null;
                    this.editSubscription = null;
                    this.selectedMembership = null;
                }, 300);
            }
        },
        canVoid(item) {
            if (!this.$page.props.staffView) return false;
            if (!this.can("Void purchase options")) return false;
            if (item.category !== "passes" && item.category !== "offers") {
                return false;
            }
            if (item.lesson) {
                if (
                    moment(new Date(item.lesson.starts_at)).isAfter(moment()) &&
                    !item.lesson.cancelled
                ) {
                    return false;
                }
            }
            return true;
        },
        handleRedeemOffer() {
            this.creditForm.post(route("user.redeemOffer", this.user.id), {
                preserveState: true,
                perserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                    this.$refs.creditForm.reset();
                },
                onError: (errors) => {
                    console.log(errors);
                },
            });
        },
    },
};
</script>

<style scoped>
.date-indicator {
    width: 12px;
    height: 85px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.v-list {
    color: unset !important;
}

.text-align-center {
    text-align: center;
}
</style>
