<template>
    <v-row dense>
        <v-col
            cols="6"
            sm="3"
            md="2"
            lg="2"
            v-for="(stat, i) in stats.filter((e) => !e.hide)"
            :key="stat.title"
        >
            <v-card class="soli-round">
                <v-container>
                    <div class="flex justify-center align-center pb-1">
                        <v-icon round outlined :color="stat.color">{{
                            stat.icon
                        }}</v-icon>
                    </div>
                    <v-card-title
                        class="soli-grey justify-center py-1"
                        style="white-space: nowrap"
                        v-if="stat.data || stat.data === 0"
                    >
                        {{ stat.data }}
                    </v-card-title>
                    <v-skeleton-loader
                        v-if="!stat.data && stat.data !== 0"
                        height="40"
                        class="mx-auto py-0 stats-skeleton"
                        max-width="120"
                        type="list-item"
                    ></v-skeleton-loader>
                    <div class="flex justify-center soli-grey text-sm">
                        {{ stat.title }}
                    </div></v-container
                >
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        stats: {
            type: Array,
            default: () => [],
        },
        links: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: String,
            default: null,
        },
    },

    data() {
        return {};
    },
    methods: {
        selectStat(stat, event) {
            if (stat.link && this.links) this.$inertia.visit(stat.link);
            this.$emit("click", stat);
            if (this.selected == stat.title) {
                event.target.blur();
                event.target.parentElement.blur();
                event.target.parentElement.parentElement.blur();
            }
        },
    },
};
</script>

<style>
.stats-skeleton .v-skeleton-loader__list-item {
    height: inherit !important;
}
</style>
