<template>
    <GetStartedLayout
        :errors="errors"
        :guest="guest"
        :child="child"
        bgGradient
        :location="location"
        :programInquiry="program.title"
        :breadcrumbs="
            guest
                ? ['Get Started', 'Programs', 'Summer Camps']
                : ['Explore', 'Summer Camps']
        "
        ><template #toggleFilter>
            <v-btn
                v-if="shoppingCartItems"
                icon
                outlined
                color="white"
                @click="$inertia.visit(route('checkout.show'))"
                ><v-badge
                    :content="shoppingCartItems"
                    color="secondary"
                    overlap
                    bordered
                >
                    <v-icon class="white--text">shopping_cart</v-icon></v-badge
                ></v-btn
            >
        </template>
        <v-col class="white--text text-center px-0 form-width" cols="12" lg="6">
            <EventRegistrationSearch
                :errors="errors"
                :program="program"
                :location="location"
            />
        </v-col>
    </GetStartedLayout>
</template>

<script>
import GetStartedLayout from "../../Layouts/GetStartedLayout.vue";
import { Link } from "@inertiajs/vue2";
import EventRegistrationSearch from "../../Components/EventRegistration/EventRegistrationSearch.vue";
import FormItem from "../../Components/Form/FormItem.vue";
import FormTitle from "../../Components/Form/FormTitle.vue";
import FormSubmit from "../../Components/Form/FormSubmit.vue";
import FormExpansion from "../../Components/Form/FormExpansion.vue";

export default {
    components: {
        GetStartedLayout,
        Link,
        FormItem,
        FormTitle,
        FormSubmit,
        FormExpansion,
        EventRegistrationSearch,
    },
    props: {
        errors: {},
        program: {
            type: Object,
            default: () => {},
        },
        location: {
            type: Object,
            default: () => {},
        },
        guest: {
            type: Object,
            default: () => {},
        },
        child: {
            type: Object,
            default: () => {},
        },
        shoppingCartItems: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {};
    },
};
</script>

<style scoped>
.form-width {
    max-width: 600px;
}
</style>
