<template>
    <div>
        <Skeleton v-if="$root.loading" :cols="listView ? 12 : 6" :length="6" />
        <v-row v-if="!$root.loading" v-masonry class="z-0">
            <v-col
                v-for="(product, idx) in products"
                :key="product.id"
                :cols="listView ? '12' : '6'"
                class="soli-round"
            >
                <v-card
                    class="soli-round"
                    :ripple="false"
                    flat
                    :outlined="!listView"
                >
                    <v-menu left v-if="can('Manage inventory')"
                        ><template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="mt-4 right-3"
                                style="z-index: 100; background: white"
                                absolute
                                icon
                                small
                                ><v-icon>more_vert</v-icon></v-btn
                            ></template
                        ><v-list
                            ><v-list-item
                                :href="`/admin/product/${product.id}/edit`"
                                v-if="is('Admin')"
                                >Admin Edit</v-list-item
                            ><v-list-item
                                v-if="can('Manage products')"
                                :disabled="product.category == 'gifts'"
                                @click="$emit('manageProduct', product)"
                                >Edit Product</v-list-item
                            ><v-list-item
                                v-if="product.physical"
                                @click="showInventory = product"
                                >Manage Inventory</v-list-item
                            >
                        </v-list></v-menu
                    >
                    <v-card-actions class="align-center">
                        <v-avatar size="128" v-if="product.photo" tile
                            ><v-img
                                position="top center"
                                :src="
                                    product.imagePath
                                        ? `/api/image-service/128x128/${encodeURIComponent(
                                              product.imagePath
                                          )}`
                                        : product.photo
                                "
                                class="soli-round"
                        /></v-avatar>
                        <v-list-item
                            style="overflow: hidden; min-height: 128px"
                            class="flex align-center"
                        >
                            <v-list-item-content class="pb-0">
                                <v-list-item-title
                                    v-if="!product.photo"
                                    style="white-space: normal"
                                    >{{ product.name }}
                                </v-list-item-title>
                                <small v-else class="pr-3">{{
                                    product.name
                                }}</small>
                                <v-list-item-subtitle
                                    class="caption"
                                    style="white-space: normal"
                                    >{{ product.description }}
                                </v-list-item-subtitle>
                                <!-- Product Packages -->

                                <v-chip-group
                                    v-if="
                                        product.prices.length > 1 &&
                                        !product.multiple_prices &&
                                        product.prices.some(
                                            (e) => e.package_name
                                        )
                                    "
                                    mandatory
                                    v-model="product.price"
                                    active-class="primary--text"
                                    ><div
                                        v-for="(price, idx) in product.prices
                                            .filter(
                                                (e) =>
                                                    e.duration ==
                                                    product.price.duration
                                            )
                                            .sort(
                                                (a, b) => a.passes - b.passes
                                            )"
                                        :key="idx"
                                    >
                                        <v-chip
                                            small
                                            outlined
                                            :value="price"
                                            @click="$log(price.id)"
                                            >{{
                                                price.package_name ??
                                                "Full Price"
                                            }}
                                        </v-chip>
                                    </div>
                                </v-chip-group>
                                <!-- Product durations -->
                                <v-chip-group
                                    v-if="
                                        product.prices.length > 1 &&
                                        !product.multiple_prices &&
                                        product.selectable_duration &&
                                        product.prices.some((e) => e.duration)
                                    "
                                    mandatory
                                    v-model="product.price"
                                    active-class="primary--text"
                                    ><div
                                        v-for="(price, idx) in product.prices
                                            .filter(
                                                (e) =>
                                                    e.package_name ==
                                                    product.price.package_name
                                            )
                                            .sort(
                                                (a, b) =>
                                                    a.duration - b.duration
                                            )"
                                        :key="idx"
                                    >
                                        <v-chip
                                            small
                                            outlined
                                            :value="price"
                                            @click="$log(price.id)"
                                        >
                                            {{ price.duration + " min" }}
                                        </v-chip>
                                    </div>
                                </v-chip-group>
                                <v-list-item-subtitle class="font-bold py-1">
                                    <v-menu
                                        right
                                        v-if="
                                            product.prices.length > 1 &&
                                            product.multiple_prices
                                        "
                                        ><template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="px-2 mr-1"
                                                outlined
                                                small
                                                text
                                                rounded
                                                style="text-transform: none"
                                            >
                                                {{ product.price.formatAmount }}
                                                <v-icon color="secondary"
                                                    >arrow_drop_down</v-icon
                                                ></v-btn
                                            ></template
                                        ><v-list
                                            ><v-list-item
                                                v-for="price in product.prices"
                                                :key="price.id"
                                                @click="product.price = price"
                                                >{{
                                                    price.formatAmount
                                                }}</v-list-item
                                            ></v-list
                                        ></v-menu
                                    >
                                    <v-list-item-title v-else>
                                        {{ product.price.formatAmount }}
                                    </v-list-item-title>

                                    <small v-if="product.physical">{{
                                        product.price.inventory?.current_stock >
                                        0
                                            ? "In-stock"
                                            : "Out-of-stock"
                                    }}</small>
                                </v-list-item-subtitle>

                                <v-list-item
                                    class="px-0"
                                    :class="{
                                        'flex justify-end': !product.photo,
                                    }"
                                >
                                    <v-btn
                                        class="soli-round"
                                        small
                                        color="accent"
                                        @click="
                                            $emit('addProductToCart', product)
                                        "
                                        >Add to Cart</v-btn
                                    ></v-list-item
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-actions> </v-card
                ><v-divider
                    v-if="listView && idx < products.length - 1 && divider"
                    class="mt-5"
                />
            </v-col>
        </v-row>
        <ManageInventory
            :product="showInventory"
            v-if="showInventory"
            @reset="showInventory = false"
            @success="$emit('success')"
        />
    </div>
</template>

<script>
import ManageInventory from "./ManageInventory.vue";
import Skeleton from "../Skeleton.vue";

export default {
    components: {
        ManageInventory,
        Skeleton,
    },
    props: {
        products: {
            type: Array,
            default: () => [],
        },
        divider: {
            type: Boolean,
            default: true,
        },
        listView: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showInventory: false,
        };
    },
};
</script>

<style scoped>
::v-deep .v-chip-group .v-slide-group__content {
    padding: 0px !important;
}
</style>
