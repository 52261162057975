<template>
    <div class="w-full">
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="list-item, list-item, list-item"
            v-if="loading && buttons"
        ></v-skeleton-loader>
        <v-select
            v-if="!autocomplete && !buttons && !iterator"
            :multiple="multiple"
            :items="locations"
            :value="value"
            v-on:input="$emit('input', $event)"
            @focus="$emit('focus')"
            item-text="name"
            item-value="id"
            :label="label"
            prepend-inner-icon="storefront"
            outlined
            :rules="rules"
            :loading="loading"
            :hide-details="hideDetails"
            :readonly="readonly"
            dense
        ></v-select>
        <v-list v-if="buttons && !loading"
            ><v-list-item v-for="location in locations" :key="location.id">
                <SoliAvatarBtn
                    :avatar="
                        location.alt_image
                            ? `${location.alt_image}/m/80x80`
                            : '/musicologie-m-white.png'
                    "
                    :subtitleBottom="showAddress ? location.address : null"
                    :title="location.name"
                    @click="$emit('input', location)"
                /> </v-list-item
        ></v-list>
        <v-autocomplete
            v-if="autocomplete && !buttons && !iterator"
            @input="$emit('input', $event)"
            @keydown.enter.prevent
            :value="value"
            :items="locations"
            :rules="rules"
            item-text="name"
            item-value="id"
            :label="label"
            :loading="loading"
            appendIcon="my_location"
            @click:append="askForLocation()"
            outlined
            class="soli-blue-text"
            prepend-inner-icon="storefront"
            dense
            :error-messages="error"
            :hide-details="hideDetails"
        >
            <template v-slot:item="data" style="max-height: 56px">
                <v-list-item-avatar size="56" class="shadow-blue">
                    <img
                        :src="
                            data.item.alt_image
                                ? `${data.item.alt_image}/m/80x80`
                                : '/musicologie-m-white.png'
                        "
                    />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title
                        v-html="data.item.name"
                        class="font-weight-bold body-2"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="data.item.address"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                        v-if="getDistance"
                        class="accent--text font-weight-bold"
                    >
                        {{ `${data.item.distance} | ${data.item.duration}` }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-skeleton-loader
            v-if="iterator && loading"
            class="mx-auto my-3"
            type="list-item-avatar, list-item-avatar, list-item-avatar"
        ></v-skeleton-loader>

        <v-data-iterator
            v-if="iterator && !loading"
            :items="locations"
            disable-pagination
            :search="search"
            hide-default-footer
        >
            <template v-slot:header
                ><v-sheet
                    light
                    style="position: sticky; top: 0px; z-index: 10"
                    v-if="locations.length > 1 && !hideSearch"
                    ><SoliText
                        v-model="search"
                        class="pa-3"
                        icon="storefront"
                        :autofocus="autofocus"
                        label="Search Locations"
                        hideDetails
                        fullRound
                        appendIcon="my_location"
                        @append="askForLocation()"
                        :loading="loading"
                    /> </v-sheet
            ></template>
            <template v-slot:item="data">
                <v-list
                    class="py-0 px-3 soli-round"
                    :class="{
                        expanded:
                            expandable && data.item.id == selectedLocation?.id,
                    }"
                >
                    <SoliAvatarBtn
                        @click="
                            [
                                $emit(
                                    'input',
                                    returnObject ? data.item : data.item.id
                                ),
                                (selectedLocation =
                                    data.item.id == selectedLocation?.id
                                        ? null
                                        : data.item),
                            ]
                        "
                        v-if="
                            !expandable || data.item.id !== selectedLocation?.id
                        "
                        :value="data.item.id == selectedLocation?.id"
                        class="w-full"
                        :avatar="
                            data.item.alt_image
                                ? `${data.item.alt_image}/m/80x80`
                                : '/musicologie-m-white.png'
                        "
                        :subtitleBottom="
                            showAddress ? data.item.market?.name : null
                        "
                        blueShadow
                        :outlined="false"
                        :title="data.item.name"
                        :icon="data.item.icon ?? null"
                        :append="
                            expandable && !getDistance ? 'expand_more' : null
                        "
                        :ripple="!expandable"
                        :id="data.item.id"
                        ><template
                            ><div
                                v-if="getDistance"
                                style="text-transform: none; position: absolute"
                                class="accent--text font-weight-bold w-full h-full top-0 left-0 flex justify-end align-center pa-3"
                            >
                                {{
                                    `${data.item.distance} | ${data.item.duration}`
                                }}
                            </div></template
                        ></SoliAvatarBtn
                    >
                    <SoliLocationExpandedContent
                        v-if="expandable"
                        :location="data.item"
                        :value="selectedLocation"
                        class="mt-3"
                        @input="[(selectedLocation = data.item)]"
                    /> </v-list></template
        ></v-data-iterator>
        <SoliChoice
            v-if="showLocationError"
            title="Location Lookup Failed"
            subtitle="Location Services is currently disabled. Please enable it to use your current location."
            button1="Okay"
            @option1="closeModal(() => (showLocationError = false))"
        />
    </div>
</template>

<script>
import { GetDistance } from "../../Mixins/DistanceAPI";

export default {
    components: {},
    props: {
        error: {},
        value: {},
        buttons: {
            type: Boolean,
            default: false,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        address: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        logo: {},
        left: {},
        rules: {},
        label: {
            type: String,
            default: "Locations",
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        iterator: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        parentLocations: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: true,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            locations: this.parentLocations,
            loading: false,
            search: null,
            selectedLocation: this.value ?? {},
            getDistance: false,
            showLocationError: false,
            expand: null,
        };
    },
    methods: {
        askForLocation() {
            if (this.getDistance) return;
            this.loading = true;
            const successCallback = (position) => {
                //console.log(position);
                GetDistance(position, this.locations)
                    .then((res) => {
                        this.getDistance = true;
                        this.locations.forEach((e, idx) => {
                            e.distance = res[idx].distance;
                            e.duration = res[idx].duration;
                        });

                        this.loading = false;
                    })
                    .catch((err) => {
                        console.log("error:", err);
                        this.loading = false;
                    });
            };

            const errorCallback = (error) => {
                console.log(error);
                this.loading = false;
                this.showLocationError = true;
            };

            navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback
            );
        },
    },
    mounted() {
        this.$loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAusPiHauq9IKpTzO7KC4-518Q_DtzG41I&libraries=places&callback=Function.prototype&loading=async"
        );
        if (this.parentLocations.length) return;
        this.loading = true;
        axios.get("/api/locations").then((resp) => {
            this.locations = resp.data;
            this.loading = false;
        });
    },
};
</script>

<style scoped>
.expanded {
    border: 3px solid #228cbd;
    margin-top: 4px;
}
</style>
