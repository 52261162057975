<script setup>
import { ref, computed } from "vue";
import { useVuetify } from "../../Composables/useVuetify.js";
import { VDialog, VBottomSheet } from "vuetify/lib";

// Expose

defineExpose({ reset });

// Composables

const breakpoint = useVuetify().breakpoint;

// Emits

const emit = defineEmits(["submit", "reset", "refresh"]);

// Props

const props = defineProps({
    title: {
        type: String,
        default: "",
        required: false,
    },
    subtitle: {
        type: String,
        default: "",
        required: false,
    },
    expandCover: {
        type: Boolean,
        default: false,
        required: false,
    },
    color: {
        type: String,
        default: "",
        required: false,
    },
    fullscreen: {
        type: Boolean,
        default: false,
        required: false,
    },
    subMenuModal: {
        type: Boolean,
        default: false,
        required: false,
        description: "Use when the modal is a child of another modal",
    },
});

// Reactive State

const show = ref(true);

// Computed

const getComponent = computed(() => {
    return {
        component: breakpoint.xs ? VBottomSheet : VDialog,
        props: {
            "max-width": breakpoint.xs ? "inherit" : "450",
        },
    };
});

// Methods

function reset() {
    show.value = false;
    emit("refresh");
    setTimeout(() => {
        emit("reset");
    }, 300);
}

function overflow() {
    expandCover.value = !expandCover.value;
}
</script>

<template>
    <component
        :is="getComponent.component"
        v-bind="{ ...getComponent.props }"
        v-model="show"
        :fullscreen="fullscreen ? breakpoint.xs : false"
        :class="{
            'soli-round': breakpoint.smAndUp || !fullscreen,
            'sub-menu-modal': subMenuModal,
        }"
        :content-class="
            [
                'responsive-modal soli-round',
                subMenuModal ? 'sub-menu-modal' : '',
            ].join(' ')
        "
        @keydown.esc="reset()"
        @click:outside="reset()"
    >
        <v-card
            flat
            :color="color"
            :dark="!!color"
            :light="!color"
            :class="{
                'half-round': breakpoint.smAndUp || !fullscreen,
                'soli-grey': !color,
            }"
            class="responsive-modal-card safe-bottom"
        >
            <v-btn
                icon
                :color="color ? 'white' : 'soli-grey'"
                class="modal-close-btn"
                @click="reset()"
                ><v-icon>clear</v-icon></v-btn
            >
            <v-container class="modal-header py-0">
                <v-card-title class="soli-text-shadow"
                    >{{ title }} <slot name="title"></slot
                ></v-card-title>
                <v-card-subtitle v-if="subtitle || $slots.subtitle"
                    >{{ subtitle }} <slot name="subtitle"></slot
                ></v-card-subtitle>
                <v-card-text v-if="$slots.body" class="modal-body">
                    <slot name="body"></slot>
                </v-card-text>
            </v-container>
            <div
                v-if="$slots.cover"
                :class="{ 'expanded-cover': expandCover }"
                class="modal-cover half-round"
                @overflow="overflow()"
            >
                <slot name="cover"></slot>
            </div>
            <div v-if="$slots.footer" class="modal-footer">
                <slot name="footer"></slot>
            </div>
        </v-card>
    </component>
</template>

<style scoped>
::v-deep .responsive-modal {
    @media screen and (min-width: 600px) {
        height: 90%;
        max-height: 712px;
    }
}

::v-deep .sub-menu-modal {
    max-height: 592px;
}

.responsive-modal-card {
    display: flex;
    flex-direction: column;
    border: none;
    height: 100%;
}

.modal-header {
    flex: none;
    width: 100%;
}

.modal-cover {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    transition: margin-top 0.3s;
}

.modal-body {
    flex-grow: 1;
    display: flex;
    width: 100%;
}

.modal-footer {
    flex: none;
    width: 100%;
}

.expanded-cover {
    margin-top: -148px;
    transition: margin-top 0.3s;
}
</style>
