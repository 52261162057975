<template>
    <div class="wrapper">
        <div class="px-4 font-weight-bold soli-grey">{{ minLabel }}</div>
        <div class="shell soli-round">
            <div
                v-if="progress > 0"
                class="bar gradient-blue soli-round"
                :style="{ width: `${progress}%` }"
            >
                <span>{{ progress }}%</span>
            </div>
        </div>
        <div class="px-4 font-weight-bold soli-grey">{{ maxLabel }}</div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 250,
        },
        minLabel: {
            type: String,
            default: "",
        },
        maxLabel: {
            type: String,
            default: "",
        },
    },
    computed: {
        progress() {
            if (this.value < this.min) return 0;
            if (this.value > this.max) return 100;
            return ((this.value / this.max) * 100).toFixed(2);
        },
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shell {
    height: 28px;
    display: flex;
    flex: auto;
    border: 1px solid #aaa;
    padding: 3px;
}

.bar {
    height: 20px;
    min-width: 48px;
    span {
        float: right;
        padding: 4px 5px;
        color: #fff;
        font-size: 0.7em;
    }
}
</style>
