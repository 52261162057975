<template>
    <div>
        <v-skeleton-loader
            v-if="!events"
            class="mx-auto"
            type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-simple-table v-if="true" class="cursor-pointer">
            <tbody>
                <tr
                    v-for="(item, index) in events"
                    :key="item.id"
                    @click="clickedLessonDetails = item.lesson_id"
                >
                    <td class="pl-1 py-4">
                        <div
                            :class="[getColor(item)]"
                            class="soli-round absolute"
                            style="width: 3px; height: 72px"
                        ></div>
                        <AttendanceDate
                            textAlignCenter
                            :date="item.lesson.start"
                            class="pl-2"
                        />
                    </td>
                    <td>
                        <div class="caption soli-grey">
                            {{
                                item.lesson.instrument_id
                                    ? `${item.lesson.instrument?.name} ${item.lesson.type}`
                                    : item.lesson.group_event.title
                            }}
                        </div>
                        <div class="soli-grey body-1">
                            {{ item.user.name }}
                        </div>
                        <AttendanceLesson :lesson="item.lesson" />
                        <div v-if="showChips(item)" class="flex py-1">
                            <v-chip
                                v-if="!item.purchase_option.paid"
                                x-small
                                color="soli-status-red"
                                class="mr-2 white--text"
                                >Unpaid</v-chip
                            >
                            <v-chip
                                v-if="item.status"
                                x-small
                                :color="
                                    item.status == 'Attended'
                                        ? 'soli-attendance-green'
                                        : 'dark'
                                "
                                class="mr-2 white--text"
                            >
                                {{ item.status }}</v-chip
                            >
                            <v-chip
                                v-if="item.converted"
                                x-small
                                color="accent"
                                class="mr-2 white--text"
                                >Enrolled</v-chip
                            >
                        </div>
                    </td>
                    <td v-if="$vuetify.breakpoint.smAndUp">
                        <v-btn icon color="secondary" class="align-self-center"
                            ><v-icon>arrow_drop_down_circle</v-icon></v-btn
                        >
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-list v-if="false" class="pa-0">
            <v-list-item
                v-for="(item, index) in events"
                :key="item.id"
                :class="{ 'mb-3': idx < events.length - 1 }"
                class="soli-round"
                style="overflow-x: auto"
                @click="clickedLessonDetails = item.lesson_id"
            >
                <div class="w-16 mr-8">
                    <AttendanceDate :date="item.lesson.start" textAlignCenter />
                </div>
                <v-list-item-content class="text-align-start">
                    <div class="caption soli-grey">
                        {{
                            item.lesson.instrument_id
                                ? `${item.lesson.instrument?.name} ${item.lesson.type}`
                                : item.lesson.group_event.title
                        }}
                    </div>
                    <div
                        :class="
                            $vuetify.breakpoint.xsOnly ? 'body-2' : 'body-1'
                        "
                    >
                        {{ item.user.name }}
                    </div>
                    <AttendanceLesson :lesson="item.lesson" />
                    <div class="flex">
                        <v-chip
                            v-if="!item.purchase_option.paid"
                            x-small
                            color="soli-status-red"
                            class="mr-2 white--text"
                            >Unpaid</v-chip
                        >
                        <v-chip
                            v-if="item.status"
                            x-small
                            :color="
                                item.status == 'Attended'
                                    ? 'soli-attendance-green'
                                    : 'dark'
                            "
                            class="mr-2 white--text"
                        >
                            {{ item.status }}</v-chip
                        >
                        <v-chip
                            v-if="item.converted"
                            x-small
                            color="accent"
                            class="mr-2 white--text"
                            >Enrolled</v-chip
                        >
                    </div>
                </v-list-item-content>
                <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                    <v-btn icon color="secondary" class="align-self-center"
                        ><v-icon>arrow_drop_down_circle</v-icon></v-btn
                    >
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <LessonDetail
            v-if="clickedLessonDetails"
            ref="details"
            :errors="$page.props.errors"
            :auth="$page.props.auth"
            :lessonId="clickedLessonDetails"
            @reset="clickedLessonDetails = null"
            @success="handleSuccess"
            @rescheduleLesson="
                [
                    (clickData = $event),
                    (bookingForm = true),
                    (clickedLessonDetails = null),
                ]
            "
            @showMessage="$emit('success')"
        />
        <CreateLesson
            v-if="bookingForm"
            :errors="errors"
            :event="clickData"
            :bookingSource="{
                isNew: false,
                isCalendar: false,
                isMySchedule: false,
                isReschedule: true,
            }"
            @reset="closeModal(() => (bookingForm = false))"
            @success="handleSuccess"
        />
    </div>
</template>

<script>
import LessonDetail from "../Lessons/LessonDetail.vue";
import CreateLesson from "./../Booking/CreateLesson.vue";
import globalMoment from "../../Mixins/GlobalMoment.js";
import AttendanceDate from "../Users/Attendance/AttendanceDate.vue";
import AttendanceLesson from "../Users/Attendance/AttendanceLesson.vue";
import AttendanceOption from "../Users/Attendance/AttendanceOption.vue";

export default {
    mixins: [globalMoment],
    props: ["events", "errors"],
    components: {
        LessonDetail,
        CreateLesson,
        AttendanceDate,
        AttendanceLesson,
        AttendanceOption,
    },
    data: function () {
        return {
            panel: false,
            clickedLessonDetails: null,
            bookingForm: false,
            clickData: null,
            location: this.$page.props.auth.user.selected_location,
        };
    },
    methods: {
        handleSuccess() {
            this.$emit("success");
            setTimeout(() => {
                this.clickedLessonDetails = null;
                this.bookingForm = false;
            }, 300);
        },
        getColor(item) {
            if (!item.purchase_option.paid) return "soli-status-red";
            if (item.converted) return "accent";
            if (item.lesson.cancelled) return "dark";
            //if (item.status == "Attended") return "soli-status-green";
            return "soli-status-group";
        },
        showChips(item) {
            return !item.purchase_option.paid || item.status || item.converted;
        },
    },
};
</script>

<style scoped>
.date-indicator {
    width: 12px;
    height: 85px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.v-list {
    color: unset !important;
}

.text-align-center {
    text-align: center;
}
</style>
