<template>
    <div>
        <div class="videoWrapper">
            <iframe
                class="soli-round"
                :src="src"
                :width="640"
                :height="360"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped>
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
