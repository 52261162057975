<template>
    <v-row dense>
        <v-col
            cols="6"
            sm="3"
            md="2"
            lg="2"
            v-for="(stat, i) in stats.filter((e) => !e.hide)"
            :key="stat.title"
        >
            <v-card class="soli-round" style="overflow: hidden" :ripple="true">
                <div
                    class="soli-round"
                    @click="selectStat(stat, $event)"
                    :class="selected == stat.filter ? 'selected' : null"
                    style="cursor: pointer"
                >
                    <div
                        :class="stat.color"
                        class="pa-2 flex-col justify-center align-content-center"
                        style="min-height: 60px"
                    >
                        <div
                            v-if="stat.header"
                            class="white--text flex justify-center subtitle-2"
                        >
                            {{ stat.header }}
                        </div>
                        <div
                            class="white--text flex justify-center whitespace-no-wrap subtitle-2"
                        >
                            {{ stat.title }}
                        </div>
                    </div>
                    <v-card-title
                        class="py-3 soli-grey justify-center"
                        style="height: 58px"
                        v-if="
                            (stat.data || stat.data === 0) &&
                            !stat.secondaryData
                        "
                    >
                        <trend
                            v-if="false"
                            style="height: 36px; position: absolute"
                            :data="stat.trend"
                            :gradient="['#6fa8dc', '#42b983', '#2c3e50']"
                            auto-draw
                            smooth
                        />
                        {{ stat.data }}
                    </v-card-title>
                    <v-row
                        v-if="stat.secondaryData || stat.secondaryData === 0"
                        class="soli-grey justify-center"
                        style="height: 58px"
                        no-gutters
                    >
                        <v-col
                            cols="6"
                            class="justify-center flex flex-col px-0 py-2"
                            ><small class="text-align-center">{{
                                stat.primaryTitle
                            }}</small>
                            <div class="text-body-1 text-align-center">
                                {{ stat.data }}
                            </div> </v-col
                        ><v-divider vertical /><v-col
                            cols="6"
                            class="justify-center flex flex-col px-0 py-2"
                            ><small class="text-align-center">{{
                                stat.secondaryTitle
                            }}</small>
                            <div class="text-body-1 text-align-center">
                                {{ stat.secondaryData }}
                            </div></v-col
                        >
                    </v-row>
                    <v-skeleton-loader
                        v-if="!stat.data && stat.data !== 0"
                        class="mx-auto"
                        max-width="300"
                        type="list-item"
                    />
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    mixins: [globalMoment],
    props: {
        inquiries: {
            type: Number,
            default: null,
        },
        leadConversion: {
            type: Number,
        },
        assessmentConversion: {
            type: Number,
        },
        membershipHours: {
            type: Number,
        },
        activeMemberships: {
            type: Number | Object,
        },
        firstVisits: {
            type: Number,
        },
        enrollments: {
            type: Number,
        },
        withdrawals: {
            type: Number,
        },
        links: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: String,
            default: null,
        },
        dateHeaders: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            stats: [
                /*{
                    title: "Membership Hours",
                    data: this.membershipHours,
                    color: "soli-status-group",
                    link: null,
                },*/
                {
                    header: "Active",
                    title: "Memberships",
                    filter: "activeMemberships",
                    data: this.activeMemberships, //.count,
                    //trend: this.activeMemberships.trend,
                    color: "soli-blue",
                    link: "/people/students",
                },
                {
                    header: this.dateHeaders
                        ? this.globalMomentMonthFull()
                        : null,
                    title: "Leads",
                    filter: "leads",
                    data: this.inquiries,
                    primaryTitle: "Total",
                    secondaryTitle: "Conversion",
                    secondaryData:
                        this.leadConversion || this.leadConversion === 0
                            ? `${this.leadConversion}%`
                            : null,
                    color: "soli-status-group",
                    link: "/dashboard/reports/leads",
                    hide: this.inquiries === null, //this.$page.url.includes("student-history"),
                },
                {
                    header: this.dateHeaders
                        ? this.globalMomentMonthFull()
                        : null,
                    title: "First Visits",
                    filter: "firstVisits",
                    data: this.firstVisits,
                    primaryTitle: "Total",
                    secondaryTitle: "Conversion",
                    secondaryData:
                        this.assessmentConversion ||
                        this.assessmentConversion === 0
                            ? `${this.assessmentConversion}%`
                            : null,
                    color: "soli-status-green",
                    link: "/dashboard/reports/first-visits",
                },
                {
                    header: this.dateHeaders
                        ? this.globalMomentMonthFull()
                        : null,
                    title: "Enrollments",
                    filter: "enrollments",
                    data: this.enrollments,
                    color: "soli-status-blue",
                    link: "/dashboard/reports/enrollments",
                },
                {
                    header: this.dateHeaders
                        ? this.globalMomentMonthFull()
                        : null,
                    title: "Withdrawals",
                    filter: "withdrawals",
                    data: this.withdrawals,
                    color: "soli-status-red",
                    link: "/dashboard/reports/withdrawals",
                },
            ],
        };
    },
    methods: {
        selectStat(stat, event) {
            if (stat.link && this.links) this.$inertia.visit(stat.link);
            this.$emit("click", stat);
            if (this.selected == stat.filter) {
                event.target.blur();
                event.target.parentElement.blur();
                event.target.parentElement.parentElement.blur();
            }
        },
    },
};
</script>

<style #scoped>
.selected {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
