<template>
    <div>
        <component
            v-if="select"
            :is="dialog.comp1"
            v-bind="{ ...dialog.props1 }"
            v-model="picker"
            ref="menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="getValue"
                    :label="label"
                    :prepend-inner-icon="icon"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules"
                    outlined
                    dense
                    :hide-details="hideDetails"
                ></v-text-field>
            </template>
            <v-time-picker
                v-model="selected"
                :allowed-minutes="allowAll ? null : allowedStep"
                full-width
                ampm-in-title
                format="ampm"
                class="soli-round"
                :min="min ?? null"
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="[(picker = false), (original = true)]"
                >
                    Cancel
                </v-btn>
                <v-btn text color="primary" @click="updateTime()">
                    OK
                </v-btn></v-time-picker
            >
        </component>

        <v-time-picker
            v-if="!select && !intervals"
            v-model="selected"
            :allowed-minutes="allowAll ? null : allowedStep"
            full-width
            ampm-in-title
            format="ampm"
            class="soli-round custom-time"
            header-color="transparent"
            :min="min ?? null"
            @input="updateTime()"
        >
            <v-spacer></v-spacer>

            <v-btn
                v-if="false"
                text
                color="primary"
                class="soli-round"
                @click="updateTime()"
            >
                Continue
            </v-btn></v-time-picker
        >

        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="list-item, list-item, list-item"
            v-if="loading"
        ></v-skeleton-loader>

        <div v-if="!select" class="py-3">
            <!--<v-btn-toggle
                v-model="selectedInterval"
                dense
                v-if="hasIntervals <= 5 && !loading"
                style="width: 100%"
                class="mt-3"
            >
                <v-row class="px-6">
                    <v-btn
                        v-for="interval in intervals"
                        :key="interval"
                        dense
                        block
                        outlined
                        :value="interval"
                        class="soli-blue-text rounded-pill mb-5"
                        @click="$emit('input', interval), $emit('selectTime')"
                        >{{ interval }}</v-btn
                    >
                </v-row>
            </v-btn-toggle>-->
            <v-btn-toggle
                v-model="selectedInterval"
                dense
                v-if="/*hasIntervals >= 6 && */ hasIntervals && !loading"
                class="w-full"
            >
                <v-row class="justify-start">
                    <v-col
                        cols="4"
                        v-for="interval in intervals"
                        :key="interval"
                        class="flex"
                    >
                        <v-btn
                            :ripple="false"
                            :value="interval"
                            :class="[
                                narrow ? 'narrow' : null,
                                'fixed-width rounded-pill',
                                activeInterval == interval
                                    ? 'active-btn shadow-blue'
                                    : null,
                            ]"
                            outlined
                            color="dark"
                            class="font-weight-bold"
                            @mousedown="activeInterval = interval"
                            @touchstart="activeInterval = interval"
                            @click="
                                $emit('input', interval), $emit('selectTime')
                            "
                            ><div
                                :class="
                                    activeInterval == interval
                                        ? 'active-text'
                                        : null
                                "
                            >
                                {{ interval }}
                            </div></v-btn
                        ></v-col
                    >
                </v-row>
            </v-btn-toggle>
            <v-col
                v-if="!hasIntervals && !loading && intervals"
                class="soli-grey py-0"
            >
                Sorry, no times are available for this selection
            </v-col>
        </div>
    </div>
</template>

<script>
import { VMenu, VBottomSheet } from "vuetify/lib";

export default {
    name: "SoliTime",
    components: {
        VMenu,
        VBottomSheet,
    },
    data: function () {
        return {
            picker: null,
            selectedTime: this.value,
            selectedInterval: this.value,
            original: false,
            activeInterval: null,
            /*showMinutes: false,
            showHours: true,
            showAMPM: false,
            selectedAMPM: null,
            hours: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12"
            ],
            minutes: ["00", "15", "30", "45"],
            selectedHour: null,
            selectedMinute: null*/
        };
    },
    props: {
        label: {
            type: String,
            default: "Start Time",
        },
        value: {
            type: String,
            default: "",
        },
        error: {
            type: String,
            default: "",
        },
        rules: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: "timer",
        },
        intervals: {
            type: Array,
            default: null,
        },
        more: {
            type: Boolean,
            default: false,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Boolean,
            default: true,
        },
        singleLesson: {
            type: Boolean,
            default: false,
        },
        isEndTime: {
            type: Boolean,
            default: false,
        },
        startTime: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        allowAll: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: null,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
    },
    methods: {
        allowedStep: (m) => m % 15 === 0,
        formatTime(time) {
            /*let formatted = new Date(
                `01 January 2000 ${time}`
            ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit"
            });*/
            let formatted = moment(new Date(`01 January 2000 ${time}`)).format(
                "h:mm A"
            );
            return formatted;
        },
        updateTime() {
            this.picker = false;
            this.original = false;
            this.selectedTime = this.value;
            this.$emit("selectTime");
        },
        /*getSelectValue() {
            let hour =
                this.selectedAMPM == "pm"
                    ? [
                          Number(this.selectedHour) + 12,
                          console.log(Number(this.selectedHour) + 12)
                      ]
                    : Number(this.selectedHour);
            let time = moment()
                .set({
                    hour: hour,
                    minute: Number(this.selectedMinute)
                })
                .format("lll");
            console.log(time);
            this.$emit("confirmTime", time);
        }*/
    },
    computed: {
        selected: {
            get() {
                return this.value
                    ? new Date(`01 January 2000 ${this.value}`)
                    : null;
            },
            set(value) {
                this.$emit("input", this.formatTime(value));
            },
        },
        getValue() {
            return this.original ? this.selectedTime : this.value;
        },
        dialog() {
            return {
                comp1: this.$vuetify.breakpoint.smAndDown
                    ? "v-bottom-sheet"
                    : "v-menu",
                props1: {
                    "close-on-content-click": false,
                    "nudge-right": "40",
                    "max-width": "450px",
                    "offset-y": true,
                    "min-width": "290px",
                },
            };
        },
        hasIntervals() {
            if (!this.intervals) return false;
            return this.intervals.length;
        },
    },
};
</script>

<style>
.narrow {
    width: 92px !important;
    padding: 0 8px;
}

.fixed-width {
    width: 106px;
}

.custom-time {
    border: none;
}

.custom-time .v-time-picker-title {
    color: slategrey;
}

.custom-time .v-picker__title {
    padding-bottom: 12px;
}

.custom-time .v-picker__body {
    transform: translate(0px, -10px);
    background: transparent;
}

.custom-time .v-time-picker-title__time .v-picker__title__btn,
.custom-time .v-time-picker-title__time span {
    height: 38px;
}

.custom-time .v-time-picker-title__time > .v-picker__title__btn {
    font-size: 56px;
}

.custom-time .v-picker__title__btn + span {
    font-size: 56px;
}

.custom-time .v-time-picker-title__ampm {
    font-size: 20px;
}

.custom-time .v-time-picker-clock {
    background: white;
}

.active-btn {
    background: #2aabe6;

    transition: background 0.3s ease-out;
}

.active-text {
    color: white;
}
</style>
