<template>
    <v-app class="min-h-screen">
        <v-app-bar
            app
            color="white"
            height="100"
            elevate-on-scroll
            :class="$vuetify.breakpoint.smAndUp ? 'px-6' : null"
        >
            <a href="https://musicologielessons.com">
                <jet-application-logo class="block h-9 w-auto"
            /></a>
        </v-app-bar>

                    <SoliForm
                        v-if="showForm"
                        title="We want to hear from you!"
                        text-subtitle="We're constantly working to improve and we want
                                to know what went right, and what we can do
                                better."
                        persistent
                        permanent
                        @submit="submit(true)"
                        @reset="showForm = false"
                        ref="form"
                    >
                        <template #body>
                            <v-row style="justify-content:space-evenly">
                                <v-btn-toggle 
                                    v-model="form.rating" 
                                    mandatory
                                >
                                    <div
                                        class="pa-2"
                                        v-for="(i, idx) in [1,2,3,4,5]"
                                        :key="idx"
                                    >
                                        <v-btn
                                            small
                                            height="55"
                                            width="55"
                                            class="round"
                                            :color="i == form.rating ? 'soli-blue' : null"
                                            :value="i"
                                            ><div class="flex-col">
                                                <div :class="[i == form.rating ? 'white--text' : 'soli-grey', 'text-xl']">
                                                {{ i }}
                                                </div>
                                            </div>                                    
                                        </v-btn>
                                        <small v-if="i == form.rating" class="flex pt-2 soli-grey">Your rating</small>
                                    </div>
                                </v-btn-toggle>

                                </v-row>

                                <br/>
                                
                                <v-textarea
                                    ref="notes"
                                    outlined
                                    dense
                                    class="soli-round"
                                    label="Please leave feedback"
                                    v-model="form.comment"
                                    :rules="[v => (v || '').length <= 255 ||
                                        `Character limit reached`]"
                                ></v-textarea>
                        </template>
                    </SoliForm>

    <div v-if="showThankYou"
        class="flex sm:justify-center pt-6 sm:pt-0 overflow-hidden"
    >
        <v-container class="flex justify-center align-center">
            <div>
                <div class="flex-col">
                    <lottie-player
                        src="https://assets3.lottiefiles.com/packages/lf20_no08B3.json"
                        background="transparent"
                        style="width: inherit; height: 300px"
                        speed="1"
                        loop
                        autoplay
                    ></lottie-player>
                    <v-card-title class="soli-grey justify-center"
                        >Thank you!</v-card-title
                    >
                    <SoliBtn
                        class="justify-center w-full"
                        text="Return Home"
                        :href="route('login')"
                        gradient="gradient-blue"
                    ></SoliBtn>
                </div>
            </div>
        </v-container>
    </div>
    </v-app>
</template>

<script>
import JetApplicationLogo from "./../Jetstream/ApplicationLogo";

export default {
    components: {
        JetApplicationLogo,
    },
    props: ['lesson', 'rating', 'user', 'errors'],
    data() {
        return {
            showThankYou: false,
            showForm: true,
            form: this.$inertia.form({
                rating: this.rating,
                lesson_id: this.lesson.id,
                user_id: this.user.id,
                comment: this.lesson.feedback?.comment,
            }),
        };
    },
    methods: {
        submit(complete){
            this.form.post(route('feedback.store'), {
                onSuccess: ()=>{
                    if(complete){
                        this.showThankYou = true;
                        this.$refs.form.reset()
                    }
                },
                onFailure: (errors)=>{
                    console.log(JSON.parse(errors))
                }
            })
        },
    },
    mounted(){
        this.submit(false)
        if (document.querySelector("#lottie")) return;
        let lottie = document.createElement("script");
        lottie.setAttribute(
            "src",
            "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        );
        lottie.id = "lottie";
        document.head.appendChild(lottie);
    }
};
</script>

<style scoped>
.round {
    border-radius: 50% !important;
}
</style>