<template>
    <div class="contents">
        <editor
            :key="refreshKey"
            ref="editor"
            class="tiny-editor"
            api-key="4wu8sj881tkfcg0i74t9scvjecuik9of64nw6tazu5iu8uqe"
            :init="config"
            v-model="content"
            v-on:onChange="this.update"
        ></editor>
        <small class="error--text px-3">{{ errors }}</small>
    </div>
</template>

<script>
export default {
    name: "TinyEditor",
    components: {
        editor: () => import("@tinymce/tinymce-vue"),
    },
    data: function () {
        return {
            content: this.value,
            config: {
                selecor: "#message-content",
                plugins: "autoresize lists link autolink emoticons", // image table code help wordcount",
                toolbar:
                    " bold italic underline strikethrough| link | bullist numlist | emoticons",
                width: "100%",
                resize: false,
                menubar: false,
                branding: false,
                statusbar: false,
                link_assume_external_targets: true,
                autoresize_bottom_margin: 0,
                placeholder: "Enter message...",
                file_picker_types: "file image media",

                /*setup: function (editor) {
                    editor.ui.registry.addButton("mySendButton", {
                        tooltip: "Send Message",
                        text: "Send",
                        onAction: function () {
                            this.submit();
                        },
                    });
                },*/
            },
            refreshKey: 1,
        };
    },
    props: {
        value: {
            type: String,
            default: "",
        },
        errors: {},
    },
    methods: {
        update() {
            this.$emit("input", this.content);
        },
        submit() {
            this.$emit("submit");
        },
    },
    mounted() {
        setTimeout(() => {
            this.refreshKey += 1;
        }, 200);
    },
};
</script>

<style>
.tox-tinymce {
    border-radius: 18px !important;
}
</style>
