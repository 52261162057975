<template>
    <div>
        <SoliBtn
            v-if="$vuetify.breakpoint.smAndDown && showFab"
            round
            fab
            fixed
            right
            :loading="fabLoading"
            :class="[
                selectedPeople.length ? 'shadow-blue-xl' : 'shadow-orange-xl',
                'mobile-fab',
                //$vuetify.breakpoint.smAndDown ? 'mobile-fab' : null
            ]"
            :icon="selectedPeople.length ? 'email' : 'add'"
            :gradient="
                selectedPeople.length ? 'gradient-blue' : 'gradient-orange'
            "
            @click="$emit('fabAction')"
        ></SoliBtn>
        <v-bottom-navigation grow app class="bottom-nav-bar shadow-grey">
            <v-btn
                v-for="item in items.filter((e) => e.permission)"
                :key="item.title"
                ref="bottomNav"
                :class="[
                    'py-2 soli-link',
                    page === item.comp || page == item.link
                        ? 'v-btn--active'
                        : 'btn-force-inactive',
                ]"
                @click="visit(item.link)"
                small
                active-class="btn-force-inactive"
            >
                <span class="mt-1">{{ item.title }}</span>
                <v-badge
                    v-if="item.badge"
                    :content="$page.props.unreadMessageCount"
                    :color="$page.props.unreadMessageCount ? 'soli-red' : null"
                    overlap
                >
                    <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>{{ item.icon }}</v-icon>
            </v-btn>
        </v-bottom-navigation>
        <ProfileMenu
            v-model="profileMenu"
            :page="page"
            @locationSelect="$emit('locationSelect')"
            @close="profileMenu = false"
            @success="$emit('success')"
        />
    </div>
</template>

<script>
import ProfileMenu from "./ProfileMenu.vue";

export default {
    name: "bottomnav",
    props: ["page", "selectedPeople", "showFab", "fabLoading"],
    components: {
        ProfileMenu,
    },
    data() {
        return {
            profileMenu: false,
            showNav: true,
        };
    },
    computed: {
        items() {
            return this.$page.props.staffView
                ? [
                      {
                          title: this.$vuetify.breakpoint.xs
                              ? "Dash"
                              : "Dashboard",
                          icon: "dashboard",
                          link: "/dashboard",
                          comp: "/dashboard",
                          permission: true,
                      },
                      {
                          title: "Inbox",
                          icon: "chat_bubble_outline",
                          link: "/inbox",
                          comp: "/inbox",
                          badge: true,
                          permission: true,
                      },
                      {
                          title: "Schedule",
                          icon: "event",
                          link: `/schedule`, ///${moment(new Date()).format("YYYY-MM-DD")}`,
                          comp: "/schedule",
                          permission: true,
                      },
                      {
                          title: "Help",
                          icon: "help",
                          link: "/help-categories",
                          permission: true,
                      },
                      {
                          title: "More",
                          icon: "more_vert",
                          link: null,
                          permission: true,
                      },
                  ]
                : [
                      {
                          title: "Explore",
                          //icon: "add_task",
                          icon: "explore",
                          link: "/explore",
                          permission:
                              !this.$page.props.auth.user.virtual_client,
                      },

                      {
                          title: "Inbox",
                          icon: "chat_bubble_outline",
                          link: "/inbox",
                          comp: "/inbox",
                          badge: true,
                          permission: true,
                      },
                      {
                          title: "Lessons",
                          icon: "event",
                          link: "/myschedule",
                          permission: true,
                      },
                      {
                          title: "Help",
                          icon: "help",
                          link: "/help-categories",
                          permission: true,
                      },
                      {
                          title: "More",
                          icon: "more_vert",
                          link: null,
                          permission: true,

                          //link: `/students/${this.$page.props.auth.user.id}`
                      },
                  ];
        },
    },
    methods: {
        visit(link) {
            if (link) {
                return this.$inertia.get(link);
            }
            this.profileMenu = true;
        },
    },
    update() {
        console.log("updating");
        if (
            this.$refs.bottomNav.classList.value.includes(
                "v-app-bar--hide-shadow"
            )
        ) {
            this.$emit("hidden", true);
        }
    },
};
</script>

<style>
/*.v-icon {
  color: white !important;
}*/

.v-item-group.v-bottom-navigation .v-btn {
    min-width: 70px;
    font-size: 11px;
}

.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
    color: #228cbd !important;
}

.bottom-nav-bar {
    padding-bottom: env(safe-area-inset-bottom);
    height: auto !important;
}

.mobile-fab {
    margin-bottom: 76px !important;
    z-index: 12;
    margin-right: env(safe-area-inset-right) !important;
    bottom: env(safe-area-inset-bottom, 12px);
}

.v-item-group.v-bottom-navigation .v-btn {
    min-width: 60px !important;
    max-width: 96px !important;
}

/*.bottom-toolbar.v-app-bar .v-app-bar--fixed {
    top: initial !important;
    bottom: 0 !important;
}

.bottom-toolbar .v-app-bar.v-app-bar--fixed {
    top: initial !important;
    bottom: 0 !important;
}

.bottom-toolbar .v-app-bar--fixed {
    top: initial !important;
    bottom: 0 !important;
}

.bottom-toolbar .v-app-bar {
    top: initial !important;
    bottom: 0 !important;
}*/

.v-item-group.v-bottom-navigation .v-btn--active.btn-force-inactive {
    color: grey !important;
}
</style>
