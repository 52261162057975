<template>
    <v-navigation-drawer
        app
        permanent
        class="soli-grey shadow-sidenav"
        width="200"
        touchless
        dark
        mini-variant
        style="z-index: 10"
    >
        <Link :href="'/'">
            <v-list-item class="mb-4 mt-1 flex justify-center">
                <jet-application-mark
                    class="block h-9 w-auto"
                    style="height: 2rem !important"
                />
            </v-list-item>
        </Link>
        <v-list>
            <Link v-for="item in tabs" :key="item.title" :href="item.link" link>
                <!-- admin sidenav-->
                <v-list-item
                    v-if="item.permission"
                    link
                    :class="[
                        page === item.comp || page == item.link.split('#')[0]
                            ? activeClass
                            : '',
                    ]"
                >
                    <v-list-item-icon class="flex flex-col">
                        <v-badge
                            v-if="item.badge"
                            :content="$page.props.unreadMessageCount"
                            :color="
                                $page.props.unreadMessageCount
                                    ? 'soli-red'
                                    : null
                            "
                            overlap
                        >
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-badge>
                        <v-icon v-else>{{ item.icon }}</v-icon>
                        <v-list-item-subtitle v-if="showMacros">{{
                            item.macro
                        }}</v-list-item-subtitle>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-if="false">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </Link>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import JetApplicationMark from "./../Jetstream/ApplicationMark";
import JetApplicationLogo from "./../Jetstream/ApplicationLogo";
import { Link } from "@inertiajs/vue2";

export default {
    name: "sidenav",
    props: {
        page: {
            type: String,
            default: "",
        },
        showMacros: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeClass: "v-list-item--active",
            tabs: [
                {
                    title: "Dashboard",
                    icon: "dashboard",
                    link: "/dashboard",
                    comp: "/dashboard",
                    permission: true,
                    macro: "+ D",
                },
                {
                    title: "Inbox",
                    icon: "chat_bubble_outline",
                    link: "/inbox",
                    comp: "/inbox",
                    permission: true,
                    badge: true,
                    macro: "+ I",
                },
                {
                    title: "People",
                    icon: "people",
                    link: "/people",
                    comp: "/people",
                    permission: true,
                    macro: "+ P",
                },
                {
                    title: "Schedule",
                    icon: "event",
                    link: `/schedule`,
                    comp: "/schedule",
                    permission: true,
                    macro: "+ S",
                },
                {
                    title: "Store",
                    icon: "local_offer",
                    link: "/store/frequent",
                    comp: "/store",
                    permission: true,
                    macro: "+ C",
                },
                {
                    title: "Help",
                    icon: "help",
                    link: "/help-articles",
                    comp: "/help-categories",
                    permission: true,
                    macro: "+ H",
                },
                /*{
                    title: "Reports",
                    icon: "analytics",
                    link: "/reports",
                    isCM: true,
                },*/
            ],
        };
    },
    components: {
        JetApplicationMark,
        JetApplicationLogo,
        Link,
    },
};
</script>

<style scoped>
.v-icon .desktop-sidenav {
    color: white !important;
}
</style>
