$body-font-family: "Nunito";

/*$material-light: (
  'background': #f2f2f2
);*/

.v-application {
    font-family: $body-font-family !important;
}

$skeleton-loader-image-height: 124px;
