<template>
    <v-input
        :value="value"
        :rules="rules"
        :error-messages="errorMessages"
        hide-details="auto"
    >
        <v-col class="pa-0">
            <v-rating
                v-model="getValue"
                length="11"
                class="flex flex-wrap justify-between py-3 mx-auto"
                :class="{
                    'nps-collapse': $vuetify.breakpoint.smAndDown,
                }"
                ><template v-slot:item="props">
                    <v-btn
                        large
                        outlined
                        icon
                        @click="props.click()"
                        :class="props.isFilled ? 'soli-blue white--text' : ''"
                        ><span class="font-weight-black">{{
                            props.index
                        }}</span></v-btn
                    ></template
                >
            </v-rating>
            <v-list-item
                dense
                class="mx-auto"
                :class="{ 'nps-collapse': $vuetify.breakpoint.smAndDown }"
                ><small class="grey--text px-1">0 - {{ labels[0] }}</small
                ><v-spacer /><small class="grey--text px-1"
                    >10 - {{ labels[1] }}</small
                ></v-list-item
            ></v-col
        ></v-input
    >
</template>

<script>
export default {
    name: "NPSOptions",
    props: {
        value: {
            type: Number,
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        errorMessages: {},
        labels: {
            type: Array,
            default: () => ["Not likely", "Very likely"],
        },
    },
    computed: {
        getValue: {
            get() {
                if (this.value === null) return null;
                return this.value + 1;
            },
            set(value) {
                this.$emit("input", value - 1);
            },
        },
    },
};
</script>

<style>
.nps-collapse {
    max-width: 320px;
    gap: 6px;
    justify-content: center;
}
</style>
