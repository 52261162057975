<template>
    <div class="flex justify-start">
        <v-chip
            x-small
            :class="certMap[teacher.certification_level]"
            class="soli-grey px-1"
        >
            <div :class="['align-center flex']">
                <v-icon class="soli-grey" x-small>star</v-icon>
                <div
                    class="font-bold"
                    style="font-size: 11px; line-height: 11px"
                >
                    {{
                        `Musicologie Certified: Level ${teacher.certification_level}`
                    }}
                </div>
            </div></v-chip
        >
        <!--<v-chip
      v-if="teacher.isCM"
      x-small
      color="primary"
      class="white--text px-1"
    >
      <div
        :class="[
          'align-center',
          $vuetify.breakpoint.width >= 375 ? 'flex' : null,
        ]"
      >
        <v-icon class="white--text" x-small>groups</v-icon>
        <div class="font-bold" style="font-size: 11px; line-height: 11px">
          Community Manager
        </div>
      </div></v-chip
    >-->
    </div>
</template>

<script>
export default {
    props: ["teacher"],
    data: function () {
        return {
            certMap: {
                M1: "cert-badge-m1",
                M2: "cert-badge-m2",
                M3: "cert-badge-m3",
                M4: "cert-badge-m4",
                M5: "cert-badge-m5",
            },
        };
    },
};
</script>

<style>
.cert-badge-m1 {
    background: rgba(204, 204, 204, 0.25) !important;
}

.cert-badge-m2 {
    background: rgba(123, 224, 169, 0.25) !important;
}

.cert-badge-m3 {
    background: rgba(186, 123, 224, 0.25) !important;
}

.cert-badge-m4 {
    background: rgba(245, 141, 105, 0.25) !important;
}

.cert-badge-m5 {
    background: rgba(123, 177, 224, 0.25) !important;
}
</style>
