<template>
    <div>
        <v-bottom-sheet
            :value="value"
            class="safe-bottom"
            @click:outside="$emit('close')"
        >
            <v-card class="half-round" elevation="0" min-height="90vh">
                <v-btn icon @click="$emit('close')" class="soli-close ma-3"
                    ><v-icon>close</v-icon></v-btn
                >
                <v-card-title class="shadow-blue py-4">
                    <v-avatar
                        class="shadow-blue mr-4"
                        style="float: left"
                        :size="64"
                    >
                        <v-img
                            :src="$page.props.auth.user.profile_photo_url"
                        ></v-img>
                    </v-avatar>
                    <div>
                        <v-list-item-title class="soli-grey title">
                            {{ $page.props.auth.user.first_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <Link
                                :href="`/profile/${$page.props.auth.user.id}/account`"
                            >
                                View Profile
                            </Link>
                        </v-list-item-subtitle>
                    </div>
                </v-card-title>
                <v-bottom-navigation
                    grow
                    app
                    class="bottom-nav-bar shadow-grey"
                    v-if="$page.props.staffView"
                >
                    <v-btn
                        v-for="item in items"
                        :key="item.title"
                        :class="[
                            'py-2 soli-link',
                            page === item.comp || page == item.link
                                ? 'v-btn--active'
                                : 'btn-force-inactive',
                        ]"
                        @click="$inertia.get(item.link)"
                        small
                        active-class="btn-force-inactive"
                    >
                        <span class="mt-1">{{ item.title }}</span
                        ><v-icon>{{ item.icon }}</v-icon></v-btn
                    ></v-bottom-navigation
                >
                <v-container
                    ><v-list>
                        <div v-if="is('Admin') && $page.props.staffView">
                            <v-list-item
                                class="soli-grey soli-round no-link"
                                href="/admin"
                                >Admin Panel</v-list-item
                            >
                            <v-divider />
                        </div>
                        <!--<v-list-item>Notifications</v-list-item>-->

                        <!--<div>
                            <Link href="/messages"
                                ><v-list-item class="soli-grey no-link">
                                    Messages
                                </v-list-item>
                            </Link>
                            <v-divider v-if="!$page.props.staffView" />
                        </div>-->
                        <!--<div v-if="$page.props.staffView">
                            <Link href="/people"
                                ><v-list-item class="soli-grey no-link">
                                    People
                                </v-list-item>
                            </Link>
                        </div>
                        <div v-if="$page.props.staffView">
                            <Link href="/store"
                                ><v-list-item class="soli-grey no-link">
                                    Store
                                </v-list-item>
                            </Link>
                            <v-divider />
                        </div>-->

                        <!--<div
                        v-if="
                            $page.props.staffView && can('View generic reports')
                        "
                    >
                        <v-list-item class="soli-grey"
                            ><Link href="/reports"> Reports</Link>
                        </v-list-item>
                        <v-divider />
                    </div>-->
                        <!--<v-list-item
                        ><Link href="/myschedule"
                            >My Schedule</Link
                        ></v-list-item
                    >-->
                        <div
                            v-if="
                                $page.props.canWorkSummary &&
                                $page.props.auth.user.locations.length > 1
                            "
                        >
                            <v-list-item
                                class="soli-grey flex soli-round"
                                @click="$emit('locationSelect')"
                            >
                                {{ $page.props.auth.user.selected_location.name
                                }}<v-spacer /><v-icon color="primary"
                                    >my_location</v-icon
                                ></v-list-item
                            >
                        </div>
                        <div v-if="can('Work')">
                            <v-list-item
                                class="soli-grey soli-round flex"
                                @click="setView"
                            >
                                Student View
                                <v-spacer />
                                <v-icon
                                    large
                                    :color="toggleView ? 'dark' : 'accent'"
                                    >{{
                                        toggleView ? "toggle_off" : "toggle_on"
                                    }}</v-icon
                                >
                            </v-list-item>
                            <v-divider v-if="!$page.props.staffView" />
                        </div>
                        <div v-if="is('Teacher') && $page.props.staffView">
                            <Link
                                :href="`/profile/${$page.props.auth.user.id}/availability`"
                                :data="{ dayView: 1 }"
                                ><v-list-item class="soli-grey no-link"
                                    >Manage Availability</v-list-item
                                ></Link
                            >
                        </div>
                        <div
                            v-if="
                                can('Use time clock') && $page.props.staffView
                            "
                        >
                            <v-list-item
                                @click="showTimeClock = true"
                                class="soli-grey soli-round"
                            >
                                Time Clock
                                <v-icon
                                    v-if="$page.props.currentTimeClock"
                                    class="animate__animated animate__pulse animate__repeat-3 justify-end flex"
                                    right
                                    color="error"
                                    >timer</v-icon
                                >
                            </v-list-item>
                            <v-divider />
                        </div>
                        <v-list-item
                            v-if="!$page.props.staffView"
                            class="soli-grey soli-round no-link"
                            href="/update-billing"
                        >
                            Manage Payment Methods
                        </v-list-item>
                        <v-list-item
                            class="soli-grey soli-round no-link"
                            @click="composeEmail = true"
                            >Share Feedback</v-list-item
                        >
                        <v-list-item
                            class="soli-grey soli-round no-link"
                            href="https://musicologielessons.com/privacy"
                            target="_blank"
                            >Privacy Policy</v-list-item
                        >
                        <v-divider />
                        <form @submit.prevent="logout">
                            <v-list-item
                                class="soli-round"
                                style="color: #f44336 !important"
                                @click="logout"
                                >Logout
                            </v-list-item>
                        </form></v-list
                    ></v-container
                ></v-card
            ></v-bottom-sheet
        >
        <TimeClock
            v-if="showTimeClock"
            :user="$page.props.auth.user"
            @success="$emit('success')"
            @reset="closeModal(() => (showTimeClock = false))"
        />
        <ComposeEmail
            v-if="composeEmail"
            :emails="['feedback@musicologie.com']"
            :errors="errors"
            :user="$page.props.auth.user"
            @success="emailSuccess()"
            @reset="closeModal(() => (composeEmail = false))"
        />
    </div>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import globalMoment from "../Mixins/GlobalMoment";
import ComposeEmail from "./Users/ComposeEmail.vue";
import TimeClock from "./TimeClock.vue";

export default {
    components: {
        Link,
        ComposeEmail,
        TimeClock,
    },
    mixins: [globalMoment],
    props: ["page", "errors", "value"],
    data() {
        return {
            locationSelect: false,
            showTimeClock: false,
            composeEmail: false,
            toggleView: this.$page.props.staffView,
        };
    },
    computed: {
        getView() {
            return this.$page.props.staffView ? "toggle_off" : "toggle_on";

            return this.$page.props.staffView //this.component == "Explore" || this.component == "MySchedule"
                ? "Student View"
                : "Staff View";
        },
        items() {
            return [
                {
                    title: "People",
                    icon: "people",
                    link: "/people",
                    comp: "/people",
                },
                {
                    title: "Store",
                    icon: "local_offer",
                    link: "/store/frequent",
                    comp: "/store",
                },
            ];
        },
    },
    methods: {
        emailSuccess() {
            this.$emit("success");
            this.closeModal(() => (this.composeEmail = false));
        },
        setView() {
            this.toggleView = !this.toggleView;
            this.$inertia.get("/mode");
        },
        logout() {
            axios.post(route("logout")).then((response) => {
                window.location = "/";
            });
        },
    },
};
</script>

<style scoped>
.v-application .v-list-item a {
    color: #4b6876 !important;
}

.v-dialog__content--active {
    backdrop-filter: blur(1px);
}
</style>
