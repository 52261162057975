<template>
    <GetStartedLayout
        ref="layout"
        :errors="errors"
        noContainer
        fluid
        :title="/*`Book a ${form.instrument.name} Lesson`*/ 'Find a Lesson Time'"
        :breadcrumbs="breadcrumbs"
        :guest="guest"
        :child="child"
        :review="review"
        :hideNavOnScroll="$vuetify.breakpoint.xs"
        :location="guest.location"
    >
        <template #toggleFilter>
            <v-menu v-model="speedDial" nudge-bottom="36" left v-if="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-model="speedDial"
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        outlined
                        color="white"
                        :icon="$vuetify.breakpoint.xs"
                        small
                        >{{ $vuetify.breakpoint.smAndUp ? "Options" : ""
                        }}<v-icon
                            :right="$vuetify.breakpoint.smAndUp"
                            color="white"
                            >tune</v-icon
                        ></v-btn
                    ></template
                >
                <v-card class="soli-round" width="250">
                    <v-container>
                        <v-chip-group
                            v-model="selection"
                            column
                            class="justify-end"
                        >
                            <v-chip
                                v-model="chip.show"
                                v-for="chip in selects"
                                :key="chip.name"
                                :value="chip.name"
                                :id="chip.name"
                                outlined
                                dark
                                color="dark"
                                :disabled="chip.disabled"
                                :class="[
                                    //'primary primary--text v-chip--outlined shadow-blue',
                                    'font-weight-bold v-chip--outlined mx-1',
                                ]"
                                :ripple="false"
                                >{{ chip.display()
                                }}<v-icon small class="ml-1"
                                    >expand_more</v-icon
                                ></v-chip
                            ></v-chip-group
                        ></v-container
                    ></v-card
                ></v-menu
            ></template
        >
        <v-col
            class="pt-0 white--text text-center px-0 mx-auto"
            cols="12"
            sm="11"
            md="10"
            lg="8"
        >
            <v-row no-gutters justify="center" class="mb-3" v-if="false">
                <v-chip-group
                    v-model="selection"
                    content-class="px-05"
                    center-active
                    ><v-chip
                        v-for="chip in selects"
                        v-model="chip.show"
                        :value="chip.name"
                        :key="chip.name"
                        :id="chip.name"
                        outlined
                        style="border: 1px solid white"
                        dark
                        :disabled="chip.disabled"
                        :class="[
                            //'primary primary--text v-chip--outlined shadow-blue',
                            'font-weight-bold v-chip--outlined mx-1',
                        ]"
                        :ripple="false"
                        >{{ chip.display()
                        }}<v-icon small class="ml-1"
                            >expand_more</v-icon
                        ></v-chip
                    ></v-chip-group
                >
            </v-row>

            <v-card
                class="mx-auto"
                :tile="$vuetify.breakpoint.xs"
                :class="{ 'soli-round': $vuetify.breakpoint.smAndUp }"
            >
                <v-container
                    class="px-0"
                    :class="{ 'pa-5': $vuetify.breakpoint.smAndUp }"
                >
                    <v-row
                        no-gutters
                        justify="center"
                        class="sticky-selects"
                        :class="{
                            'sticky-padded': !$refs.layout?.appBarHidden,
                        }"
                    >
                        <v-chip-group
                            v-model="selection"
                            center-active
                            v-if="!virtualClient"
                            ><v-chip
                                v-for="(chip, name, idx) in selects"
                                v-model="chip.show"
                                :value="chip.name"
                                :key="chip.name"
                                :id="chip.name"
                                v-show="!chip.hidden"
                                :disabled="chip.disabled"
                                :class="[
                                    'font-weight-bold v-chip--outlined soli-grey',
                                    idx == 0 ? 'ml-3' : 'mx-1',
                                    idx == selects.length - 1 ? 'mr-3' : 'mx-1',
                                ]"
                                :ripple="false"
                                >{{ chip.display()
                                }}<v-icon small class="ml-1"
                                    >expand_more</v-icon
                                ></v-chip
                            ></v-chip-group
                        >
                        <v-chip-group
                            center-active
                            v-model="virtualClientAlert"
                            v-else
                            ><v-chip
                                v-for="(chip, name, idx) in selects"
                                :value="chip.name"
                                :key="chip.name"
                                :id="chip.name"
                                v-show="!chip.hidden"
                                :disabled="chip.disabled"
                                :class="[
                                    'font-weight-bold v-chip--outlined soli-grey',
                                    idx == 0 ? 'ml-3' : 'mx-1',
                                    idx == selects.length - 1 ? 'mr-3' : 'mx-1',
                                ]"
                                :ripple="false"
                                >{{ chip.display()
                                }}<v-icon
                                    small
                                    class="ml-1"
                                    v-if="!virtualClient"
                                    >expand_more</v-icon
                                ></v-chip
                            ></v-chip-group
                        >
                    </v-row>

                    <SoliDateScroll
                        ref="date"
                        :error="errors.starts_at"
                        :future="true"
                        :events="
                            searchAssignments.map((e) =>
                                globalMomentYMD(e.starts_at)
                            )
                        "
                        :select="false"
                        :intervalsLoading="apiLoading"
                        :intervals="assignmentIntervals"
                        :loading="assignmentsLoading"
                        :selectedInstrument="form.instrument?.id"
                        :selectedLocation="form.location"
                        :floating="false"
                        @input="selectDate($event)"
                        @inputTime="selectTime($event)"
                        v-model="form.startDate"
                        :class="{
                            'grow-height': !!form.startDate,
                            'shrink-height': $vuetify.breakpoint.smAndUp,
                            'shrink-height-mobile': $vuetify.breakpoint.xs,
                        }"
                    />
                    <v-container class="justify-center flex" v-if="true">
                        <v-card-subtitle
                            class="soli-grey text-align-start"
                            style="max-width: 600px"
                            >*Lessons can be scheduled up to two weeks in the
                            future. Please try and select a time that works for
                            you on a weekly basis.</v-card-subtitle
                        >
                    </v-container>
                </v-container>
            </v-card>

            <SoliResponsiveDialog
                @reset="[(selects[selection].show = false), (selection = null)]"
                ref="selection"
                v-if="selection"
                :close="false"
                ><template #body>
                    <div
                        class="px-0"
                        :class="{ 'py-3': $vuetify.breakpoint.xs }"
                    >
                        <component
                            :is="selects[selection].comp"
                            v-bind="{ ...selects[selection].props() }"
                            v-model="form[selection]"
                            @input="resetOptions()"
                        ></component></div
                ></template>
            </SoliResponsiveDialog>
            <div v-for="alert in alerts" :key="alert?.subtitle">
                <SoliChoice
                    v-if="alert"
                    :title="alert.title"
                    :subtitle="alert.subtitle"
                    :button1="alert.button1"
                    :button2="alert.button2"
                    :warning="alert.warning"
                    @option1="alert.option1()"
                    @option2="alert.option2()"
                    :permanent="alert.permanent"
                    ><template>
                        <v-list dense v-if="!futureBookingConflict?.date">
                            <v-list-item
                                v-for="conflict in futureBookingConflict"
                                :key="conflict.starts_at"
                                ><small class="soli-grey">{{
                                    `· ${conflict.type} on ${globalMomentDate(
                                        conflict.starts_at
                                    )}`
                                }}</small></v-list-item
                            >
                        </v-list>
                    </template></SoliChoice
                >
            </div>
        </v-col>
        <v-bottom-sheet
            v-if="false"
            v-model="showSubmit"
            @click:outside="showSubmit = false"
            ><v-card class="half-round"
                ><v-container style="max-width: 600px">
                    <Lesson
                        :lesson="{ ...form, starts_at: $_data_lessonStart }"
                        :errors="errors"
                        hideMakeAChange
                        wide
                        size="64"
                        :guest="guest" />
                    <SoliBtn
                        block
                        text="Continue to Checkout"
                        style="color: white !important"
                        gradient="gradient-orange" /></v-container></v-card
        ></v-bottom-sheet>

        <SoliChoice
            v-if="virtualClientAlert"
            title="Online Lessons for Busy Adults"
            subtitle="This program is only available with the selected options at this time. If you'd like to explore other options, please contact us."
            button1="Close"
            button2="Contact Us"
            @option1="closeModal(() => (virtualClientAlert = false))"
            @option2="
                closeModal(() => {
                    virtualClientAlert = false;
                    $refs.layout.showContact();
                })
            "
            @reset="closeModal(() => (virtualClientAlert = false))"
        />
    </GetStartedLayout>
</template>

<script>
import GetStartedLayout from "../../Layouts/GetStartedLayout.vue";
import { Link } from "@inertiajs/vue2";
import GlobalMoment from "../../Mixins/GlobalMoment";
import BookingAPIs from "../../Mixins/Booking/BookingAPIs";
import BookingAlerts from "../../Mixins/Booking/BookingAlerts";
import BookingData from "../../Mixins/Booking/BookingData";
import Lesson from "../../Components/GetStarted/Lesson.vue";

export default {
    components: {
        GetStartedLayout,
        Link,
        GlobalMoment,
        BookingAPIs,
        BookingAlerts,
        Lesson,
    },
    mixins: [BookingAPIs, BookingAlerts, BookingData, GlobalMoment],
    props: {
        errors: {},
        /*relationships: {
            type: Array,
            default: () => [],
        },*/
        guest: {
            type: Object,
            default: () => {},
        },
        child: {
            type: Object,
            default: () => {},
        },
        lesson: {
            type: Object,
            default: null,
        },
        instruments: {
            type: Array,
            default: () => [],
        },
        locations: {
            type: Array,
            default: () => [],
        },
        ageRanges: {
            type: Array,
            default: () => [],
        },
        online: {
            type: Boolean,
            default: false,
        },
        review: {
            type: Object,
            default: null,
        },
    },
    name: "BookNow",
    data() {
        return {
            breadcrumbs: this.guest.virtual_client
                ? ["Book Now"]
                : ["Get Started", "Programs", "Book Now"],
            showSubmit: false,
            conflicts: [],
            bookingSource: { isGuestSession: true },
            assignment: null,
            availability: null,
            virtualClient: this.guest.virtual_client,
            virtualClientAlert: false,
            form: this.$inertia.form({
                instrument: this.child?.instrument ?? this.guest.instrument,
                location: this.guest.virtual_client
                    ? null
                    : this.guest.location,
                ageRange: this.child?.age_range ?? {
                    name: "Adults (18+)",
                },
                online: this.guest.online,
                duration: this.guest.virtual_client ? 60 : 30,
                startDate: null,
                startTime: null,
                repeating: false,
                type: {
                    name: "Assessment",
                    originalType: "Assessment",
                    recurring: false,
                    disabled: false,
                    assessment: true,
                    pending: true,
                },
                groupEvent: null,
                requireAssignment: true,
                rrule_freq: "WEEKLY",
                rrule_int: "1",
                studentRescheduleGroupEvent: false,
                assessmentType: "Assessment",
                notes: null,
                guest_id: this.guest.id,
                capacity: 1,
                occurrences: 1,
            }),
            speedDial: false,
            selection: null,
            selects: {
                location: {
                    name: "location",
                    show: false,
                    hidden: this.guest.virtual_client,
                    display: () => this.form.location?.name,
                    comp: "SoliLocations",
                    props: () => ({
                        hideDetails: true,
                        showAddress: true,
                        iterator: true,
                        parentLocations: this.locations,
                    }),
                },
                instrument: {
                    name: "instrument",
                    show: false,
                    hidden: false,
                    display: () => this.form.instrument?.name,
                    comp: "SoliInstruments",
                    props: () => ({
                        hideDetails: true,
                        iterator: true,
                        autofocus: true,
                        parentInstruments: this.instruments,
                    }),
                },
                online: {
                    name: "online",
                    show: false,
                    hidden: false,
                    display: () => (this.form.online ? "Online" : "In-Studio"),
                    comp: "SoliOnline",
                    props: () => ({
                        location: this.form?.location,
                    }),
                },
                duration: {
                    name: "duration",
                    show: false,
                    display: () => this.form.duration + " min",
                    disabled: false,
                    hidden: false,
                    comp: "SoliDuration",
                    props: () => ({
                        filter: [60, 45, 90, 15],
                    }),
                },
                ageRange: {
                    name: "ageRange",
                    show: false,
                    hidden: false,
                    display: () => this.form.ageRange?.name,
                    comp: "SoliBtnList",
                    props: () => ({
                        items: this.ageRanges,
                    }),
                },
            },
        };
    },

    methods: {
        selectDate(date) {
            this.form.startDate = date;
            this.$_apis_getAssignmentOpenings();
        },
        selectTime(event) {
            // order of data assignment matters here
            this.form.startTime = event.time;
            this.form.teacher = event.teacher;
            this.assignment = this.searchAssignments.find(
                (e) => e.id == event.assignment_id
            );
            this.availability = this.assignment.availability;
            this.form.room = this.assignment.room;
            //this.showSubmit = true;
            this.submit();
        },
        resetOptions() {
            this.assignmentIntervals = null;
            this.$_apis_getAssignments();
            if (this.form.startDate) {
                this.form.startDate = null;
                this.searchAssignments = [];
            }
            this.closeDialogs();
        },
        getStarted() {
            this.$inertia.visit("/get-started");
        },
        closeDialogs() {
            this.$refs.selection.show = false;

            this.closeModal(() => {
                this.selects[this.selection].show = false;
                this.selection = null;
            });
        },
        submit() {
            this.form
                .transform((form) => ({
                    ...form,
                    starts_at: this.$_data_lessonStart.format("lll"),
                    futureEvents: false,
                    user_id: this.form.teacher.id,
                    room_id: this.form.room.id,
                    instrument_id: form.instrument.id,
                    repeating: false,
                    type: "Pending",
                    conflicts: this.conflicts,
                    status: null,
                    noConflict: this.noConflict,
                    billingUser: null,
                    assignment_id: this.assignment.id,
                    availability_id: this.availability.id,
                    student_id: null,
                    courtesyReschedule: false,
                    hashId: this.guest.id,
                }))
                .post(route("getStarted.hold", this.child?.hash_id), {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {},
                    onError: (errors) => {
                        console.log(
                            "Form returned errors: " + JSON.stringify(errors)
                        );
                        if (errors.contactSupport) return;
                        if (errors.soliError) return;
                        if (errors.date) {
                            this.showFutureConflictAlert(errors);
                        } else {
                            let futureConflicts = [];
                            Object.values(errors).forEach((e) => {
                                futureConflicts.push(JSON.parse(e));
                            });
                            this.showFutureConflictAlert(futureConflicts);
                            this.showAssessmentConflictAlert(futureConflicts);
                        }
                    },
                });
        },
    },
    mounted() {
        this.$_apis_getAssignments();
    },
};
</script>

<style scoped>
.no-wrap {
    flex-wrap: nowrap;
}

.grow-height {
    max-height: 4000px;
    min-height: 400px;
    transition: min-height 0.3s ease-out;
}

.shrink-height {
    min-height: 450px;
    transition: min-height 0.3s ease-out;
}

.shrink-height-mobile {
    min-height: 350px;
    transition: min-height 0.3s ease-out;
}

.sticky-selects {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 100;
}

.sticky-padded {
    top: 64px;
    transition: top 0.1s ease-out;
}
</style>
