<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            groupBy="position"
            dense
            :expanded="[]"
            :height="
                null /*`calc(100vh - ${$vuetify.breakpoint.smAndDown ? 317 : 257}px)`*/
            "
        >
            <template v-slot:[`header`]="{ props }">
                <thead
                    v-if="props.headers.find((e) => e.label)"
                    style="
                        border-bottom: hidden;
                        box-shadow: 0px 1px rgba(0, 0, 0, 0.12);
                    "
                >
                    <tr :class="{ 'sticky-header': false }">
                        <th
                            v-for="(header, idx) in props.headers.filter(
                                (e) => e.colspan
                            )"
                            :key="idx"
                            :colspan="header.colspan"
                        >
                            <div
                                class="font-weight-black"
                                :class="header.class"
                            >
                                {{ header.label }}
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:[`group.header`]="{ group, items }">
                <td
                    class="soli-blue white--text font-weight-black"
                    v-for="key in columnOrder"
                    :key="key"
                >
                    <div v-if="key == 'name'">
                        {{ categoryOrder[group] }}
                    </div>
                    <div
                        v-if="items.find((e) => e.headerSummary)"
                        :set="
                            (headerSummary = items.find((e) => e.headerSummary))
                        "
                    >
                        <div v-if="key !== 'difference' && key !== 'name'">
                            {{ headerSummary[key] }}
                        </div>

                        <v-icon
                            v-if="key == 'difference'"
                            :color="headerSummary[key].color"
                            >{{ headerSummary[key].icon }}</v-icon
                        >
                    </div>
                </td>
            </template>
            <template v-slot:[`group.summary`]="{ items }">
                <template v-if="items.find((e) => e.summary)">
                    <td
                        class="font-weight-bold px-4 white"
                        v-for="key in columnOrder"
                        :key="key"
                    >
                        <div v-if="key !== 'difference'">
                            {{ items.find((e) => e.summary)[key] }}
                        </div>

                        <v-icon
                            v-else
                            :color="items.find((e) => e.summary)[key].color"
                            >{{
                                items.find((e) => e.summary)[key].icon
                            }}</v-icon
                        >
                    </td></template
                >
            </template>
            <template v-slot:[`item`]="{ item }">
                <tr v-if="!item.summary && !item.headerSummary">
                    <td v-for="key in columnOrder" :key="key">
                        <div v-if="key !== 'difference'">
                            <div
                                v-if="
                                    !item.userEntry ||
                                    key !== 'format' ||
                                    !userEntry
                                "
                                class="flex"
                            >
                                {{ item[key] }}
                                <v-tooltip top maxWidth="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                            right
                                            color="accent"
                                            v-if="
                                                item['description'] &&
                                                key == 'format'
                                            "
                                            >help</v-icon
                                        >
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                            right
                                            color="accent"
                                            v-if="
                                                item['percent_description'] &&
                                                key == 'percent'
                                            "
                                            >help</v-icon
                                        ></template
                                    >{{
                                        item["description"] ||
                                        item["percent_description"]
                                    }}</v-tooltip
                                >
                            </div>
                            <v-currency-field
                                v-else
                                v-model="form[item.key]"
                                ref="currencyField"
                                dense
                                flat
                                prefix="$"
                                hide-details
                                :max="99999"
                                class="pb-2"
                                style="width: 78px; font-size: 0.875rem"
                            />
                        </div>

                        <v-icon
                            v-if="key == 'difference'"
                            :color="item.difference?.color"
                            >{{ item.difference?.icon }}</v-icon
                        >
                    </td>
                </tr>
            </template>
        </v-data-table>

        <!--<SoliResponsiveDialog
            v-if="showHelp"
            max-width="450"
            @reset="closeModal(() => (showHelp = null))"
            :title="`About ${showHelp.name}`"
            :subtitle="showHelp.description || showHelp.percent_description"
        />-->
    </div>
</template>

<script>
import SoliResponsiveDialog from "../Base/SoliResponsiveDialog.vue";

export default {
    props: {
        columnOrder: {
            type: Array,
            default: () => [],
        },
        categoryOrder: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
            default: () => [],
        },
        meta: {
            type: Object,
            default: () => {},
        },
        userEntry: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                checks:
                    this.items.find((e) => e.name == "Checks")?.value / 100 ||
                    0,
                teacher_costs:
                    this.items.find((e) => e.name == "Teacher")?.value / 100 ||
                    0,
                occupancy:
                    this.items.find((e) => e.name == "Occupancy")?.value /
                        100 || 0,
                marketing:
                    this.items.find((e) => e.name == "Marketing")?.value /
                        100 || 0,
                operating:
                    this.items.find((e) => e.name == "Operating")?.value /
                        100 || 0,
                admin_payroll:
                    this.items.find((e) => e.name == "Admin Payroll")?.value /
                        100 || 0,
            },
            formCopy: null,
            showHelp: null,
        };
    },
    methods: {
        save() {
            this.$inertia.put(`/studio-health/${this.meta.id}`, this.form, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("editing", false);
                    this.formCopy = JSON.stringify(this.form);
                    this.$emit("success");
                },
                onError: (errors) => {
                    console.log(
                        "Form returned errors: " + JSON.stringify(errors)
                    );
                },
            });
        },
        cancel() {
            this.$emit("editing", false);
            this.form = JSON.parse(this.formCopy);
        },
    },
    mounted() {
        this.formCopy = JSON.stringify(this.form);
    },
    watch: {
        form: {
            handler: function (val) {
                if (JSON.stringify(val) == this.formCopy) {
                    this.$emit("editing", false);
                } else {
                    this.$emit("editing", true);
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.sticky-header {
    /*
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;*/
}

tbody tr:last-child > td:first-child {
    border-bottom-left-radius: 18px;
}

tbody tr:last-child > td:last-child {
    border-bottom-right-radius: 18px;
}

/*tbody tr > td:first-child {
    position: sticky;
    left: 0;
    background: white;
    z-index: 0;
}

thead tr:first-child > th:first-child {
    position: sticky;
    left: 0;
    background: white;
    z-index: 0;
}

.v-data-table-header tr:first-child > th:first-child {
    position: sticky;
    left: 0;
    background: white;
    z-index: 0;
}*/
</style>
