<template>
    <v-list id="scroller" class="w-full overflow-y-auto" scroll-region>
        <div
            id="bottom-anchor"
            v-intersect="{
                root: '#scroller',
                handler: bottomVisible,
                options: { rootMargin: '100px', threshold: 0 },
            }"
        ></div>
        <div
            v-if="nextLoading && conversation.message_id"
            class="w-full h-full flex justify-center align-center my-4"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                class="mr-4"
            ></v-progress-circular>
        </div>
        <div
            v-for="(message, idx) in messageData"
            :key="message.id"
            class="flex flex-col contents scroller-item"
        >
            <v-list-item :id="`message-${message.id}`" class="chat-items">
                <div class="soli-round soli-grey pa-2 flex">
                    <v-avatar
                        class="mr-4"
                        :size="$vuetify.breakpoint.smAndUp ? 48 : 36"
                    >
                        <v-img
                            :src="
                                profiles.find((e) => e.id == message.user_id)
                                    ?.profile_photo_url ??
                                $page.props.auth.user.profile_photo_url
                            "
                        />
                    </v-avatar>
                    <div class="flex flex-col">
                        <div class="flex font-weight-bold">
                            {{
                                profiles.find((e) => e.id == message.user_id)
                                    ?.first_name ??
                                $page.props.auth.user.first_name
                            }}<small class="caption ml-2 my-auto">{{
                                moment(new Date(message.created_at)).format(
                                    "h:mm A"
                                )
                            }}</small>
                        </div>
                        <div
                            v-html="message.content"
                            class="message-content"
                        ></div>
                    </div>
                </div>
            </v-list-item>

            <small
                v-if="
                    idx ==
                    messageData.map((e) => e.date).lastIndexOf(message.date)
                "
                class="justify-center flex align-center font-bold soli-grey w-full my-4"
                ><v-divider class="flex" />
                <div class="mx-4">{{ message.date }}</div>
                <v-divider class="flex" />
            </small>
        </div>
        <div
            id="top-anchor"
            v-intersect="{
                root: '#scroller',
                handler: topVisible,
                options: { rootMargin: '-100px', threshold: 0 },
            }"
        ></div>

        <div
            class="w-full h-full flex justify-center align-center my-4"
            style="min-height: 32px"
        >
            <v-progress-circular
                v-if="previousLoading"
                indeterminate
                color="primary"
                class="mr-4"
            ></v-progress-circular>
        </div>
    </v-list>
</template>

<script>
export default {
    props: {
        conversation: {
            type: Object,
            default: () => {},
        },
        profiles: {
            type: Array,
            default: () => [],
        },
        messages: {
            type: Array,
            default: () => [],
        },
        messageCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            //messageData: this.messages.data ?? [],
            nextLoading: false,
            previousLoading: false,
            viewingLatest: true,
            initLoading: true,
            previousMessages: [],
            nextMessages: [],
        };
    },
    computed: {
        messageData() {
            if (!this.messages.length) return [];
            return [
                // ...new Set([
                ...this.nextMessages,
                ...this.messages,
                ...this.previousMessages,
                //]),
            ];
        },
        previousPage() {
            if (!this.messageData.length) return null;

            return `/messages/${
                this.messageData[this.lastMessage].id
            }/check-previous`;
        },
        nextPage() {
            if (!this.messageData.length) return null;
            return `/messages/${this.messageData[0].id}/check-next`;
        },
        lastMessage() {
            return this.messageData.length - 1;
        },
    },
    methods: {
        initMessages() {
            if (!this.conversation?.id) return this.$emit("loading", false);

            this.$inertia.visit(`/inbox/${this.conversation.id}`, {
                data: this.conversation.message_id
                    ? {
                          message_id: this.conversation.message_id,
                      }
                    : {},
                preserveState: true, //!this.conversation.message_id,
                only: ["index", "conversation", "messages"],
                onFinish: () => {
                    if (!this.conversation.message_id) {
                        this.$inertia.reload({
                            preserveState: true,
                            only: [
                                "unreadMessageCount",
                                "messageCount",
                                "newMessageAvailable",
                            ],
                        });
                    }
                    //this.messageData = this.messages.data;

                    this.scrollToMessage(this.conversation.message_id);
                    this.$emit("loading", false);
                    this.initLoading = false;

                    /*if (!document.getElementById("scroller").scrollTop) {
                        this.loadNext();
                    }*/
                },
            });
        },
        scrollToMessage(messageId, block = "start") {
            if (!messageId) return;

            this.$nextTick(() => {
                document.getElementById(`message-${messageId}`).scrollIntoView({
                    behavior: "instant",
                    block: block,
                });
            });
        },
        topVisible(entries, observer) {
            if (entries[0].isIntersecting) {
                this.loadPrevious();
            }
        },
        bottomVisible(entries, observer) {
            if (entries[0].isIntersecting) {
                this.viewingLatest = true;
                this.loadNext();
            } else {
                this.viewingLatest = false;
            }
        },
        messageReceived(message) {
            this.$inertia.reload({
                preserveState: true,
                only: [
                    "index",
                    "conversation",
                    this.viewingLatest ? "messages" : null,
                ],
                onFinish: () => {
                    this.$inertia.reload({
                        preserveState: true,
                        only: [
                            "unreadMessageCount",
                            "messageCount",
                            "newMessageAvailable",
                        ],
                    });
                    this.$emit("loading", false);
                },
            });
        },
    },
    created() {
        this.loadPrevious = this.throttle(() => {
            if (this.messageData.length == this.messageCount) return;
            if (!this.previousPage) return;
            if (this.initLoading) return;

            this.previousLoading = true;

            axios.get(this.previousPage).then((resp) => {
                this.previousMessages = [
                    ...new Set([...this.previousMessages, ...resp.data]),
                ];
                if (resp.data.length) {
                    this.scrollToMessage(resp.data[0].id, "start");
                }
                this.previousLoading = false;
            });
        });

        this.loadNext = this.throttle(() => {
            if (this.messageData.length == this.messageCount) return;
            if (!this.nextPage) return;
            if (this.initLoading) return;

            this.nextLoading = true;

            const currentMessageId = this.messageData[0].id;

            axios.get(this.nextPage).then((resp) => {
                this.nextMessages = [
                    ...new Set([...resp.data, ...this.nextMessages]),
                ];
                if (resp.data.length) {
                    this.scrollToMessage(currentMessageId, "end");
                }
                this.nextLoading = false;
            });
        });
    },
    mounted() {
        this.initMessages();
    },
    beforeDestroy() {
        this.loadPrevious = null;
        this.loadNext = null;
    },
};
</script>

<style>
#bottom-anchor {
    overflow-anchor: auto;
    height: 1px;
}

#top-anchor {
    overflow-anchor: auto;
    height: 1px;
}

#scroller * {
    overflow-anchor: none;
    /*display: flex;*/
    transform: translateZ(0);
}

#scroller {
    flex: 1 1 0;
    flex-direction: column-reverse;
    display: flex;
    position: relative;
    overflow-y: auto;
    justify-content: flex-start;
    overflow-anchor: auto;
}

.chat-items {
    flex: 0;
    min-height: unset;
}

.scroller-item {
    flex-direction: column-reverse;
    justify-content: flex-start;
    flex: 0;
}

/*.content {
    white-space: pre;
    word-wrap: break-word;
    font-family: inherit;
}*/

.message-content {
    ul,
    ol {
        list-style: unset;
    }
}

.message-content p:last-child {
    margin: 0px 0px;
}

.message-content iframe {
    border-radius: 20px;
    padding: 8px 4px;
}

@media screen and (max-width: 600px) {
    .message-content iframe {
        height: 200px;
    }
}
</style>
