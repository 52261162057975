<template>
    <FormItem>
        <template #question>Event details</template>
        <template #response>
            <v-list class="pa-0">
                <v-list-item
                    :class="[$vuetify.breakpoint.smAndUp ? 'px-2' : 'px-0']"
                    v-for="(item, idx) in items"
                    :key="item.icon"
                >
                    <v-list-item-avatar>
                        <v-icon
                            color="accent"
                            :large="$vuetify.breakpoint.smAndUp"
                        >
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-align-start ml-2 py-0">
                        <v-list-item-title v-if="item.title"
                            >{{ item.title
                            }}<small
                                v-if="item.autoCoupon"
                                class="line-through px-2"
                                >{{ item.autoCoupon.original_amount }}</small
                            >
                        </v-list-item-title>
                        <small
                            v-if="item.autoCoupon?.auto_coupon_amount"
                            class="secondary--text font-weight-bold"
                            >{{
                                `${item.autoCoupon.auto_coupon_name}! Save ${item.autoCoupon.auto_coupon_savings_format}`
                            }}</small
                        >

                        <v-list-item-subtitle
                            v-if="item.subtitle"
                            ref="subtitle"
                            :class="item.subtitleClass"
                            >{{ item.subtitle }}</v-list-item-subtitle
                        >
                    </v-list-item-content>
                    <v-list-item-action v-if="item.action"
                        ><v-btn
                            x-small
                            text
                            plain
                            color="accent"
                            rounded
                            @click="item.action(idx)"
                            >{{ item.toggle ? "less" : "more" }}</v-btn
                        ></v-list-item-action
                    >
                </v-list-item>
            </v-list>
        </template>
    </FormItem>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";
import { Link } from "@inertiajs/vue2";
import FormItem from "../Form/FormItem.vue";

export default {
    mixins: [globalMoment],
    components: {
        Link,
        FormItem,
    },
    props: {
        eventRegister: {
            type: Object,
            default: () => {},
        },
        size: {
            type: [String, Number],
            default: 80,
        },
        wide: {
            type: Boolean,
            default: false,
        },
        autoCoupon: {
            type: Object,
            default: () => {},
        },
    },
    data: function () {
        return {};
    },
    computed: {
        items() {
            return [
                {
                    subtitle: this.eventRegister.group_event.description,
                    icon: "description",
                    subtitleClass: "subtitle-max subtitle-wrap",
                    action: (idx) => {
                        this.$refs.subtitle[idx].classList.toggle(
                            "subtitle-max"
                        );
                        this.$refs.subtitle[idx].classList.toggle("mb-2");
                        this.items[idx].toggle = !this.items[idx].toggle;
                    },
                    toggle: false,
                },
                {
                    title: this.eventRegister.details.date,
                    subtitle: this.eventRegister.details.time,
                    icon: "today",
                },
                {
                    title: this.eventRegister.details.location,
                    subtitle: this.eventRegister.details.address,
                    icon: "place",
                },
                {
                    title: `${
                        this.autoCoupon?.formatAmount ??
                        this.eventRegister.tuition.formatAmount
                    }/seat`,
                    autoCoupon: this.autoCoupon,
                    subtitle: this.eventRegister.require_deposit
                        ? `${this.eventRegister.deposit.formatAmount}/seat deposit due today`
                        : null,
                    icon: "credit_card",
                },
            ];
        },
    },
};
</script>

<style scoped>
.subtitle-wrap {
    white-space: normal !important;
}

.subtitle-max {
    max-height: 2rem !important;
}
</style>
