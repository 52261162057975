<template>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <FormTitle class="pa-4 pt-0" dark v-if="true"
            >So Long, Farewell
            <template #subtitle>Thanks for being a student!</template>
        </FormTitle>

        <FormItem v-if="memberships.length">
            <template #question>
                Which membership would you like to cancel?
            </template>
            <template #response>
                <v-select
                    v-model="form.purchase_option_id"
                    :items="memberships"
                    item-value="id"
                    item-text="name"
                    label="Select a membership"
                    :rules="[(v) => !!v || 'You must select a membership']"
                    outlined
                    dense
                    @input="getUpcomingLessons()"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title
                                v-html="data.item.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.details">
                                {{
                                    `${data.item.details.location} | ${
                                        data.item.details?.instrument ?? "Class"
                                    }`
                                }}</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </template>
                </v-select>
            </template>
        </FormItem>

        <v-container v-if="!memberships.length">
            <div class="pa-2 white--text text-align-start text-lg">
                We can't find any memberships on your account. Please contact
                one of our studios for help.
            </div>
        </v-container>

        <FormItem v-if="form.purchase_option_id">
            <template #question>
                Please select your final lesson date. It should be AT LEAST 30
                days from today. Even if it is not, you will still be billed for
                the next 30 days in accordance with the withdrawal policy in
                your student contract.
            </template>
            <template #response>
                <SoliDate
                    v-model="form.final_lesson_date"
                    :events="upcomingLessons"
                    :icon="null"
                    :loading="lessonsLoading"
                    :rules="[
                        (v) => !!v || 'You must select a final lesson date',
                    ]"
                    noLimit
                    class="w-full"
                    :select="false"
                ></SoliDate>
            </template>
        </FormItem>

        <div v-if="form.final_lesson_date">
            <FormItem>
                <template #question> Why are you withdrawing? </template>
                <template #response>
                    <v-textarea
                        v-model="form.why_exit_reason"
                        label="Please briefly explain"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="rules_text_limit"
                        counter
                        maxlength="255"
                    />
                </template>
            </FormItem>

            <FormItem>
                <template #question>
                    Was your teacher a good fit for you and your goals?
                </template>
                <template #response>
                    <v-radio-group v-model="form.perfect_match" class="mt-0">
                        <v-radio label="Yes" :value="true" />
                        <v-radio label="No" :value="false" />
                    </v-radio-group>
                    <v-textarea
                        v-model="form.perfect_match_reason"
                        outlined
                        dense
                        label="Please briefly say why"
                        hide-details="auto"
                        :rules="rules_text_limit"
                        counter
                        maxlength="255"
                    />
                    <v-checkbox
                        v-model="form.confidential"
                        label="I'd like to keep my answer confidential"
                        class="mt-0"
                    ></v-checkbox>
                </template>
            </FormItem>

            <FormItem>
                <template #question>
                    How likely are you to recommend <u>your teacher</u> to a
                    friend or colleague?
                </template>
                <template #response>
                    <NPSOptions v-model="form.nps_teacher_value" />
                </template>
            </FormItem>

            <FormItem>
                <template #question>
                    How likely are you to recommend <u>Musicologie</u> to a
                    friend or colleague?
                </template>
                <template #response>
                    <NPSOptions v-model="form.nps_value" />
                    <div
                        v-if="form.nps_value >= 8"
                        class="soli-grey text-start"
                        style="display: block"
                    >
                        Awesome!
                        <a
                            href="https://musicologielessons.com/review"
                            target="_blank"
                            >Go here to review us</a
                        >. It just takes 30 seconds and won't mess up this form.
                    </div>
                    <v-textarea
                        v-if="form.nps_value !== null && form.nps_value < 8"
                        v-model="form.nps_reason"
                        outlined
                        dense
                        label="What's the reason for this score?"
                        hide-details="auto"
                        class="pt-3"
                        :rules="rules_text_limit"
                        counter
                        maxlength="255"
                    />
                </template>
            </FormItem>

            <FormItem>
                <template #question>
                    Do you think you'll re-enroll in the future?
                </template>
                <template #response>
                    <v-radio-group v-model="form.will_return" class="mt-0">
                        <v-radio label="Yes" :value="true" />
                        <v-radio label="No" :value="false" />
                    </v-radio-group>
                    <div
                        v-if="form.will_return"
                        class="soli-grey font-weight-bold text-start mb-4"
                    >
                        We'd love to have you back! When might you return?
                    </div>
                    <SoliDate
                        v-if="form.will_return"
                        v-model="form.return_date"
                        type="month"
                        :rules="[]"
                        class="w-full"
                        future
                        noLimit
                        :select="false"
                        label="When might you re-enroll?"
                    />
                </template>
            </FormItem>

            <FormItem>
                <template #question> What could we do better? </template>
                <template #response>
                    <v-textarea
                        v-model="form.do_better"
                        label="Your answer"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="rules_text_limit"
                        counter
                        maxlength="255"
                    />
                </template>
            </FormItem>

            <FormItem v-if="form.will_return">
                <template #question
                    >When you withdraw, your time and teacher will be made
                    available to other students. Many teachers have waiting
                    lists and there is no guarantee they will be available if
                    you wish to re-enroll. Please indicate below that you
                    understand.*</template
                ><template #response
                    ><v-checkbox
                        v-model="form.agree"
                        label="I understand"
                        :rules="[(val) => !!val || 'This is required']"
                        >I understand</v-checkbox
                    ></template
                >
            </FormItem>

            <!--<v-list-item
                v-if="form.will_return"
                class="soli-grey font-weight-bold text-start"
                >When you withdraw, your time and teacher will be made available
                to other students. Many teachers have waiting lists and there is
                no guarantee they will be available if you wish to re-enroll.
                Please indicate below that you understand.*</v-list-item
            >
            <v-list-item v-if="form.will_return">
                <v-checkbox
                    v-model="form.agree"
                    label="I understand"
                    :rules="[(val) => !!val || 'This is required']"
                    >I understand</v-checkbox
                ></v-list-item
            >-->
            <FormSubmit
                type="submit"
                :errors="errors"
                :valid="valid"
                dark
                :loading="loading"
            />
        </div>
    </v-form>
</template>

<script>
import BookingEmail from "./Booking/BookingEmail.vue";
import globalMoment from "../Mixins/GlobalMoment.js";
import NPSOptions from "./Form/NPSOptions.vue";
import FormItem from "./Form/FormItem.vue";
import FormSubmit from "./Form/FormSubmit.vue";
import FormTitle from "./Form/FormTitle.vue";
import FormRules from "../Mixins/FormRules";

export default {
    name: "ExitForm",
    mixins: [globalMoment, FormRules],
    components: {
        BookingEmail,
        NPSOptions,
        FormItem,
        FormSubmit,
        FormTitle,
    },
    props: {
        errors: {},
        user: {
            type: Object,
            default: () => {},
        },
        memberships: {
            type: Array,
            default: () => [],
        },
        whyExitOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                purchase_option_id: null,
                final_lesson_date: null,
                why_exit: [],
                why_exit_reason: null,
                perfect_match: null,
                perfect_match_reason: null,
                confidential: false,
                will_return: null,
                return_date: null,
                do_better: null,
                agree: null,
                nps_value: null,
                nps_reason: null,
                nps_teacher_value: null,
            }),
            upcomingLessons: [],
            lessonsLoading: false,
            loading: false,
            valid: false,
        };
    },
    computed: {
        npsScore: {
            get() {
                if (this.form.nps_value === null) return null;
                return this.form.nps_value + 1;
            },
            set(value) {
                this.form.nps_value = value - 1;
            },
        },
    },
    methods: {
        submit() {
            if (!this.$refs.form.validate()) return;
            this.loading = true;

            this.form.post(route("exitForm.store"), {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.loading = false;
                    this.$emit("success");
                },
                onError: (errors) => {
                    this.loading = false;
                    console.log(
                        "Form returned errors: " + JSON.stringify(errors)
                    );
                    if (errors.contactSupport) return;
                    if (errors.soliError) return;
                },
            });
        },
        blurInput() {
            document.activeElement.blur();
        },
        reset() {
            this.$refs.form.reset();
        },
        getUpcomingLessons() {
            this.lessonsLoading = true;
            axios
                .get(`/api/membership/${this.form.purchase_option_id}/lessons`)
                .then((resp) => {
                    this.upcomingLessons = resp.data.map((e) => {
                        return this.globalMomentISO(e.starts_at);
                    });
                    this.lessonsLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.lessonsLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.animate__animated.animate__slideInDown {
    --animate-duration: 0.3s;
}
.appear {
    max-height: 224px;
    transition: max-height 0.3s ease-out;
}
.disappear {
    max-height: 0px;
    transition: max-height 0.3s ease-out;
}

.nps-collapse {
    max-width: 320px;
    gap: 6px;
    justify-content: center;
}
</style>
