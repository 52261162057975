<template>
    <v-btn
        v-if="!hide"
        v-bind="$attrs"
        :icon="round"
        :outlined="outlined"
        :fab="fab"
        :right="right"
        :absolute="absolute"
        :fixed="fixed"
        :bottom="bottom"
        :loading="loading"
        :disabled="disabled"
        :href="href"
        :plain="plain"
        :small="small"
        :large="large"
        :input-value="inputValue"
        :text="flat"
        :class="[
            !disabled ? gradient : null,
            color,
            outlined ? null : textColor,
            variant,
        ]"
        @click="$emit('click')"
    >
        <slot v-if="!round && !left">{{ text }}</slot>
        <v-icon
            v-if="!left && icon"
            :right="!round ? true : false"
            :color="iconColor"
            >{{ icon }}</v-icon
        >
        <jet-application-logo
            v-if="logo"
            class="block h-9 w-auto"
            style="height: 1rem !important"
        />
        <v-icon left v-if="left">{{ icon }}</v-icon>
        <slot v-if="left">{{ text }}</slot>
    </v-btn>
</template>

<script>
import JetApplicationLogo from "./../../Jetstream/ApplicationLogo";

export default {
    name: "SoliBtn",
    components: {
        JetApplicationLogo,
    },
    props: {
        color: {
            type: String,
            default: "primary",
        },
        round: {
            type: Boolean,
            default: false,
        },
        gradient: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: "Submit",
        },
        textColor: {
            type: String,
            default: "white--text",
        },
        icon: {
            type: String,
            default: null,
        },
        iconColor: {
            type: String,
            default: null,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        logo: {
            type: Boolean,
            default: false,
        },
        fab: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        bottom: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        noOverflow: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: null,
        },
        plain: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        inputValue: {},
    },
    computed: {
        variant() {
            if (this.round) {
                return null;
            } else if (this.fixed) {
                return "soli-fixed-btn";
            } else {
                return "soli-round";
            }
        },
    },
};
</script>

<style scoped>
.soli-fixed-btn {
    border-radius: 0px !important;
    position: absolute;
    bottom: 0px;
    min-height: 44px !important;
    height: auto !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-bottom: max(env(safe-area-inset-bottom), 12px) !important;
}
</style>
