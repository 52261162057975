<template>
    <AppLayout page="/teachers" backLink="/teachers" pageTitle="Profile">
        <div>
            <div class="max-w-7xl mx-auto py-10 px-0 mx-0">
                <update-profile-information-form
                    :first_name="$page.props.auth.user.first_name"
                    :last_name="$page.props.auth.user.last_name"
                    :email="$page.props.auth.user.email"
                />

                <jet-section-border />

                <update-password-form class="mt-10 sm:mt-0" />

                <div
                    v-if="
                        $page.props.jetstream.canManageTwoFactorAuthentication
                    "
                >
                    <jet-section-border />

                    <two-factor-authentication-form class="mt-10 sm:mt-0" />
                </div>

                <jet-section-border />

                <logout-other-browser-sessions-form
                    :sessions="sessions"
                    class="mt-10 sm:mt-0"
                />

                <jet-section-border />

                <delete-user-form class="mt-10 sm:mt-0" />
            </div>
        </div>
    </AppLayout>
</template>
<script>
import AppLayout from "./../../Layouts/AppLayout";
import DeleteUserForm from "./DeleteUserForm";
import JetSectionBorder from "./../../Jetstream/SectionBorder";
import LogoutOtherBrowserSessionsForm from "./LogoutOtherBrowserSessionsForm";
import TwoFactorAuthenticationForm from "./TwoFactorAuthenticationForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import UpdateProfileInformationForm from "./UpdateProfileInformationForm";

export default {
    props: ["sessions"],

    components: {
        AppLayout,
        DeleteUserForm,
        JetSectionBorder,
        LogoutOtherBrowserSessionsForm,
        TwoFactorAuthenticationForm,
        UpdatePasswordForm,
        UpdateProfileInformationForm,
    },
};
</script>

<style>
.form-input {
    border: 1px solid #d2d6dc;
}
</style>
