<template>
    <v-list>
        <v-form ref="form" v-model="valid">
            <v-list-item>
                <v-radio-group v-model="showChildForm" mandatory class="mt-0">
                    <v-radio label="Me" :value="false" />
                    <v-radio label="My Child" :value="true" />
                </v-radio-group>
            </v-list-item>
            <v-list-item v-if="showChildForm" class="soli-grey"
                >Your Information:</v-list-item
            >
            <v-list-item>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="form.first_name"
                            prepend-inner-icon="person"
                            label="First Name"
                            outlined
                            :rules="[(v) => !!v || 'First name is required']"
                            dense
                            hide-details
                            class="py-05"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="form.last_name"
                            :prepend-inner-icon="
                                $vuetify.breakpoint.xs ? 'person' : null
                            "
                            label="Last Name"
                            outlined
                            :rules="[(v) => !!v || 'Last name is required']"
                            dense
                            class="py-05"
                        ></v-text-field
                    ></v-col>
                </v-row>
            </v-list-item>
            <v-list-item>
                <BookingEmail v-model="form.email" :errors="errors.email"
            /></v-list-item>
            <v-list-item>
                <SoliPhone
                    v-model="form.phone"
                    @input="$emit('input', $event)"
                    :errors="errors.phone"
                    @blur="blurInput()"
                />
            </v-list-item>
        </v-form>
        <v-form
            :class="[showChildForm ? 'appear' : 'disappear']"
            v-model="childFormValid"
            ref="childForm"
        >
            <v-list-item class="soli-grey"> Child Information: </v-list-item>
            <v-list-item>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="childForm.child_first_name"
                            prepend-inner-icon="person"
                            label="First Name"
                            outlined
                            hide-details
                            class="py-05"
                            :rules="
                                showChildForm
                                    ? [(v) => !!v || 'First name is required']
                                    : []
                            "
                            dense
                        ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="childForm.child_last_name"
                            label="Last Name"
                            outlined
                            class="py-05"
                            :rules="
                                showChildForm
                                    ? [(v) => !!v || 'Last name is required']
                                    : []
                            "
                            :prepend-inner-icon="
                                $vuetify.breakpoint.xs ? 'person' : null
                            "
                            dense
                        ></v-text-field
                    ></v-col>
                </v-row>
            </v-list-item>
            <v-list-item>
                <SoliBirthday
                    v-model="childForm.birthday"
                    label="Child's Birthday"
                    :required="showChildForm"
                    :key="showChildForm"
                ></SoliBirthday
            ></v-list-item>
        </v-form>
    </v-list>
</template>

<script>
import BookingEmail from "../Booking/BookingEmail.vue";

export default {
    components: {
        BookingEmail,
    },
    props: ["errors", "clientInfo"],
    data() {
        return {
            form: {
                first_name: this.clientInfo.first_name ?? null,
                last_name: this.clientInfo.last_name ?? null,
                phone: this.clientInfo.phone ?? null,
                email: this.clientInfo.email ?? null,
            },
            showChildForm: null,
            valid: false,
            childForm: {
                child_first_name: this.clientInfo.child_first_name ?? null,
                child_last_name: this.clientInfo.child_last_name ?? null,
                child_birthday: this.clientInfo.child_birthday ?? null,
            },
            childFormValid: false,
        };
    },
    methods: {
        blurInput() {
            document.activeElement.blur();
        },
        reset() {
            this.$refs.form.reset();
        },
    },
    computed: {
        formsValid() {
            return this.valid && this.childFormValid;
        },
    },
    mounted() {
        this.$emit("mounted");
    },
    watch: {
        formsValid(val) {
            if (!this.childForm.birthday && this.showChildForm) val = false;
            this.$emit("valid", val);
        },
        form: {
            handler: function (val) {
                if (val.phone?.length < 12 && this.valid) {
                    this.$refs.form.validate();
                }
                this.$emit("input", { ...this.form, ...this.childForm });
            },
            deep: true,
        },
        childForm: {
            handler: function (val) {
                if (
                    !val.birthday &&
                    this.showChildForm &&
                    this.childFormValid
                ) {
                    this.$refs.childForm.validate();
                }

                this.$emit("input", { ...this.form, ...this.childForm });
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.animate__animated.animate__slideInDown {
    --animate-duration: 0.3s;
}

.disappear {
    max-height: 0px;
    transition: max-height 0.3s ease-out;
}

.appear {
    max-height: 212px;
    transition: max-height 0.3s ease-out;
}
</style>
