<template>
    <v-container
        :class="{
            'pa-0': !wrapper,
            'border border-grey-200 soli-round w-full h-auto flex flex-col py-1':
                wrapper,
        }"
    >
        <editor-content :editor="editor" />
    </v-container>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

export default {
    components: { EditorContent },
    props: {
        value: {
            type: String,
            default: "",
        },
        wrapper: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editor: null,
        };
    },
    mounted() {
        this.editor = new Editor({
            content: this.value,
            editable: false,
            editorProps: {
                attributes: {
                    class: this.wrapper ? "pa-2 focus:outline-none" : null,
                },
            },
            extensions: [StarterKit],
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style>
.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.tiptap {
    ul,
    ol {
        list-style: unset;
    }
}

.ProseMirror div.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
</style>
