<template>
    <div>
        <v-card-title>Payment Method</v-card-title>

        <v-container class="pt-0">
            <!--<v-btn-toggle
        v-model="selectPaymentMethod"
        class="soli-green-text"
        mandatory
        group
      >
        <v-btn
          v-if="client.defaultMethod"
          value="default"
          class="soli-round"
          small
        >
          Default Card
        </v-btn>

        <v-btn
          @click="swipedCard ? null : connectReaderHandler()"
          value="capture"
          class="soli-round"
          small
        >
          Card Reader
        </v-btn>
        <v-btn
          v-if="!client.defaultMethod"
          value="default"
          class="soli-round"
          small
          @click="addCard"
        >
          Manual Entry
        </v-btn>
        <v-btn
          @click="alert('This is not yet functional')"
          value="new"
          class="soli-round"
          small
        >
          Account
        </v-btn>
      </v-btn-toggle>-->
            <v-tabs v-model="tabs" class="px-1">
                <v-tab :disabled="!client.defaultMethod">Default Card</v-tab>
                <v-tab
                    @click="
                        swipedCard || terminalLoading || cardCapture
                            ? null
                            : connectReaderHandler()
                    "
                    >Card Reader</v-tab
                >
                <v-tab disabled>Account</v-tab>
                <v-tab>Promo</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs">
                <v-tab-item
                    ><div
                        :class="['pa-3 my-3 mx-0 soli-round']"
                        style="/*border: 1px solid rgba(0, 0, 0, 0.54)*/"
                    >
                        <v-row
                            v-if="client.defaultMethod"
                            class="align-center pa-3"
                        >
                            <v-col class="col-1 px-0"
                                ><v-icon color="rgba(0,0,0,.54)"
                                    >credit_card</v-icon
                                ></v-col
                            >
                            <v-col class="pl-0 py-0">
                                <v-list class="mx-2">
                                    <div class="caption soli-grey">
                                        {{
                                            client.defaultMethod.billing_details
                                                .name
                                        }}
                                    </div>
                                    <div
                                        :class="[
                                            'soli-grey',
                                            $vuetify.breakpoint.xsOnly
                                                ? 'body-2'
                                                : 'body-1',
                                        ]"
                                    >
                                        {{ client.defaultMethod.card.brand }}
                                        ****
                                        {{ client.defaultMethod.card.last4 }}
                                    </div>
                                    <div class="caption soli-grey">
                                        Expires
                                        {{
                                            client.defaultMethod.card.exp_month
                                        }}/{{
                                            client.defaultMethod.card.exp_year
                                                .toString()
                                                .slice(-2)
                                        }}
                                    </div>
                                </v-list>
                            </v-col>
                        </v-row>
                    </div></v-tab-item
                >
                <v-tab-item>
                    <div
                        :class="['pa-3 my-3 mx-0 soli-round flex align-center']"
                        style="
                            /*border: 1px solid rgba(0, 0, 0, 0.54);*/
                            height: 86px !important;
                        "
                    >
                        <v-row
                            v-if="swipedCard && !cardCapture"
                            class="align-center pa-3"
                        >
                            <v-col class="col-1 px-0"
                                ><v-icon color="rgba(0,0,0,.54)"
                                    >credit_card</v-icon
                                ></v-col
                            >
                            <v-col class="pl-0 py-0">
                                <v-list class="mx-2">
                                    <div
                                        :class="[
                                            'soli-grey',
                                            $vuetify.breakpoint.xsOnly
                                                ? 'body-2'
                                                : 'body-1',
                                        ]"
                                    >
                                        {{ swipedCard.brand }}
                                        ****
                                        {{ swipedCard.last4 }}
                                    </div>
                                    <div class="caption soli-grey">
                                        Expires
                                        {{ swipedCard.exp_month }}/{{
                                            swipedCard.exp_year
                                                .toString()
                                                .slice(-2)
                                        }}
                                    </div>
                                </v-list>
                            </v-col>
                        </v-row>
                        <div
                            v-if="terminalLoading"
                            class="align-center pa-3 justify-center flex soli-caption"
                        >
                            Loading...
                        </div>
                        <v-container v-if="cardCapture">
                            <v-row
                                class="fill-height"
                                align-content="center"
                                justify="center"
                            >
                                <v-col
                                    class="text-subtitle-1 text-center"
                                    cols="12"
                                    ><div
                                        class="flex justify-center align-content-center"
                                    >
                                        <v-icon color="dark" class="mr-3"
                                            >credit_card</v-icon
                                        >
                                        Reader is ready
                                    </div>
                                </v-col>
                                <v-col cols="6" class="py-1">
                                    <v-progress-linear
                                        color="primary"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                </v-col> </v-row
                        ></v-container>
                        <div
                            v-if="
                                !selectPaymentMethod &&
                                !terminalLoading &&
                                !cardCapture
                            "
                            class="align-center pa-3 justify-center flex soli-caption"
                        >
                            <v-btn
                                @click="connectReaderHandler()"
                                outlined
                                small
                                class="soli-blue-text soli-round"
                                >Connect to Reader</v-btn
                            >
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item></v-tab-item>
                <v-tab-item><Promos :errors="errors"></Promos> </v-tab-item>
            </v-tabs-items>

            <small
                v-if="signedContract"
                class="flex justify-center pa-3 soli-grey"
                style="text-align: center"
                >{{
                    `I am purchasing a monthly membership and
                                    allow Musicologie to charge my credit card
                                    for this and future payments of ${formatUnitAmount(
                                        totalRecurring
                                    )}/month in accordance with their policies.`
                }}
            </small>
            <!--<SoliBtn
        class="mt-5"
        :text="
          !signedContract
            ? 'Review Policies'
            : `Pay ${formatUnitAmount(finalPrice)}`
        "
        gradient="gradient-blue"
        :disabled="
          selectPaymentMethod == null || terminalLoading || cardCapture
        "
        block
        :loading="loading || terminalLoading"
        @click="!signedContract ? (loadContract = true) : checkout()"
      ></SoliBtn>
    </v-container>
    <v-container v-if="!client">
      <SoliBtn
        text="Add New Customer"
        gradient="gradient-blue"
        block
        @click="addCard"
      ></SoliBtn>-->
        </v-container>
        <!--<SoliSnack
      v-if="showMessage"
      @hide="showMessage = false"
      :text="showMessage"
    ></SoliSnack>-->
    </div>
</template>

<script>
import Contracts from "./Contracts";
import Promos from "./Promos";

export default {
    props: ["client", "prices", "cart_id", "errors"],
    components: {
        Contracts,
        Promos,
    },
    data() {
        return {
            cardHolderName: null,
            loading: false,
            cardCapture: false,
            terminal: null,
            terminalLoading: false,
            promoCode: null,
            activePromos: [],
            showMessage: false,
            promoRules: [],
            promoLoading: false,
            signedContract: null,
            loadContract: false,
            paymentIntent: null,
            swipedCard: null,
            tabs: this.client.defaultMethod ? 0 : 1,
        };
    },
    computed: {
        selectPaymentMethod() {
            return this.client.defaultMethod
                ? "default"
                : this.swipedCard
                ? "capture"
                : null;
        },
        totalRecurring() {
            return 14600;
        },
        subtotal() {
            let prices = this.prices.map((e) => {
                if (e.proration) {
                    return Number(e.proration);
                } else {
                    return Number(e.unit_amount);
                }
            });
            return prices.reduce((a, b) => a + b);
        },
        totalDiscount() {
            return this.activePromos.length > 0
                ? this.activePromos
                      .map((e) => this.getDiscount(e))
                      .reduce((a, b) => a + b)
                : 0;
        },
        finalPrice() {
            return this.subtotal - this.totalDiscount;
        },
    },
    methods: {
        handleContractSuccess(success) {
            console.log(success);
            //this.showMessage = success.message;
            this.signedContract = success.contractUrl;
            this.loadContract = false;
        },
        getDiscount(promo) {
            console.log(promo);
            if (promo.coupon.amount_off) {
                return promo.coupon.amount_off;
            } else {
                return (
                    (promo.coupon.percent_off *
                        this.prices.filter((e) =>
                            e.product.includes(promo.applies_to)
                        )[0].unit_amount) /
                    100
                );
            }
        },
        applyPromo() {
            this.promoLoading = true;
            this.promoRules.push((v) => !!v || "Required");

            if (!this.$refs.promo.validate()) {
                this.promoLoading = false;
                return;
            }

            axios
                .get(
                    route("stripe.promo", {
                        promo: this.promoCode,
                        products: this.prices.map((e) => e.product),
                    })
                )
                .then((e) => {
                    if (e.data.promo) {
                        this.$page.props.errors.promo = e.data.promo;
                        this.promoLoading = false;
                        setTimeout(() => {
                            this.promoRules = [];
                            this.$page.props.errors.promo = null;
                            this.promoCode = null;
                        }, 3000);
                    } else {
                        console.log(e.data);
                        this.activePromos.push(e.data);
                        this.showMessage = "Promo Applied!";
                        this.promoLoading = false;
                    }
                });
        },
        addCard() {
            this.$inertia.get(
                route("stripe.card", {
                    billingUser: this.client,
                    cart_id: this.cart_id,
                }),
                {
                    onSuccess: (result) => {
                        console.log(result);
                        this.loading = false;
                    },
                    onError: (errors) => {
                        console.log(errors);
                        this.loading = false;
                    },
                }
            );
        },
        loadTerminal() {
            this.terminal = StripeTerminal.create({
                onFetchConnectionToken: this.fetchConnectionToken,
                onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
            });
        },
        async fetchConnectionToken() {
            // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
            const response = await axios.get("/cards/create");
            console.log(response.data.secret);
            return response.data.secret;
        },
        unexpectedDisconnect() {
            // In this function, your app should notify the user that the reader disconnected.
            // You can also include a way to attempt to reconnect to a reader.
            console.log("Disconnected from reader");
        },
        async connectReaderHandler() {
            this.terminalLoading = true;
            this.loadTerminal();
            const config = { simulated: false, location: "tml_ERj9SAvSAA8lAJ" };
            const discoverResult = await this.terminal.discoverReaders(config);
            if (discoverResult.error) {
                console.log("Failed to discover: ", discoverResult.error);
                this.terminalLoading = false;
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.log("No available readers.");
                this.terminalLoading = false;
            } else {
                // Just select the first reader here.
                const selectedReader = discoverResult.discoveredReaders[0];

                const connectResult = await this.terminal.connectReader(
                    selectedReader
                );
                if (connectResult.error) {
                    console.log("Failed to connect: ", connectResult.error);
                    this.showMessage = "Could not connect to card reader";
                    this.terminalLoading = false;
                } else {
                    console.log(
                        "Connected to reader: ",
                        connectResult.reader.label
                    );
                    this.terminalLoading = false;
                    this.cardCapture = true;
                    //this.collectPayment(this.terminal);
                    this.getCard();
                }
            }
        },
        /*readerDisplay() {
            this.terminal.setReaderDisplay({
                type: "cart",
                cart: {
                    line_items: [
                        {
                            description: "Caramel latte",
                            amount: 659,
                            quantity: 1
                        },
                        {
                            description: "Dozen donuts",
                            amount: 1239,
                            quantity: 1
                        }
                    ],
                    tax: 100,
                    total: 1998,
                    currency: "usd"
                }
            });
        },*/
        getCard() {
            this.terminal.readReusableCard().then((resp) => {
                console.log(resp);
                this.swipedCard = resp.payment_method.card;
                axios
                    .get(
                        route("stripe.future", {
                            paymentMethod: resp.payment_method.id,
                            clientId: this.client.id,
                        })
                    )
                    .then((e) => {
                        console.log("card saved", e);
                        this.cardCapture = false;
                        this.terminalLoading = false;
                        this.loading = false;
                        //this.showMessage = "Card Captured!";

                        //this.checkout();
                    });
            });
        },

        closeTerminal() {
            this.terminal.disconnectReader().then((e) => {
                console.log("Disconnected from terminal");
                this.cardCapture = false;
                this.loading = false;
            });
        },

        checkout() {
            this.loading = true;
            this.prices.forEach((e) => {
                if (e.recurring) {
                    e.contractUrl = this.signedContract;
                } else {
                    console.log("Membership is missing a contract");
                    return;
                }
            });
            this.$inertia.post(
                route("subscriptions.store", {
                    billingUser: this.client,
                    prices: this.prices,
                }),
                {
                    onSuccess: (result) => {
                        console.log(result);
                        this.loading = false;
                    },
                    onError: (errors) => {
                        console.log(errors);
                        this.loading = false;
                    },
                }
            );
        },
    },
    /*updated() {
    this.client.defaultMethod = {};
  },*/
};
</script>

<style>
.v-size--x-small .v-progress-circular {
    height: 15px !important;
    width: 15px !important;
}
</style>
