<template>
    <v-list style="background: transparent">
        <v-list-item class="px-0">
            <div class="text-align-end" style="flex-grow: 1">
                <v-list-item-avatar :size="76" class="my-auto"
                    ><v-img :src="review.img"
                /></v-list-item-avatar>
            </div>
            <v-list-item-content class="text-align-start" style="flex-grow: 18"
                ><v-rating
                    :value="5"
                    dense
                    color="yellow darken-3"
                    class="mb-0"
                />
                <v-list-item-title
                    style="white-space: normal"
                    :class="[
                        $vuetify.breakpoint.smAndDown
                            ? 'text-body-2'
                            : 'text-h6',
                        'pa-1 white--text ',
                    ]"
                    >{{ review.content }}</v-list-item-title
                >
                <v-list-item-subtitle
                    :class="[
                        $vuetify.breakpoint.smAndDown ? null : 'text-body-2',
                        'white--text',
                    ]"
                    >- {{ review.user }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment.js";
import { Link } from "@inertiajs/vue2";

export default {
    mixins: [globalMoment],
    components: {
        Link,
    },
    props: {
        review: {
            type: Object,
            default: () => {},
        },

        size: {
            type: [String, Number],
            default: 64,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        lessonTitle() {
            if (this.event) return this.event.title;
            if (this.assessmentType?.apl) return "Piano Lab";
            if (this.instrument) {
                return `${this.instrument.name} ${this.type.name}`;
            }
        },
    },
};
</script>

<style>
.blue-border {
    border: 3px solid #2aabe6;
}
</style>
