<template>
    <v-expansion-panels
        class="my-3"
        :value="value"
        :class="{ 'soli-round': $vuetify.breakpoint.smAndUp }"
    >
        <v-expansion-panel
            :class="{ 'soli-round': $vuetify.breakpoint.smAndUp }"
        >
            <v-expansion-panel-header>
                <div
                    class="py-3 soli-grey text-align-start font-weight-bold text-lg flex-col flex"
                >
                    <slot name="question"></slot>
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        class="mt-4 soli-round"
                    />

                    <div v-if="required" class="grey--text caption">
                        *required
                    </div>
                    <slot name="action"></slot>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="custom-expansion-panel">
                <div class="pa-2 pt-0 soli-grey" v-if="content && !loading">
                    <slot name="response"></slot>
                </div>
                <div
                    v-if="!content && !loading"
                    class="flex justify-start pa-6 soli-grey pt-0"
                >
                    Sorry, no events found
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        question: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        content: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
.custom-expansion-panel > .v-expansion-panel-content__wrap {
    padding: 0px !important;
    margin-top: -16px;
}
</style>
