<template>
    <AppLayout
        page="/people"
        :backLink="`/people${
            can('View teacher list') ? '/teachers' : '/students'
        }`"
        :pageTitle="profile.name"
        :tabs="true"
        :showFooter="showFooter"
        :submitProfileLoading="submitProfileLoading"
        :selectedPeople="selectedPeople"
        :hasFab="
            !!selectedPeople.length || selectedTab.includes('availability')
        "
        @fabAction="
            selectedPeople.length
                ? emailUsers()
                : ($refs.tabs[0].clickData = { newBtn: true })
        "
        @save="save()"
        @cancel="cancel()"
        ref="layout"
    >
        <template #tabs>
            <v-tabs
                v-model="selectedTab"
                background-color="transparent"
                height="42px"
                class="pr-5 custom-tabs"
            >
                <v-tab
                    v-for="tab in filterTabs"
                    :key="tab.title"
                    :href="getUrl(tab)"
                    :class="['no-link']"
                >
                    <Link
                        class="no-link flex align-center min-h-full"
                        :href="getUrl(tab)"
                        :data="
                            tab.slug == 'availability'
                                ? {
                                      dayView: Number($vuetify.breakpoint.xs),
                                  }
                                : {}
                        "
                        preserve-state
                        >{{ tab.title }}</Link
                    >
                    <v-badge
                        v-if="tab.warning"
                        color="error--text"
                        icon="error_outline"
                        offset-x="8px"
                        offset-y="-2px"
                    />
                </v-tab>
            </v-tabs>
        </template>

        <v-tabs-items :value="selectedTab" touchless :key="profile.id">
            <div v-for="tab in filterTabs" :key="tab.slug">
                <v-tab-item
                    :value="getUrl(tab)"
                    v-if="selectedTab.includes(tab.slug)"
                    :transition="false"
                    :class="{
                        container: !$page.props.staffView,
                        setOverflow: tab.slug == 'availability',
                    }"
                >
                    <div class="pa-1 animate__animated animate__fadeIn">
                        <component
                            :key="range"
                            ref="tabs"
                            :is="tab.comp"
                            :user="profile"
                            :relationships="relationships"
                            :rules="rules"
                            :errors="errors"
                            :range="range"
                            :dateRange="dateRange"
                            :sharedSubscriptions="sharedSubscriptions"
                            :attendances="attendances"
                            :activities="activities"
                            :receivedHistory="receivedHistory"
                            :sentHistory="sentHistory"
                            :availabilities="availabilities"
                            :studentHistory="studentHistory"
                            :payRates="payRates"
                            :feedback="feedback"
                            :annualReviews="annualReviews"
                            :instruments="instruments"
                            :retention="retention"
                            :conversion="conversion"
                            :attendance="attendance"
                            :trends="trends"
                            :enrollmentHistory="enrollmentHistory"
                            :viewNotes="viewNotes"
                            @success="showMessage = true"
                            @hook:destroyed="showMessage = false"
                            @editing="showFooter = $event"
                            @reset="submitProfileLoading = false"
                            @submit="submitProfileLoading = true"
                            @hook:mounted="submitProfileLoading = false"
                            @selectedPeople="selectedPeople = $event"
                        >
                        </component>
                    </div>
                </v-tab-item>
            </div>
        </v-tabs-items>

        <SoliSnack
            v-if="showMessage"
            @hide="showMessage = false"
            :text="$page.props.successMessage"
        ></SoliSnack>

        <SoliChoice
            v-if="!viewInLocation"
            title="hold up."
            :subtitle="`${profile.first_name} doesn't belong to this location.`"
            :button1="
                profile.locations.length == 1
                    ? `Switch to ${profile.locations[0].name}`
                    : 'Change Locations'
            "
            button2="Go Back"
            loading1
            permanent
            @option1="
                profile.locations.length == 1
                    ? $refs.layout.updateSelectedLocation(profile.locations[0])
                    : [
                          ($refs.layout.locationSelect = true),
                          ($refs.layout.studentLocations = profile.locations),
                      ]
            "
            @option2="$inertia.visit('/people/students')"
        ></SoliChoice>

        <SoliSnack
            v-if="errors.noAssessments"
            :text="errors.noAssessments"
        ></SoliSnack>

        <ComposeEmail
            v-if="composeEmail"
            :emails="selectedEmails"
            signature
            sendAsStudio
            tinyMCE
            :user="$page.props.auth.user"
            :errors="errors"
            @success="emailSuccess()"
            @reset="closeModal(() => (composeEmail = false))"
        />
    </AppLayout>
</template>

<script>
import AppLayout from "./../Layouts/AppLayout";
import { Link } from "@inertiajs/vue2";
import UserProfile from "./../Components/Users/UserProfile";
import UserAccount from "./../Components/Users/UserAccount";
import UserPreferences from "./../Components/Users/UserPreferences";
import UserAttendance from "./../Components/Users/UserAttendance";
import UserLogs from "./../Components/Users/UserLogs";
import TeacherStudents from "./../Components/Teachers/TeacherStudents.vue";
import TeacherAvailabilities from "./../Components/Teachers/TeacherAvailabilities";
import ManageTeacher from "./../Components/Teachers/ManageTeacher.vue";
import SoliForm from "./../Components/Base/SoliForm.vue";
import ComposeEmail from "./../Components/Users/ComposeEmail.vue";

export default {
    props: {
        errors: {},
        profile: {
            type: Object,
            default: null,
        },
        relationships: {
            type: Array,
            default: null,
        },
        sharedSubscriptions: {
            type: Array,
            default: null,
        },
        attendances: {
            type: Array,
            default: null,
        },
        activities: {
            type: Array,
            default: null,
        },
        receivedHistory: {
            type: Array,
            default: null,
        },
        sentHistory: {
            type: Array,
            default: null,
        },
        availabilities: {
            type: Array,
            default: null,
        },
        studentHistory: {
            type: Array,
            default: null,
        },
        tabList: {
            type: Array,
            default: null,
        },
        range: {
            type: String,
            default: moment().format("YYYY-MM-DD"),
        },
        payRates: {
            type: Array,
            default: () => [],
        },
        feedback: {
            type: Array,
            default: () => [],
        },
        annualReviews: {
            type: Array,
            default: () => [],
        },
        instruments: {
            type: Array,
            default: () => [],
        },
        retention: {
            type: Object,
            default: () => {},
        },
        conversion: {
            type: Object,
            default: () => {},
        },
        attendance: {
            type: Object,
            default: () => {},
        },
        dateRange: {
            type: Array,
            default: () => [],
        },
        trends: {
            type: Object,
            default: () => {},
        },
        enrollmentHistory: {
            type: Array,
            default: () => [],
        },
        viewNotes: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppLayout,
        UserProfile,
        UserAccount,
        Link,
        UserPreferences,
        UserAttendance,
        UserLogs,
        TeacherAvailabilities,
        TeacherStudents,
        ManageTeacher,
        SoliForm,
        ComposeEmail,
    },
    data: function () {
        return {
            rules: SoliForm.formRules,
            showMessage: !!this.$page.props.successMessage,
            showFooter: false,
            submitProfileLoading: false,
            selectedTab: window.location.pathname,
            composeEmail: false,
            selectedPeople: [],
            selectedEmails: [],
        };
    },
    computed: {
        filterTabs() {
            let tabs = this.tabList;

            if (!this.profile.email) {
                tabs = tabs.filter((e) => !e.view.requiresEmail);
            }

            if (
                !this.profile.hasRoles.some((e) =>
                    e.includes("Teacher", "Archived Teacher")
                )
            ) {
                tabs = tabs.filter((e) => !e.view.teachersOnly);
            }

            if (!this.profile.hasPermissions.includes("Work")) {
                tabs = tabs.filter((e) => !e.view.staffOnly);
            }
            return tabs;
        },
        viewInLocation() {
            return this.profile.locations
                .map((e) => e.id)
                .includes(this.$page.props.auth.user.selected_location.id);
        },
    },
    methods: {
        getUrl(tab) {
            let url = `/profile/${this.profile.id}/${tab.slug}`;
            if (tab.range) url = `${url}/${this.range}`;
            return url;
        },
        save() {
            this.$refs.tabs[0].save();
        },
        cancel() {
            this.$refs.tabs[0].cancel();
        },
        emailUsers() {
            if (!this.selectedPeople.length) return;
            axios
                .get("/api/users/email", {
                    params: {
                        selectedPeople: this.selectedPeople.map((e) => e.id),
                        getStudents: 1,
                    },
                })
                .then((resp) => {
                    this.selectedEmails = resp.data;
                    this.composeEmail = true;
                });
        },
        emailSuccess() {
            this.$refs.layout.showMessage = true;
            this.closeModal(() => (this.composeEmail = false));
        },
    },
};
</script>

<style scoped>
.setOverflow {
    overflow: unset !important;
}
</style>
