<template>
    <SoliForm
        ref="form"
        :title="currentStep.hideTitle ? null : title"
        :hideBtn="!isFinalStep"
        :lowTitle="lowTitle"
        :showCancelBtn="!currentStep.hideCancelBtn"
        :selection="selection"
        :limitScroll="
            !currentStep.hideProgress &&
            !continueBtn.show &&
            !saveBtn.show &&
            !$vuetify.breakpoint.xs
                ? true
                : null
        "
        :cardBody="!isFinalStep && cardBody"
        :extraBtn="continueBtn.show || saveBtn.show"
        :loadingProp="formLoadingProp"
        :mobileScroll="$vuetify.breakpoint.xs && !relative"
        persistent
        :flat="flat"
        :relative="relative"
        :permanent="permanent"
        text="Book it!"
        icon="music_note"
        @findChip="jumpToStep($event)"
        @success="$emit('success')"
        @reset="$emit('reset')"
        @submit="submit"
    >
        <template #header><slot name="header"></slot></template>
        <template #body>
            <div
                class="flex align-center py-3 px-4 soli-grey"
                v-if="!currentStep.hideProgress"
            >
                <v-progress-circular
                    class="flex-shrink-0 mr-5"
                    :value="progressVal"
                    :rotate="180"
                    :size="55"
                    color="#228cbd"
                    width="5"
                    :indeterminate="apiLoading"
                    ><!--<div
                        class="progress-text"
                        v-if="steps.map((e) => e.name).includes('confirm')"
                    >
                        {{ stepNumber }} of {{ totalSteps }}
                    </div>-->
                    <div v-if="!apiLoading">{{ stepNumber }}</div>
                </v-progress-circular>
                <div v-if="!apiLoading" style="text-align: start">
                    {{ currentStep.title() }}
                    <small class="flex" style="text-align: start">
                        {{ currentStep.subText }}
                    </small>
                </div>
                <v-skeleton-loader
                    width="245"
                    type="list-item"
                    v-if="apiLoading"
                ></v-skeleton-loader>
            </div>
            <v-stepper
                v-if="!apiLoading"
                v-model="stepNumber"
                class="elevation-0"
                :class="[
                    currentStep.scrollTime && !!currentStep.value()
                        ? 'scrollTimes'
                        : null,
                    currentStep.hideProgress && !isFinalStep ? 'py-3' : null,
                ]"
            >
                <div v-for="step in selectableOptions" :key="step.name">
                    <v-stepper-content
                        :step="selectableOptions.indexOf(step) + 1"
                        class="pa-0"
                    >
                        <slot :name="step.name"></slot>
                    </v-stepper-content>
                </div>
            </v-stepper>
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="list-item, list-item, list-item"
                v-if="apiLoading"
            ></v-skeleton-loader>
        </template>
        <template v-slot:cancelBtn="attrs">
            <SoliBtn
                v-if="continueBtn.show && !isFinalStep"
                :disabled="continueBtn.disabled"
                :loading="continueBtn.loading"
                text="Continue"
                v-bind="attrs"
                class="mt-3"
                gradient="gradient-orange"
                @click="$emit('continueBtn')"
            ></SoliBtn>
            <SoliBtn
                v-if="saveBtn.show && !isFinalStep"
                :disabled="saveBtn.disabled"
                text="Continue"
                v-bind="attrs"
                class="mt-3"
                gradient="gradient-orange"
                @click="$emit('saveInnerForm')"
            ></SoliBtn>
            <SoliBtn
                v-if="currentStep.payment"
                :loading="paymentBtn.loading"
                text="Book It!"
                icon="music_note"
                v-bind="attrs"
                class="mt-3"
                gradient="gradient-orange"
                @click="$emit('paymentBtn')"
            ></SoliBtn>
            <SoliBtn
                v-if="!isFirstStep"
                class="mt-3"
                text="Back a step"
                icon="chevron_left"
                left
                v-bind="attrs"
                color="grey"
                @click="$emit('backStep')"
            ></SoliBtn>
        </template>
    </SoliForm>
</template>

<script>
/**
 * Stepper handles progression and animation through a form series
 * Context agnostic
 * Any information about the steps should be passed down from parent
 * @requires SoliForm.vue
 */

export default {
    props: {
        steps: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "Book Something",
        },
        currentStep: {
            type: Object,
            default: () => {},
        },
        continueBtn: {
            type: Object,
            default: () => ({
                show: false,
                disabled: false,
            }),
        },
        paymentBtn: {
            type: Object,
            default: () => ({
                loading: false,
            }),
        },
        saveBtn: {
            type: Object,
            default: () => ({
                show: false,
                disabled: false,
            }),
        },
        selectableOptions: {
            type: Array,
            default: () => [],
        },
        stepNumber: {
            type: Number,
            default: null,
        },
        apiLoading: {
            type: Boolean,
            default: false,
        },
        formLoadingProp: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        relative: {
            type: Boolean,
            default: false,
        },
        permanent: {
            type: Boolean,
            default: false,
        },
        cardBody: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    computed: {
        isFirstStep() {
            return this.currentStep.step == 1;
        },
        isFinalStep() {
            //return this.currentStep.step == this.totalSteps;
            return this.currentStep.confirm;
        },
        progressVal() {
            return (
                (this.currentStep.step * 100) /
                (this.steps.map((e) => e.name).includes("confirm")
                    ? this.totalSteps
                    : 5)
            );
        },
        totalSteps() {
            return this.selectableOptions.length;
        },
        lowTitle() {
            //if (this.currentStep.lowTitle) return this.currentStep.lowTitle;
            if (!this.selection.length) return true;
            if (
                Object.values(this.selection.map((e) => e.selection)).every(
                    (e) => e.hide
                )
            ) {
                return true;
            }
            //if (this.selection.length == 1) return true;
        },
        /**
         * Build chips and text that represent form selection
         * Shows at top of stepper
         * If chip, can be clicked to jump to related form step
         * @returns {Array}
         */
        selection() {
            if (this.isFinalStep) return [];
            return this.steps.filter((e) => !!e.selection?.data());
        },
    },
    methods: {
        submit() {
            this.$emit("submit");
        },
        jumpToStep(step) {
            this.$emit("jumpToStep", step);
        },
    },
};
</script>
<style scoped>
.scrollTimes {
    transform: translate(0px, -90px);
    transition: transform 0.5s ease;
}
</style>
