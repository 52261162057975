<template>
    <div class="white">
        <v-select
            v-if="select"
            ref="select"
            :value="dates"
            label="Date Range"
            prepend-inner-icon="today"
            :items="dates"
            readonly
            :outlined="outlined"
            hide-details="auto"
            :clearable="clearable"
            outlined
            :disabled="disabled"
            multiple
            dense
            @click:append="menu = !menu"
            @click:clear="clear()"
            ><template v-slot:selection="{ item }">
                <v-chip small>
                    <strong>{{ item }}</strong>
                </v-chip>
            </template>
        </v-select>
        <v-date-picker
            class="transparent"
            :type="month ? 'month' : 'date'"
            noTitle
            label="Date Range"
            clearable
            hide-details
            :max="max ?? null"
            v-model="dates"
            :disabled="disabled"
            range
            full-width
            :picker-date="pickerDate"
            @update:picker-date="pickerDate = $event"
            @change="menu = false"
            @input="
                let getDates = $event.sort();
                $emit('input', {
                    rangeStart: getDates[0] ?? '0',
                    rangeEnd: getDates[1],
                });
            "
        />
        <v-col>
            <v-chip-group
                v-model="selectedOption"
                :column="$vuetify.breakpoint.mdAndUp"
                ><v-chip
                    v-for="option in presetOptions"
                    :key="option.display"
                    @click="[option.method(), (option.loading = true)]"
                    small
                    :class="option.class"
                    ><strong>{{ option.display }}</strong>
                    <v-progress-circular
                        v-if="option.loading"
                        class="ml-2"
                        size="12"
                        width="2"
                        indeterminate
                        color="primary"
                    />
                </v-chip> </v-chip-group
        ></v-col>
    </div>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment";
import { VMenu, VBottomSheet } from "vuetify/lib";

export default {
    components: {
        VMenu,
        VBottomSheet,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default: () => [],
        },
        max: {
            type: String,
            default: null,
        },
        month: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Boolean,
            default: false,
        },
        dateOptions: {
            type: String,
            default: "today, month, year, quarter",
        },
    },
    mixins: [globalMoment],
    data() {
        return {
            menu: false,
            dates: null,
            pickerDate: null,
            selectedOption: null,
        };
    },
    methods: {
        clear() {
            this.dates = [];
            this.$emit("clear");
        },
        setPicker() {
            this.pickerDate = this.month
                ? moment(this.value[0]).format("YYYY")
                : moment(this.value[0]).format("YYYY-MM");
        },
    },
    computed: {
        format() {
            if (this.month) return "YYYY-MM";
            return "YYYY-MM-DD";
        },
        presetOptions() {
            return [
                {
                    display: "Today",
                    type: "today",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("day")
                                .format(this.format),
                            rangeEnd: moment().endOf("day").format(this.format),
                        });
                    },
                },
                {
                    display: "Upcoming",
                    type: "upcoming",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("day")
                                .format(this.format),
                            rangeEnd: moment()
                                .add(6, "months")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "Past",
                    type: "past",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: "0",
                            rangeEnd: moment().format(this.format),
                        });
                    },
                },
                {
                    display: "All-Time",
                    type: "all",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: "0",
                            rangeEnd: moment().format(this.format),
                        });
                    },
                },
                {
                    display: "This Month",
                    type: "month",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("month")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("month")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "Month-to-Date",
                    type: "month",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("month")
                                .format(this.format),
                            rangeEnd: moment().endOf("day").format(this.format),
                        });
                    },
                },
                {
                    display: "Last Month",
                    type: "month",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("month")
                                .add(-1, "month")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("month")
                                .add(-1, "month")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "This Year",
                    type: "year",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("year")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("year")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "Year-to-Date",
                    type: "year",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("year")
                                .format(this.format),
                            rangeEnd: moment().endOf("day").format(this.format),
                        });
                    },
                },
                {
                    display: "Last Year",
                    type: "year",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("year")
                                .add(-1, "year")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("year")
                                .add(-1, "year")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "This Quarter",
                    type: "quarter",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("quarter")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("quarter")
                                .format(this.format),
                        });
                    },
                },
                {
                    display: "Quarter-to-Date",
                    type: "quarter",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("quarter")
                                .format(this.format),
                            rangeEnd: moment().endOf("day").format(this.format),
                        });
                    },
                },
                {
                    display: "Last Quarter",
                    type: "quarter",
                    method: () => {
                        this.$emit("input", {
                            rangeStart: moment()
                                .startOf("quarter")
                                .add(-1, "quarter")
                                .format(this.format),
                            rangeEnd: moment()
                                .endOf("quarter")
                                .add(-1, "quarter")
                                .format(this.format),
                        });
                    },
                },
            ].filter((e) => this.dateOptions.includes(e.type));
        },
    },
    mounted() {
        this.dates = this.value.map((e) => moment(e).format(this.format));
        this.setPicker();
    },
    watch: {
        value(val) {
            this.dates = val.map((e) => moment(e).format(this.format));
            this.setPicker();
            this.presetOptions.forEach((e) => (e.loading = false));
        },
    },
};
</script>

<style scoped>
.block-chip {
    margin-right: 240px;
}
</style>
