<template>
    <v-list :class="{ 'absolute-header': absolute }">
        <v-overlay v-if="loader" :value="loading" z-index="999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="soli-subtitle">
                    <component
                        :is="datePicker.menuComp"
                        v-bind="{ ...datePicker.menuProps }"
                        v-model="picker"
                        v-if="dateRange.length && !legacyDate"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                                class="flex align-center"
                                style="width: min-content"
                            >
                                {{ getTitle }}
                                <v-icon small right color="dark"
                                    >keyboard_arrow_down</v-icon
                                >
                            </div>
                        </template>
                        <DateRange
                            :value="dateRange"
                            :dateOptions="dateOptions"
                            :month="monthSelector"
                            @input="
                                updateRange({
                                    ...$event,
                                    allLocations: allLocations,
                                })
                            "
                            @clear="picker = false"
                        />
                    </component>
                    <div v-else class="flex align-center">
                        {{ getTitle }}
                    </div>
                </v-list-item-title>
                <small v-if="updated">{{
                    `Last updated ${globalMomentDateAtTime(new Date(updated))}`
                }}</small>
                <v-list-item-subtitle style="white-space: normal"
                    >{{ subtitle }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    v-if="can('View admin reports') && locationOptions"
                    class="font-bold py-1"
                    style="white-space: normal"
                    ><v-btn
                        x-small
                        outlined
                        class="soli-round soli-blue-text"
                        @click="jumpDate(0, !allLocations)"
                        >{{
                            allLocations
                                ? `View ${selectedLocation.name}`
                                : `View All Locations`
                        }}</v-btn
                    >
                </v-list-item-subtitle>
                <slot name="extendedSubtitle"></slot>
            </v-list-item-content>
            <v-list-item-action>
                <v-row class="justify-end">
                    <component
                        v-if="filter"
                        :is="dialog.menuComp"
                        v-bind="{ ...dialog.menuProps }"
                        v-model="menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                id="menu-button"
                                v-bind="attrs"
                                v-on="on"
                                :small="$vuetify.breakpoint.xs"
                                color="white"
                                icon
                                class="elevation-3"
                                :class="{ 'mr-4': dateSelection }"
                            >
                                <v-badge
                                    color="accent"
                                    overlap
                                    bordered
                                    dot
                                    :value="search || filterData"
                                >
                                    <v-icon
                                        class="soli-blue-text text-shadow-blue"
                                        >tune</v-icon
                                    >
                                </v-badge></v-btn
                            >
                        </template>
                        <v-list class="safe-bottom"
                            ><v-list-item v-if="!hideSearch">
                                <v-text-field
                                    v-model="search"
                                    label="Filter"
                                    single-line
                                    outlined
                                    dense
                                    clearable
                                    :append-icon="search ? null : 'tune'"
                                    hide-details
                                    autofocus
                                ></v-text-field></v-list-item
                            ><v-list-item v-if="false"
                                ><v-select
                                    label="Group By"
                                    dense
                                    outlined
                                    item-text="display"
                                    item-value="value"
                                    @input="$emit('setGroupBy', $event)"
                                    v-model="groupBy"
                                    :items="[
                                        {
                                            display: 'Total',
                                            value: null,
                                        },
                                        {
                                            display: 'Location',
                                            value: 'location',
                                        },
                                        {
                                            display: 'Date',
                                            value: 'date',
                                        },
                                    ]"
                                    return-object
                                    hide-details
                                ></v-select
                            ></v-list-item>
                            <slot name="filterItem"></slot>
                        </v-list>
                    </component>
                </v-row>
            </v-list-item-action>
            <v-list-item-action v-if="dateSelection"
                ><v-row>
                    <v-btn
                        :small="$vuetify.breakpoint.xs"
                        icon
                        class="elevation-3"
                        @click="jumpDate(-1, allLocations)"
                        ><v-icon color="dark">chevron_left</v-icon></v-btn
                    >
                    <v-btn
                        :small="$vuetify.breakpoint.xs"
                        icon
                        class="elevation-3 ml-1"
                        @click="jumpDate(1, allLocations)"
                        ><v-icon color="dark">chevron_right</v-icon></v-btn
                    >
                    <v-text-field
                        v-if="false"
                        v-model="search"
                        style="width: 200px"
                        class="pl-6"
                        append-icon="filter_list"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field
                ></v-row>
            </v-list-item-action> </v-list-item
    ></v-list>
</template>

<script>
import globalMoment from "../../Mixins/GlobalMoment";
import { VMenu, VBottomSheet } from "vuetify/lib";
import DateRange from "./DateRange.vue";

export default {
    components: {
        VMenu,
        VBottomSheet,
        DateRange,
    },
    props: {
        loader: {
            type: Boolean,
            default: false,
        },
        selectedLocation: {
            type: Object,
            default: null,
        },
        allLocations: {
            type: Boolean,
            default: null,
        },
        locationOptions: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Boolean,
            default: false,
        },
        legacyDate: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        updated: {
            type: String,
            default: null,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        filterData: {
            type: Boolean,
            default: false,
        },
        dateSelection: {
            type: Boolean,
            default: true,
        },
        dateRange: {
            type: Array,
            default: () => [],
        },
        reportUrl: {
            type: String,
            default: null,
        },
        dateOptions: {
            type: String,
            default: "today, month, year, quarter",
        },
        staticDate: {
            type: String,
            default: null,
        },
        monthSelector: {
            type: Boolean,
            default: false,
        },
        rangeOptions: {
            type: Object,
            default: () => {
                return {
                    interval: 1,
                    frequency: "month",
                };
            },
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        params: {},
    },
    mixins: [globalMoment],
    data() {
        return {
            search: "",
            groupBy: { display: "Total", value: null },
            menu: false,
            picker: false,
            loading: false,
        };
    },
    methods: {
        jumpDate(adjust, allLocations = false) {
            const date = this.legacyDate ?? this.dateRange[0];
            const int = this.rangeOptions.interval;
            const freq = this.rangeOptions.frequency;

            const rangeStart = moment(new Date(date))
                .tz("UTC")
                .add(int * adjust, freq);

            const rangeEnd = rangeStart;

            this.updateRange({
                rangeStart: adjust ? rangeStart.format("YYYY-MM-DD") : date,
                rangeEnd: adjust
                    ? rangeEnd.endOf("month").format("YYYY-MM-DD")
                    : this.dateRange[1],
                allLocations: allLocations,
            });
        },
        updateRange({
            rangeStart,
            rangeEnd,
            allLocations = false,
            compareMonth = null,
        }) {
            if (this.legacyDate) {
                return this.legacyRange(rangeStart, allLocations, compareMonth);
            }
            if (!rangeStart || !rangeEnd) return;

            let timer = setTimeout(() => {
                this.loading = true;
            }, 100);

            let data = {};
            if (compareMonth !== null) data.compareMonth = compareMonth;
            if (allLocations !== null) data.allLocations = Number(allLocations);
            if (rangeStart !== null) data.rangeStart = rangeStart;
            if (rangeEnd !== null) data.rangeEnd = rangeEnd;
            if (this.params) data = { ...data, ...this.params };

            this.$inertia.visit(this.reportUrl, {
                data: data,
                preserveState: true,
                onSuccess: () => {
                    clearTimeout(timer);
                    this.$emit("reset");
                    this.loading = false;
                    this.picker = false;
                },
            });
        },
        legacyRange(date, allLocations, compareMonth) {
            let timer = setTimeout(() => {
                this.loading = true;
            }, 300);

            let data = {};
            if (compareMonth !== null) data.compareMonth = compareMonth;
            if (allLocations !== null) data.allLocations = Number(allLocations);

            this.$inertia.visit(this.reportUrl + date, {
                data: data,
                preserveState: true,
                onSuccess: () => {
                    clearTimeout(timer);
                    this.$emit("reset");
                    this.loading = false;
                },
            });
        },
    },
    computed: {
        getTitle() {
            if (!this.dateRange.length) {
                if (this.staticDate) return this.staticDate;

                return this.legacyDate
                    ? moment(this.legacyDate).format("MMMM YYYY ")
                    : null;
            }

            const viewingCurrentYear =
                moment(this.dateRange[0]).isSame(this.dateRange[1], "year") &&
                moment(this.dateRange[0]).isSame(moment(), "year");

            let format = null;

            if (viewingCurrentYear) {
                format = this.$vuetify.breakpoint.xs ? "MMM DD" : "MMMM DD";
            } else {
                format = this.$vuetify.breakpoint.xs ? "MMM DD, YY" : "LL";
            }

            return `${moment(this.dateRange[0]).format(format)} - ${moment(
                this.dateRange[1]
            ).format(format)}`;
        },
        dialog() {
            return {
                menuComp: this.$vuetify.breakpoint.smAndDown
                    ? "v-bottom-sheet"
                    : "v-menu",
                menuProps: this.$vuetify.breakpoint.smAndDown
                    ? {
                          "close-on-content-click": false,
                          ref: "menu",
                      }
                    : {
                          "close-on-content-click": false,
                          "nudge-bottom": "4",
                          "offset-y": true,
                          left: true,
                          "max-width": "260",
                          ref: "menu",
                      },
            };
        },
        datePicker() {
            return {
                menuComp: this.$vuetify.breakpoint.smAndDown
                    ? "v-bottom-sheet"
                    : "v-menu",
                menuProps: this.$vuetify.breakpoint.smAndDown
                    ? {
                          "close-on-content-click": false,
                          ref: "datePicker",
                      }
                    : {
                          "close-on-content-click": false,
                          "offset-y": true,
                          "nudge-bottom": "2",
                          "nudge-left": "8",
                          "max-width": "360",
                          ref: "datePicker",
                      },
            };
        },
    },
    watch: {
        search(val) {
            this.$emit("search", val);
        },
        loading(val) {
            this.$emit("loading", val);
        },
    },
};
</script>

<style>
.absolute-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent !important;
    padding: 6px 12px;
}
</style>
