<template>
    <div>
        <SoliForm
            v-model="valid"
            :title="`${editing ? 'Edit' : 'Create'} Lead`"
            :limit-scroll="$vuetify.breakpoint.sm"
            :mobile-scroll="$vuetify.breakpoint.xs"
            :errors="errors"
            :loadingProp="form.loading"
            :saveChanges="(edited || !editing) && !form.readonly"
            :disabled="form.readonly"
            persistent
            text="Save"
            icon="save"
            ref="form"
            @reset="$emit('reset')"
            @submit="submit"
        >
            <template #subtitle>
                <v-btn
                    v-if="!form.readonly && editing"
                    rounded
                    x-small
                    outlined
                    color="accent"
                    :disabled="edited"
                    @click="handleConversion(false)"
                    >Convert To User</v-btn
                >
            </template>
            <template #tabs>
                <v-card-subtitle
                    v-if="form.children.length && !loading"
                    :class="{ 'pt-0': !form.readonly }"
                    class="pb-0"
                >
                    <p v-if="form.readonly" class="caption soli-grey mb-0">
                        This lead has converted to a customer and can no longer
                        be edited.
                    </p>
                    <v-tabs v-model="tab" touchless>
                        <v-tab>Info</v-tab>
                        <v-tab>Relationships</v-tab>
                    </v-tabs>
                </v-card-subtitle>
            </template>
            <template #body>
                <v-skeleton-loader v-if="loading" type="article" />

                <v-tabs-items v-if="!loading" v-model="tab">
                    <v-tab-item :value="0">
                        <div
                            :class="{ 'pt-5': form.children.length }"
                            class="flex flex-col gap-4 py-1"
                        >
                            <v-row no-gutters class="gap-4">
                                <v-col>
                                    <v-text-field
                                        v-model="form.first_name"
                                        :rules="rules.input"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        prepend-inner-icon="person"
                                        label="First Name"
                                    ></v-text-field
                                ></v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.last_name"
                                        :rules="rules.input"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        label="Last Name"
                                        @input="
                                            form.children.forEach((e) => {
                                                if (e.last_name) return;
                                                e.last_name = $event;
                                            })
                                        "
                                    ></v-text-field
                                ></v-col>
                            </v-row>
                            <SoliText
                                v-model="form.email"
                                :errors="errors.email"
                                :rules="rules.email"
                                icon="alternate_email"
                                label="Email"
                                hide-details="auto"
                            ></SoliText>
                            <SoliPhone
                                v-model="form.phone"
                                icon="phone"
                                label="Phone"
                                :errors="errors.phone"
                                hide-details="auto"
                            />
                            <SoliInstruments
                                v-model="form.instrument_id"
                                :multiple="false"
                                :return-object="false"
                                hide-details="auto"
                                notSure
                                autocomplete
                            />
                            <SoliPrograms
                                v-model="form.program_id"
                                :multiple="false"
                                :return-object="false"
                                hide-details="auto"
                                notSure
                                autocomplete
                            />
                            <!--<v-textarea
                            ref="notes"
                            outlined
                            dense
                            class="soli-round"
                            label="Notes"
                            v-model="form.comments"
                            :rules="rules.textArea"
                            prepend-inner-icon="sticky_note_2"
                        />-->
                        </div>
                    </v-tab-item>
                    <v-tab-item :value="1">
                        <v-expansion-panels v-model="panel" mandatory accordion>
                            <v-expansion-panel
                                v-for="(child, idx) in form.children"
                                :key="idx"
                            >
                                <v-expansion-panel-header
                                    @click="removeEmpty()"
                                    class="py-0 px-1 custom-panel-header font-semibold soli-grey"
                                >
                                    {{
                                        child.first_name
                                            ? `${child.first_name} ${child.last_name}`
                                            : child.permanent
                                            ? "Edit Relationship"
                                            : "New Relationship"
                                    }}
                                    <template
                                        v-if="panel == idx"
                                        v-slot:actions
                                    >
                                        <v-btn
                                            v-if="!child.permanent"
                                            icon
                                            small
                                            @click.stop="removeRelation(idx)"
                                            ><v-icon color="error"
                                                >remove_circle_outline</v-icon
                                            ></v-btn
                                        >
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="custom-panel">
                                    <div class="flex flex-col gap-4 pt-1 pb-3">
                                        <v-row no-gutters class="gap-4">
                                            <v-col>
                                                <v-text-field
                                                    v-model="child.first_name"
                                                    prepend-inner-icon="person"
                                                    label="First Name"
                                                    outlined
                                                    :rules="rules.input"
                                                    dense
                                                    hide-details="auto"
                                                ></v-text-field
                                            ></v-col>
                                            <v-col>
                                                <v-text-field
                                                    v-model="child.last_name"
                                                    label="Last Name"
                                                    outlined
                                                    :rules="rules.input"
                                                    dense
                                                    hide-details="auto"
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                        <v-select
                                            :items="relationshipOptions"
                                            item-text="relation"
                                            label="Relationship"
                                            return-object
                                            :value="child.inverse"
                                            @change="
                                                (e) => (
                                                    (child.inverse =
                                                        e.relation),
                                                    (child.relation = e.inverse)
                                                )
                                            "
                                            outlined
                                            dense
                                            :rules="rules.select"
                                            prepend-inner-icon="handshake"
                                            hide-details="auto"
                                        />
                                        <SoliBirthday
                                            v-model="child.birthday"
                                            label="Birthday"
                                            hide-details="auto"
                                        />
                                        <SoliPrograms
                                            v-model="child.program_id"
                                            :multiple="false"
                                            :return-object="false"
                                            hide-details="auto"
                                            notSure
                                            autocomplete
                                        />
                                        <SoliInstruments
                                            v-model="child.instrument_id"
                                            :multiple="false"
                                            :return-object="false"
                                            hide-details="auto"
                                            notSure
                                            autocomplete
                                        /></div
                                ></v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-tab-item>
                </v-tabs-items>
            </template>

            <template #extraBtn>
                <v-btn
                    v-if="
                        form.children.length < 3 && !loading && !form.readonly
                    "
                    :disabled="form.readonly"
                    rounded
                    small
                    plain
                    block
                    color="accent"
                    class="mb-4"
                    @click="addChild()"
                    >Add Relationship<v-icon small class="pl-1 pb-05"
                        >person_add</v-icon
                    ></v-btn
                >
            </template>
        </SoliForm>
        <SoliChoice
            v-if="confirmConversion"
            ref="confirmConversion"
            title="Are you sure?"
            subtitle="This will convert this lead and any relationships to user accounts"
            loading1
            button1="Confirm"
            button2="Cancel"
            @option1="handleConversion(true)"
            @reset="confirmConversion = false"
        />
    </div>
</template>

<script>
import SoliForm from "./../Base/SoliForm.vue";
import globalMoment from "../../Mixins/GlobalMoment.js";

export default {
    props: {
        errors: {
            type: Object,
            default: () => ({}),
        },
        leadId: {
            type: Number,
            default: null,
        },
    },
    mixins: [globalMoment],
    data() {
        return {
            form: this.$inertia.form({
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                instrument_id: null,
                program_id: null,
                //comments: null,
                children: [],
                loading: false,
                online: false,
                virtual_client: false,
                timezone: this.$page.props.selectedLocation.timezone,
                readonly: false,
            }),
            edited: false,
            valid: false,
            conversionLoading: false,
            confirmConversion: false,
            rules: SoliForm.formRules,
            fetchLead: null,
            tab: 0,
            panel: 0,
            loading: true,
            formCopy: null,
            relationshipOptions: [
                {
                    inverse: "Parent",
                    relation: "Child",
                },
                {
                    inverse: "Child",
                    relation: "Parent",
                },
                {
                    inverse: "Grandchild",
                    relation: "Grandparent",
                },
                {
                    inverse: "Grandparent",
                    relation: "Grandchild",
                },
                {
                    inverse: "Sibling",
                    relation: "Sibling",
                },
                {
                    inverse: "Friend",
                    relation: "Friend",
                },
                {
                    inverse: "Spouse",
                    relation: "Spouse",
                },
                {
                    inverse: "Other",
                    relation: "Other",
                },
            ],
        };
    },
    computed: {
        hasChild() {
            return !!this.form.children.length;
        },
        editing() {
            return !!this.leadId;
        },
    },
    methods: {
        addChild() {
            if (!this.$refs.form.$refs.form.validate()) return;
            this.form.children.push({
                first_name: "",
                last_name: this.form.last_name,
                birthday: null,
                relation: null,
                inverse: null,
                program_id: null,
                instrument_id: null,
                online: false,
                virtual_client: false,
                promo: null,
                referrer: null,
                lesson_id: null,
                include_online: false,
                contact_only: false,
            });

            this.$nextTick(() => {
                this.tab = 1;
                this.panel = this.form.children.length - 1;
            });
        },
        removeRelation(idx) {
            if (this.form.children.length == 1) this.tab = 0;
            this.panel -= 1;
            setTimeout(
                () => {
                    this.form.children.splice(idx, 1);
                },
                this.form.children.length == 1 ? 0 : 300
            );
        },
        removeEmpty() {
            setTimeout(
                () => {
                    this.form.children = this.form.children.filter(
                        (child) => child.first_name || child.permanent
                    );
                },
                this.form.children.length > 1 ? 300 : 0
            );
        },
        submit() {
            if (!this.$refs.form.$refs.form.validate()) return;
            this.form.loading = true;

            this.form.transform((data) => ({
                ...data,
                instrument_id:
                    data.instrument_id === 0 ? null : data.instrument_id,
                program_id: data.program_id === 0 ? null : data.program_id,
                location_id: this.$page.props.selectedLocation.id,
                source: "Staff Entry",
                contact_only: false,
                has_child: this.hasChild,
                children: data.children.map((child) => ({
                    ...child,
                    instrument_id:
                        child.instrument_id === 0 ? null : child.instrument_id,
                    program_id:
                        child.program_id === 0 ? null : child.program_id,
                })),
            }));

            const postUrl = this.editing
                ? route("lead.update", this.leadId)
                : route("lead.store");

            this.form.post(postUrl, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$refs.form.dialog = false;
                    this.$refs.form.reset();
                    this.$emit("success");
                },
                onError: (errors) => {
                    this.form.loading = false;
                    this.errors.form = Object.values(errors)[0];
                    console.log(
                        "This form returned errors:" + JSON.stringify(errors)
                    );
                },
            });
        },
        handleConversion(confirmed = false) {
            if (!confirmed) {
                this.confirmConversion = true;
                return;
            }
            this.conversionLoading = true;

            this.$inertia.put(
                route("lead.convert", this.leadId),
                {},
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$refs.confirmConversion.dialog = false;
                        this.conversionLoading = false;
                        this.$refs.form.dialog = false;
                        this.$emit("success");
                    },
                }
            );
        },
    },
    watch: {
        tab(val) {
            if (val == 0) this.removeEmpty(true);
        },
        form: {
            handler: function (val) {
                if (!this.formCopy) return;
                if (JSON.stringify(val.data()) == this.formCopy) {
                    this.edited = false;
                } else {
                    this.edited = true;
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.leadId) {
            this.formCopy = JSON.stringify(this.form.data());
            this.loading = false;
            return;
        }
        axios.get(route("lead.show", this.leadId)).then((response) => {
            this.fetchLead = response.data;
            for (const key in this.fetchLead) {
                if (key == "children") continue;
                if (this.form.hasOwnProperty(key)) {
                    this.form[key] = this.fetchLead[key];
                }
            }
            this.form.children = this.fetchLead.children.map((child) => {
                const getRelationship = this.relationshipOptions.find(
                    (e) => e.relation == child.relation
                );

                return {
                    ...child,
                    relation: getRelationship?.relation ?? null,
                    inverse: getRelationship?.inverse ?? null,
                    permanent: true,
                    disabled: !!child.user_id,
                    birthday: child.birthday
                        ? this.globalMomentBirthday(child.birthday)
                        : null,
                };
            });

            this.panel = this.form.children.length - 1;
            this.formCopy = JSON.stringify(this.form.data());
            this.loading = false;
        });
    },
};
</script>

<style>
.custom-panel .v-expansion-panel-content__wrap {
    padding: 0px;
}

.custom-panel-header {
    min-height: 48px !important;
}
</style>
