<template>
    <v-container class="pb-0">
        <v-card class="soli-round mb-5 stripe-shadow" outlined>
            <v-list dense class="px-3">
                <v-list-item v-if="subtotal !== null">
                    <v-list-item-subtitle class="pb-0 justify-start flex"
                        >Subtotal</v-list-item-subtitle
                    ><v-spacer />
                    <v-list-item-subtitle class="flex justify-end">
                        {{ formatUnitAmount(subtotal) }}
                    </v-list-item-subtitle></v-list-item
                >
                <!--<v-list-item v-if="subtotal !== null">
                    <v-list-item-subtitle class="pb-0 justify-start flex"
                        >Tax</v-list-item-subtitle
                    ><v-spacer />
                    <v-list-item-subtitle class="flex justify-end">
                        $0.00
                    </v-list-item-subtitle></v-list-item
                >-->
                <v-list-item v-if="appliedGift">
                    <v-list-item-subtitle class="justify-start flex">{{
                        `${appliedGift.code} · ${formatUnitAmount(
                            appliedGift.amount
                        )}`
                    }}</v-list-item-subtitle>

                    <v-spacer />
                    <v-list-item-subtitle class="justify-end flex">{{
                        `- ${formatUnitAmount(appliedGift.discount)}`
                    }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="appliedPromo">
                    <v-list-item-subtitle class="justify-start flex">{{
                        `${appliedPromo.code} · ${appliedPromo.name}`
                    }}</v-list-item-subtitle>

                    <v-spacer />
                    <v-list-item-subtitle class="justify-end flex">{{
                        `- ${formatUnitAmount(appliedPromo.amount)}`
                    }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="subtotal !== null">
                    <v-list-item-subtitle class="pb-0 justify-start flex"
                        >Amount Due</v-list-item-subtitle
                    ><v-spacer />
                    <v-list-item-title class="flex justify-end title soli-grey">
                        {{ formatUnitAmount(totalAmountDue) }}
                    </v-list-item-title></v-list-item
                >
                <v-skeleton-loader
                    v-else
                    type="list-item-two-line"
                ></v-skeleton-loader>
            </v-list>

            <!--<v-card-actions>
                <v-btn text color="dark" class="soli-round" small
                    >Gift or promo code?</v-btn
                >
            </v-card-actions>-->
        </v-card>

        <ShoppingCartPromo
            v-if="prices.length"
            :errors="errors"
            :prices="prices"
            :subtotal="subtotal"
            :guest="guest"
            @applyPromo="applyPromo($event)"
            @applyGift="applyGift($event)"
        />

        <!-- Display a payment form -->
        <form id="payment-form" style="min-height: 200px">
            <div id="payment-element">
                <!-- Elements will create form elements here -->
            </div>
            <div id="error-message">
                <!-- Display error message to your customers here -->
            </div>
        </form>

        <v-card-text class="my-4">
            This will schedule an assessment lesson with
            <a>{{ teacherName }}</a> risk free with our
            <a
                href="https://musicologielessons.com/why-choose-musicologie"
                target="_blank"
                >Perfect Match Promise</a
            >
            {{
                totalAmountDue
                    ? `Your card will be charged ${formatUnitAmount(
                          totalAmountDue
                      )}`
                    : "Your card will not be charged"
            }}. You'll receive a welcome email at <a>{{ guest.email }}</a> with
            further instructions.
        </v-card-text>
        <p v-if="errors" class="caption red--text mx-auto">
            {{ Object.values(errors)[0] }}
        </p>
        <SoliBtn
            gradient="gradient-orange"
            text="Book It!"
            icon="music_note"
            block
            @click="submitManualCard()"
            :loading="loading"
        ></SoliBtn>
    </v-container>
</template>

<script>
import ShoppingCartPromo from "../../Components/ShoppingCart/ShoppingCartPromo.vue";

export default {
    components: {
        ShoppingCartPromo,
    },
    props: {
        errors: {},
        guest: {},
        child: {},
        location_id: {},
        teacherName: {},
        lesson: {
            type: Object,
            default: () => {},
        },
        valid: false,
    },
    data() {
        return {
            paid: false,
            elementsLoading: false,
            stripe: null,
            stripeElements: null,
            loading: false,
            amountDue: null,
            prices: [],
            appliedPromo: null,
            subtotal: null,
            totalAmountDue: null,
            appliedGift: null,
        };
    },
    methods: {
        async manualCardElement() {
            this.elementsLoading = true;
            await this.$loadScript("https://js.stripe.com/v3/");

            const response = axios
                .get(route("stripe.guestPayment"), {
                    params: {
                        email: this.guest.email,
                        location_id: this.location_id,
                        duration: this.lesson.duration,
                    },
                })
                .then((resp) => {
                    const clientSecret = resp.data.client_secret;
                    this.stripe = Stripe(resp.data.stripe_key);
                    this.subtotal = resp.data.amountDue;
                    this.totalAmountDue = resp.data.amountDue;
                    this.prices = resp.data.prices;
                    const appearance = {
                        theme: "stripe",
                        labels: "floating",
                        variables: {
                            colorText: "#4b6876",
                            spacingUnit: "2px",
                            borderRadius: "20px",
                            spacingTab: "16px",
                            spacingGridRow: "20px",
                            spacingGridColumn: "16px",
                            // See all possible variables below
                        },
                    };
                    const elements = this.stripe.elements({
                        clientSecret,
                        appearance,
                    });
                    this.stripeElements = elements;

                    const paymentElement = elements.create("payment", {
                        wallets: { applePay: "never", googlePay: "never" },
                        terms: { card: "never" },
                    });
                    paymentElement.mount("#payment-element");
                    paymentElement.on("ready", (event) => {
                        this.elementsLoading = false;
                        this.paid = true;
                    });
                });
        },
        submitManualCard() {
            this.$emit("paymentLoading", true);
            this.loading = true;
            const elements = this.stripeElements;
            this.stripe
                .confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: route(
                            "getStarted.confirm",
                            this.child?.hash_id
                        ),
                    },
                    redirect: "if_required",
                })
                .then((resp) => {
                    if (resp.error) {
                        this.loading = false;
                        this.$emit("paymentLoading", false);
                        this.$page.props.errors = {
                            error: resp.error.message,
                        };
                    } else {
                        //this.loading = false;
                        //this.$emit("paymentLoading", false);
                        this.$emit("submit", {
                            payment: resp.setupIntent,
                            priceId: this.prices[0].id,
                            promoId: this.appliedPromo?.id ?? null,
                            giftId: this.appliedGift?.id ?? null,
                        });
                    }
                });
        },
        applyPromo(promo) {
            this.resetPromo();
            let total = this.subtotal - promo.amount;
            if (total >= 0) this.totalAmountDue = total;
            this.appliedPromo = promo;
            this.$emit("applyPromo");
        },
        applyGift(gift) {
            this.resetPromo();

            let remainingGift = gift.amount - this.subtotal;
            if (remainingGift > 0) {
                this.totalAmountDue = 0;
                this.appliedGift.discount = this.subtotal;
            } else {
                this.totalAmountDue = this.subtotal - gift.amount;
                this.appliedGift.discount = gift.amount;
            }

            this.appliedGift = gift;
            this.$emit("applyPromo"); // just shows message in parent
        },
        resetPromo() {
            this.appliedGift = null;
            this.appliedPromo = null;
            this.totalAmountDue = this.subtotal;
        },
    },
    mounted() {
        this.manualCardElement();
    },
};
</script>

<style>
.short-bone {
    transform: translateY(-6px);
}
</style>
