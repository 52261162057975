<template>
    <div>
        <v-banner
            v-if="user.hasRoles.includes('Archived Teacher')"
            color="soli-status-red"
            ><div class="white--text flex justify-center">
                This teacher has been archived
            </div></v-banner
        >
        <v-row v-masonry>
            <v-col cols="12" md="6">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title class="soli-grey">
                            Settings
                        </v-card-title>
                        <v-card-subtitle>
                            <v-btn
                                @click="
                                    isTeacher
                                        ? (archiveTeacherWarning = true)
                                        : (setTeacherConfirm = true)
                                "
                                x-small
                                outlined
                                class="soli-round soli-blue-text"
                                >{{
                                    isTeacher
                                        ? "Archive Teacher"
                                        : isArchivedTeacher
                                        ? "Unarchive Teacher"
                                        : "Hire as Teacher"
                                }}</v-btn
                            >
                            <v-btn
                                @click="
                                    isStaff
                                        ? (archiveStaffWarning = true)
                                        : (setStaffConfirm = true)
                                "
                                x-small
                                outlined
                                class="soli-round soli-blue-text"
                                >{{
                                    isStaff
                                        ? "Archive Staff"
                                        : isArchivedStaff
                                        ? "Unarchive Staff"
                                        : "Hire as Staff"
                                }}</v-btn
                            ></v-card-subtitle
                        >
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-form ref="form">
                                        <StaffRoles
                                            v-if="isStaff || isArchivedStaff"
                                            v-model="form.staff_role"
                                            :disabled="isArchivedStaff"
                                            clearable
                                        />
                                        <SoliDate
                                            v-if="isStaff || isArchivedStaff"
                                            label="Staff Start Date"
                                            v-model="form.staffStartDate"
                                            :error="errors.starts_at"
                                            :rules="rules.input"
                                            noLimit
                                        />
                                        <div
                                            v-if="
                                                isTeacher || isArchivedTeacher
                                            "
                                        >
                                            <!--<SoliInstruments
                                            :disabled="isArchivedTeacher"
                                            v-model="form.instruments"
                                            @input="form.instruments.sort()"
                                            :rules="rules.select"
                                        ></SoliInstruments>-->

                                            <TeacherCertification
                                                :disabled="isArchivedTeacher"
                                                :alert="
                                                    isTeacher &&
                                                    !user.certification_level
                                                "
                                                v-model="
                                                    form.certification_level
                                                "
                                            ></TeacherCertification>
                                            <SoliDate
                                                v-if="
                                                    isTeacher ||
                                                    isArchivedTeacher
                                                "
                                                label="Teacher Start Date"
                                                v-model="form.teacherStartDate"
                                                :error="errors.starts_at"
                                                :rules="rules.input"
                                                noLimit
                                            />
                                            <v-switch
                                                :disabled="isArchivedTeacher"
                                                class="mt-0"
                                                dense
                                                label="Can auto-assign online availability"
                                                v-model="form.autoAssign"
                                            ></v-switch>
                                        </div>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" v-if="hasWorked">
                <StaffPayRates
                    :user="user"
                    :payRates="payRates"
                    :rules="rules"
                    :isStaff="isStaff"
                    :isTeacher="isTeacher"
                    :errors="errors"
                    @hook:updated="redrawVueMasonry()"
                    @success="$emit('success')"
                />
            </v-col>

            <v-col cols="12" md="6" v-if="isTeacher || isArchivedTeacher">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title class="soli-grey"
                            >Instruments<v-icon
                                v-if="missingInstruments"
                                right
                                color="error"
                                >error_outline</v-icon
                            ><v-spacer />
                            <v-btn icon @click="showInstrumentHelp = true"
                                ><v-icon color="primary">help</v-icon></v-btn
                            ></v-card-title
                        >
                        <v-card-subtitle>
                            <v-btn
                                @click="[(showInstrumentForm = true)]"
                                x-small
                                outlined
                                class="soli-round soli-blue-text"
                                :disabled="
                                    !user.hasPermissions.includes('Work')
                                "
                                >Add Instrument</v-btn
                            >
                        </v-card-subtitle>
                        <v-data-table
                            :headers="instrumentHeaders"
                            :items="instruments"
                            hide-default-footer
                            mobile-breakpoint="0"
                            @click:row="editInstrument"
                            no-data-text="No instruments available"
                            style="cursor: pointer; white-space: nowrap"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:[`item.ageRanges`]="{ item }">
                                <v-chip
                                    v-for="range in getAgeRange(item)"
                                    :key="range"
                                    pill
                                    class="soli-status-group white--text ma-05"
                                    small
                                    >{{ range }}</v-chip
                                >
                            </template>
                            <template v-slot:[`item.skillLevels`]="{ item }">
                                <v-chip
                                    v-for="level in getSkillLevel(item)"
                                    :key="level"
                                    pill
                                    class="soli-status-blue white--text ma-05"
                                    small
                                    >{{ level }}</v-chip
                                >
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card>
            </v-col>

            <SoliResponsiveDialog
                v-if="showInstrumentHelp"
                title="About Ages and Levels"
                @reset="showInstrumentHelp = false"
                ><template #body>
                    <v-card-text class="pt-0"
                        ><ul class="inside-list">
                            <li>
                                A teacher who passes the Beginner level Audition
                                can teach: young beginners ages 5-7
                            </li>
                            <li>
                                A teacher who passes the Intermediate level
                                Audition can teach: beginner students of all
                                ages and Labs
                            </li>
                            <li>
                                A teacher who passes the Advanced level Audition
                                can teach: Labs, beginner to advanced students
                                of all ages
                            </li>
                        </ul>
                    </v-card-text>
                </template></SoliResponsiveDialog
            >

            <v-col cols="12" md="6" v-if="isTeacher || isArchivedTeacher">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title
                            class="pb-0 soli-grey"
                            style="
                                max-height: 64px;
                                z-index: 100;
                                position: relative;
                            "
                        >
                            Stats <v-spacer />
                            <v-btn icon @click="showStatsHelp = true"
                                ><v-icon color="primary">help</v-icon></v-btn
                            ></v-card-title
                        >
                        <v-tabs v-model="statsTab" class="custom-tabs px-4">
                            <v-tab
                                v-for="stat in teacherStats"
                                :key="stat.title"
                                :disabled="stat.disabled"
                            >
                                {{ stat.title }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="statsTab" touchless>
                            <v-tab-item
                                v-for="(stat, idx) in teacherStats"
                                :key="stat.title"
                                :class="{
                                    'stat-with-options': stat.hasOptions,
                                }"
                                :transition="false"
                                style="min-height: 304px"
                            >
                                <TeacherStat
                                    v-if="idx == statsTab"
                                    :teacher="user"
                                    :showStatsHelp="showStatsHelp"
                                    :title="stat.title"
                                    :defaultOption="stat.defaultOption"
                                    :help="stat.help"
                                    @rendered="redrawVueMasonry"
                                    @reset="
                                        closeModal(
                                            () => (showStatsHelp = false)
                                        )
                                    "
                                    class="animate__animated animate__fadeIn"
                                />
                                <!--<TeacherStats
                                    v-if="stat.value"
                                    :showStatsHelp="
                                        !!(showStatsHelp && stat.help)
                                    "
                                    :stats="Object.values(stat.value)"
                                    :teacher="user"
                                    :title="stat.title"
                                    :help="stat.help"
                                    @reset="
                                        closeModal(
                                            () => (showStatsHelp = false)
                                        )
                                    "
                                />
                                <TeacherStatsChart
                                    v-if="stat.value?.chartData"
                                    :chartData="stat.value.chartData"
                                    @reset="
                                        closeModal(
                                            () => (showStatsHelp = false)
                                        )
                                    "
                                />
                                <div v-else class="pb-5" />-->
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>
                </v-card>
            </v-col>

            <!--<v-col cols="12" lg="6" v-if="isTeacher || isArchivedTeacher">
            <v-card class="soli-round mb-3">
                <v-container>
                    <v-card-title class="pb-0"> Review </v-card-title>
                    <v-tabs v-model="reviewTab" class="custom-tabs px-4">
                        <v-tab> Annual Reviews </v-tab>
                        <v-tab> Feedback </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="reviewTab">
                        <v-tab-item> </v-tab-item>
                    </v-tabs-items>
                </v-container>
            </v-card>
        </v-col>-->

            <v-col cols="12" md="6" v-if="isTeacher || isArchivedTeacher">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title class="soli-grey">
                            Annual Reviews
                        </v-card-title>
                        <v-card-subtitle>
                            <v-btn
                                @click="$refs.review.dialog = true"
                                x-small
                                outlined
                                class="soli-round soli-blue-text"
                                >New Review</v-btn
                            ></v-card-subtitle
                        ><TeacherReview
                            ref="review"
                            :teacher="user"
                            :annualReviews="annualReviews"
                            :errors="errors"
                            @success="$emit('success')"
                            @reset="$emit('reset')"
                        />
                    </v-container>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" v-if="isTeacher || isArchivedTeacher">
                <v-card class="soli-round">
                    <v-container>
                        <v-card-title class="soli-grey">
                            Feedback
                        </v-card-title>
                        <v-card-subtitle>
                            <v-btn
                                @click="$refs.feedback.dialog = true"
                                x-small
                                outlined
                                class="soli-round soli-blue-text"
                                >Add Feedback</v-btn
                            ></v-card-subtitle
                        ><TeacherFeedback
                            ref="feedback"
                            :teacher="user"
                            :feedback="feedback"
                            :errors="errors"
                            @success="$emit('success')"
                            @reset="$emit('reset')"
                        />
                    </v-container>
                </v-card>
            </v-col>

            <SoliForm
                v-if="showInstrumentForm"
                :title="`${
                    showInstrumentForm.id
                        ? `Edit ${showInstrumentForm.name} Details`
                        : 'Add Instrument'
                }`"
                @reset="reset()"
                @submit="saveInstrument(false)"
                text="Save"
                icon="save"
                ref="instrumentForm"
                mobileScroll
                :saveChanges="editInstrumentForm"
                persistent
            >
                <template #body>
                    <SoliInstruments
                        v-model="instrumentForm.id"
                        v-if="!showInstrumentForm.id"
                        :multiple="false"
                        :rules="rules.select"
                        :hideIcon="true"
                        :returnObject="false"
                        :filter="instruments.map((e) => e.id)"
                    />
                    <InstrumentSkillLevel
                        v-model="instrumentForm.skillLevels"
                        :rules="rules.input"
                    ></InstrumentSkillLevel>
                    <InstrumentAgeRange
                        v-model="instrumentForm.ageRanges"
                        :rules="rules.input"
                    ></InstrumentAgeRange>
                </template>
                <template v-slot:cancelBtn="attrs">
                    <SoliBtn
                        v-if="!editInstrumentForm && showInstrumentForm.id"
                        text="Delete"
                        icon="delete"
                        v-bind="attrs"
                        class="mt-3"
                        color="soli-status-red"
                        @click="saveInstrument(true)"
                    ></SoliBtn>
                </template>
            </SoliForm>

            <SoliChoice
                v-if="archiveTeacherWarning"
                title="Are you sure?"
                :subtitle="`This will remove ${user.first_name} from your teacher list. ${user.first_name} will no longer be able to access teacher-specific functionality. Any pending lessons will be paid at the end of this period. You will still need to remove any availability and lessons from ${user.first_name}'s schedule.`"
                button1="Confirm"
                button2="Nevermind"
                @option1="setTeacherStatus(false)"
                @reset="closeModal(() => (archiveTeacherWarning = false))"
            ></SoliChoice>
            <SoliChoice
                v-if="setTeacherConfirm"
                title="Are you sure?"
                :subtitle="`This will add ${user.first_name} to your teacher list. ${user.first_name} will gain access teacher-specific functionality.`"
                button1="Confirm"
                button2="Nevermind"
                @option1="setTeacherStatus(true)"
                @reset="closeModal(() => (setTeacherConfirm = false))"
            ></SoliChoice>
            <SoliChoice
                v-if="archiveStaffWarning"
                title="Are you sure?"
                :subtitle="`This will remove ${user.first_name} from your staff list. ${user.first_name} will no longer be able to access staff-specific functionality.`"
                button1="Confirm"
                button2="Nevermind"
                @option1="setStaffStatus(false)"
                @reset="closeModal(() => (archiveStaffWarning = false))"
            ></SoliChoice>
            <SoliChoice
                v-if="setStaffConfirm"
                title="Are you sure?"
                :subtitle="`This will add ${user.first_name} to your staff list. ${user.first_name} will gain access staff-specific functionality.`"
                button1="Confirm"
                button2="Nevermind"
                @option1="setStaffStatus(true)"
                @reset="closeModal(() => (setStaffConfirm = false))"
            ></SoliChoice>
        </v-row>
    </div>
</template>

<script>
import { Link } from "@inertiajs/vue2";
import TeacherCertification from "./TeacherCertification";
//import TeacherStats from "./TeacherStats";
import TeacherStat from "./TeacherStat";
import TeacherStatsChart from "./TeacherStatsChart.vue";
import { path } from "ramda";
import SoliForm from "./../Base/SoliForm.vue";
import StaffRoles from "../Staff/StaffRoles.vue";
import TeacherFeedback from "../Teachers/TeacherFeedback.vue";
import TeacherReview from "../Teachers/TeacherReview.vue";
import InstrumentSkillLevel from "../Instruments/InstrumentSkillLevel.vue";
import InstrumentAgeRange from "../Instruments/InstrumentAgeRange.vue";
import IndentList from "../GetStarted/AboutPrograms/IndentList.vue";
import StaffPayRates from "../Staff/StaffPayRatesTable.vue";
import SoliResponsiveDialog from "../Base/SoliResponsiveDialog.vue";

export default {
    name: "ManageTeacher",
    components: {
        Link,
        TeacherCertification,
        //TeacherStats,
        TeacherStat,
        TeacherStatsChart,
        StaffRoles,
        TeacherFeedback,
        TeacherReview,
        InstrumentSkillLevel,
        InstrumentAgeRange,
        IndentList,
        StaffPayRates,
    },
    props: {
        user: { type: Object },
        feedback: {},
        annualReviews: {},
        payRates: {},
        rules: {},
        errors: {},
        retention: {},
        conversion: {},
        attendance: {},
        instruments: {},
    },
    data() {
        return {
            instrumentHeaders: [
                { text: "Instrument", value: "name", align: "start" },
                {
                    text: "Age Range",
                    value: "ageRanges",
                    align: "start",
                },
                {
                    text: "Skill Level",
                    value: "skillLevels",
                    align: "start",
                },
            ],
            showStatsHelp: false,
            showInstrumentHelp: false,
            statsTab: null,
            reviewTab: null,

            showInstrumentForm: false,
            editInstrumentForm: false,
            instrumentFormCopy: null,

            archiveTeacherWarning: false,
            setTeacherConfirm: false,
            archiveStaffWarning: false,
            setStaffConfirm: false,
            formCopy: null,
            editing: false,
            submitProfileLoading: false,
            loading: false,
            form: {
                certification_level: this.user.certification_level,
                autoAssign: !this.user.hasPermissions.includes(
                    "Cannot auto-assign online availability"
                ),
                staff_role: this.getStaffRoles() ?? null,
                remove_role: null,
                teacherStartDate: this.user.teacherStartDate,
                staffStartDate: this.user.staffStartDate,
            },
            instrumentForm: {
                id: null,
                skillLevels: null,
                ageRanges: null,
                remove: false,
            },
        };
    },
    computed: {
        teacherStats() {
            return [
                {
                    title: "Retention",
                    defaultOption: "retention",
                    disabled: false,
                },
                {
                    title: "Conversion",
                    defaultOption: "conversion",
                    disabled: false,
                },
                {
                    title: "Attendance",
                    defaultOption: "attendance",
                    disabled: false,
                    hasOptions: true,
                },
                {
                    title: "Hours",
                    defaultOption: "hours",
                    disabled: false,
                    hasOptions: true,
                },
            ];
        },
        /*getCurrentRetention() {
            if (!this.retentionHistory.length) return;
            let length = this.retentionHistory.length;
            let value = this.retentionHistory[length - 1].teacher * 100;
            //let previous = this.retentionHistory[length - 2].teacher * 100;
            let previous = this.retentionHistory[length - 1].global * 100;

            let change = Number(value - previous).toFixed(2);
            return {
                value: value.toFixed(2),
                change: {
                    value: change,
                    color:
                        change > 0
                            ? "green"
                            : change < 0
                            ? "error"
                            : "soli-grey",
                    icon:
                        change > 0
                            ? "arrow_drop_up"
                            : change < 0
                            ? "arrow_drop_down"
                            : "trending_flat",
                },
            };
        },*/
        isStaff() {
            return this.user.hasRoles.includes("Staff");
        },
        isArchivedStaff() {
            return this.user.hasRoles.includes("Archived Staff");
        },
        isTeacher() {
            return this.user.hasRoles.includes("Teacher");
        },
        isArchivedTeacher() {
            return this.user.hasRoles.includes("Archived Teacher");
        },
        hasWorked() {
            return (
                this.isStaff ||
                this.isTeacher ||
                this.isArchivedStaff ||
                this.isArchivedTeacher
            );
        },
        missingInstruments() {
            return this.isTeacher && !this.instruments.length;
        },
    },
    methods: {
        getStaffRoles() {
            return this.user.hasRoles.filter((e) =>
                [
                    "Area Manager",
                    "Community Manager",
                    "Guest Community Manager",
                ].includes(e)
            )[0];
        },
        getSkillLevel(instrument) {
            let skillLevels = instrument.skillLevels.map((e) => e.name);
            if (skillLevels.length == 3) return ["All Abilities"];
            return skillLevels;
        },
        getAgeRange(instrument) {
            let ageRanges = instrument.ageRanges.map((e) => e.name);
            if (ageRanges.length == 3) return ["All-ages"];
            return ageRanges;
        },
        setTeacherStatus(status) {
            this.$inertia.put(
                `/users/${this.user.id}/manage`,
                { teacher_status: status },
                {
                    preserveState: false,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit("success");
                        this.$emit("reset");
                    },
                    onError: (errors) => {
                        this.$emit("reset");
                    },
                }
            );
        },
        setStaffStatus(status) {
            this.$inertia.put(
                `/users/${this.user.id}/manage`,
                { staff_status: status },
                {
                    preserveState: false,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit("success");
                        this.$emit("reset");
                    },
                    onError: (errors) => {
                        this.$emit("reset");
                    },
                }
            );
        },

        editInstrument(instrument) {
            console.log(instrument.id);
            this.showInstrumentForm = instrument;
            this.instrumentForm.id = instrument.id;
            this.instrumentForm.skillLevels = instrument.skillLevels;
            this.instrumentForm.ageRanges = instrument.ageRanges;
            this.instrumentFormCopy = JSON.stringify(this.instrumentForm);
        },
        cancel() {
            this.$inertia.visit(this.$page.url);
        },
        save() {
            this.$emit("submit");
            this.$inertia.put(`/users/${this.user.id}/manage`, this.form, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                    this.editing = false;
                    this.formCopy = JSON.stringify(this.form);
                    this.$emit("reset");
                },
                onError: (errors) => {
                    console.log(
                        "Form returned errors: " + JSON.stringify(errors)
                    );
                    this.$emit("reset");
                },
            });
        },
        reset() {
            this.$refs.instrumentForm?.reset();
            this.formCopy = JSON.stringify(this.form);
            setTimeout(() => {
                this.instrumentForm.id = null;
                this.instrumentForm.remove = null;
                this.instrumentForm.skillLevels = null;
                this.instrumentForm.ageRanges = null;
                this.instrumentFormCopy = JSON.stringify(this.instrumentForm);
                this.showInstrumentForm = false;
            }, 300);
        },
        saveInstrument(remove = false) {
            let instrumentData = {
                instrument: {
                    remove: remove,
                    id: this.instrumentForm.id,
                    skillLevels: this.instrumentForm.skillLevels.map(
                        (e) => e.id
                    ),
                    ageRanges: this.instrumentForm.ageRanges.map((e) => e.id),
                },
            };
            this.$inertia.put(`/users/${this.user.id}/manage`, instrumentData, {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit("success");
                    this.reset();
                },
                onError: (errors) => {
                    console.log(
                        "Form returned errors: " + JSON.stringify(errors)
                    );
                    this.$emit("reset");
                },
            });
        },
        redrawVueMasonry() {
            for (let i = 0; i <= 350; i += 350) {
                setTimeout(() => {
                    this.$redrawVueMasonry();
                }, i);
            }
        },
    },
    mounted() {
        this.formCopy = JSON.stringify(this.form);
    },
    beforeDestroy() {
        this.$emit("editing", false);
    },
    updated() {
        this.redrawVueMasonry();
    },
    watch: {
        form: {
            handler: function (val) {
                if (JSON.stringify(val) == this.formCopy) {
                    this.editing = false;
                } else {
                    this.editing = true;
                }

                if (val.staff_role !== JSON.parse(this.formCopy).staff_role) {
                    this.form.remove_role = JSON.parse(
                        this.formCopy
                    ).staff_role;
                } else {
                    this.form.remove_role = null;
                }
            },
            deep: true,
        },
        instrumentForm: {
            handler: function (val) {
                if (JSON.stringify(val) == this.instrumentFormCopy) {
                    this.editInstrumentForm = false;
                } else {
                    this.editInstrumentForm = true;
                }
            },
            deep: true,
        },
        editing(editing) {
            this.$emit("editing", editing);
        },
    },
};
</script>

<style>
.stat-with-options {
    min-height: 344px !important;
}

.inside-list {
    list-style: inside;
    padding-left: 4px !important;
}

.inside-list li {
    margin-bottom: 10px;
}
</style>
