<template>
    <v-list class="pa-1">
        <SoliStudent :student="form.student" class="custom-btn" disabled />
        <SoliTeacher
            :teacher="form.teacher"
            class="custom-btn"
            disabled
            blueBorder
        />
        <SoliLocation
            v-if="!$page.props.auth.user.virtual_client"
            :location="form.location"
            :room="form.room"
            :online="form.online"
            :address="form.offSiteLocation?.address"
            class="custom-btn"
            disabled
        />
        <ConfirmDateTime
            :date="form.startDate"
            :time="form.startTime"
            :repeating="getRepeating"
            class="custom-btn"
            disabled
        />
        <ConfirmLesson
            :type="form.type"
            :instrument="form.instrument"
            :duration="form.duration"
            :assessmentType="form.assessmentType"
            :event="form.templateEvent || form.credit?.group_event"
            :substitutePass="form.substitutePass"
            class="custom-btn"
            disabled
        />
        <ConfirmBilling
            :billingUser="form.billingUser"
            :purchaseOption="form.purchaseOption"
            class="custom-btn"
            disabled
        />
    </v-list>
</template>

<script>
import ConfirmDateTime from "./ConfirmDateTime.vue";
import ConfirmLesson from "./ConfirmLesson.vue";
import ConfirmBilling from "./ConfirmBilling.vue";

export default {
    components: {
        ConfirmDateTime,
        ConfirmLesson,
        ConfirmBilling,
    },
    props: {
        form: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        getRepeating() {
            const occurrences = this.form.templateEvent?.occurrences;
            if (occurrences) {
                const freqHuman = this.form.templateEvent?.freq_human;
                return `Repeats ${this.form.templateEvent?.rrule_freq} for ${occurrences} ${freqHuman}`;
            }

            if (this.form.repeating) {
                return "Repeats Weekly";
            }

            return "Does Not Repeat";
        },
    },
};
</script>

<style scoped>
.v-btn--outlined.custom-btn {
    border: none !important;
}

.custom-btn {
    cursor: default !important;
}

.custom-btn::before {
    background-color: transparent !important;
}
</style>
