<template>
    <v-container
        class="border border-grey-200 soli-round w-full h-auto flex flex-col py-1 relative"
    >
        <div
            v-if="showFormatMenu && $vuetify.breakpoint.smAndUp"
            class="flex gap-1"
        >
            <div v-for="(item, idx) in formatMenu" :key="idx">
                <v-btn
                    v-if="item.icon"
                    icon
                    small
                    :input-value="editor?.isActive(item.name)"
                    tabindex="1"
                    @click="item.apply()"
                    ><v-icon
                        :color="editor?.isActive(item.name) ? 'accent' : 'dark'"
                        >{{ item.icon }}</v-icon
                    ></v-btn
                >
                <v-divider v-if="!item.icon" vertical class="mx-2" />
            </div>
        </div>
        <editor-content :editor="editor" class="editor" />

        <div class="flex justify-between">
            <div :class="{ 'flex gap-2': !showFormatMenu }">
                <v-btn v-if="false" icon small color="dark"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                >
                <v-btn
                    icon
                    small
                    color="dark"
                    tabindex="1"
                    @click="
                        [
                            (showFormatMenu = !showFormatMenu),
                            editor.commands.focus(),
                        ]
                    "
                >
                    <v-icon>text_format</v-icon>
                </v-btn>

                <!--<v-btn
                    v-if="!showFormatMenu || $vuetify.breakpoint.smAndUp"
                    icon
                    small
                    color="dark"
                    @click=""
                    ><v-icon>insert_emoticon</v-icon></v-btn
                >-->
            </div>
            <div
                v-if="showFormatMenu && $vuetify.breakpoint.xs"
                class="flex px-2 gap-1"
            >
                <div v-for="(item, idx) in formatMenu" :key="idx">
                    <v-btn
                        v-if="item.icon"
                        icon
                        small
                        @click="item.apply()"
                        :input-value="editor?.isActive(item.name)"
                        ><v-icon
                            :color="
                                editor?.isActive(item.name) ? 'accent' : 'dark'
                            "
                            >{{ item.icon }}</v-icon
                        ></v-btn
                    >
                    <v-divider v-if="!item.icon" vertical class="mx-2" />
                </div>
            </div>
            <v-spacer />
            <div
                v-if="
                    characterLimit &&
                    editor &&
                    !(showFormatMenu && $vuetify.breakpoint.xs)
                "
                class="caption whitespace-nowrap my-auto mr-4"
            >
                {{ editor.storage.characterCount.characters() }} /
                {{ characterLimit }}
            </div>
            <v-btn
                v-if="!hideSubmit"
                icon
                small
                :color="valid ? 'primary' : 'dark'"
                @click="$emit('submit')"
                :loading="processing"
                ><v-icon>send</v-icon></v-btn
            >
        </div>
    </v-container>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import History from "@tiptap/extension-history";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
//import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import { Extension } from "@tiptap/core";
import Youtube from "@tiptap/extension-youtube";
import HardBreak from "@tiptap/extension-hard-break";
import CharacterCount from "@tiptap/extension-character-count";
//import Strike from "@tiptap/extension-strike";
//import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

export default {
    components: { EditorContent },

    props: {
        value: {
            type: String,
            default: "",
        },
        processing: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: false,
        },
        hideSubmit: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: true,
        },
        breakOnEnter: {
            type: Boolean,
            default: false,
        },
        characterLimit: {
            type: Number,
            default: null,
        },
        formatToggle: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            editor: null,
            showFormatMenu: this.$vuetify.breakpoint.smAndUp,
            formatting: [],
            formatMenu: [
                {
                    name: "bold",
                    apply: () =>
                        this.editor?.chain().focus().toggleBold().run(),
                    icon: "format_bold",
                },
                {
                    name: "italic",
                    apply: () =>
                        this.editor?.chain().focus().toggleItalic().run(),
                    icon: "format_italic",
                },
                {
                    name: "underline",
                    apply: () =>
                        this.editor?.chain().focus().toggleUnderline().run(),
                    icon: "format_underline",
                },
                /*{
                    apply: () =>
                        this.editor?.chain().focus().toggleStrike().run(),
                    icon: "format_strikethrough",
                },*/
                {
                    divider: true,
                },
                {
                    name: "bulletList",
                    apply: () =>
                        this.editor
                            ?.chain()
                            .focus()
                            /*.insertContent(
                                this.editor?.isActive("bulletList")
                                    ? null
                                    : "<p></p>"
                            )*/
                            .toggleBulletList()
                            .run(),
                    icon: "format_list_bulleted",
                },
                /*{
                    apply: () =>
                        this.editor?.chain().focus().toggleOrderedList().run(),
                    icon: "format_list_numbered",
                },*/
            ],
        };
    },
    methods: {
        submit() {
            this.$emit("submit");
        },
    },
    computed: {
        onScreenKeyboard() {
            return "ontouchstart" in document.documentElement;
        },
    },
    mounted() {
        const CustomEnter = Extension.create({
            addKeyboardShortcuts: () => {
                return {
                    Enter: ({ editor }) => {
                        if (this.breakOnEnter) {
                            return editor.commands.first(({ commands }) => [
                                () => commands.newlineInCode(),
                                () => commands.splitListItem("listItem"),
                                () => commands.createParagraphNear(),
                                () => commands.liftEmptyBlock(),
                                () => commands.splitBlock(),
                            ]);
                        }
                        if (this.onScreenKeyboard) {
                            return false;
                        } else {
                            this.$emit("submit");
                            return true;
                        }
                    },
                    "Shift-Enter": ({ editor }) =>
                        editor.commands.first(({ commands }) => [
                            () => commands.newlineInCode(),
                            () => commands.splitListItem("listItem"),
                            () => commands.createParagraphNear(),
                            () => commands.liftEmptyBlock(),
                            () => commands.splitBlock(),
                        ]),
                };
            },
        });

        this.editor = new Editor({
            content: this.value,
            extensions: [
                Document,
                Paragraph.extend({
                    renderHTML({ HTMLAttributes }) {
                        return ["div", HTMLAttributes, 0];
                    },
                }),
                History,
                Text,
                Placeholder.configure({
                    placeholder: "Your message...",
                }),
                //ShiftEnter,
                HardBreak,
                //OrderedList,
                BulletList.configure({
                    keepMarks: false,
                }),
                ListItem,
                Bold.extend({
                    renderHTML({ HTMLAttributes }) {
                        return ["b", HTMLAttributes, 0];
                    },
                }),
                Underline,
                Italic.extend({
                    renderHTML({ HTMLAttributes }) {
                        return ["i", HTMLAttributes, 0];
                    },
                }),
                CustomEnter,
                Link,
                Youtube.configure({
                    width: "100%",
                    height: 300,
                    inline: false,
                }),
                CharacterCount.configure({
                    limit: this.characterLimit,
                }),
                //Strike,
                /*Emoji.configure({
                    emojis: gitHubEmojis,
                    enableEmoticons: true,
                }),*/
            ],
            autofocus: this.autofocus,
            editable: true,
            editorProps: {
                attributes: {
                    class: "pa-2 focus:outline-none",
                },
            },
            onUpdate: () => {
                // HTML
                this.$emit("input", this.editor.getHTML());

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },
    watch: {
        processing(val) {
            this.editor.options.editable = !val;
        },
    },
};
</script>

<style scoped>
.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.tiptap {
    ul,
    ol {
        list-style: unset;
    }
}

.tip-tap-submit {
    transform: translate(0, -4px);
}

.ProseMirror div.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

/*https://github.com/ueberdosis/tiptap/issues/4571*/

::v-deep .ProseMirror {
    max-height: 50vh;
    max-height: 50svh;
    overflow-y: auto;

    @media screen and (max-width: 960px) {
        max-height: 200px;
        overflow-y: auto;
    }
}
</style>
